import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";

const UPLOAD_PROSPECT_ONBOARDING_DOCUMENT = gql`
query($input: ProspectDocumentUploadUrlInput!) {
  getProspectOnboardingDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

export const uploadProspectOnboardingDocument = ({
  document,
  prospectId,
  documentType,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_PENDING,
    });

    const fileSize = bytesToGigabytes(document.size);

    if (fileSize > 5) {
      throw "You can only upload files up to 5GB";
    }

    const response = await apolloClient.query({
      query: UPLOAD_PROSPECT_ONBOARDING_DOCUMENT,
      variables: {
        input: {
          prospect_id: prospectId,
          document_type: documentType,
          filename: document.name,
        },
      },
    });
    const uploadUrl = response.data.getProspectOnboardingDocumentUploadUrl;

    await fetch(uploadUrl.url, {
      method: "PUT",
      body: document,
    });

    dispatch({
      type: types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_SUCCESS,
      payload: {
        document,
      },
    });

    return document.name;
  }
  catch (err) {
    dispatch({
      type: types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
