export const REACT_APP_COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;
export const REACT_APP_GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const REACT_APP_BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;
export const REACT_APP_IS_DEMO = process.env.REACT_APP_IS_DEMO === "true";
export const ITEMS_PER_PAGE = [25, 50, 100];
export const DEFAULT_ITEMS_PER_PAGE = 25;
export const EXCLUSIVE_VAT_LABEL = "excl. VAT";
export const INCLUSIVE_VAT_LABEL = "incl. VAT";

// Feature flags
export const REACT_APP_FEATURE_FLAG_SHOW_TRANSFER_TAB = process.env.REACT_APP_FEATURE_FLAG_SHOW_TRANSFER_TAB === "true";

export default {
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_BUILD_NUMBER,
  ITEMS_PER_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
  EXCLUSIVE_VAT_LABEL,
  INCLUSIVE_VAT_LABEL,
};
