import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { SortIndicator } from "../../../common/components";
import AgencyRow from "./AgencyRow/AgencyRow";
import styles from "./AgencyList.module.scss";

export default function AgencyList(props) {
  const handleColumnClicked = (name) => {
    props.onColumnClick(name, props.sortOrder[name] || "NONE");
  };

  return (
    <Table
      className={styles.container}
      basic
    >
      <Table.Header>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("known_as")}
        >
          <div>
            Agency Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.known_as}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("exact_legal_name")}
        >
          <div>
          Exact Legal Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.exact_legal_name}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("short_code")}
        >
          <div>
          Short Code
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.short_code}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          Type of Worker
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("agency_status_id")}
        >
          <div>
          Status
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.agency_status_id}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* This is for the expand icon */}
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {props.agencies?.map((agency, index) => {
          const isExpanded = props.expandedRows.includes(agency.id);

          return (
            <AgencyRow
              index={index}
              key={`agency_row_${agency.id}`}
              agency={agency}
              expanded={isExpanded}
              onRowClick={() => {
                if (isExpanded) {
                  props.onRowClick(props.expandedRows.filter(x => x !== agency.id));
                }
                else {
                  props.onRowClick([...props.expandedRows, agency.id]);
                }
              }}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
}

AgencyList.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  expandedRows: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  sortOrder: PropTypes.shape({
    known_as: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    exact_legal_name: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    short_code: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    agency_status_id: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
  }).isRequired,
  onColumnClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
