import _ from "lodash";

/**
 * @param {object} obj
 * @param {string} obj.subtaskKey
 * @param {number} obj.minLength
 * @returns
 */
const getValidationMessage = ({
  subtaskKey,
  minLength,
} = {}) => {
  switch (subtaskKey) {
    case "vat_registration_number":
      return `UK VAT Numbers should include ${minLength-2} numbers`;

    case "sort_code":
      return `Sort codes must be ${minLength-2} numbers`;

    default:
      return `Must be at least ${minLength} characters`;
  }
};

/**
 * Checks to see if the task is valid.
 *
 * This determines if the task can be completed.
 *
 * @param {object} obj
 * @param {object} obj.field
 * @param {string} obj.field.type
 * @param {any} obj.field.value
 * @param {boolean} obj.field.minLength
 * @param {string} obj.subtaskKey
 * @param {any} obj.state The state of the onboarding task
 * @returns
 */
export const isSubtaskValid = ({
  field,
  subtaskKey,
  state,
} = {}) => {
  let success = true;
  let validationErrors = {};

  if (field?.minLength) {
    if (state[subtaskKey] && state[subtaskKey].length < field.minLength) {
      success = false;
      validationErrors = _.set(validationErrors, subtaskKey, getValidationMessage({ subtaskKey, minLength: field.minLength }));
    }
  }

  if (field?.type === "definelist") {
    // Check for any duplicates in the list (case-insensitive)
    const hasNoDuplicates = state[subtaskKey].every((fieldValue) => {
      const duplicates = _.reduce(state[subtaskKey], (r, v) => {
        return r += (_.lowerCase(v) === _.lowerCase(fieldValue)) ? 1 : 0;
      }, -1);

      return duplicates === 0;
    });

    if (!hasNoDuplicates) {
      success = false;
      validationErrors = _.set(validationErrors, subtaskKey, "You cannot have duplicate values");
    }
  }

  /* --- Rules for specific fields --- */

  if (field?.name === "account_number") {
    // This is a soft warning that does not prevent progress so we will not set success to false for it.
    if (state["account_number"] !== null && state["account_number"].length > 8) {
      validationErrors = _.set(validationErrors, subtaskKey, "Please check the number entered, most UK bank account numbers are 8 digits long");
    }
  }

  return {
    success,
    validationErrors,
  };
};
