import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Divider, Message } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { Add, InfoOutlined, ReportProblemOutlined } from "@material-ui/icons";
import RecipientInput from "./RecipientInput/RecipientInput";
import RecipientList from "./RecipientList/RecipientList";
import CommunicateToAgencyModalValidator from "./CommunicateToAgencyModalValidator";
import { Alert, ConfirmationModal, Form, IconButton } from "../../../common/components";
import { validators } from "../../../../utils";
import styles from "./CommunicateToAgencyModal.module.scss";

const RECIPIENT_TYPES = [{
  label: "To",
  value: "to",
}, {
  label: "CC",
  value: "cc",
}, {
  label: "BCC",
  value: "bcc",
}];

const createEmptyRecipient = () => ({
  contactId: null,
  recipientType: "to",
  email: null,
});

export default function CommunicateToAgencyModal(props) {
  const [recipients, setRecipients] = useState([
    { ...createEmptyRecipient(), recipientType: "to" },
  ]);
  const [errors, setErrors] = useState({});
  const [newContactFormData, setNewContactFormData] = useState(null);
  const [newContacts, setNewContacts] = useState([]);

  useEffect(() => {
    props.getAgencyContacts({ agencyId: props.agencyId });
  }, []);

  const onAddNewRecipient = useCallback(
    () => {
      setRecipients([...recipients, createEmptyRecipient()]);
      setErrors({});
    },
    [recipients, errors],
  );

  // When adding a new contact we have to integrate that new contact into the
  // recipients array and store it in an array of new contacts to pass to the API.
  // This has been refactored out of 'onAddNewContact' to remove the state and allow
  // it to be used in the submit function.
  const mergeNewContact = ({ recipients, newContactFormData, newContacts }) => {
    // Give the new contact a proper id
    const id = v4();
    const { firstName, lastName, preferredName, email } = newContactFormData;
    const contact = {
      first_name: firstName,
      last_name: lastName,
      preferred_name: preferredName,
      email,
      id,
    };

    const editIndex = recipients.length - 1;

    // Add the new contact to the newContacts array
    // and update the recipient to use its new id
    const updatedContacts = [...newContacts, contact];
    const updatedRecipients = Object.assign([...recipients], {
      [editIndex]: {
        ...recipients[editIndex],
        contactId: id,
        email,
      },
    });

    return {
      updatedContacts,
      updatedRecipients,
    };
  };

  const onAddNewContact = useCallback(
    () => {
      const { updatedContacts, updatedRecipients } = mergeNewContact({
        recipients,
        newContactFormData,
        newContacts,
      });

      setNewContacts(updatedContacts);
      setRecipients(
        [
          ...updatedRecipients,
          createEmptyRecipient(),
        ]
      );
      setNewContactFormData(null);
      setErrors({});
    },
    [recipients, errors, newContactFormData, newContacts],
  );

  const onRemoveRecipient = useCallback(
    (index) => {
      // In case the user was creating a new contact
      if (index === editIndex) {
        setNewContactFormData(null);
      }

      // In case the removed recipient was a new contact
      setNewContacts(newContacts.filter(contact => contact.id !== recipients[index].contactId));

      setRecipients(recipients
        .filter((_r, i) => i !== index)
        .map((r, i) => i === 0 ? { ...r, recipientType: "to" } : r)
      );
      setErrors({});
    },
    [recipients, errors, newContacts],
  );

  const onRecipientChange = useCallback(
    (recipient) => {
      if (recipient.contactId === -1 && !newContactFormData) {
        setNewContactFormData({});
      }
      if (recipient.contactId !== -1 && newContactFormData) {
        setNewContactFormData(null);
      }

      const editIndex = recipients.length - 1;

      setRecipients(Object.assign([...recipients], {
        [editIndex]: recipient,
      }));

      setErrors({});
    },
    [recipients, errors],
  );

  const handleSubmit = () => {
    // Where we're going, we don't need state.
    let _recipients = recipients;
    let _newContacts = newContacts;

    // If we're currently editing a contact we need to add it to the
    // locally scoped recipients and newContacts arrays so it's
    // immediately available for the action.
    if (newContactFormData !== null) {
      const { updatedContacts, updatedRecipients } = mergeNewContact({
        recipients,
        newContactFormData,
        newContacts,
      });

      _recipients = updatedRecipients;
      _newContacts = updatedContacts;
    }

    const validator = new CommunicateToAgencyModalValidator(_recipients);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.sendCreditRequestResultNotification({
      recipients: _recipients,
      newContacts: _newContacts,
      agencyId: props.agencyId,
      creditRequestId: props.creditRequestId,
      redirectUrl: `/credit-limit-management/legal-entities/${props.legalEntityId}/credit-management`,
    });
    props.hideModal(CommunicateToAgencyModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(CommunicateToAgencyModal.displayName);
  };

  const handleNewContactFormChange = e => setNewContactFormData({ ...newContactFormData, [e.target.name]: e.target.value });

  const canSubmit = () => {
    return recipients.length > 0 && recipients.every((x) => {
      if (!x.contactId) return false;
      if (!x.recipientType) return false;
      if (x.email === "") return false;
      if (x.email && !validators.EmailValidator.validate(x.email)) return false;
      return true;
    });
  };

  const canAddAnotherRecipient = () => {
    return canSubmit();
  };

  const canAddNewContact = () => {
    if (!newContactFormData) {
      return false;
    }
    if (!newContactFormData.firstName) {
      return false;
    }
    if (!newContactFormData.lastName) {
      return false;
    }
    if (!newContactFormData.email || !validators.EmailValidator.validate(newContactFormData.email)) {
      return false;
    }
    if (!recipients[editIndex].contactId) {
      return false;
    }
    if (!recipients[editIndex].recipientType) {
      return false;
    }
    return true;
  };

  const editIndex = recipients.length - 1;

  return (
    <ConfirmationModal
      className={styles.container}
      title="Communicate to Agency"
      visible={props.visible}
      submitText="Communicate"
      submitDisabled={!(canSubmit() || canAddNewContact())}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Please select the agency contacts that you wish to communicate to.
      </div>
      {props.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          {!props.creditController && (
            <Message className={styles.message}>
              <ReportProblemOutlined  className={styles.warningIcon} />
              There is no credit controller assigned to this agency.
            </Message>
          )}
          <Form className={styles.form} requiredKey>
            <Form.Section>
              <Form.Group className={styles.recipientsGroup} inline wide>
                <Form.Label className={styles.recipientsLabel} inline required>
                  Select Recipient(s)
                </Form.Label>
                <div>
                  <Form.Value>
                    {recipients.length > 0 && (
                      <RecipientList
                        contacts={[...props.contacts, ...newContacts]}
                        recipients={recipients.slice(0, recipients.length - 1)}
                        recipientTypes={RECIPIENT_TYPES}
                        onRemoveRecipient={onRemoveRecipient}
                      />
                    )}
                    <RecipientInput
                      key={editIndex}
                      index={editIndex}
                      recipientTypes={RECIPIENT_TYPES}
                      contacts={props.contacts}
                      contactId={recipients[editIndex].contactId}
                      recipientType={recipients[editIndex].recipientType}
                      email={recipients[editIndex].email}
                      disabledContacts={recipients.map(r => r.contactId)}
                      highlightError={errors.recipient}
                      onChange={onRecipientChange}
                      onRemove={() => onRemoveRecipient(editIndex)}
                      showNewContactOption
                    />
                  </Form.Value>
                  {!newContactFormData && (
                    <IconButton
                      className={styles.addButton}
                      icon={(<Add />)}
                      iconPosition="left"
                      text="Add Another Recipient"
                      disabled={!canAddAnotherRecipient()}
                      onClick={onAddNewRecipient}
                    />
                  )}
                </div>
              </Form.Group>

              {newContactFormData && (
                <>
                  <Form.Group className={styles.newContactFormGroup} inline wide>
                    <Form.Label className={styles.newContactFormLabel} inline required>
                      First Name
                    </Form.Label>
                    <Form.Value fluid>
                      <Form.Input
                        name="firstName"
                        placeholder="First name..."
                        value={newContactFormData.firstName}
                        fluid
                        onChange={handleNewContactFormChange}
                      />
                    </Form.Value>
                  </Form.Group>

                  <Form.Group className={styles.newContactFormGroup} inline wide>
                    <Form.Label className={styles.newContactFormLabel} inline required>
                        Last Name
                    </Form.Label>
                    <Form.Value fluid>
                      <Form.Input
                        name="lastName"
                        placeholder="Last name..."
                        value={newContactFormData.lastName}
                        fluid
                        onChange={handleNewContactFormChange}
                      />
                    </Form.Value>
                  </Form.Group>

                  <Form.Group className={styles.newContactFormGroup} inline wide>
                    <Form.Label className={styles.newContactFormLabel} inline>
                      Preferred Name
                    </Form.Label>
                    <Form.Value fluid>
                      <Form.Input
                        name="preferredName"
                        placeholder="Preferred name (optional)..."
                        value={newContactFormData.preferredName}
                        fluid
                        onChange={handleNewContactFormChange}
                      />
                    </Form.Value>
                  </Form.Group>

                  <Form.Group className={styles.newContactFormGroup} inline wide>
                    <Form.Label className={styles.newContactFormLabel} inline required>
                      Email Address
                    </Form.Label>
                    <div>
                      <Form.Value fluid>
                        <Form.Input
                          name="email"
                          placeholder="Email address..."
                          value={newContactFormData.email}
                          fluid
                          onChange={handleNewContactFormChange}
                        />
                      </Form.Value>
                      <IconButton
                        className={styles.addButton}
                        icon={(<Add />)}
                        iconPosition="left"
                        text="Add Another Recipient"
                        disabled={!canAddNewContact()}
                        onClick={onAddNewContact}
                      />
                    </div>
                  </Form.Group>
                </>
              )}

            </Form.Section>
          </Form>
        </>
      )}
      <Divider fitted />
      {errors.recipient && (
        <Alert variant="error" showClose={false}>
          <div className={styles.errorContent}>
            <InfoOutlined />
            <strong>No contact selected</strong> Please select a contact or remove the field
          </div>
        </Alert>
      )}
    </ConfirmationModal>
  );
}

CommunicateToAgencyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  agencyId: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string.isRequired,
  creditRequestId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  contacts: PropTypes.array.isRequired,
  creditController: PropTypes.object,
  getAgencyContacts: PropTypes.func.isRequired,
  getCreditController: PropTypes.func.isRequired,
  sendCreditRequestResultNotification: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

CommunicateToAgencyModal.displayName = "CommunicateToAgencyModal";

