import {
  completeCreditApplication,
  createCreditApplication,
  showInsurerApplicationCreatedModal,
} from ".";
import { getUsersName } from "../../../../utils";

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 * @param {number} obj.amountApproved
 * @param {string} obj.notes
 * @param {string} obj.status
 * @param {string} obj.useDiscretionaryLimit
 * @param {Date} obj.dateOfResult
 * @param {string} obj.legalEntityId
 * @param {string} obj.createdBy
 * @returns
 */
export const goToInsurerFromBroker = obj => async (dispatch) => {
  const amountApproved = parseInt(obj.amountApproved, 10);

  dispatch(completeCreditApplication({
    creditApplicationId: obj.creditApplicationId,
    amountApproved: null,
    notes: obj.notes,
    status: obj.status,
    useDiscretionaryLimit: obj.useDiscretionaryLimit,
    dateOfResult: obj.dateOfResult,
  }));

  dispatch(createCreditApplication({
    legalEntityId: obj.legalEntityId,
    amountNeeded: amountApproved,
    requestedBy: getUsersName(obj.createdBy),
    dateOfRequest: obj.dateOfResult,
    source: "insurer",
  }));

  dispatch(showInsurerApplicationCreatedModal({
    requestedAmount: amountApproved,
  }));
};
