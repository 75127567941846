import _ from "lodash";
import { PhoneNumberValidator, UrlValidator } from "../../../../../utils/validators";

export default class PartnershipLegalEntityFormValidator {
  constructor(data) {
    this.data=  data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data.name)) {
      errors.name = "Partnership Name is required";
    }

    if (!_.isEmpty(data.website) && !UrlValidator.validate(data.website)) {
      errors.website = "Website URL is not valid";
    }

    errors.partners = data.partners.map(this._validatePartner);

    if (errors.partners.some(error => Object.keys(error).length > 0)) {
      return {
        success: false,
        errors,
      };
    }

    return {
      success: Object.keys(_.omit(errors, "partners")).length === 0,
      errors,
    };
  }

  _validatePartner(partner) {
    const error = {};

    if (_.isEmpty(partner.title)) {
      error.title = "Title is required";
    }

    if (_.isEmpty(partner.first_name)) {
      error.first_name = "First Name is required";
    }

    if (_.isEmpty(partner.last_name)) {
      error.last_name = "Last Name is required";
    }

    if (!_.isEmpty(partner.phone_number) && !PhoneNumberValidator.validate(partner.phone_number)) {
      error.phone_number = "Phone Number is not valid";
    }

    if (_.isEmpty(partner.address.address_line_one)) {
      error.address = {
        ...error.address,
        address_line_one: "Address Line 1 is required",
      };
    }

    if (_.isEmpty(partner.address.town_or_city)) {
      error.address = {
        ...error.address,
        town_or_city: "Town / City is required",
      };
    }

    if (_.isEmpty(partner.address.county)) {
      error.address = {
        ...error.address,
        county: "County is required",
      };
    }

    if (_.isEmpty(partner.address.postcode)) {
      error.address = {
        ...error.address,
        postcode: "Postcode is required",
      };
    }

    return error;
  }
}
