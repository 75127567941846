import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { actions } from "../../../documents/duck";
import { Alert, Button, ConfirmationModal } from "../../../common/components";
import { DocumentList } from "../../../documents/components";
import styles from "./DocumentsTab.module.scss";
import { ErrorOutline } from "@material-ui/icons";

class DocumentsTab extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    agencyDocuments: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      hasNextPage: PropTypes.bool.isRequired,
      documents: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired,
        lastModified: PropTypes.number.isRequired,
      })),
      folders: PropTypes.arrayOf(PropTypes.string),
      error: PropTypes.string,
    }),
    documentTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })),
    getAgencyDocuments: PropTypes.func.isRequired,
    downloadAgencyDocument: PropTypes.func.isRequired,
    removeAgencyDocument: PropTypes.func.isRequired,
    getAgencyDocumentTypes: PropTypes.func.isRequired,
    uploadAgencyDocument: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  state = {
    showRemoveConfirmationModal: false,
    toBeRemoved: {},
    selectedUploadType: { id: 0, type: "" },
  }

  fileUpload = React.createRef();

  componentDidMount() {
    const { agencyId, getAgencyDocuments, getAgencyDocumentTypes, match, onLoad } = this.props;

    getAgencyDocuments(agencyId);
    getAgencyDocumentTypes();
    onLoad && onLoad(match.path);
  }

  render() {
    const { showRemoveConfirmationModal, toBeRemoved } = this.state;
    const { loading, documents, folders, error } = this.props.agencyDocuments;

    const docs = documents?.reduce((prev,next) => {
      const value = [...prev, { ...next, version: next.versions.length + 1 }];
      const versions = next.versions
        // It seems when deployed they come back in a different order so we order to make sure
        .sort((a, b) => a.metadata.date_uploaded - b.metadata.date_uploaded)
        .map((value, index) => ({ ...value, version: index + 1 }))
        .reverse(); // When done adding version numbers, we want newest first
      return [...value, ...versions];
    }, []);
    const convertedFolders = folders?.map(folderName => ({ name: folderName, type: "folder" }));
    const convertedDocuments = docs?.map(doc => ({ ...doc, type: "document" }));
    const tableData = convertedFolders?.concat(convertedDocuments);

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <input type="file" id="file" ref={ref => this.fileUpload = ref} onChange={this.uploadFile} className={styles.hiddenInput}/>
          <Dropdown
            trigger={
              <Button className={styles.button} variant="primary" onClick={() => {}}>
                Add Document
              </Button>
            }
            icon={null}
            floating
            scrolling
            direction="left"
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Select Document Type" />
              {this.props.documentTypes.map(documentType => (
                <Dropdown.Item
                  key={documentType}
                  text={documentType.type}
                  value={documentType.id}
                  onClick={() => this.onDocumentTypeClicked(documentType)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <DocumentList
          loading={loading}
          documents={tableData}
          onDownloadClick={this.onDownloadClicked}
          onRemoveClick={this.onRemoveClicked}
        />
        <ConfirmationModal
          visible={showRemoveConfirmationModal}
          title="Delete Item"
          onSubmit={this.handleFileRemoval}
          onCancel={() => this.setState({ showRemoveConfirmationModal: false })}
        >
          <div>
            Are you sure you want to delete <strong>{toBeRemoved.metadata?.document_type || toBeRemoved.name}</strong>?
          </div>
        </ConfirmationModal>
        {error && (
          <Alert
            variant="error"
            style={{
              position: "fixed",
              bottom: "40px",
              left: "50%",
              transform: "translateX(-50%)",
              marginLeft: "142px",
              alignItems: "baseline",
            }}
          >
            <div className={styles.error}>
              <div className={styles.title}>
                <ErrorOutline className={styles.errorIcon} />
                Document upload failed
              </div>
              <div className={styles.content}>
                {error}
              </div>
            </div>
          </Alert>
        )}
      </div>
    );
  }

  onDocumentTypeClicked = (item) => {
    this.setState({
      selectedUploadType: item,
    });

    this.fileUpload.click();
  }

  uploadFile = async (e) => {
    const { selectedUploadType } = this.state;
    const { agencyId, uploadAgencyDocument } = this.props;
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    await uploadAgencyDocument(file, agencyId, selectedUploadType.type);

    this.setState({ selectedUploadType: { id: 0, type: "" } });
  }

  onDownloadClicked = (row) => {
    const { agencyId, downloadAgencyDocument } = this.props;

    downloadAgencyDocument(agencyId, row.name, row.versionId);
  }

  onRemoveClicked = (row) => {
    this.setState({
      toBeRemoved: row,
      showRemoveConfirmationModal: true,
    });
  }

  handleFileRemoval = async() => {
    const { toBeRemoved } = this.state;
    const { agencyId, removeAgencyDocument, getAgencyDocuments } = this.props;

    await removeAgencyDocument(agencyId, toBeRemoved);
    await getAgencyDocuments(agencyId);

    this.setState({
      toBeRemoved: {},
      showRemoveConfirmationModal: false,
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.documents,
  agencyId: ownProps.match.params.agencyId,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);
