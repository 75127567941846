import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import PerformCreditCheckPage from "./PerformCreditCheckPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  initialQuestions: ownProps.location?.state?.data,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchAgencyCustomers: actions.searchAgencyCustomers,
  }, dispatch),
  onCancel: () => {
    dispatch(push("/credit-limit-management"));
  },
  onGoToAddNewCompany: (data) => {
    dispatch(push("/credit-limit-management/registered-company/new", {
      data,
    }));
  },
  onGoToLegalEntity: (id) => {
    dispatch(push(`/credit-limit-management/legal-entities/${id}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformCreditCheckPage);
