import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../duck";
import { actions as documentActions } from "../../../documents/duck";
import { PayrollView } from "../../components";
import { ConfirmationModal } from "../../../common/components";
import styles from "./PayrollTab.module.scss";

// Conditional upon worker types (from onboarding):
// *only applicable for agencies with PAYE workers
// **only applicable for agencies with limited/umbrella workers

export class PayrollTab extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      can_remove_worker_types: PropTypes.bool.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    payrollInfo: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object,
      workerTypes: PropTypes.array,
      error: PropTypes.string,
      hasNewOnboardingTasks: PropTypes.bool,
    }).isRequired,
    payrollHistory: PropTypes.shape({
      data: PropTypes.object,
    }),
    agencyDisabledFields: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.string,
      ).isRequired,
    }).isRequired,
    hasNewOnboardingTasks: PropTypes.bool,
    needsTransferring: PropTypes.bool,
    getPayrollHistory: PropTypes.func.isRequired,
    getPayrollInfo: PropTypes.func.isRequired,
    downloadAgencyDocument: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: props.hasNewOnboardingTasks,
      showTransferModal: props.needsTransferring,
    };
  }

  async componentDidMount() {
    const { agencyId, getPayrollHistory, getPayrollInfo, onLoad, match } = this.props;

    await Promise.all([
      getPayrollHistory({ agencyId }),
      getPayrollInfo(agencyId),
    ]);

    onLoad && onLoad(match.path);
  }

  componentWillReceiveProps(props) {
    const { agencyId, payrollInfo, history } = props;

    if (_.isNil(payrollInfo.hasNewOnboardingTasks)) return;

    this.setState({
      showConfirmationModal: payrollInfo.hasNewOnboardingTasks,
    });

    if (!payrollInfo.hasNewOnboardingTasks) {
      history.push(`/agencies/${agencyId}/payroll`);
    }
  }

  render() {
    const {
      showConfirmationModal,
      showTransferModal,
    } = this.state;
    const {
      payrollInfo: { loading, data, workerTypes },
      payrollHistory,
      agencyDisabledFields,
    } = this.props;

    if (loading) return <ActivityIndicator />;
    // if (error) return <Alert className={styles.error} variant="error" text={error} showClose={false} />;

    return (
      <>
        <ConfirmationModal
          title="Onboarding Updated"
          visible={showConfirmationModal}
          submitText="Okay"
          cancelText="Go To Onboarding"
          cancelVariant="outline-primary"
          onSubmit={this.handleOkay}
          onCancel={this.handleGoToOnboarding}
        >
          This change has added new task(s) to the Onboarding tasks for this agency.
        </ConfirmationModal>
        <ConfirmationModal
          title="Transfer Required"
          visible={showTransferModal}
          submitText="Okay"
          onSubmit={this.handleCloseTransferModal}
        >
          <div>
            You will need to perform another <strong>Transfer to Payroll</strong> due to the change of <strong>Payroll Frequency</strong>.
          </div>
        </ConfirmationModal>
        {data && (
          <PayrollView
            className={styles.container}
            payrollInfo={data}
            payrollHistory={payrollHistory.data}
            workerTypes={workerTypes}
            vatIsDisabled={agencyDisabledFields?.data.some(item => (
              item.disabled_field === "vat_registration_number"
            ))}
            onLoad={this.handleLoad}
            onEdit={this.handleEdit}
            onDownload={this.handleDownload}
          />
        )}
      </>
    );
  }

  handleLoad = async (data) => {
    const { agencyId, getAgencyDisabledFields } = this.props;

    await getAgencyDisabledFields(agencyId, data);
  }

  handleEdit = (section) => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/edit/payroll/${section}`);
  }

  handleOkay = () => {
    const { agencyId, history } = this.props;

    this.setState({
      showConfirmationModal: false,
    });

    // TODO: Remove when we remove the Edit Details button
    history.push(`/agencies/${agencyId}/payroll`);
  }

  handleGoToOnboarding = () => {
    const { agencyId, history } = this.props;

    this.setState({
      showConfirmationModal: false,
    });

    history.push(`/onboarding/agencies/${agencyId}`);
  }

  handleCloseTransferModal = () => {
    this.setState({
      showTransferModal: false,
    });
  }

  handleDownload = async (key) => {
    const { agencyId, downloadAgencyDocument } = this.props;

    downloadAgencyDocument(agencyId, key.name);
  }

  handleDisabledFieldClicked = (disabledField) => {
    const { history, agencyId } = this.props;

    history.push(`/onboarding/agencies/${agencyId}/${disabledField.agency_onboarding_task_id}`);
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
  hasNewOnboardingTasks: state.router.location.state?.hasNewOnboardingTasks,
  needsTransferring: state.router.location.state?.needsTransferring,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...documentActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTab);
