import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as documentActions } from "../../../documents/duck";
import DocumentsTab from "./DocumentsTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.documents,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onFetchDocumentTypes: documentActions.getAgencyDocumentTypes,
  onFetchDocuments: documentActions.getProspectDocuments,
  onDownloadDocument: documentActions.downloadProspectDocument,
  onUploadDocument: documentActions.uploadProspectDocument,
  onRemoveDocument: documentActions.removeProspectDocument,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);
