import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { capitalize, formatAnswer, parseDate } from "utils";
import { Edit } from "@material-ui/icons";
import {
  DocumentDownload,
  Form,
  IconButton,
  MaskedValue,
  SeeHistoryTooltip,
} from "../../../common/components";
import { List } from "../../../lists/containers";
import { DOCUMENT_TYPES } from "../../../documents";
import { options } from "../../../../constants";
import styles from "./PayrollView.module.scss";

export default class PayrollView extends Component {

  static propTypes = {
    className: PropTypes.string,
    workerTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    payrollInfo: PropTypes.shape({
      vat_number: PropTypes.string,
      auto_enrolment_pension_info: PropTypes.shape({
        pension_company: PropTypes.shape({
          label: PropTypes.string.isRequired,
        }),
        pension_scheme_weekly: PropTypes.string,
        pension_scheme_monthly: PropTypes.string,
        staging_date: PropTypes.number,
        payment_source_reference_weekly: PropTypes.string,
        payment_source_reference_monthly: PropTypes.string,
        default_postponement_months: PropTypes.number,
        eligible_job_holder_postponement_months: PropTypes.number,
        employer_pension_reference: PropTypes.string,
      }),
      is_existing_paye_scheme: PropTypes.bool,
      vat_period_ending_date: PropTypes.string,
      vat_frequency: PropTypes.string,
      next_vat_return_period_end: PropTypes.string,
      applying_registered_vat: PropTypes.bool,
      previous_pension_provider: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }),
      is_new_agency: PropTypes.bool.isRequired,
      hmrc_info: PropTypes.shape({
        paye_scheme_reference: PropTypes.string,
        accounts_office_reference: PropTypes.string,
        claiming_employers_ni_allowance: PropTypes.bool,
        small_employers_relief: PropTypes.bool,
        intermediary_reporting: PropTypes.bool,
        apprenticeship_levy: PropTypes.bool,
      }),
      holiday_pay_info: PropTypes.shape({
        has_holiday_pot: PropTypes.bool,
        end_of_holiday_year_pay_period: PropTypes.number,
        end_of_holiday_year_tax_year_end: PropTypes.number,
        can_carry_over_accrual: PropTypes.bool,
        default_holiday_scheme: PropTypes.shape({
          label: PropTypes.string,
        }),
        holiday_accrual_period: PropTypes.shape({
          label: PropTypes.string,
        }),
      }),
      preferences: PropTypes.shape({
        payroll_frequency: PropTypes.oneOf([
          "monthly",
          "weekly",
          "both",
        ]),
        default_payslip_preference: PropTypes.string,
        default_customer_vat_rate: PropTypes.number,
      }),
      worker_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
      })),
      pensionDocument: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      vatDocument: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    payrollHistory: PropTypes.shape({
      default_holiday_scheme: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      end_of_holiday_year: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      applying_registerd_vat: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      payroll_frequency: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      vat_frequency: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      vat_period_ending_date: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    vatIsDisabled: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        worker_types: props.payrollInfo.worker_types?.map(x => x.id),
        pension_company: props.payrollInfo.auto_enrolment_pension_info?.pension_company?.label,
        pension_scheme_weekly: props.payrollInfo.auto_enrolment_pension_info?.pension_scheme_weekly,
        pension_scheme_monthly: props.payrollInfo.auto_enrolment_pension_info?.pension_scheme_monthly,
        staging_date: props.payrollInfo.auto_enrolment_pension_info?.staging_date,
        payment_source_reference_weekly: props.payrollInfo.auto_enrolment_pension_info?.payment_source_reference_weekly,
        payment_source_reference_monthly: props.payrollInfo.auto_enrolment_pension_info?.payment_source_reference_monthly,
        default_postponement_months: props.payrollInfo.auto_enrolment_pension_info?.default_postponement_months,
        eligible_job_holder_postponement_months: props.payrollInfo.auto_enrolment_pension_info?.eligible_job_holder_postponement_months,
        employer_pension_reference: props.payrollInfo.auto_enrolment_pension_info?.employer_pension_reference,
        is_existing_paye_scheme: props.payrollInfo.is_existing_paye_scheme,
        paye_scheme_reference: props.payrollInfo.hmrc_info?.paye_scheme_reference,
        accounts_office_reference: props.payrollInfo.hmrc_info?.accounts_office_reference,
        claiming_employers_ni_allowance: props.payrollInfo.hmrc_info?.claiming_employers_ni_allowance,
        small_employers_relief: props.payrollInfo.hmrc_info?.small_employers_relief,
        intermediary_reporting: props.payrollInfo.hmrc_info?.intermediary_reporting,
        apprenticeship_levy: props.payrollInfo.hmrc_info?.apprenticeship_levy,
        payroll_frequency: props.payrollInfo.preferences?.payroll_frequency,
        default_payslip_preference: props.payrollInfo.preferences?.default_payslip_preference,
        default_customer_vat_rate: props.payrollInfo.preferences?.default_customer_vat_rate,
        has_holiday_pot: props.payrollInfo.holiday_pay_info?.has_holiday_pot,
        default_holiday_scheme: props.payrollInfo.holiday_pay_info?.default_holiday_scheme?.label,
        holiday_accrual_period: props.payrollInfo.holiday_pay_info?.holiday_accrual_period?.label,
        end_of_holiday_year_pay_period: props.payrollInfo.holiday_pay_info?.end_of_holiday_year_pay_period,
        end_of_holiday_year_tax_year_end: props.payrollInfo.holiday_pay_info?.end_of_holiday_year_tax_year_end,
        can_carry_over_accrual: props.payrollInfo.holiday_pay_info?.can_carry_over_accrual,
        vat_number: props.payrollInfo.vat_number,
        vat_period_ending_date: props.payrollInfo.vat_period_ending_date,
        vat_frequency: props.payrollInfo.vat_frequency,
        next_vat_period_ending_date: props.payrollInfo.next_vat_return_period_end,
        applying_registered_vat: props.payrollInfo.applying_registered_vat,
        previous_pension_provider: props.payrollInfo.previous_pension_provider?.label,
      },
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;

    onLoad && onLoad(
      Object.keys(this.state.data)
    );
  }

  render() {
    const { payrollInfo } = this.props;

    const hasWTR = payrollInfo.worker_types?.some(x => x.name === "PAYE");

    return (
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.row}>
            {this.renderGeneralSection()}
            {this.renderPreferencesSection()}
          </div>
          <div className={classnames(styles.row, styles.sidle)}>
            {this.renderVatSection()}
            {hasWTR
              ? this.renderWtrHolidaySection()
              : this.renderHmrcSection()
            }
          </div>
          <div className={classnames(styles.row, styles.sidle)}>
            {hasWTR
              ? this.renderHmrcSection()
              : this.renderPensionsSection()
            }
            {hasWTR
              ? this.renderPensionsSection()
              : <div></div>
            }
          </div>
        </div>
      </div>
    );
  }

  renderGeneralSection = () => {
    const { data } = this.state;

    return (
      <Form.Card title="General" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("general")}
        />}
      >
        <Form.Group inline>
          <Form.Label inline>Type of Workers</Form.Label>
          <Form.ValueInline>
            <List
              identifier="worker_types"
              value={data.worker_types}
              checklistDisplayType="values"
              readOnly
            />
          </Form.ValueInline>
        </Form.Group>
      </Form.Card>
    );
  }

  renderHmrcSection = () => {
    const { data } = this.state;

    return (
      <Form.Card title="HMRC Online" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("hmrc-online")}
        />}
      >
        <Form.Group inline>
          <Form.Label inline>Existing PAYE Scheme?</Form.Label>
          <Form.ValueInline>{formatAnswer(data.is_existing_paye_scheme, "-")}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>PAYE Scheme Reference</Form.Label>
          <MaskedValue
            value={data.paye_scheme_reference}
            maskOptions={{ mask: "999/**********" }}
            style={{ fontSize: "16px", color: "#1a1a1a" }}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Accounts Office Reference</Form.Label>
          <Form.ValueInline>{data.accounts_office_reference || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Back Office to apply for Employment Allowance?</Form.Label>
          <Form.ValueInline>{formatAnswer(data.claiming_employers_ni_allowance, "-")}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Entitled to Small Employers Relief?</Form.Label>
          <Form.ValueInline>{formatAnswer(data.small_employers_relief, "-")}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Back Office to do Employment Intermediary Reporting (EIR)?</Form.Label>
          <Form.ValueInline>{formatAnswer(data.intermediary_reporting, "-")}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Does the Apprenticeship Levy apply?</Form.Label>
          <Form.ValueInline>{formatAnswer(data.apprenticeship_levy, "-")}</Form.ValueInline>
        </Form.Group>
      </Form.Card>
    );
  }

  renderPreferencesSection = () => {
    const { data } = this.state;

    return (
      <Form.Card title="Agency Preferences" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("agency-preferences")}
        />}
      >
        <Form.Group inline>
          <Form.Label inline>Payroll Frequency</Form.Label>
          <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.payroll_frequency}>
            <Form.ValueInline>
              {data.payroll_frequency === "both"
                ? "Weekly, Monthly"
                : (capitalize(data.payroll_frequency) || "-")
              }
            </Form.ValueInline>
          </SeeHistoryTooltip>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Default Payslip Preference</Form.Label>
          <Form.ValueInline>
            {this.renderDefaultPayslipPreference(data?.default_payslip_preference)}
          </Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Default Customer VAT Rate</Form.Label>
          <Form.ValueInline>{options.DEFAULT_CUSTOMER_VAT_OPTIONS.find(x => x.value === data.default_customer_vat_rate)?.label || "-"}</Form.ValueInline>
        </Form.Group>
      </Form.Card>
    );
  }

  renderPensionsSection = () => {
    const { data } = this.state;
    const { payrollInfo: { pensionDocument } } = this.props;
    const documents = [
      {
        name: DOCUMENT_TYPES.PENSION_DOCUMENT,
        file: pensionDocument,
      },
    ];
    const downloads = documents.filter(doc => doc.file);
    const showWeeklyFields = ["weekly", "both"].includes(data.payroll_frequency);
    const showMonthlyFields = ["monthly", "both"].includes(data.payroll_frequency);
    const showPaymentSourceReference = _.lowerCase(data.pension_company) === "nest";

    return (
      <Form.Card title="Auto-Enrolment Pensions" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("auto-enrolment-pensions")}
        />}
      >
        <div className={styles.contentWrapper}>
          { !this.props.payrollInfo.is_new_agency &&
            <Form.Group inline>
              <Form.Label inline>Previous Pension Provider</Form.Label>
              <Form.ValueInline>{data.previous_pension_provider || "-"}</Form.ValueInline>
            </Form.Group>
          }
          <Form.Group inline>
            <Form.Label inline>Pension Company</Form.Label>
            <Form.ValueInline>{data.pension_company || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>Staging Date</Form.Label>
            <Form.ValueInline>{parseDate(data.staging_date) || "-"}</Form.ValueInline>
          </Form.Group>
          {showWeeklyFields && (
            <Form.Group inline>
              <Form.Label inline>Pension Scheme (Weekly)</Form.Label>
              <Form.ValueInline>{data.pension_scheme_weekly || "-"}</Form.ValueInline>
            </Form.Group>
          )}
          {showMonthlyFields && (
            <Form.Group inline>
              <Form.Label inline>Pension Scheme (Monthly)</Form.Label>
              <Form.ValueInline>{data.pension_scheme_monthly || "-"}</Form.ValueInline>
            </Form.Group>
          )}
          <Form.Group inline>
            <Form.Label inline>Employer Pension Reference</Form.Label>
            <Form.ValueInline>{data.employer_pension_reference || "-"}</Form.ValueInline>
          </Form.Group>
          {showPaymentSourceReference && (
            <>
              {showWeeklyFields && (
                <Form.Group inline>
                  <Form.Label inline>Payment Source Reference (Weekly)</Form.Label>
                  <Form.ValueInline>{data.payment_source_reference_weekly || "-"}</Form.ValueInline>
                </Form.Group>
              )}
              {showMonthlyFields && (
                <Form.Group inline>
                  <Form.Label inline>Payment Source Reference (Monthly)</Form.Label>
                  <Form.ValueInline>{data.payment_source_reference_monthly || "-"}</Form.ValueInline>
                </Form.Group>
              )}
            </>
          )}
          <Form.Group inline>
            <Form.Label inline>Default Postponement Months</Form.Label>
            <Form.ValueInline>{data.default_postponement_months || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>Eligible Job Holder Postponement Months</Form.Label>
            <Form.ValueInline>{data.eligible_job_holder_postponement_months || "-"}</Form.ValueInline>
          </Form.Group>
          <DocumentDownload documents={downloads} onDownload={this.handleDownload} variant="footer" />
        </div>
      </Form.Card>
    );
  }

  renderWtrHolidaySection = () => {
    const { payrollInfo } = this.props;
    const { data } = this.state;

    if (!payrollInfo.worker_types?.some(x => x.name === "PAYE")) {
      return null;
    }

    return (
      <Form.Card title="WTR / Holiday" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("wtr-holiday")}
        />}
      >
        <Form.Group inline>
          <Form.Label inline>Holiday Pot</Form.Label>
          <Form.ValueInline>{formatAnswer(data.has_holiday_pot, "-")}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>
            Default Holiday Scheme
          </Form.Label>
          <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.default_holiday_scheme}>
            <Form.ValueInline>{data.default_holiday_scheme || "-"}</Form.ValueInline>
          </SeeHistoryTooltip>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Holiday Accrual Period</Form.Label>
          <Form.ValueInline>{data.holiday_accrual_period || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>
          End of Holiday Year<br />
          (Pay Period / Tax Year)
          </Form.Label>
          <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.end_of_holiday_year}>
            <Form.ValueInline>
              {data.end_of_holiday_year_pay_period && data.end_of_holiday_year_tax_year_end
                ? `${data.end_of_holiday_year_pay_period} ${data.end_of_holiday_year_tax_year_end}`
                : "-"
              }
            </Form.ValueInline>
          </SeeHistoryTooltip>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Carry Over Accrual</Form.Label>
          <Form.ValueInline>{formatAnswer(data.can_carry_over_accrual, "-")}</Form.ValueInline>
        </Form.Group>
      </Form.Card>
    );
  }

  renderVatSection = () => {
    const { payrollInfo: { vatDocument } } = this.props;
    const { data } = this.state;
    const documents = [
      {
        name: DOCUMENT_TYPES.VAT_CERTIFICATE,
        file: vatDocument,
      },
    ];
    const downloads = documents.filter(doc => doc.file);

    return (
      <Form.Card title="VAT" rightAccessory=
        {<IconButton
          icon={<Edit />}
          className={styles.edit}
          disabled={!this.props.onEdit}
          tooltip="Edit"
          onClick={() => this.handleEditClicked("vat-information")}
        />}
      >
        <div className={styles.contentWrapper}>
          <div>
            <Form.Group inline>
              <Form.Label inline>Agency applying / registered for VAT</Form.Label>
              <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.applying_registered_vat} currentValue={formatAnswer(data.applying_registered_vat)}>
                <Form.ValueInline>{formatAnswer(data.applying_registered_vat)}</Form.ValueInline>
              </SeeHistoryTooltip>
            </Form.Group>
            <Form.Group inline>
              <Form.Label inline>VAT Number</Form.Label>
              <MaskedValue value={data.vat_number} maskOptions={ { mask: "999 9999 99" } } style={ { fontSize: "16px", color: "#1a1a1a" } } />
            </Form.Group>
            <Form.Group inline>
              <Form.Label inline>VAT Period Ending Date</Form.Label>
              <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.vat_period_ending_date}>
                <Form.ValueInline>{parseDate(this.verifyVatValue(data.vat_period_ending_date)) || "-"}</Form.ValueInline>
              </SeeHistoryTooltip>
            </Form.Group>
            <Form.Group inline>
              <Form.Label inline>VAT Frequency</Form.Label>
              <SeeHistoryTooltip variant="compact" data={this.props.payrollHistory?.vat_frequency}>
                <Form.ValueInline>{capitalize(this.verifyVatValue(data.vat_frequency)) || "-"}</Form.ValueInline>
              </SeeHistoryTooltip>
            </Form.Group>
            <Form.Group inline>
              <Form.Label inline>Next VAT Return Period End</Form.Label>
              <Form.ValueInline>{parseDate(this.verifyVatValue(data.next_vat_period_ending_date)) || "-"}</Form.ValueInline>
            </Form.Group>
          </div>
          <DocumentDownload documents={downloads} onDownload={this.handleDownload} variant="footer" />
        </div>
      </Form.Card>
    );
  }

  verifyVatValue = (value) => {
    const { vatIsDisabled } = this.props;
    const { data: { applying_registered_vat } } = this.state;


    if (!value || vatIsDisabled) {
      return value;
    }

    return applying_registered_vat
      ? value
      : null;
  };

  renderDefaultPayslipPreference = (pref) => {
    return pref
      ? pref === "both" ? "Email, Post" : capitalize(pref)
      : "-";
  }

  handleEditClicked = (section) => {
    const { onEdit } = this.props;

    onEdit && onEdit(section);
  }

  handleDownload = (document) => {
    const { onDownload } = this.props;

    onDownload && onDownload(document);
  }
}
