import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_DETAILS = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectInput!
  $industry_sectors: [String]
) {
  updateProspect(id: $prospect_id, input: $input) {
    id
    known_as
    legal_name
    vat_number
    trading_as
    website
    linked_in
    twitter
    previous_provider {
      id
      label
      value
    }

    industry_sectors {
      name
    }
  }

  updateProspectIndustrySectors(prospect_id: $prospect_id, industry_sectors: $industry_sectors) {
    name
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.known_as
 * @param {string} obj.data.trading_as
 * @param {string} obj.data.vat_number
 * @param {string} obj.data.website
 * @param {string} obj.data.linked_in
 * @param {string} obj.data.twitter
 * @param {object} obj.data.previous_provider
 * @param {number} obj.data.previous_provider.id
 * @param {Array.<string>} obj.data.industry_sectors
 * @param {string} obj.redirectUrl
 *
 */
export const updateAgencyDetails = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_DETAILS,
      variables: {
        prospect_id: prospectId,
        input: {
          known_as: data.known_as,
          trading_as: data.trading_as,
          vat_number: data.vat_number,
          website: data.website,
          linked_in: data.linked_in,
          twitter: data.twitter,
          previous_provider_id: +data.previous_provider?.id,
        },
        industry_sectors: data.industry_sectors,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_SUCCESS,
      payload: {
        prospect: response.data.updateProspect,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
