import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import CreditCard from "./CreditCard/CreditCard";
import CreditChecks from "./CreditChecks/CreditChecks";
import RiskCard from "./RiskCard/RiskCard";
import {
  AgencyCustomerList,
  ManageCreditModal,
  ReportModal,
  SuspensionConfirmationModal,
} from "../../../components";
import { Button, ButtonGroup } from "../../../../common/components";
import styles from "./CreditOverview.module.scss";

export default class CreditOverview extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    legalEntity: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    creditOverview: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        credit_limit_excluding_vat: PropTypes.number,
        credit_limit_including_vat: PropTypes.number,
        undistributed_credit_limit: PropTypes.number,
        credit_suspended: PropTypes.bool,
        latest_experian_report: PropTypes.object,
        latest_insurer_report: PropTypes.object,
        latest_credit_review_date: PropTypes.object,
        experian_credit_checks: PropTypes.arrayOf(PropTypes.shape({
          approved_credit: PropTypes.number.isRequired,
          created_at: PropTypes.number.isRequired,
        })),
        credit_applications: PropTypes.arrayOf(PropTypes.shape({
          approved_credit_excluding_vat: PropTypes.number.isRequired,
          created_at: PropTypes.number.isRequired,
        })),
      }),
    }),
    agencyCustomers: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }),
    insurerReportingActivity: PropTypes.shape({
      loading: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    experianCreditCheckRequest: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        legal_entity: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        experian_credit_check: PropTypes.shape({
          experian_credit_result: PropTypes.number.isRequired,
          approved_credit: PropTypes.number.isRequired,
          approved_credit_including_vat: PropTypes.number.isRequired,
        }).isRequired,
        status: PropTypes.string.isRequired,
      }),
    }),
    globalDefaults: PropTypes.shape({
      data: PropTypes.shape({
        standard_vat_rate: PropTypes.string,
      }),
    }).isRequired,
    getCreditOverview: PropTypes.func.isRequired,
    getAgencyCustomers: PropTypes.func.isRequired,
    getGlobalDefault: PropTypes.func.isRequired,
    unsuspendCredit: PropTypes.func.isRequired,
    suspendCredit: PropTypes.func.isRequired,
    addInsurerReportingActivity: PropTypes.func.isRequired,
    addExperianCreditCheck: PropTypes.func.isRequired,
    createScheduledCreditAction: PropTypes.func.isRequired,
    createScheduledExperianAction: PropTypes.func.isRequired,
    createCreditApplication: PropTypes.func.isRequired,
    clearExperianCreditCheckRequest: PropTypes.func.isRequired,
    distributeCredit: PropTypes.func.isRequired,
    showDateOfNextReviewModal: PropTypes.func.isRequired,
  }

  state = {
    showSuspensionWarning: false,
    showCreditManagementModal: false,
    showReportModal: false,
  };

  async componentDidMount() {
    const { legalEntityId, getCreditOverview, getAgencyCustomers, getGlobalDefault } = this.props;

    await Promise.all([
      getCreditOverview({ legalEntityId }),
      getAgencyCustomers({ legalEntityId }),
      getGlobalDefault({ globalDefaultId: "standard_vat_rate" }),
    ]);
  }

  render() {
    const { creditOverview, agencyCustomers } = this.props;
    const isSuspended = creditOverview.data?.credit_suspended;

    return (
      <>
        <Grid className={styles.container}>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="middle">
              <Header>
                Credit Overview
              </Header>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <ButtonGroup className={styles.actions}>
                <Button
                  variant="outline-danger"
                  onClick={this.handleReportClicked}
                >
                  Report
                </Button>
                <Button
                  variant={isSuspended ? "outline-danger" : "danger"}
                  onClick={this.handleSuspendClicked}
                >
                  {isSuspended ? "Unsuspend" : "Suspend"}
                </Button>
                <Button
                  variant="primary"
                  onClick={this.handleManageCreditClicked}
                >
                  Manage Credit
                </Button>
              </ButtonGroup>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column width={4}>
              <CreditCard
                loading={creditOverview.loading}
                creditLimit={creditOverview.data?.credit_limit_including_vat}
                undistributedCredit={creditOverview.data?.undistributed_credit_limit}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <RiskCard
                loading={creditOverview.loading}
                totalDebt={0}
                totalExposure={0}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <CreditChecks
                loading={creditOverview.loading}
                latestExperianReport={creditOverview.data?.latest_experian_report}
                latestInsurerReport={creditOverview.data?.latest_insurer_report}
                nextReviewDate={creditOverview.data?.latest_credit_review_date}
                onEditDateOfNextReview={this.handleEditDateOfNextReview}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AgencyCustomerList
                data={agencyCustomers.data}
                onColumnClick={this.handleColumnClick}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderSuspensionConfirmationModal()}
        {this.renderReportModal()}
        {this.renderManageCreditModal()}
      </>
    );
  }

  renderSuspensionConfirmationModal = () => {
    const isSuspended = this.props.creditOverview.data?.credit_suspended;

    return (
      <SuspensionConfirmationModal
        isSuspending={!isSuspended}
        legalEntityName={this.props.legalEntity?.data?.name}
        visible={this.state.showSuspensionWarning}
        onSubmit={this.handleAcceptToggleSuspension}
        onCancel={this.handleDeclineToggleSuspension}
      />
    );
  }

  renderReportModal = () => {
    const { showReportModal } = this.state;
    const { currentUser, insurerReportingActivity } = this.props;

    if (!showReportModal) {
      return null;
    }

    return (
      <ReportModal
        currentUserName={currentUser.attributes.name}
        visible={showReportModal}
        submitting={insurerReportingActivity.loading}
        onSubmit={this.handleSubmitReport}
        onCancel={this.handleCancelReport}
      />
    );
  }

  renderManageCreditModal = () => {
    const { showCreditManagementModal } = this.state;
    const { agencyCustomers, creditOverview, experianCreditCheckRequest, legalEntity, globalDefaults } = this.props;
    const currentAmountDistributed = creditOverview?.data?.credit_limit_including_vat - creditOverview?.data?.undistributed_credit_limit;

    if (!showCreditManagementModal) return null;

    return (
      <ManageCreditModal
        currentCreditLimit={creditOverview?.data?.credit_limit_excluding_vat}
        currentExperianResult={creditOverview.data?.latest_experian_report?.result_excluding_vat}
        currentAmountDistributed={currentAmountDistributed}
        undistributedCredit={creditOverview?.data?.undistributed_credit_limit}
        currentVatRate={Number(globalDefaults?.data?.standard_vat_rate)}
        visible={showCreditManagementModal}
        busy={!!experianCreditCheckRequest?.loading || !!creditOverview?.loading}
        experianCreditCheck={experianCreditCheckRequest?.data}
        legalEntity={legalEntity}
        agencyCustomers={agencyCustomers}
        onCloseModal={this.handleCloseManageCreditForm}
        onPerformCreditCheck={this.handleExperianCreditCheck}
        onCreateCreditApplication={this.handleCreateCreditApplication}
        onDistributeCredit={this.handleDistributeCredit}
      />
    );
  }

  handleReportClicked = () => {
    this.setState({
      showReportModal: true,
    });
  }

  handleSubmitReport = async (data) => {
    const { legalEntityId, addInsurerReportingActivity } = this.props;

    await addInsurerReportingActivity({
      legalEntityId,
      ...data,
    });

    this.setState({
      showReportModal: false,
    });
  }

  handleCancelReport = () => {
    this.setState({
      showReportModal: false,
    });
  }

  handleSuspendClicked = () => {
    this.setState({
      showSuspensionWarning: true,
    });
  }

  handleAcceptToggleSuspension = async () => {
    const { legalEntityId, creditOverview, suspendCredit, unsuspendCredit } = this.props;

    if (creditOverview.data?.credit_suspended) {
      await unsuspendCredit({ legalEntityId });
    }
    else {
      await suspendCredit({ legalEntityId });
    }

    this.setState({
      showSuspensionWarning: false,
    });
  }

  handleDeclineToggleSuspension = () => {
    this.setState({
      showSuspensionWarning: false,
    });
  }

  handleManageCreditClicked = () => {
    this.props.clearExperianCreditCheckRequest();

    this.setState({
      showCreditManagementModal: true,
    });
  }

  handleCreateCreditApplication = async (data) => {
    const { legalEntityId, currentUser, createCreditApplication, createScheduledCreditAction } = this.props;

    if (data.reason === "increase") {
      createCreditApplication({
        legalEntityId,
        amountNeeded: parseInt(data.newCreditLimit),
        requestedBy: currentUser.attributes.name,
        source: data.typeOfCheck,
        dateOfRequest: data.dateOfRequest,
        comment: data.comment,
      });
    }
    else {
      createScheduledCreditAction({
        legal_entity_id: legalEntityId,
        type: data.reason,
        source: data.source,
        new_credit_limit: data.newCreditLimit,
        scheduled_at: data.scheduledFor,
        scheduled_by: currentUser.attributes.name,
        comment: data.comment,
      });
    }

    this.setState({
      showCreditManagementModal: false,
    });
  }

  handleDistributeCredit = async (data) => {
    const { currentUser, distributeCredit } = this.props;

    distributeCredit({
      fromAccount: data.fromAccount,
      toAccount: data.toAccount,
      amount: data.distributeAmount,
      distributedBy: currentUser.attributes.name,
    });

    this.setState({
      showCreditManagementModal: false,
    });
  }

  handleCloseManageCreditForm = () => {
    this.setState({
      showCreditManagementModal: false,
    });
  }

  handleExperianCreditCheck = async (data) => {
    const { addExperianCreditCheck, createScheduledExperianAction, legalEntityId } = this.props;

    if (data.reason === "increase") {
      addExperianCreditCheck({
        legalEntityId,
        companyFound: data.companyFound,
        experianCreditResult: data.newCreditLimit,
        experianDateOfResult: data.dateOfResult,
        experianCreditReport: data.experianCreditReport,
      });
    }
    else {
      createScheduledExperianAction({
        legalEntityId,
        type: data.reason,
        newCreditLimit: data.newCreditLimit,
        scheduledFor: data.scheduledFor,
        comment: data.comment,
        documentedReason: data.experianCreditReport,
      });
    }

    this.setState({
      showCreditManagementModal: false,
    });
  }

  handleEditDateOfNextReview = async () => {
    const { legalEntityId, creditOverview, showDateOfNextReviewModal } = this.props;

    await showDateOfNextReviewModal({
      legalEntityId,
      dateOfNextReview: creditOverview.data?.latest_credit_review_date?.next_review_date,
    });
  }

  handleColumnClick = async (sortOrder) => {
    const { legalEntityId, getAgencyCustomers } = this.props;

    await getAgencyCustomers({ legalEntityId, sortOrder });
  }

}
