import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { CompanyDetailsForm } from "../../components";
import styles from "./EditCompanyDetailsPage.module.scss";

export default class EditCompanyDetailsPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    companyDetails: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
    }).isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }).isRequired,
    getCompanyDetails: PropTypes.func.isRequired,
    updateCompanyDetails: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getCompanyDetails } = this.props;

    await getCompanyDetails({ legalEntityId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.companyDetails.loading ? (
          <ActivityIndicator />
        ) : (
          <CompanyDetailsForm
            data={this.props.companyDetails.data}
            submitting={this.props.ui.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { legalEntityId, onCancel } = this.props;

    await onCancel(legalEntityId);
  }

  handleSubmit = async (data) => {
    const { legalEntityId, updateCompanyDetails } = this.props;

    await updateCompanyDetails({
      legalEntityId,
      isRegisteredCharity: data.is_registered_charity,
      registeredCharityNumber: data.registered_charity_number,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/details`,
    });
  }

}
