import { connect } from "react-redux";
import { push } from "connected-react-router";
import CreditLimitManagementPage from "./CreditLimitManagementPage";

const mapStateToProps = null;
const mapDispatchToProps = dispatch => ({
  onOptionClick: (optionName) => {
    switch (optionName) {
      case "legal-entities":
        dispatch(push("/credit-limit-management/legal-entities"));
        break;

      case "perform-credit-check":
        dispatch(push("/credit-limit-management/perform-credit-check"));
        break;

      default:
        // eslint-disable-next-line no-console
        console.error("Unknown option:", optionName);
        break;
    }
  },
  onViewTaskListClick: () => {
    dispatch(push("/credit-limit-management/task-list"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditLimitManagementPage);
