import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./GoToInsurerModal.module.scss";
import GoToInsurerModalValidator from "./GoToInsurerModalValidator";

export default function GoToInsurerModal (props) {
  const [formData, setFormData] = useState({
    resultDate: Date.now(),
    comments: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.getCreditApplicationForApproval({
      legalEntityId: props.legalEntityId,
      creditApplicationId: props.creditApplicationId,
    });
  }, []);

  const canComplete = () => {
    if (props.loading) {
      return false;
    }

    if (!formData?.resultDate) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const validator = new GoToInsurerModalValidator(formData);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.goToInsurerFromBroker({
      creditApplicationId: props.creditApplicationId,
      notes: formData.comments,
      useDiscretionaryLimit: false,
      status: "accepted",
      amountApproved: props.creditLimitRequested,
      legalEntityId: props.legalEntityId,
      dateOfResult: formData.resultDate,
      createdBy: props.createdBy,
    });

    props.hideModal(GoToInsurerModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(GoToInsurerModal.displayName);
  };

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <ConfirmationModal
      title="Go To Insurer"
      visible={props.visible}
      className={styles.container}
      busy={props.loading || props.submitting}
      submitText="Complete"
      submitDisabled={!canComplete()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Please enter the date that the broker returned with this decision.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors.resultDate}>
            <Form.Label className={styles.label} inline required>
              Date result was obtained
            </Form.Label>
            <Form.Date
              name="resultDate"
              value={formData.resultDate}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.resultDate}
              onChange={handleFormChange}
              max={parseDate(Date.now(), "YYYY-MM-DD")}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              name="comments"
              className={styles.textArea}
              value={formData.comments}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={handleFormChange}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

GoToInsurerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string,
  creditLimitRequested: PropTypes.number,
  createdBy: PropTypes.shape({
    first_name: PropTypes.string,
    last_name : PropTypes.string,
  }),
  goToInsurerFromBroker: PropTypes.func.isRequired,
  getCreditApplicationForApproval: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

GoToInsurerModal.displayName = "GoToInsurerModal";
