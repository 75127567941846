import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DOWNLOAD_AGENCY_DOCUMENT = gql`
query($input: AgencyDocumentUrlInput!) {
  getAgencyDocumentDownloadUrl(input: $input) {
    url
  }
}
`;

export const downloadAgencyDocument = (agency_id, document_type, version_id) => async (dispatch) => {
  try {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: DOWNLOAD_AGENCY_DOCUMENT,
      variables: {
        input: {
          agency_id,
          document_type,
          version_id,
        },
      },
    });
    const url = response.data.getAgencyDocumentDownloadUrl.url;

    window.open(url, "_blank");

    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
