import _ from "lodash";
import { PhoneNumberValidator, UrlValidator } from "../../../../../utils/validators";

export default class SoleTraderLegalEntityFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data.title)) {
      errors.title = "Title is required";
    }

    if (_.isEmpty(data.first_name)) {
      errors.first_name = "First Name is required";
    }

    if (_.isEmpty(data.last_name)) {
      errors.last_name = "Last Name is required";
    }

    if (!_.isEmpty(data.telephone) && !PhoneNumberValidator.validate(data.telephone)) {
      errors.telephone = "Phone Number is not valid";
    }

    if (!_.isEmpty(data.website) && !UrlValidator.validate(data.website)) {
      errors.website = "Website URL is not valid";
    }

    if (_.isEmpty(data.company_address.address_line_one)) {
      errors.company_address = {
        ...errors.company_address,
        address_line_one: "Address Line 1 is required",
      };
    }

    if (_.isEmpty(data.company_address.town_or_city)) {
      errors.company_address = {
        ...errors.company_address,
        town_or_city: "Town / City is required",
      };
    }

    if (_.isEmpty(data.company_address.county)) {
      errors.company_address = {
        ...errors.company_address,
        county: "County is required",
      };
    }

    if (_.isEmpty(data.company_address.postcode)) {
      errors.company_address = {
        ...errors.company_address,
        postcode: "Postcode is required",
      };
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
