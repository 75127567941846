import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { Form } from "../../../common/components";

export class TradingStyleSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    tradingStyles: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    legalEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    agencyId: PropTypes.string,
    getTradingStyles: PropTypes.func.isRequired,
    onItemSelected: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    const { agencyId } = this.props;

    if (prevProps.agencyId !== agencyId) {
      this.handleGetTradingStyles();
    }
  }

  render() {
    const { tradingStyles, agencyId, name } = this.props;
    const { value } = this.state;

    return (
      <Form.Select
        name={name}
        placeholder="Select Trading Style..."
        value={value}
        options={tradingStyles && tradingStyles.data.map(t => ({ value: t.id, label: t.name }))}
        onChange={this.handleResultSelected}
        disabled={!agencyId || tradingStyles.data.length === 0}
      />
    );
  }

  handleResultSelected = (e) => {
    const { onItemSelected, tradingStyles } = this.props;

    const tradingStyle = tradingStyles.data.find(t => t.id === e.target.value);

    if (tradingStyle) {
      this.setState({ value: tradingStyle.id }, () => {
        onItemSelected({
          id: tradingStyle.id,
          name: tradingStyle.name,
        });
      });
    }
  }

  handleGetTradingStyles = async () => {
    const { getTradingStyles, legalEntity, agencyId, name } = this.props;

    this.handleResultSelected({ target: { name: name, value: "" } });

    await getTradingStyles({
      legalEntityId: legalEntity.id,
      agencyId: agencyId,
    });
  }
}

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TradingStyleSelect);
