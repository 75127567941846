import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Heading,
  Option,
} from "../../../common/components";
import styles from "./CreditLimitManagementPage.module.scss";

export default function CreditLimitManagementPage(props) {
  return (
    <div>
      <Heading
        title="Credit Limit Management"
        rightAccessory={(
          <Button onClick={() => props.onViewTaskListClick()}>
            View Task List
          </Button>
        )}
      />
      <div className={styles.options}>
        <Option
          title="Perform Credit Check"
          subtitle="Perform a credit check on behalf of an Agency"
          onClick={() => props.onOptionClick("perform-credit-check")}
          hideView
        />
        <Option
          title="View Credit Limits"
          subtitle="View all Credit Limits"
          onClick={() => props.onOptionClick("legal-entities")}
          hideView
        />
      </div>
    </div>
  );
}

CreditLimitManagementPage.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  onViewTaskListClick: PropTypes.func.isRequired,
};
