import * as EmailValidator from "email-validator";

/**
 *
 * @param {string} email
 * @returns bool
 */
const validate = (email) => {
  return EmailValidator.validate(email);
};

export default {
  validate,
};
