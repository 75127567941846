import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Divider } from "semantic-ui-react";
import { Add, ErrorOutline } from "@material-ui/icons";
import { Alert, Form, IconButton, Modal } from "../../../common/components";
import { toMoney } from "../../../../utils";
import DistributeCreditRequestModalValidator from "./DistributeCreditRequestModalValidator";
import DistributeInput from "./DistributeInput/DistributeInput";
import DistributeList from "./DistributeList/DistributeList";
import styles from "./DistributeCreditRequestModal.module.scss";

const createEmptyDistribution = () => ({
  accountId: null,
  amount: null,
});

export default function DistributeCreditRequestModal (props) {
  const [distributions, setDistributions] = useState([{}]);
  const [errors, setErrors] = useState({});
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    props.getAgencyCustomers({ legalEntityId: props.legalEntity?.data?.id });
    props.getCreditOverview({ legalEntityId: props.legalEntity?.data?.id });
  }, []);

  // Save the full list of standardised account objects to use for lookup
  useEffect(() => {
    const accounts = normaliseAccounts();
    setAccounts(accounts);
  }, [props.creditOverview, props.legalEntity, props.agencyCustomers]);

  const isLoading = _.some([
    props.agencyCustomers,
    props.creditOverview,
  ], x => x.loading);

  const total = distributions
    .map(distribution => distribution.amount)
    .reduce((r, v) => {
      if (!v) {
        return r;
      }
      return r + parseInt(v);
    }, 0);

  /**
   * @param  {string} context=null (optional) The field that wants the accounts
   */
  const normaliseAccounts = (context = null) => {
    const { agencyCustomers, creditOverview, legalEntity } = props;
    if (!agencyCustomers?.data || !legalEntity?.data) {
      return [];
    }

    const accounts = [];

    // Add undistributed if it's not already selected
    if (!alreadySelected(legalEntity.data.id, context)) {
      accounts.push({
        id: legalEntity.data.id,
        type: "legalEntity",
        name: "Undistributed",
        credit: creditOverview.data?.undistributed_credit_limit,
      });
    }

    // Move on to adding the agency customers
    const sortedAgencyCustomers = _.sortBy(agencyCustomers.data, agencyCustomer => agencyCustomer.agency.name.toLowerCase());
    sortedAgencyCustomers.forEach((agencyCustomer) => {
      let customerAccounts = [];

      // Sort the active accounts (ie. not "Reserved") alphabetically
      const reserved = agencyCustomer.customer_accounts.find(account => account.display_name === "Reserved");
      const activeAccounts = agencyCustomer.customer_accounts.filter(account => account.display_name !== "Reserved");
      const sortedActiveAccounts = activeAccounts.sort((a, b) => a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase()));

      // Currently only the "Reserved" account can be the target is this is fine for now.
      const isTargetAgency = agencyCustomer.agency.id === props.creditRequest.agency.id;
      // Add "Reserved" to the top of each agency's customers
      if (!alreadySelected(reserved.id, context) && !isTargetAgency) {
        customerAccounts.push({
          id: reserved.id,
          type: "customerAccount",
          name: reserved.display_name,
          agency: agencyCustomer.agency.name,
          credit: reserved.allocated_credit,
        });
      }

      // Add the rest of the agency's customers
      sortedActiveAccounts.forEach((customerAccount) => {
        if (!alreadySelected(customerAccount.id, context)) {
          customerAccounts.push({
            id: customerAccount.id,
            type: "customerAccount",
            name: customerAccount.display_name,
            agency: agencyCustomer.agency.name,
            credit: customerAccount.allocated_credit,
          });
        }
      });

      // If the agency doesn't have any customers don't add it.
      // This will occur if it only had "reserved" and this has been selected in the other field.
      if (customerAccounts.length > 0) {
        customerAccounts.unshift({
          id: `agency_${agencyCustomer.agency.id}`,
          type: "agency",
          name: agencyCustomer.agency.name,
        });
      }

      // Add this agency and its customers to the accounts array we're building.
      customerAccounts.forEach(account => accounts.push(account));
    });

    return accounts;
  };

  // An account selection field doesn't want to show an account if it's selected
  // in the other account selection field.
  const alreadySelected = (optionId, context) => {
    for (let i = 0; i < distributions.length; i++) {
      if (i === context) {
        continue;
      }
      if (distributions[i].accountId === optionId) {
        return true;
      }
    }
    return false;
  };

  // Build the array for the <Form.Select/>.
  const buildOptions = (accounts) => {
    const options = [];

    accounts.forEach((account) => {
      switch (account.type) {
        case "legalEntity":
          options.push({
            key: "LE_" + account.id,
            value: account.id,
            label: <div>Undistributed <span className={styles.accountCredit}>({toMoney(account.credit)})</span></div>,
          });
          break;

        case "agency":
          options.push({
            key: "AC_" + account.id,
            content: <div className={styles.agency}>{account.name}</div>,
            disabled: true,
          });
          break;

        case "customerAccount":
          // label shows when selected. Content shows in the dropdown.
          options.push({
            key: "CA_" + account.id,
            value: account.id,
            label: (
              <div>
                {account.agency}: {account.name}{" "}<span className={styles.accountCredit}>({toMoney(account.credit)})</span>
              </div>
            ),
            content: (
              <div className={styles.customerAccount}>
                {account.name}{" "}<span className={styles.accountCredit}>({toMoney(account.credit)})</span>
              </div>
            ),
          });
          break;

        default:
          break;
      }
    });

    return options;
  };

  const findRequestingAgencyCustomer = () => {
    return props.agencyCustomers?.data?.find(
      agencyCustomer => agencyCustomer.agency.id === props.creditRequest.agency.id
    );
  };

  // Find the target customer account from the agency customer
  const findTargetCustomerAccount = (agencyCustomer) => {
    return agencyCustomer.customer_accounts.find(
      customerAccount => customerAccount.display_name === "Reserved"
    );
  };

  // From the AC: "currently this will be the 'reserved' account, but in future
  // may be a specific 'trading' customer account" 🙈
  const requestingAccount = () => {
    if (props.agencyCustomers?.loading) {
      return "-";
    }

    const agencyCustomer = findRequestingAgencyCustomer();

    if (!agencyCustomer) {
      return "-";
    }

    const customerAccount = findTargetCustomerAccount(agencyCustomer);

    if (!customerAccount) {
      return "-";
    }

    return (
      <>
        {agencyCustomer.agency.name}
        {": Reserved "}
        <span className={styles.accountCredit}>
          ({toMoney(customerAccount.allocated_credit)})
        </span>
      </>
    );
  };

  const canAddAnotherDistribution = () => {
    const accountsThatCanBeAdded = accounts.filter(account => ["customerAccount", "legalEntity"].includes(account.type));

    if (distributions.length === accountsThatCanBeAdded.length) {
      return false;
    }

    for (const distribution of distributions) {
      if (!distribution.accountId) {
        return false;
      }
      if (!distribution.amount) {
        return false;
      }

      const account = accounts.find(account => account.id === distribution.accountId);
      if (distribution.amount > account.credit) {
        return false;
      }
    }

    return true;
  };

  const canComplete = () => {
    for (const distribution of distributions) {
      if (!distribution.accountId || !distribution.amount) {
        return false;
      }
    }
    return true;
  };

  const onDistributionChange = useCallback(
    (distribution) => {
      const editIndex = distributions.length - 1;

      setDistributions(Object.assign([...distributions], {
        [editIndex]: distribution,
      }));
      setErrors({
        ...errors,
        [String(editIndex)]: null,
      });
    },
    [distributions, errors],
  );

  const onAddAnotherDistribution = useCallback(
    () => {
      setDistributions([...distributions, createEmptyDistribution()]);
      setErrors({});
    },
    [distributions, errors],
  );

  const onRemoveDistribution = useCallback(
    (index) => {
      setDistributions(distributions.filter((_r, i) => i !== index));
      setErrors({});
    },
    [distributions, errors],
  );

  const handleCancel = () => {
    props.hideModal(DistributeCreditRequestModal.displayName);
  };

  const handleComplete = () => {
    const { distributeCreditRequest, creditRequest, currentUser } = props;
    const validator = new DistributeCreditRequestModalValidator(distributions, accounts);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    distributeCreditRequest({
      creditRequestId: creditRequest.id,
      targetAccountId: findTargetCustomerAccount(findRequestingAgencyCustomer()).id,
      distributions: distributions.map(distribution => ({
        ...distribution,
        account: accounts.find(account => account.id === distribution.accountId),
      })),
      distributedBy: currentUser.attributes.name,
    });

    props.hideModal(DistributeCreditRequestModal.displayName);
  };

  const editIndex = distributions.length - 1;

  return (
    <Modal
      title="Distribute Credit"
      visible={true}
      busy={isLoading}
      primaryButtonDisabled={!canComplete()}
      onSubmit={handleComplete}
      onCancel={handleCancel}
      submitText="Distribute"
      className={styles.container}
      buttonPosition="middle"
    >
      <Form requiredKey>
        <Form.Group inline>
          <Form.Label inline>Requested</Form.Label>
          <div>{toMoney(props.creditRequest.credit_requested)}</div>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>To</Form.Label>
          <div>
            {requestingAccount()}
          </div>
        </Form.Group>
        {distributions.length > 1 ? (
          <DistributeList
            accounts={accounts.filter(account => ["customerAccount", "legalEntity"].includes(account.type))}
            distributions={distributions.slice(0, distributions.length - 1)}
            onDistributionRemoved={onRemoveDistribution}
          />
        ): (
          <Divider />
        )}
        <DistributeInput
          key={`distribute_${editIndex}`}
          index={editIndex}
          loading={isLoading}
          distribution={distributions[editIndex]}
          error={errors[String(editIndex)]}
          accounts={buildOptions(normaliseAccounts(editIndex))}
          onChange={onDistributionChange}
          onRemove={() => onRemoveDistribution(editIndex)}
        />
        <IconButton
          className={styles.addButton}
          icon={(<Add />)}
          iconPosition="left"
          text="Add Another Distribution"
          disabled={!canAddAnotherDistribution()}
          onClick={onAddAnotherDistribution}
        />

        <Divider />
        <div className={styles.total}>
          Total: <span>{toMoney(total)}</span>
        </div>
      </Form>

      {total > props.creditRequest.credit_requested && (
        <Alert variant="error" showClose={false} className={styles.alert}>
          <div className={styles.error}>
            <ErrorOutline className={styles.errorIcon} />
            <div>
              <strong>Warning:</strong> The total amount of credit to be distributed exceeds the amount requested by {toMoney(total - props.creditRequest.credit_requested)}
            </div>
          </div>
        </Alert>
      )}
    </Modal>
  );
}

DistributeCreditRequestModal.displayName = "DistributeCreditRequestModal";

DistributeCreditRequestModal.propTypes = {
  creditRequest: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  legalEntity: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  agencyCustomers: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
  }),
  getAgencyCustomers: PropTypes.func.isRequired,
  getCreditOverview: PropTypes.func.isRequired,
  distributeCreditRequest: PropTypes.func.isRequired,
  creditOverview: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      undistributed_credit_limit: PropTypes.number.isRequired,
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
