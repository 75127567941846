import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DISTRIBUTE_CREDIT = gql`
mutation($input: DistributeCreditInput!) {
  distributeCredit(input: $input) {
    amount
    legal_entity {
      id
      undistributed_credit_limit

      agency_customers(
        pager: { page: 1, limit: 1000 }
      ) {
        id
        allocated_credit
        agency {
          id
          name
        }
        agency_customer_request {
          verified_at
          verified_by {
            first_name
            last_name
          }
          verify_mismatch_reason {
            label
          }
          verify_mismatch_reason_details
        }
        customer_accounts {
          id
          display_name
          allocated_credit
        }
      }
    }
  }
}
`;

const convertAccount = (account) => {
  if (account.type === "legalEntity") {
    return { legal_entity_id: account.id };
  }
  if (account.type === "customerAccount") {
    return { customer_account_id: account.id };
  }
};

/**
 *
 * @param {object} obj
 * @param {number} obj.fromAccount
 * @param {string} obj.toAccount
 * @param {string} obj.amount
 * @param {string} obj.distributedBy
 * @returns
 */
export const distributeCredit = ({
  fromAccount,
  toAccount,
  amount,
  distributedBy,
}) => async (dispatch) => {
  try {
    dispatch({ type: types.DISTRIBUTE_CREDIT_PENDING });

    const response = await apolloClient.query({
      query: DISTRIBUTE_CREDIT,
      variables: {
        input: {
          from: convertAccount(fromAccount),
          to: convertAccount(toAccount),
          amount: parseFloat(amount || 0),
          distributed_by: distributedBy,
        },
      },
    });

    if (!response.data.distributeCredit) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DISTRIBUTE_CREDIT_SUCCESS,
      payload: response.data.distributeCredit,
    });

  } catch (err) {
    dispatch({
      type: types.DISTRIBUTE_CREDIT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
