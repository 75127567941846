import gql from "graphql-tag";
import { push } from "connected-react-router";
import {
  addAgencyCustomerRequest,
  reassignAgencyCustomerRequest,
  updateAgencyCustomerRequest,
} from "./";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_LEGAL_ENTITY = gql`
mutation($input: AddLegalEntityInput!) {
  addLegalEntity(input: $input) {
    id
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.registeredCompanyNumber
 * @param {string} obj.exactLegalName
 * @param {string} obj.companyType
 * @param {string} obj.companyStatus
 * @param {string} obj.website
 * @param {string} obj.isRegisteredCharity
 * @param {string} obj.registeredCharityNumber
 * @param {string} obj.agencyCustomerRequestId (optional)
 * @param {object} obj.agencyCustomerRequest
 * @param {string} obj.agencyCustomerRequest.agencyId
 * @param {number} obj.agencyCustomerRequest.creditLimitRequested
 * @param {string} obj.agencyCustomerRequest.registeredCompanyNumber
 * @param {string} obj.agencyCustomerRequest.exactLegalName
 * @param {boolean} obj.validate
 * @returns
 */
export const addRegisteredCompany = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_REGISTERED_COMPANY_PENDING,
    });

    const response = await apolloClient.query({
      query: ADD_LEGAL_ENTITY,
      variables: {
        input: {
          name: obj.exactLegalName,
          company_type: obj.companyType,
          registered: {
            company_number: obj.registeredCompanyNumber,
            company_status: obj.companyStatus,
            website: obj.website,
            is_registered_charity: obj.isRegisteredCharity,
            registered_charity_number: obj.isRegisteredCharity ? obj.registeredCharityNumber : null,
          },
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const legalEntityId = response.data.addLegalEntity.id;

    // We don't mind if this fails. We only really need the Legal Entity
    try {
      if (obj.agencyCustomerRequestId) {
        await dispatch(reassignAgencyCustomerRequest({
          agencyCustomerRequestId: obj.agencyCustomerRequestId,
          legalEntityId,
        }));
        await dispatch(updateAgencyCustomerRequest({
          agencyCustomerRequestId: obj.agencyCustomerRequestId,
          registeredCompanyNumber: obj.agencyCustomerRequest.registeredCompanyNumber,
          exactLegalName: obj.agencyCustomerRequest.exactLegalName,
        }));
      }
      else {
        await dispatch(addAgencyCustomerRequest({
          agencyId: obj.agencyCustomerRequest.agencyId,
          legalEntityId,
          creditLimitRequested: parseFloat(obj.agencyCustomerRequest.creditLimitRequested || 0),
          registeredCompanyNumber: obj.agencyCustomerRequest.registeredCompanyNumber,
          exactLegalName: obj.agencyCustomerRequest.exactLegalName,
        }));
      }
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error("Failed to create Agency Customer", err);
    }

    dispatch({
      type: types.ADD_REGISTERED_COMPANY_SUCCESS,
      payload: {
        data: response.data.addLegalEntity,
      },
    });

    dispatch(push(`/credit-limit-management/legal-entities/${legalEntityId}`));
  }
  catch (err) {
    dispatch({
      type: types.ADD_REGISTERED_COMPANY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
