import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const CHASE_CREDIT_APPLICATION = gql`
mutation(
  $input: ChaseCreditApplicationInput!
) {
  chaseCreditApplication(input: $input) {
    id
    credit_application {
      id
    }
    name
    date_of_chase
    method_of_communication {
      label
      value
    }
    comments
    chased_by
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.creditApplicationId
 * @param {string} obj.name
 * @param {number} obj.dateOfChase
 * @param {number} obj.methodOfCommunicationId
 * @param {string} obj.comments
 * @param {string} obj.chasedBy
 * @returns
 */
export const chaseCreditApplication = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.CHASE_CREDIT_APPLICATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: CHASE_CREDIT_APPLICATION,
      variables: {
        input: {
          credit_application_id: parseInt(obj.creditApplicationId),
          name: obj.name,
          date_of_chase: obj.dateOfChase,
          method_of_communication_id: parseInt(obj.methodOfCommunicationId),
          comments: obj.comments,
          chased_by: obj.chasedBy,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CHASE_CREDIT_APPLICATION_SUCCESS,
      payload: {
        data: response.data.chaseCreditApplication,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.CHASE_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
