import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_INSURER_REPORTING_ACTIVITY = gql`
mutation($input: AddInsurerReportingActivityInput!) {
  addInsurerReportingActivity(input: $input) {
    id
    legal_entity {
      id
    }
    reported_by
    reported_at
    report_reason {
      label
    }
    created_at
    updated_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.reportedBy
 * @param {number} obj.reportedAt
 * @param {string} obj.reportReasonId
 * @param {string} obj.reportedComment
 * @returns
 */
export const addInsurerReportingActivity = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_INSURER_REPORTING_ACTIVITY_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_INSURER_REPORTING_ACTIVITY,
      variables: {
        input: {
          legal_entity_id: obj.legalEntityId,
          reported_by: obj.reportedBy,
          reported_at: obj.reportedAt,
          report_reason_id: obj.reportReasonId,
          reported_comment: obj.reportedComment,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_INSURER_REPORTING_ACTIVITY_SUCCESS,
      payload: {
        insurerReportingActivity: response.data.addInsurerReportingActivity,
      },
    });
  } catch (err) {
    dispatch({
      type: types.ADD_INSURER_REPORTING_ACTIVITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
