import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const SEARCH_AGENCY_CUSTOMERS = gql`
query(
  $agencyId: ID!,
  $companyNumber: String!
) {
  searchAgencyCustomers(agency_id: $agencyId, company_number: $companyNumber) {
    id
    name
    ...on RegisteredCompany {
      agency_customers {
        agency {
          id
        }
      }
    }
  }
}
`;

export const searchAgencyCustomers = ({
  agencyId,
  companyNumber,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.AGENCY_CUSTOMER_SEARCH_PENDING,
    });

    const response = await apolloClient.query({
      query: SEARCH_AGENCY_CUSTOMERS,
      variables: {
        agencyId,
        companyNumber,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.AGENCY_CUSTOMER_SEARCH_SUCCESS,
      payload: {
        data: response.data.searchAgencyCustomers,
        showModal: true,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.AGENCY_CUSTOMER_SEARCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
