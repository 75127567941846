import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../duck";
import { actions as documentActions } from "../../../documents/duck";
import { Alert } from "../../../common/components";
import { CompanyView } from "modules/agencies/components";
import styles from "./CompanyTab.module.scss";

export class CompanyTab extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    section: PropTypes.oneOf([
      "agency-details",
      "contract-information",
      "trading-address",
      "bank-information",
    ]),
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    companyInfo: PropTypes.object,
    companyHistory: PropTypes.shape({
      registered_address: PropTypes.array,
      contract_start_date: PropTypes.array,
      legal_name: PropTypes.array,
      trading_as: PropTypes.array,
    }),
    agencyDisabledFields: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.string,
      ).isRequired,
    }),
    getAgency: PropTypes.func.isRequired,
    getCompanyInfo: PropTypes.func.isRequired,
    downloadAgencyDocument: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, getCompanyInfo, onLoad, match } = this.props;

    await getCompanyInfo(agencyId);

    onLoad && onLoad(match.path);
  }

  render() {
    const { companyInfo, companyHistory, agencyDisabledFields } = this.props;

    if (companyInfo.loading) return <ActivityIndicator />;
    if (companyInfo.error) return <Alert className={styles.error} variant="error" text={companyInfo.error} showClose={false} />;

    return (
      <CompanyView
        companyInfo={companyInfo}
        companyHistory={companyHistory}
        agencyDisabledFields={agencyDisabledFields.data}
        readOnly={true}
        onLoad={this.handleLoad}
        onEdit={this.handleEdit}
        onDownload={this.handleDownload}
      />
    );
  }

  handleLoad = async (data) => {
    const { agencyId, getAgencyDisabledFields } = this.props;

    await getAgencyDisabledFields(agencyId, data);
  }

  handleEdit = (section) => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/edit/${section}`);
  }

  handleCancel = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleDownload = async (key) => {
    const { agencyId, downloadAgencyDocument } = this.props;

    downloadAgencyDocument(agencyId, key.name);
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...documentActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTab);
