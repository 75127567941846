import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import { ContactList, Form } from "../../../../common/components";
import { KeyCaseConverter } from "../../../../../converters";
import styles from "./AgencyContacts.module.scss";

export default function AgencyContacts(props) {
  const contacts = props.data.map(contact => ({
    ...KeyCaseConverter.toCamelCase(contact),
    primaryContact: contact.is_primary_contact,
  }));

  return (
    <Form>
      <Form.Section
        title="Agency Contacts"
        underlined
        rightAccessory={(
          <Form.Checkbox
            label="Show Archived Contacts"
            checked={props.showArchived}
            onChange={props.onShowArchived}
          />
        )}
      >
        <Form.Group
          className={classnames(
            props.submitting && styles.submitting
          )}
        >
          {props.loading ? (
            <ActivityIndicator />
          ) : (
            <ContactList
              name="agency_contacts"
              contacts={contacts.filter(contact => !contact.archived)}
              readOnly={props.readOnly}
              canAdd={!props.readOnly}
              canEdit={!props.readOnly}
              canArchive={!props.readOnly}
              canMakePrimaryContact={!props.readOnly}
              onContactAdd={props.onContactAdd}
              onContactChange={props.onContactChange}
              onContactRemove={props.onContactRemove}
              onContactArchive={props.onContactArchive}
            />
          )}
        </Form.Group>
      </Form.Section>
      {!props.loading && props.showArchived && (
        <Form.Section
          className={styles.archivedContactsSection}
          title="Archived Contacts"
          rightAccessory={<span />}
        >
          <Form.Group
            className={classnames(
              props.submitting && styles.submitting
            )}
          >
            <ContactList
              name="archived_agency_contacts"
              contacts={contacts.filter(contact => contact.archived)}
              readOnly={props.readOnly}
              canAdd={false}
              canEdit={false}
              canArchive={false}
              canMakePrimaryContact={false}
              onContactRemove={props.onContactRemove}
            />
          </Form.Group>
        </Form.Section>
      )}
    </Form>
  );
}

AgencyContacts.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email_address: PropTypes.string,
    telephone_number: PropTypes.string,
    mobile_number: PropTypes.string,
    job_role: PropTypes.string,
    is_primary_contact: PropTypes.bool,
    archived: PropTypes.bool,
  })),
  submitting: PropTypes.bool,
  showArchived: PropTypes.bool,
  onShowArchived: PropTypes.func.isRequired,
  onContactAdd: PropTypes.func.isRequired,
  onContactChange: PropTypes.func.isRequired,
  onContactRemove: PropTypes.func.isRequired,
  onContactArchive: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

AgencyContacts.defaultProps = {
  data: [],
};
