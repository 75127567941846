import React, { useState } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Dropdown } from "semantic-ui-react";
import { VerifiedUser } from "@material-ui/icons";
import { Alert, TextLink } from "../../../common/components";
import styles from "./AgencyCustomerRequestAlert.module.scss";

export default function AgencyCustomerRequestAlert(props) {
  const [expanded, setExpanded] = useState(false);

  if (props.loading) {
    return <ActivityIndicator />;
  }

  if (props.data.length === 0) {
    return null;
  }

  const renderSingleEntry = () => {
    const agencyCustomerRequest = props.data[0];

    return (
      <div className={styles.alertWrapper}>
        <div className={styles.alertContent}>
          <VerifiedUser className={styles.icon} />
          <strong className={styles.notVerified}>Not Verified</strong>
          <div className={styles.unverifiedText}>
            <strong>{agencyCustomerRequest.agency.name}</strong> has not yet been verified for this Legal Entity
          </div>
          <TextLink
            className={styles.verifyLink}
            text="Verify now"
            onClick={() => props.onVerifyClick(agencyCustomerRequest)}
          />
        </div>
      </div>
    );
  };

  const renderMultipleEntries = () => {
    return (
      <div className={styles.alertWrapper}>
        <div className={styles.alertContent}>
          <VerifiedUser className={styles.icon} />
          <div className={styles.unverifiedText}>
            The following agency customers have not yet been verified for this Legal Entity
          </div>
        </div>
        <Dropdown
          icon={expanded ? "chevron up" : "chevron down"}
          scrolling
          floating
          direction="left"
          open={expanded}
          onClose={() => setExpanded(false)}
          onOpen={() => setExpanded(true)}
        >
          <Dropdown.Menu>
            {props.data.map(agencyCustomerRequest => (
              <>
                <Dropdown.Item
                  key={agencyCustomerRequest.id}
                  className={styles.dropdownItem}
                  content={(
                    <div className={styles.agencyVerifyItem}>
                      <div className={styles.agencyName}>
                        {agencyCustomerRequest.agency.name}
                      </div>
                      <TextLink
                        className={styles.verifyLink}
                        text="Verify"
                        onClick={() => props.onVerifyClick(agencyCustomerRequest)}
                      />
                    </div>
                  )}
                />
                <Dropdown.Divider className={styles.separator} />
              </>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <Alert variant="warning" showClose={false}>
      {props.data.length === 1 && renderSingleEntry()}
      {props.data.length > 1 && renderMultipleEntries()}
    </Alert>
  );
}

AgencyCustomerRequestAlert.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    created_at: PropTypes.number.isRequired,
    agency: PropTypes.shape({
      name: PropTypes.string.isRequired,
      extended: PropTypes.shape({
        logo_url: PropTypes.string,
      }),
    }).isRequired,
  })),
  onVerifyClick: PropTypes.func.isRequired,
};
