import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import RequestHistoryRow from "./RequestHistoryRow/RequestHistoryRow";
import styles from "./RequestHistoryList.module.scss";

export default function RequestHistoryList(props) {
  return (
    <Table className={styles.container} basic>
      <Table.Header>
        <Table.HeaderCell>
          Credit Requested By
        </Table.HeaderCell>
        <Table.HeaderCell>
          Requested Date
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <div>
            Credit Requested
          </div>
          <div>
            (Incl. VAT)
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <div>
            Credit Distributed
          </div>
          <div>
            (Incl. VAT)
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          Result
        </Table.HeaderCell>
        <Table.HeaderCell>
          Result Communicated
        </Table.HeaderCell>
        <Table.HeaderCell>
          Result Communicated to
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* This is for the action menu */}
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {props.data?.map((requestHistory, index) => (
          <RequestHistoryRow
            key={`request_history_${requestHistory.id}`}
            index={index}
            requestHistory={requestHistory}
            legalEntityId={props.legalEntityId}
            handleCommunicateToAgencyRequest={props.handleCommunicateToAgencyRequest}
            handleDeclineCreditRequest={props.handleDeclineCreditRequest}
            handleDistributeCreditRequest={props.handleDistributeCreditRequest}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

RequestHistoryList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(RequestHistoryRow.propTypes.requestHistory)),
  legalEntityId: PropTypes.string.isRequired,
  handleCommunicateToAgencyRequest: PropTypes.func.isRequired,
  handleDeclineCreditRequest: PropTypes.func.isRequired,
  handleDistributeCreditRequest: PropTypes.func.isRequired,
};
