const initialState = {
  errors: [],
};

const CUSTOM_ERROR_ACTIONS = [
  "DOCUMENTS/UPLOAD_AGENCY_DOCUMENT_FAILURE",
  "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENT_FAILURE",
];

export default function reducer(state = initialState, action = {}) {
  if (action.type.endsWith("_FAILURE") && !CUSTOM_ERROR_ACTIONS.includes(action.type)) {
    const error = (action.payload.error.message || action.payload.error)
      .replace("GraphQL error:", "")
      .trim();

    return {
      ...state,
      errors: [
        ...state.errors,
        error,
      ],
    };
  }
  return initialState;
}
