import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";
import { SortOrderConverter, KeyCaseConverter } from "../../../../converters";

const GET_CURRENT_TASKS = gql`
query(
  $filter_options: CreditLimitManagementTaskFilterOptions!
  $search_text: String
  $pager: Pager!
  $sort_order: [SortOrder]!
) {
  getCreditLimitManagementTasks(
    filter_options: $filter_options
    search_text: $search_text
    pager: $pager
    sort_order: $sort_order
  ) {
    data {
      id
      title
      legal_entity {
        name
      }
      agency {
        known_as
      }
      is_urgent
      due_date
    }

    total
  }
}
`;

/**
 * @param {object} obj
 * @param {Date} obj.upToDate
 * @param {object} obj.pager
 * @param {number} obj.page
 * @param {number} obj.limit
 * @param {string} obj.searchText
 * @param {object} obj.sortOrder
 * @returns
 */
export const getCurrentTasks = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CURRENT_TASKS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CURRENT_TASKS,
      variables: {
        filter_options: {
          completed: false,
          up_to_date: obj.upToDate?.valueOf(),
        },
        pager: {
          page: obj.pager.page,
          limit: obj.pager.limit,
        },
        sort_order: SortOrderConverter(KeyCaseConverter.toSnakeCase(obj.sortOrder)),
        search_text: obj.searchText,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.getCreditLimitManagementTasks,
      obj.pager.page,
      obj.pager.limit
    );

    dispatch({
      type: types.GET_CURRENT_TASKS_SUCCESS,
      payload: {
        data: response.data.getCreditLimitManagementTasks.data,
        ...pagination,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CURRENT_TASKS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
