import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../duck";
import { ContractDetailForm } from "../../components";
import { ButtonGroup, Button } from "../../../common/components";
import styles from "./ContractDetails.module.scss";

export class ContractDetails extends Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    contractId: PropTypes.string.isRequired,
    contract: {
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object,
    },
    getContract: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { contractId, getContract } = this.props;

    await getContract(contractId);
  }

  render() {
    const { contract } = this.props;

    if (contract.loading) {
      return <ActivityIndicator />;
    }

    return (
      <div className={styles.container}>
        <ButtonGroup className={styles.buttons}>
          <Button variant="outline-primary" onClick={this.handleCloseClicked}>
            Close
          </Button>
        </ButtonGroup>
        <ContractDetailForm
          contract={contract.data}
        />
      </div>
    );
  }

  handleCloseClicked = () => {
    this.props.history.goBack();
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  contractId: ownProps.match.params.contractId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);
