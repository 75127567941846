import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import FullyApproveCreditApplicationModalValidator from "./FullyApproveCreditApplicationModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./FullyApproveCreditApplicationModal.module.scss";

export default function FullyApproveCreditApplicationModal(props) {
  const [state, setState] = useState({
    resultDate: moment().valueOf(),
    comments: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.getCreditApplicationForApproval({
      legalEntityId: props.legalEntityId,
      creditApplicationId: props.creditApplicationId,
    });
  }, []);

  const handleSubmit = () => {
    const validator = new FullyApproveCreditApplicationModalValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.completeCreditApplication({
      creditApplicationId: props.creditApplicationId,
      amountApproved: props.creditLimitRequested,
      notes: state.comments,
      status: "accepted",
      useDiscretionaryLimit: false,
    });
    props.hideModal(FullyApproveCreditApplicationModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(FullyApproveCreditApplicationModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Fully Approve Credit Application"
      visible={props.visible}
      submitText="Approve Credit Application"
      submitDisabled={props.submitting}
      busy={props.loading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Please enter the date that this application was approved.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors?.resultDate}>
            <Form.Label className={styles.label} inline required>
              Date result was obtained
            </Form.Label>
            <Form.Date
              name="resultDate"
              value={state.resultDate}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.resultDate}
              onChange={e => setState({ ...state, resultDate: e.target.value })}
              max={parseDate(Date.now(), "YYYY-MM-DD")}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              className={styles.textArea}
              value={state.comments}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={e => setState({ ...state, comments: e.target.value })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

FullyApproveCreditApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string,
  creditLimitRequested: PropTypes.number,
  getCreditApplicationForApproval: PropTypes.func.isRequired,
  completeCreditApplication: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

FullyApproveCreditApplicationModal.displayName = "FullyApproveCreditApplicationModal";
