import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import styles from "./SearchInput.module.scss";

export default class SearchInput extends Component {

  render() {
    const { className, name, value, placeholder } = this.props;

    return (
      <div className={classnames(styles.container, className)}>
        <FontAwesomeIcon className={styles.icon} icon={faSearch} />
        <input
          className={styles.input}
          type="text"
          name={name}
          value={value || ""}
          placeholder={placeholder}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          autoComplete="off"
        />
      </div>
    );
  }

  handleChange = (e) => {
    const { onChange } = this.props;

    onChange && onChange(e);

    this.handleSearch();
  }

  handleKeyPress = (e) => {
    const { onSearch } = this.props;

    e.key === "Enter" && onSearch && onSearch();
  }

  handleSearch = _.debounce(this.props.onSearch, Math.max(0, this.props.debounce));

}

SearchInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
};

SearchInput.defaultProps = {
  debounce: 500,
  onSearch: () => {},
};
