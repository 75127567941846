import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import RegisteredCharityLegalEntityForm from "./RegisteredCharityLegalEntityForm/RegisteredCharityLegalEntityForm";
import RegisteredLegalEntityForm from "./RegisteredLegalEntityForm/RegisteredLegalEntityForm";
import CharityLegalEntityFormValidator from "../NonRegisteredLegalEntityForm/CharityLegalEntityForm/CharityLegalEntityFormValidator";
import GovernmentLegalEntityFormValidator from "../NonRegisteredLegalEntityForm/GovernmentLegalEntityForm/GovernmentLegalEntityFormValidator";
import PartnershipLegalEntityFormValidator from "../NonRegisteredLegalEntityForm/PartnershipLegalEntityForm/PartnershipLegalEntityFormValidator";
import SoleTraderLegalEntityFormValidator from "../NonRegisteredLegalEntityForm/SoleTraderLegalEntityForm/SoleTraderLegalEntityFormValidator";
import { NonRegisteredLegalEntityForm } from "../";
import {
  Button,
  ButtonGroup,
  UnsavedChangesModal,
} from "../../../common/components";
import styles from "./LegalEntityForm.module.scss";

export default class LegalEntityForm extends Component {

  static propTypes = {
    legalEntity: PropTypes.shape({
      name: PropTypes.string.isRequired,
      company_type: PropTypes.string.isRequired,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      block: PropTypes.func.isRequired,
    }),
    legalEntityHistory: PropTypes.object,
    readOnly: PropTypes.bool,
    busy: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    isEditing: false,
    data: {},
    errors: {},
    isDirty: false,
    nextLocation: null,
    showUnsavedChangesModal: false,
  }

  componentDidMount() {
    const { history, onSubmit } = this.props;

    window.onbeforeunload = this.handlePageLeave;

    if (history && onSubmit) {
      this.unblock = history.block((nextLocation) => {
        const { isDirty } = this.state;

        if (!isDirty) return true;

        this.setState({
          showUnsavedChangesModal: true,
          nextLocation: nextLocation,
        });

        return false;
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.unblock && this.unblock();
  }

  render() {
    const { readOnly, busy } = this.props;
    const isEditing = !readOnly;

    return (
      <div className={styles.container}>
        <ButtonGroup className={classnames(styles.buttons, readOnly || styles.buttonsFixed)}>
          {isEditing ? (
            <>
              <Button variant="primary" onClick={this.handleSaveClicked} disabled={busy}>Save</Button>
              <Button variant="outline-danger" onClick={this.handleCancelClicked} disabled={busy}>Cancel</Button>
            </>
          ) : (
            <Button variant="secondary" onClick={this.handleEditClicked}>Edit Details</Button>
          )}
        </ButtonGroup>
        {this.renderForm()}
        <UnsavedChangesModal
          visible={this.state.showUnsavedChangesModal}
          onClose={this.handleCloseSavedChangesModal}
          onGoBackClick={this.handleCloseSavedChangesModal}
          onLeaveClick={this.handleLeaveClicked}
        />
      </div>
    );
  }

  renderForm = () => {
    const { legalEntity, legalEntityHistory, readOnly } = this.props;

    switch (legalEntity?.company_type) {
      case "charity":
      case "government":
      case "partnership":
      case "sole_trader":
        return (
          <NonRegisteredLegalEntityForm
            companyType={legalEntity.company_type}
            legalEntity={legalEntity}
            readOnly={readOnly}
            onChange={this.handleFormDataChanged}
            errors={this.state.errors}
            mode="edit"
            onLoad={this.handleFormLoad}
          />
        );

      case "charitable-incorporated-organisation":
        return (
          <RegisteredCharityLegalEntityForm
            legalEntity={legalEntity}
            legalEntityHistory={legalEntityHistory}
            readOnly={readOnly}
            onChange={this.handleFormDataChanged}
          />
        );

      default:
        return (
          <RegisteredLegalEntityForm
            legalEntity={legalEntity}
            legalEntityHistory={legalEntityHistory}
            readOnly={readOnly}
            onChange={this.handleFormDataChanged}
            onLoad={this.handleFormLoad}
          />
        );
    }
  }

  handleEditClicked = () => {
    const { onEdit } = this.props;

    onEdit && onEdit();
  }

  handleSaveClicked = () => {
    const { onSubmit } = this.props;

    if (!this.validate()) return;

    this.setState({
      isDirty: false,
    });

    onSubmit && onSubmit(this.state.data);
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleFormDataChanged = (data) => {
    this.setState({
      isDirty: true,
      data,
    });
  }

  handleFormLoad = (data) => {
    this.setState({
      isDirty: false,
      data,
    });
  }

  validate = () => {
    const validator = this.getValidator();

    if (!validator) return true;

    const result = validator.validate();

    this.setState({ errors: result.errors });

    return result.success;
  }

  getValidator = () => {
    const { legalEntity } = this.props;
    const { data } = this.state;

    switch (legalEntity.company_type) {
      case "charity":
        return new CharityLegalEntityFormValidator(data);

      case "government":
        return new GovernmentLegalEntityFormValidator(data);

      case "partnership":
        return new PartnershipLegalEntityFormValidator(data);

      case "sole_trader":
        return new SoleTraderLegalEntityFormValidator(data);

      default:
        return null;
    }
  }

  handleCloseSavedChangesModal = () => {
    this.setState({
      showUnsavedChangesModal: false,
    });
  }

  handleLeaveClicked = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.setState({
      isDirty: false,
      showUnsavedChangesModal: false,
    });

    window.onbeforeunload = null;
    this.unblock();

    history.push(nextLocation.pathname);
  }

}
