import _ from "lodash";
import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_AGENCY_CONTACT = gql`
mutation(
  $agency_id: ID!
  $input: AddAgencyContactInput!
) {
  addAgencyContact(agency_id: $agency_id, input: $input) {
    id
  }
}
`;

const UPDATE_AGENCY_CONTACT = gql`
mutation($id: ID!, $input: UpdateAgencyContactInput!) {
  updateAgencyContact(id: $id, input: $input) {
    id
  }
}
`;

const SEND_CREDIT_REQUEST_RESULT_NOTIFICATION = gql`
mutation($input: SendCreditRequestResultNotificationInput!) {
  sendCreditRequestResultNotification(input: $input) {
    success
    message
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditRequestId
 * @param {object[]} obj.recipients
 * @param {string} obj.recipients[].contactId
 * @param {string} obj.recipients[].recipientType
 * @param {string} obj.recipients[].email
 * @param {string} obj.redirectUrl (optional)
 * @param {object[]} obj.newContacts (optional)
 * @param {stirng} obj.adencyId (optional) - Required for adding new contacts.
 * @returns
 */
export const sendCreditRequestResultNotification = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_PENDING,
    });

    if (obj.newContacts) {
      for (const contact of obj.newContacts) {
        const addResponse = await apolloClient.mutate({
          mutation: ADD_AGENCY_CONTACT,
          variables: {
            agency_id:  obj.agencyId,
            input: {
              first_name: contact.first_name,
              last_name: contact.last_name,
              preferred_name: contact.preferred_name,
              email_address: contact.email,
              is_primary_contact: false,
            },
          },
        });

        if (addResponse.errors) {
          throw addResponse.errors[0].message;
        }

        // update the id in recipients array to still reference this contact
        const newId = addResponse.data.addAgencyContact.id;

        const recipient = obj.recipients.find(recipient => recipient.contactId === contact.id);
        recipient.contactId = newId;
      }
    }

    const contactsWithNewEmails = obj.recipients.filter(x => !_.isEmpty(x.email));

    for (const contact of contactsWithNewEmails) {
      const updateResponse = await apolloClient.mutate({
        mutation: UPDATE_AGENCY_CONTACT,
        variables: {
          id: contact.contactId,
          input: {
            email_address: contact.email,
          },
        },
      });

      if (updateResponse.errors) {
        throw updateResponse.errors[0].message;
      }
    }

    const to = obj.recipients
      .filter(recipient => recipient.recipientType === "to")
      .map(recipient => recipient.contactId);
    const cc = obj.recipients
      .filter(recipient => recipient.recipientType === "cc")
      .map(recipient => recipient.contactId);
    const bcc = obj.recipients
      .filter(recipient => recipient.recipientType === "bcc")
      .map(recipient => recipient.contactId);

    const response = await apolloClient.mutate({
      mutation: SEND_CREDIT_REQUEST_RESULT_NOTIFICATION,
      variables: {
        input: {
          credit_request_id: obj.creditRequestId,
          to,
          cc,
          bcc,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_SUCCESS,
      payload: response.data.sendCreditRequestResultNotification,
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
