import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DOCUMENT_TYPES = gql`
query {
  getAgencyDocumentTypes {
    id
    type
  }
}
`;

export const getAgencyDocumentTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_DOCUMENT_TYPES_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_DOCUMENT_TYPES,
    });

    dispatch({
      type: types.GET_AGENCY_DOCUMENT_TYPES_SUCCESS,
      payload: response.data.getAgencyDocumentTypes,
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_DOCUMENT_TYPES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
