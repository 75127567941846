import {
  createCreditApplication,
} from "./";
import {
  InsurerApplicationModal,
} from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {number} obj.currentCreditLimit
 * @param {number} obj.requestedAmount
 * @returns
 */
export const showInsurerApplicationModal = obj => async (dispatch, getState) => {
  const { currentUser } = await getState();

  dispatch(modalActions.showModal(InsurerApplicationModal.displayName, {
    currentCreditLimit: obj.currentCreditLimit,
    requestedAmount: obj.requestedAmount,
    onSubmit: (data) => {
      dispatch(createCreditApplication({
        legalEntityId: obj.legalEntityId,
        amountNeeded: data.amountApplyingFor,
        requestedBy: currentUser.user.attributes.name,
        source: data.source,
        redirectUrl: `/credit-limit-management/legal-entities/${obj.legalEntityId}/credit-management`,
      }));

      dispatch(modalActions.hideModal(InsurerApplicationModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(InsurerApplicationModal.displayName));
    },
  }));
};
