import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import ContractDetails from "./ContractDetails";
import ContractsLanding from "./ContractsLanding";
import CreateContract from "./CreateContract";

export default class ContractsTab extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={ContractsLanding} />
        <Route exact path={`${match.path}/create`} component={CreateContract} />
        <Route exact path={`${match.path}/contracts/:contractId`} component={ContractDetails} />
      </Switch>
    );
  }
}
