import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { Form, TextLink } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { formatUrl, upToNearestThousand } from "../../../../../utils";
import styles from "./SoleTraderLegalEntityForm.module.scss";

export default class SoleTraderLegalEntityForm extends Component {

  static propTypes = {
    legalEntity: PropTypes.shape({
      title: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      telephone: PropTypes.string,
      website: PropTypes.string,
      trading_as: PropTypes.string.isRequired,
      initial_credit_limit_requested: PropTypes.number.isRequired,
      company_address: {
        address_line_one: PropTypes.string.isRequired,
        address_line_two: PropTypes.string,
        town_or_city: PropTypes.string.isRequired,
        county: PropTypes.string.isRequired,
        postcode: PropTypes.string.isRequired,
      },
    }),
    errors: PropTypes.object,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
  }

  initialState = {
    title: "",
    first_name: "",
    last_name: "",
    telephone: "",
    website: "",
    trading_as: "",
    initial_credit_limit_requested: 1000,
    company_address: {
      address_line_one: "",
      address_line_two: "",
      town_or_city: "",
      county: "",
      postcode: "",
    },
  }

  constructor(props) {
    super(props);
    const legalEntityData = _.pick(props.legalEntity, _.keys(this.initialState));
    this.state = { ...this.initialState, ...legalEntityData };
    props.onLoad && props.onLoad(this.state);
  }

  render() {
    const { readOnly, errors } = this.props;

    return (
      <Form className={styles.container}>
        {readOnly && (
          <Form.Section title="Company Details">
            <Form.Group>
              <Form.Label>Company Type</Form.Label>
              <Form.Input
                value="Sole Trader"
                readOnly={readOnly}
              />
            </Form.Group>
          </Form.Section>
        )}
        <Form.Section title="Trader Details">
          <Form.Group error={errors?.title}>
            <Form.Label>Title</Form.Label>
            <List
              className={classnames(
                styles.titleSelect,
                  errors?.title && styles.error,
              )}
              identifier="title"
              placeholder="Select Title..."
              value={this.state.title}
              readOnly={readOnly}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group error={errors?.first_name}>
            <Form.Label>First Name</Form.Label>
            <Form.Input
              placeholder="Enter First Name..."
              name="first_name"
              value={this.state.first_name}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.first_name}
              maxLength={100}
            />
          </Form.Group>
          <Form.Group error={errors?.last_name}>
            <Form.Label>Last Name</Form.Label>
            <Form.Input
              placeholder="Enter Last Name..."
              name="last_name"
              value={this.state.last_name}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.last_name}
              maxLength={100}
            />
          </Form.Group>
          <Form.Group error={errors?.telephone}>
            <Form.Label size="small" note={readOnly || "(Optional)"}>Phone Number</Form.Label>
            <Form.Input
              size="small"
              placeholder="Enter Phone Number..."
              name="telephone"
              value={this.state.telephone}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.telephone}
              maxLength={30}
            />
          </Form.Group>
          <Form.Group error={errors?.website}>
            <Form.Label note={readOnly || "(Optional)"}>Website URL</Form.Label>
            {readOnly && this.state.website ? (
              <Form.Value>
                <TextLink
                  className={styles.link}
                  target="_blank"
                  href={formatUrl(this.state.website)}
                  text={this.state.website}
                />
              </Form.Value>
            ) : (
              <Form.Input
                placeholder="Enter Website URL..."
                name="website"
                value={this.state.website}
                readOnly={readOnly}
                onChange={this.handleInputChange}
                highlightError={errors?.website}
                maxLength={255}
              />
            )}
          </Form.Group>
          <Form.Group error={errors?.trading_as}>
            <Form.Label note={readOnly || "(Optional)"}>Trading As</Form.Label>
            <Form.Input
              placeholder="Enter Trading As Name..."
              name="trading_as"
              value={this.state.trading_as}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.trading_as}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Estimated Credit Needed</Form.Label>
            <Form.Number
              size="small"
              name="initial_credit_limit_requested"
              value={this.state.initial_credit_limit_requested || 1000}
              placeholder="Set Amount..."
              prefix="£"
              readOnly={readOnly}
              onChange={this.handleInputChange}
              onBlur={() => this.handleInputChange({ target: { name: "initial_credit_limit_requested", value: upToNearestThousand(this.state.initial_credit_limit_requested) } })}
              formatNumber
            />
          </Form.Group>
        </Form.Section>
        <Form.Section title="Private Address">
          <Form.Group error={errors?.company_address?.address_line_one}>
            <Form.Label>Address Line 1</Form.Label>
            <Form.Input
              placeholder="Address Line 1..."
              name="address_line_one"
              value={this.state.company_address.address_line_one}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.address_line_one}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.address_line_two}>
            <Form.Label note={readOnly || "(Optional)"}>Address Line 2</Form.Label>
            <Form.Input
              placeholder="Address Line 2..."
              name="address_line_two"
              value={this.state.company_address.address_line_two}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.address_line_two}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.town_or_city}>
            <Form.Label>Town / City</Form.Label>
            <Form.Input
              placeholder="Town / City..."
              name="town_or_city"
              value={this.state.company_address.town_or_city}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.town_or_city}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.county}>
            <Form.Label>County</Form.Label>
            <Form.Input
              placeholder="County..."
              name="county"
              value={this.state.company_address.county}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.county}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.postcode}>
            <Form.Label size="small">Postcode</Form.Label>
            <Form.Input
              size="small"
              placeholder="Postcode..."
              name="postcode"
              value={this.state.company_address.postcode}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.postcode}
              maxLength={8}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => this.publishChange());
  }

  handleCompanyAddressChange = (e) => {
    this.setState({
      company_address: {
        ...this.state.company_address,
        [e.target.name]: e.target.value,
      },
    }, () => this.publishChange());
  }

  publishChange = () => {
    const { onChange } = this.props;

    onChange && onChange(this.state);
  }

}
