import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DISTRIBUTE_CREDIT_REQUEST = gql`
mutation($input: DistributeCreditRequestInput!) {
  distributeCreditRequest(input: $input) {
    id
    agency {
      id
      name
    }
    credit_requested
    credit_distributed
    date_communicated
    status
    is_agency_customer_verified
    created_at
    credit_request_communications {
      id
      address_type
      contact {
        first_name
        last_name
        email
      }
    }
  }
}
`;

const convertAccount = (distribution) => {
  const { account, amount } = distribution;
  if (account.type === "legalEntity") {
    return {
      amount: parseInt(amount),
      account: { legal_entity_id: account.id },
    };
  }
  if (account.type === "customerAccount") {
    return {
      amount: parseInt(amount),
      account: { customer_account_id: account.id },
    };
  }
};

/**
 *
 * @param {object} obj
 * @param {string} obj.creditRequestId
 * @param {string} obj.targetAccountId
 * @param {object[]} obj.distributions
 * @param {object} obj.distributions[].account
 * @param {string} obj.distributions[].account.id
 * @param {string} obj.distributions[].account.type
 * @param {number} obj.distributions[].amount
 * @param {string} obj.distributedBy
 * @returns
 */
export const distributeCreditRequest = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.DISTRIBUTE_CREDIT_REQUEST_PENDING,
    });

    const response = await apolloClient.query({
      query: DISTRIBUTE_CREDIT_REQUEST,
      variables: {
        input: {
          credit_request: obj.creditRequestId,
          target_account: obj.targetAccountId,
          source_accounts: obj.distributions.map(distribution => convertAccount(distribution)),
          distributed_by: obj.distributedBy,
        },
      },
    });

    if (!response.data.distributeCreditRequest) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DISTRIBUTE_CREDIT_REQUEST_SUCCESS, // TODO Redux this baby
      payload: {
        data: response.data.distributeCreditRequest,
      },
    });

  } catch (err) {
    dispatch({
      type: types.DISTRIBUTE_CREDIT_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

