import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../duck";
import { SearchInput } from "../../../common/components";
import styles from "./ContractContactSearch.module.scss";

export class ContractContactSearch extends Component {
  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    agencyId: PropTypes.string.isRequired,
    contractContactSearch: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string,
        primary_number: PropTypes.string,
        job_role: PropTypes.string,
      })).isRequired,
      error: PropTypes.string,
    }),
    getContractContacts: PropTypes.func.isRequired,
    onItemSelected: PropTypes.func.isRequired,
  }

  state = {
    searchText: "",
    showSearchResults: false,
  }

  render() {
    const { contractContactSearch } = this.props;
    const { searchText, showSearchResults } = this.state;
    const searchResults = _.chain(contractContactSearch.data)
      .uniqBy("email", "primary_number")
      .value();

    return (
      <div className={styles.container}>
        <SearchInput
          name="searchText"
          placeholder="Search for contacts..."
          value={searchText}
          onChange={this.handleSearchTextChanged}
          onSearch={this.handleSearch}
        />
        {showSearchResults && (
          <div className={styles.results}>
            {searchResults.length === 0 ? (
              <div className={styles.noResults}>No results found</div>
            ) : searchResults.map(contractContact => (
              <div key={contractContact.id} className={styles.resultItem} onClick={this.handleSearchResultClicked.bind(this, contractContact)}>
                <div className={styles.resultItemRow}>
                  <FontAwesomeIcon className={styles.icon} icon={faUser} />
                  <span className={classnames(styles.resultItemText, styles.resultItemName)}>{contractContact.first_name} {contractContact.last_name}</span>
                </div>
                {contractContact.email && (
                  <div className={styles.resultItemRow}>
                    <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
                    <span className={styles.resultItemText}>{contractContact.email}</span>
                  </div>
                )}
                {contractContact.primary_number && (
                  <div className={styles.resultItemRow}>
                    <FontAwesomeIcon className={styles.icon} icon={faPhone} />
                    <span className={styles.resultItemText}>{contractContact.primary_number}</span>
                  </div>
                )}
                {contractContact.job_role && (
                  <div className={styles.resultItemRow}>
                    <FontAwesomeIcon className={styles.icon} icon={faBriefcase} />
                    <span className={styles.resultItemText}>{contractContact.job_role}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  handleSearchTextChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = async () => {
    const { legalEntityId, agencyId, getContractContacts } = this.props;
    const { searchText } = this.state;

    await getContractContacts({
      legalEntityId,
      agencyId,
      searchText,
      sortOrder: {
        first_name: "ASC",
      },
    });

    this.setState({
      showSearchResults: !_.isEmpty(searchText),
    });
  }

  handleSearchResultClicked = (contractContact) => {
    const { onItemSelected } = this.props;

    this.setState({
      searchText: "",
      showSearchResults: false,
    });

    onItemSelected(contractContact);
  }

}

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractContactSearch);
