import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table2, TextLink } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./ContractList.module.scss";

export default class ContractList extends PureComponent {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      legal_entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      agency: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
      created_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
    sortOrder: PropTypes.shape({
      agency: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
      title: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
      created_at: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
      created_by: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
    }).isRequired,
    onColumnClick: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
  }

  render() {
    const { loading, data, sortOrder, onColumnClick, onItemClick } = this.props;

    return (
      <Table2
        className={styles.container}
        loading={loading}
        data={data}
      >
        <Table2.SortableColumn
          name="agency"
          value={row => row.agency.name}
          sortOrder={sortOrder.agency}
          onClick={onColumnClick}
        >
          Agency
        </Table2.SortableColumn>
        <Table2.Column
          name="customer_ref"
          value="-"
        >
          Customer Ref
        </Table2.Column>
        <Table2.SortableColumn
          name="title"
          value={row => row.title}
          sortOrder={sortOrder.title}
          onClick={onColumnClick}
        >
          Contract Title
        </Table2.SortableColumn>
        <Table2.SortableColumn
          name="created_at"
          value={row => parseDate(row.created_at)}
          sortOrder={sortOrder.created_at}
          onClick={onColumnClick}
        >
          Created At
        </Table2.SortableColumn>
        <Table2.SortableColumn
          name="created_by"
          value={row => [row.created_by.first_name, row.created_by.last_name].join(" ")}
          sortOrder={sortOrder.created_by}
          onClick={onColumnClick}
        >
          Created By
        </Table2.SortableColumn>
        <Table2.Column
          name="status"
          value="-"
        >
          Status
        </Table2.Column>
        <Table2.Column
          name=""
          value={row => (
            <TextLink
              className={styles.link}
              text="View"
              onClick={() => onItemClick(row)}
            />
          )}
        />
      </Table2>
    );
  }
}
