import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  AgencyCustomerAlreadyExistsModal,
  AgencyCustomerRequestAlreadyExistsModal,
  LegalEntityAlreadyExistsModal,
  RegisteredLegalEntityForm,
} from "../../components";
import { Heading } from "../../../common/components";
import styles from "./NewRegisteredCompanyPage.module.scss";

export default class NewRegisteredCompanyPage extends Component {

  static propTypes = {
    loading: PropTypes.bool,
    initialQuestions: PropTypes.object,
    exactLegalName: PropTypes.string,
    agency: PropTypes.shape({
      agencyId: PropTypes.string.isRequired,
      agencyName: PropTypes.string.isRequired,
    }),
    estimatedCreditLimit: PropTypes.number.isRequired,
    companiesHouseCompanyProfile: PropTypes.object,
    companiesHouseResults: PropTypes.object,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }),
    agencyCustomerRequestId: PropTypes.string,
    addAgencyCustomerRequest: PropTypes.func.isRequired,
    addRegisteredCompany: PropTypes.func.isRequired,
    searchCompaniesHouse: PropTypes.func.isRequired,
    getCompaniesHouseCompanyProfile: PropTypes.func.isRequired,
    reassignAgencyCustomerRequest: PropTypes.func.isRequired,
    updateAgencyCustomerRequest: PropTypes.func.isRequired,
    deleteAgencyCustomerRequest: PropTypes.func.isRequired,
    createCreditRequest: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      data: {
        registeredCompanyNumber: "",
        exactLegalName: props.exactLegalName || "",
      },
      legalEntityExistsModal: {
        visible: false,
        data: null,
      },
      agencyCustomerRequestExistsModal: {
        visible: false,
        data: null,
      },
      agencyCustomerExistsModal: {
        visible: false,
        data: null,
      },
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <Heading title="Add New Registered Company" />
        {this.renderStep()}
        <LegalEntityAlreadyExistsModal
          visible={this.state.legalEntityExistsModal.visible}
          agencyName={this.props.agency.agencyName}
          onSubmit={this.handleExistingLegalEntitySelected}
        />
        <AgencyCustomerRequestAlreadyExistsModal
          visible={this.state.agencyCustomerRequestExistsModal.visible}
          onSubmit={this.handleExistingAgencyCustomerRequestSelected}
          onCancel={this.handleCancelExistingAgencyCustomerRequest}
        />
        <AgencyCustomerAlreadyExistsModal
          visible={this.state.agencyCustomerExistsModal.visible}
          onSubmit={this.handleExistingAgencyCustomerSelected}
          onCancel={this.handleCancelExistingAgencyCustomer}
        />
      </div>
    );
  }

  renderStep = () => {
    switch (this.state.step) {
      case 0:
        return (
          <RegisteredLegalEntityForm.CompaniesHouseDetailsForm
            className={styles.form}
            loading={this.props.loading}
            registeredCompanyNumber={this.state.data.registeredCompanyNumber}
            exactLegalName={this.state.data.exactLegalName}
            companiesHouseResults={this.props.companiesHouseResults}
            onCancelClick={this.handleCancelClick}
            onCompanySelected={this.handleCompanySelected}
            onSaveAsDraftClick={this.handleSaveAsDraftClick}
            onSearchCompaniesHouseClick={this.handleSearchCompaniesHouseClick}
          />
        );

      case 1:
        return (
          <RegisteredLegalEntityForm.CompanyDetailsForm
            className={styles.form}
            loading={this.props.companiesHouseCompanyProfile.loading}
            companyProfile={this.props.companiesHouseCompanyProfile.data}
            submitting={this.props.ui.submitting}
            onCancelClick={this.handleCompanyDetailsFormCancel}
            onCompleteClick={this.handleCompleteClick}
          />
        );

      default:
        return (
          <div>Failed to load step</div>
        );
    }
  }

  handleCancelClick = () => {
    const { agencyCustomerRequestId, initialQuestions, onCancel, onGoBack } = this.props;

    if (agencyCustomerRequestId) {
      onGoBack();
    }
    else {
      onCancel(initialQuestions);
    }
  }

  handleCompanyDetailsFormCancel = () => {
    this.setState({
      step: 0,
    });
  }

  handleCompanySelected = async ({ company, existing }, formState) => {
    if (!existing) {
      this.setState({
        step: 1,
        data: {
          registeredCompanyNumber: formState.registeredCompanyNumber,
          exactLegalName: formState.exactLegalName,
        },
      }, () => this.props.getCompaniesHouseCompanyProfile({
        companyNumber: company.companyNumber,
      }));
      return;
    }
    else if (_.isEmpty(existing.agency_customer_requests)) {
      this.setState({
        legalEntityExistsModal: {
          visible: true,
          data: {
            company,
            existing,
            formState,
          },
        },
      });
    }
    else if (_.first(existing.agency_customer_requests).id === this.props.agencyCustomerRequestId) {
      // If we are not changing the legal entity associated with the agency customer request
      // then we can just update the details for it and redirect back to the Legal Entity profile.
      await this.props.updateAgencyCustomerRequest({
        agencyCustomerRequestId: this.props.agencyCustomerRequestId,
        registeredCompanyNumber: formState.registeredCompanyNumber,
        exactLegalName: formState.exactLegalName,
        redirectUrl: `/credit-limit-management/legal-entities/${existing.id}/credit-management`,
      });
    }
    else if (existing.agency_customer_requests.every(x => x.verified_at)) {
      this.setState({
        agencyCustomerExistsModal: {
          visible: true,
          data: {
            company,
            existing,
            formState,
          },
        },
      });
    }
    else {
      this.setState({
        agencyCustomerRequestExistsModal: {
          visible: true,
          data: {
            company,
            existing,
            formState,
          },
        },
      });
    }
  }

  handleExistingLegalEntitySelected = async () => {
    if (this.props.agencyCustomerRequestId) {
      // This is used to reassign the agency customer request if we have come via the
      // "Change Companies House Selection" route.
      await this.props.reassignAgencyCustomerRequest({
        agencyCustomerRequestId: this.props.agencyCustomerRequestId,
        legalEntityId: this.state.legalEntityExistsModal.data.existing.id,
      });
      await this.props.updateAgencyCustomerRequest({
        agencyCustomerRequestId: this.props.agencyCustomerRequestId,
        registeredCompanyNumber: this.state.legalEntityExistsModal.data.formState.registeredCompanyNumber,
        exactLegalName: this.state.legalEntityExistsModal.data.formState.exactLegalName,
        redirectUrl: `/credit-limit-management/legal-entities/${this.state.legalEntityExistsModal.data.existing.id}/credit-management`,
      });
    }
    else {
      await this.props.addAgencyCustomerRequest({
        agencyId: this.props.agency.agencyId,
        legalEntityId: this.state.legalEntityExistsModal.data.existing.id,
        creditLimitRequested: this.props.estimatedCreditLimit,
        registeredCompanyNumber: this.state.legalEntityExistsModal.data.formState.registeredCompanyNumber,
        exactLegalName: this.state.legalEntityExistsModal.data.formState.exactLegalName,
        redirectUrl: `/credit-limit-management/legal-entities/${this.state.legalEntityExistsModal.data.existing.id}/credit-management`,
      });
    }
  }

  handleExistingAgencyCustomerRequestSelected = async () => {
    const agencyCustomerRequest = _.first(this.state.agencyCustomerRequestExistsModal.data.existing.agency_customer_requests);

    if (this.props.agencyCustomerRequestId) {
      await this.props.deleteAgencyCustomerRequest({
        agencyCustomerRequestId: this.props.agencyCustomerRequestId,
      });
    }

    await this.props.updateAgencyCustomerRequest({
      agencyCustomerRequestId: agencyCustomerRequest.id,
      registeredCompanyNumber: this.state.agencyCustomerRequestExistsModal.data.formState.registeredCompanyNumber,
      exactLegalName: this.state.agencyCustomerRequestExistsModal.data.formState.exactLegalName,
      creditRequested: this.props.estimatedCreditLimit,
      redirectUrl: `/credit-limit-management/legal-entities/${this.state.agencyCustomerRequestExistsModal.data.existing.id}/credit-management`,
    });
  }

  handleCancelExistingAgencyCustomerRequest = () => {
    this.setState({
      agencyCustomerRequestExistsModal: {
        visible: false,
        data: null,
      },
    });
  }

  handleExistingAgencyCustomerSelected = async () => {
    // We have already verified, so we don't need to update the underlying request anymore.
    // We would now want to create a new credit request

    if (this.props.agencyCustomerRequestId) {
      await this.props.deleteAgencyCustomerRequest({
        agencyCustomerRequestId: this.props.agencyCustomerRequestId,
      });
    }

    await this.props.createCreditRequest({
      legalEntityId: this.state.agencyCustomerExistsModal.data.existing.id,
      agencyId: this.props.agency.agencyId,
      creditRequested: this.props.estimatedCreditLimit,
      redirectUrl: `/credit-limit-management/legal-entities/${this.state.agencyCustomerExistsModal.data.existing.id}/credit-management`,
    });
  }

  handleCancelExistingAgencyCustomer = () => {
    this.setState({
      agencyCustomerExistsModal: {
        visible: false,
        data: null,
      },
    });
  }

  handleCompleteClick = async (data) => {
    await this.props.addRegisteredCompany({
      registeredCompanyNumber: this.props.companiesHouseCompanyProfile.data.company_number,
      exactLegalName: this.props.companiesHouseCompanyProfile.data.company_name,
      companyType: this.props.companiesHouseCompanyProfile.data.type,
      companyStatus: this.props.companiesHouseCompanyProfile.data.company_status,
      website: data.website,
      isRegisteredCharity: data.isRegisteredCharity,
      registeredCharityNumber: data.registeredCharityNumber,
      agencyCustomerRequestId: this.props.agencyCustomerRequestId,
      agencyCustomerRequest: {
        agencyId: this.props.agency.agencyId,
        creditLimitRequested: this.props.estimatedCreditLimit,
        registeredCompanyNumber: this.state.data.registeredCompanyNumber,
        exactLegalName: this.state.data.exactLegalName,
      },
      validate: true,
    });
  }

  handleSaveAsDraftClick = (data) => {
    // eslint-disable-next-line no-console
    console.log("save as draft", data);
  }

  handleSearchCompaniesHouseClick = (data) => {
    const { searchCompaniesHouse } = this.props;

    searchCompaniesHouse({
      ...data,
      agencyId: this.props.agency.agencyId,
    });
  }

}
