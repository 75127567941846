import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";
import { parseDateTime, getUsersName } from "../../../../utils";
import styles from "./CreditApplicationCommentTooltip.module.scss";

export default function CreditApplicationCommentTooltip(props) {
  return (
    <Popup
      trigger={(
        <span className={styles.text}>
          {props.text || "See Comment"}
        </span>
      )}
      on="click"
      position="bottom right"
      eventsEnabled
      wide
    >
      <Popup.Content className={styles.content}>
        <div>
          {getUsersName(props.created_by)}
        </div>
        <div>
          <strong>{parseDateTime(props.created_at)}</strong>
        </div>
        <div className={styles.comment}>
          {`"${props.comment}"`}
        </div>
      </Popup.Content>
    </Popup>
  );
}

CreditApplicationCommentTooltip.propTypes = {
  text: PropTypes.string,
  comment: PropTypes.string,
  created_by: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  created_at: PropTypes.string,
};
