import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_CREDIT_REQUESTS = gql`
query(
  $legal_entity_id: ID!
  $sort_order: [SortOrder]
) {
  getLegalEntity(id: $legal_entity_id) {
    id

    credit_requests(sort_order: $sort_order) {
      id
      agency {
        id
        name
      }
      credit_requested
      credit_distributed
      date_communicated
      status
      is_agency_customer_verified
      created_at
      credit_request_communications {
        id
        address_type
        contact {
          first_name
          last_name
          email
        }
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getCreditRequests = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CREDIT_REQUESTS_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_CREDIT_REQUESTS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        sort_order: SortOrderConverter({
          created_at: "desc",
        }),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CREDIT_REQUESTS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.credit_requests,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_REQUESTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
