import { bytesToGigabytes } from "../../../../utils";

export default class ManageCreditModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate({ currentCreditLimit, currentExperianResult }) {
    const { data } = this;
    const errors = {};

    if (data?.reason === "increase") {
      if (data?.typeOfCheck === "insurer" || data?.typeOfCheck === "broker") {
        if (data?.newCreditLimit < currentCreditLimit) {
          errors.newCreditLimit = "Cannot apply for less than the existing credit limit";
        }

        if (data?.dateOfRequest >= new Date().setUTCHours(24, 0, 0, 0)) {
          errors.dateOfRequest = "Cannot select a date in the future";
        }
      }

      if (data?.typeOfCheck === "experian") {
        if (data?.dateOfResult >= new Date().setUTCHours(24, 0, 0, 0)) {
          errors.dateOfResult = "Cannot select a date in the future";
        }

        if (data?.companyFound && !data?.experianCreditReport) {
          errors.experianCreditReport = "Experian Credit Report is required";
        }

        if (bytesToGigabytes(data?.experianCreditReport?.size) > 5) {
          errors.experianCreditReport = "File size is too large. Must be smaller than 5GB";
        }
      }
    }

    if (data?.reason === "decrease") {
      if (data?.scheduledFor < new Date().setUTCHours(0, 0, 0, 0)) {
        errors.scheduledFor = "Cannot select a date in the past";
      }

      if (data?.typeOfCheck === "insurer") {
        if (data?.newCreditLimit >= currentCreditLimit) {
          errors.newCreditLimit = "New credit limit must be lower than the existing limit";
        }
      }

      if (data?.typeOfCheck === "experian") {
        if (data?.newCreditLimit >= currentExperianResult) {
          errors.newCreditLimit = "New credit limit must be lower than the existing Experian result";
        }
      }
    }

    if (data?.reason === "removal") {
      if (data?.scheduledFor < new Date().setHours(0, 0, 0, 0)) {
        errors.scheduledFor = "Cannot select a date in the past";
      }
    }

    if (data?.reason === "housekeeping") {
      if (data?.scheduledFor < new Date().setHours(0, 0, 0, 0)) {
        errors.scheduledFor = "Cannot select a date in the past";
      }
    }

    if (data?.reason === "distribute") {
      if (data?.distributeAmount > data?.fromAccount?.credit) {
        errors.distributeAmount = "This exceeds the amount available in selected from account";
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
