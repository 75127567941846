export const PAYROLL_FREQUENCY_OPTIONS = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "both", label: "Both" },
];

export const DEFAULT_PAYSLIP_PREFERENCE_OPTIONS = [
  { value: "email", label: "Email" },
  { value: "post", label: "Post" },
  { value: "both", label: "Both" },
];

export const DEFAULT_CUSTOMER_VAT_OPTIONS = [
  { value: "standard", label: "Standard (20%)" },
  { value: "exempt", label: "Exempt (0%)" },
  { value: "zero_rated", label: "Zero Rated (0%)" },
];

export const VAT_FREQUENCY_OPTIONS = [
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "annually", label: "Annually" },
];

export const CREDIT_APPLICATION_SOURCE_OPTIONS = [
  { value: "insurer", label: "Insurer" },
  { value: "broker", label: "Broker" },
  { value: "back_office", label: "Back Office" },
];

export const CREDIT_APPLICATION_STATUS_OPTIONS = [
  { value: "accepted", label: "Fully Approved" },
  { value: "partially_accepted", label: "Part Approved" },
  { value: "declined", label: "Declined" },
  { value: "cancelled", label: "Cancelled" },
  { value: "company_not_found", label: "Company Not Found" },
  { value: "pending", label: "Awaiting Result" },
  { value: "decrease", label: "Reduced" },
  { value: "removal", label: "Removed" },
  { value: "housekeeping", label: "Housekept" },
];

export const BROKER_CREDIT_APPLICATION_STATUS_OPTIONS = [
  { value: "accepted", label: "Go to Insurer" },
  { value: "declined", label: "Use Discretionary Limit" },
  { value: "cancelled", label: "Cancelled" },
  { value: "pending", label: "Awaiting Result" },
];
