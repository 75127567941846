import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CreditApplicationList } from "../../components";
import { options } from "../../../../constants";
import { getUsersName } from "../../../../utils";
import styles from "./CreditApplicationsTab.module.scss";

export default class CreditApplicationsTab extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    legalEntityId: PropTypes.string.isRequired,
    creditApplications: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        legal_entity: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
        created_at: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        credit_limit_requested: PropTypes.number,
        approved_credit: PropTypes.number,
        completed_at_excluding_vat: PropTypes.number,
        credit_requested_by: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        comments: PropTypes.array,
        chases: PropTypes.array,
      })).isRequired,
    }).isRequired,
    experianCreditChecks: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        experian_credit_limit: PropTypes.number.isRequired,
        created_at: PropTypes.number.isRequired,
        created_by: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        status: PropTypes.string.isRequired,
      })).isRequired,
      total: PropTypes.number.isRequired,
    }),
    scheduledCreditActions: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        source: PropTypes.string.isRequired,
        new_credit_limit: PropTypes.number.isRequired,
        scheduled_at: PropTypes.number.isRequired,
        scheduled_by: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
      })).isRequired,
    }),
    scheduledExperianActions: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        new_credit_limit: PropTypes.number.isRequired,
        scheduled_at: PropTypes.number.isRequired,
        scheduled_by: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
        }).isRequired,
        type: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
      })).isRequired,
    }),
    currentUser: PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    getCreditApplications: PropTypes.func.isRequired,
    getExperianCreditChecks: PropTypes.func.isRequired,
    getScheduledCreditActions: PropTypes.func.isRequired,
    getScheduledExperianActions: PropTypes.func.isRequired,
    showCancelBrokerRequestModal: PropTypes.func.isRequired,
    showCancelCreditApplicationModal: PropTypes.func.isRequired,
    showCancelScheduledCreditActionModal: PropTypes.func.isRequired,
    showChaseCreditApplicationModal: PropTypes.func.isRequired,
    showCompanyNotFoundModal: PropTypes.func.isRequired,
    showDeclineCreditApplicationModal: PropTypes.func.isRequired,
    showDownloadExperianDocumentsModal: PropTypes.func.isRequired,
    showEditCreditApplicationModal: PropTypes.func.isRequired,
    showFullyApproveCreditApplicationModal: PropTypes.func.isRequired,
    showGoToInsurerModal: PropTypes.func.isRequired,
    showPartApproveCreditApplicationModal: PropTypes.func.isRequired,
    showUseDiscretionaryLimitModal: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const {
      legalEntityId,
      getExperianCreditChecks,
      getCreditApplications,
      getScheduledCreditActions,
      getScheduledExperianActions,
    } = this.props;

    await Promise.all([
      getExperianCreditChecks(legalEntityId),
      getCreditApplications(legalEntityId),
      getScheduledCreditActions(legalEntityId),
      getScheduledExperianActions({ legalEntityId }),
    ]);
  }

  render() {
    const { history, legalEntityId } = this.props;

    const data = _.chain([
      ...this.props.creditApplications.data.map(creditApplication => ({
        type: "credit_application",
        id: creditApplication.id,
        key: `application_${creditApplication.id}`,
        dateApplied: creditApplication.date_of_request,
        source: options.CREDIT_APPLICATION_SOURCE_OPTIONS?.find(
          option => option.value === creditApplication.source
        )?.label || creditApplication.source,
        amountApplied: creditApplication.credit_limit_requested,
        amountRequested: creditApplication.credit_limit_requested,
        useDiscretionaryLimit: creditApplication.use_discretionary_limit,
        result: creditApplication.approved_credit_excluding_vat,
        resultDate: creditApplication.date_of_result,
        requestedBy: creditApplication.credit_requested_by,
        status: creditApplication.status,
        comments: creditApplication.comments,
        chases: creditApplication.chases,
        viewLink: `/credit-limit-management/legal-entities/${creditApplication.legal_entity.id}/credit-management/credit-applications/${creditApplication.id}`,
      })),
      ...this.props.experianCreditChecks.data.map(experianCreditCheck => ({
        type: "experian_credit_check",
        id: experianCreditCheck.id,
        key: `experian_${experianCreditCheck.id}`,
        dateApplied: experianCreditCheck.date_of_result,
        source: "Experian",
        amountApplied: null,
        amountRequested: null,
        result: experianCreditCheck.experian_credit_result,
        resultDate: experianCreditCheck.date_of_result,
        requestedBy: `${experianCreditCheck.created_by?.first_name} ${experianCreditCheck.created_by?.last_name}`,
        status: experianCreditCheck.status,
        viewLink: `/credit-limit-management/legal-entities/${legalEntityId}/credit-management`,
      })),
      ...this.props.scheduledCreditActions.data.map(scheduledCreditAction => ({
        type: "scheduled_credit_action",
        id: scheduledCreditAction.id,
        key: `scheduled_${scheduledCreditAction.id}`,
        dateApplied: scheduledCreditAction.created_at,
        source: options.CREDIT_APPLICATION_SOURCE_OPTIONS?.find(
          option => option.value === scheduledCreditAction.source
        )?.label || scheduledCreditAction.source,
        amountApplied: (scheduledCreditAction.type === "removal" || scheduledCreditAction.type === "housekeeping" )
          ? null
          : scheduledCreditAction.new_credit_limit,
        amountRequested: (scheduledCreditAction.type === "removal" || scheduledCreditAction.type === "housekeeping" )
          ? null
          : scheduledCreditAction.new_credit_limit,
        result: scheduledCreditAction.new_credit_limit,
        resultDate: scheduledCreditAction.scheduled_at,
        requestedBy: scheduledCreditAction.scheduled_by,
        status: scheduledCreditAction.type,
        isScheduled: scheduledCreditAction.status === "scheduled",
        isCancelled: scheduledCreditAction.status === "cancelled",
        viewLink: `/credit-limit-management/legal-entities/${scheduledCreditAction.legal_entity.id}/credit-management/scheduled-credit-actions/${scheduledCreditAction.id}`,
      })),
      ...this.props.scheduledExperianActions.data.map(scheduledExperianAction => ({
        type: "scheduled_experian_action",
        id: scheduledExperianAction.id,
        key: `scheduled_experian_${scheduledExperianAction.id}`,
        dateApplied: scheduledExperianAction.created_at,
        source: "Experian",
        amountApplied: null,
        amountRequested: null,
        result: scheduledExperianAction.new_credit_limit,
        resultDate: scheduledExperianAction.scheduled_at,
        requestedBy: getUsersName(scheduledExperianAction.scheduled_by),
        status: scheduledExperianAction.type,
        isScheduled: scheduledExperianAction.status === "scheduled",
        isCancelled: scheduledExperianAction.status === "cancelled",
        viewLink: `/credit-limit-management/legal-entities/${legalEntityId}/credit-management`,
      })),
    ]).orderBy("dateApplied").reverse().value();

    return (
      <div className={styles.container}>
        <CreditApplicationList
          history={history}
          data={data}
          onCancelBrokerRequestClick={(item) => {
            this.props.showCancelBrokerRequestModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onCancelCreditApplicationClick={(item) => {
            this.props.showCancelCreditApplicationModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onCancelScheduledCreditActionClick={(item) => {
            this.props.showCancelScheduledCreditActionModal({
              scheduledActionId: item.id,
            });
          }}
          onChaseCreditApplicationClick={(item) => {
            this.props.showChaseCreditApplicationModal({
              creditApplicationId: item.id,
              chasedBy: this.props.currentUser.attributes.name,
            });
          }}
          onCompanyNotFoundClick={(item) => {
            this.props.showCompanyNotFoundModal({
              creditApplicationId: item.id,
            });
          }}
          onDeclineClick={(item) => {
            this.props.showDeclineCreditApplicationModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onDownloadExperianDocumentsClick={(item) => {
            this.props.showDownloadExperianDocumentsModal({
              legalEntityId: this.props.legalEntityId,
              experianCreditCheckId: item.id,
            });
          }}
          onEditClick={(item) => {
            this.props.showEditCreditApplicationModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onFullyApproveClick={(item) => {
            this.props.showFullyApproveCreditApplicationModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onGoToInsurerClick={(item) => {
            this.props.showGoToInsurerModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onPartApproveClick={(item) => {
            this.props.showPartApproveCreditApplicationModal({
              creditApplicationId: item.id,
              legalEntityId: this.props.legalEntityId,
            });
          }}
          onUseDiscretionaryLimitClick={(item) => {
            this.props.showUseDiscretionaryLimitModal({
              creditApplicationId: item.id,
            });
          }}
        />
      </div>
    );
  }

}
