import _ from "lodash";

export default class AutoEnrolmentPensionsFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data, props } = this;
    let errors = {};

    if (!data?.pension_company) {
      errors = _.set(errors, "pension_company", "Pension Company is required");
    }

    if (!data?.staging_date) {
      errors = _.set(errors, "staging_date", "Staging Date is required");
    }

    if (!data?.default_postponement_months) {
      errors = _.set(errors, "default_postponement_months", "Default Postponement Months is required");
    }
    else if (data?.default_postponement_months < 0) {
      errors = _.set(errors, "default_postponement_months", "Default Postponement Months must be greater than zero");
    }

    if (!data?.eligible_job_holder_postponement_months) {
      errors = _.set(errors, "eligible_job_holder_postponement_months", "Eligible Job Holder Postponement Months is required");
    }
    else if (data?.eligible_job_holder_postponement_months < 0) {
      errors = _.set(errors, "eligible_job_holder_postponement_months", "Eligible Job Holder Postponement Months must be greater than zero");
    }

    if (!data?.employer_pension_reference) {
      errors = _.set(errors, "employer_pension_reference", "Employer Pension Reference is required");
    }

    if (!data?.is_new_agency && !data?.previous_pension_provider) {
      errors = _.set(errors, "previous_pension_provider", "Previous Pension Provider is required");
    }

    if (["weekly", "both"].includes(props?.data?.payroll_frequency)) {
      if (_.isEmpty(data?.pension_scheme_weekly)) {
        errors = _.set(errors, "pension_scheme_weekly", "Pension Scheme (Weekly) is required");
      }

      if (data?.pension_company?.value === "nest" && _.isEmpty(data?.payment_source_reference_weekly)) {
        errors = _.set(errors, "payment_source_reference_weekly", "Payment Source Reference (Weekly) is required");
      }
    }

    if (["monthly", "both"].includes(props?.data?.payroll_frequency)) {
      if (_.isEmpty(data?.pension_scheme_monthly)) {
        errors = _.set(errors, "pension_scheme_monthly", "Pension Scheme (Monthly) is required");
      }

      if (data?.pension_company?.value === "nest" && _.isEmpty(data?.payment_source_reference_monthly)) {
        errors = _.set(errors, "payment_source_reference_monthly", "Payment Source Reference (Monthly) is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
