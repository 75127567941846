import gql from "graphql-tag";

export const GET_LEGAL_ENTITIES = gql`
query($pager: Pager) {
  getLegalEntities(pager: $pager) {
    data {
      id
      name
      company_type
      company_number
      company_status
      credit_limit_excluding_vat
      credit_limit_including_vat
      undistributed_credit_limit
    }
    total
  }
}
`;

export const GET_COMPANY_HOUSE_COMPANY_PROFILE = gql`
query($company_number: String!) {
  getCompaniesHouseCompanyProfile(companyNumber: $company_number) {
    company_name
    type
    company_number
    accounts {
      last_accounts {
        made_up_to
      }
    }
    links {
      self
    }
    previous_company_names {
      name
      ceased_on
      effective_from
    }
    company_status
    company_status_detail
    registered_office_address {
      region
      postal_code
      locality
      address_line_2
      country
      address_line_1
    }
  }
}
`;

export const SEARCH_LEGAL_ENTITIES = gql`
query($pager: Pager!, $search_options: LegalEntitySearchOptions!) {
  searchLegalEntities(pager: $pager, search_options: $search_options) {
    data {
      id
      name
      company_type
      credit_limit_excluding_vat
      credit_limit_including_vat
      undistributed_credit_limit
      credit_suspended
      __typename

      ...on RegisteredCompany {
        company_number
        company_status
        inactive_since
        registered_charity_number
      }

      ...on RegisteredCharity {
        company_number
        company_status
        inactive_since
        registered_charity_number
      }

      ...on Charity {
        registered_charity_number
      }
    }
    total
  }
}
`;

export const ADD_LEGAL_ENTITY = gql`
mutation($input: AddLegalEntityInput!) {
  addLegalEntity(input: $input) {
    id
  }
}
`;

export const UPDATE_LEGAL_ENTITY = gql`
mutation($id: ID!, $input: UpdateLegalEntityInput!) {
  updateLegalEntity(id: $id, input: $input) {
    id
    name
    company_type
    meta {
      key
      value
    }

    ...on RegisteredCompany {
      company_number
      company_status
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        previous_company_names {
          name
        }
        registered_office_address {
          address_line_1
          address_line_2
          locality
          region
          postal_code
          country
        }
        type
      }
      registered_charity_number
    }

    ...on RegisteredCharity {
      registered_charity_number
      registered_charity_info {
        registered_charity_name
        charity_address_line_one
        charity_city
        charity_postcode
        charity_status
      }
    }
  }
}
`;

export const GET_CREDIT_DETAILS = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit
    total_credit_applications
    credit_suspended

    ...on RegisteredCompany {
      company_number
      companies_house_info {
        accounts {
          next_due
        }
      }
    }
  }
}
`;

export const GET_CREDIT_APPLICATION = gql`
query($legal_entity_id: ID!, $credit_application_id: ID!) {
  getLegalEntityCreditApplication(
    legal_entity_id: $legal_entity_id
    credit_application_id: $credit_application_id
  ) {
    id
    source
    reference_number
    status
    credit_limit_requested
    credit_requested_by
    date_of_request
    date_of_result
    created_at
    created_by {
        id
        first_name
        last_name
    }
    comments {
      id
      created_by {
        first_name
        last_name
      }
      type
      comment
      created_at
    }
    legal_entity {
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }
    experian_credit_check {
      experian_credit_result
    }
  }
}
`;

export const CREATE_CREDIT_APPLICATION_COMMENT = gql`
mutation($input: CreateCreditApplicationCommentInput!) {
  createCreditApplicationComment(input:$input) {
    id
    created_by {
      first_name
      last_name
    }
    comment
    created_at
  }
}
`;

export const GET_EXPERIAN_CREDIT_CHECKS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getExperianCreditChecks(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      experian_credit_result
      date_of_result
      created_at
      created_by {
        first_name
        last_name
      }
      status
    }
  }
}
`;

export const GET_LATEST_EXPERIAN_CREDIT_CHECK = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getExperianCreditChecks(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      experian_credit_result
      date_of_result
      created_at
      created_by {
        first_name
        last_name
      }
      status
    }
  }
}
`;

export const GET_CONTRACTS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder], $search_options: ContractSearchOptions) {
  getContracts(
    legal_entity_id: $legal_entity_id
    pager: $pager
    sort_order: $sort_order
    search_options: $search_options
  ) {
    data {
      id
      agency {
        name
      }
      title
      created_at
      created_by {
        first_name
        last_name
      }
    }
    total
  }
}
`;

export const GET_CONTRACT = gql`
query($contract_id: ID!) {
  getContract(id: $contract_id) {
    id
    title
    start_date
    end_date
    estimated_credit
    type
    self_billing
    po_number_required
    default_po_number
    self_billing_org
    invoice_email_address
    invoice_telephone_number
    invoice_frequency
    invoice_preference
    invoice_fao
    timesheet_groupings
    vat_type
    invoice_address_same_as_site_address
    invoice_email_addresses {
      email_address
      copy_preference
    }
    trading_style {
      name
    }
    payment_terms {
      type
      value
    }
    agency {
      id
      name
    }
    legal_entity {
      id
      name
    }
    invoice_address {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
    }
    site_address {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
    }
    contacts {
      first_name
      last_name
      email
      primary_number
      secondary_number
      job_role
    }
  }
}
`;

export const GET_CREDIT_ACTIVITY = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getCreditActivity(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    legal_entity {
      id
    }
    action
    via
    type
    document_id
    created_by {
      first_name
      last_name
    }
    created_at
  }
}
`;

export const GET_CREDIT_APPLICATIONS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder], $include_completed: Boolean) {
  getLegalEntityCreditApplications(
    legal_entity_id: $legal_entity_id
    pager: $pager
    sort_order: $sort_order
    include_completed: $include_completed
  ) {
    data {
      id
      legal_entity {
        id
      }
      created_at
      date_of_request
      date_of_result
      source
      credit_limit_requested
      approved_credit_excluding_vat
      use_discretionary_limit
      completed_at
      credit_requested_by
      status
      comments {
        id
        comment
        type
        created_by {
          first_name
          last_name
        }
        created_at
      }
      chases {
        name
        date_of_chase
        comments
      }
    }
  }
}
`;

export const CANCEL_CREDIT_APPLICATION = gql`
mutation($input: CancelCreditApplicationInput!) {
  cancelCreditApplication(input:$input) {
    id
    reference_number
    credit_limit_requested
    credit_requested_by
    status
    date_of_request
    date_of_result
    created_at
    created_by {
        id
        first_name
        last_name
    }
    comments {
      id
      created_by {
        first_name
        last_name
      }
      comment
      type
      created_at
    }
    legal_entity {
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }
  }
}
`;

export const CREATE_SCHEDULED_CREDIT_ACTION = gql`
mutation($input: CreateScheduledCreditActionInput!) {
  createScheduledCreditAction(input: $input) {
    id
    legal_entity {
      id
    }
    type
    source
    new_credit_limit
    scheduled_by
    scheduled_at
    comment
    status
    completed_at
    created_at
    updated_at
    created_by {
      first_name
      last_name
    }
  }
}
`;
export const GET_SCHEDULED_CREDIT_ACTIONS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getScheduledCreditActions(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      legal_entity {
        id
      }
      source
      new_credit_limit
      scheduled_at
      scheduled_by
      type
      status
      created_at
    }
  }
}
`;

export const GET_SCHEDULED_CREDIT_ACTION = gql`
query($scheduled_credit_action_id: ID!) {
  getScheduledCreditAction(id: $scheduled_credit_action_id) {
    id
    type
    source
    new_credit_limit
    scheduled_at
    scheduled_by
    status
    comment
    created_at
    created_by {
      first_name
      last_name
    }
  }
}
`;

export const CANCEL_SCHEDULED_CREDIT_ACTION = gql`
mutation($scheduled_credit_action_id: ID!) {
  cancelScheduledCreditAction(id: $scheduled_credit_action_id) {
    id
    status
  }
}
`;

export const CREATE_CONTRACT = gql`
mutation($input: AddContractInput!) {
  addContract(input: $input) {
    id
  }
}
`;

export const GET_CONTRACT_CONTACTS = gql`
query(
  $legal_entity_id: ID!
  $agency_id: ID!
  $pager: Pager!
  $sort_order: [SortOrder]
  $search_options: ContractContactSearchOptions
) {
  getContractContacts(
    legal_entity_id: $legal_entity_id
    agency_id: $agency_id
    pager: $pager
    sort_order: $sort_order
    search_options: $search_options
  ) {
    data {
      first_name
      last_name
      email
      primary_number
      job_role
    }
    total
  }
}
`;

export const GET_TRADING_STYLES = gql`
query(
  $legal_entity_id: ID!,
  $agency_id: ID!,
  $search_options: TradingStyleSearchOptions
) {
  getTradingStyles(
    legal_entity_id: $legal_entity_id,
    agency_id: $agency_id,
    search_options: $search_options
  ) {
      id
      name
    }
  }
`;
