import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import InsurerApplicationCreatedModal from "./InsurerApplicationCreatedModal";

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(null, mapDispatchToProps)(InsurerApplicationCreatedModal);

container.displayName = "InsurerApplicationCreatedModal";

export default container;
