import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, DocumentDownloadCard } from "../../../common/components";
import styles from "./DownloadExperianDocumentsModal.module.scss";

export default function DownloadExperianDocumentsModal (props) {
  useEffect(() => {
    props.getExperianCreditCheckDocuments({
      legalEntityId: props.legalEntityId,
      experianCreditCheckId: props.experianCreditCheckId,
    });
  }, []);

  const handleDownload = () => {
    // Currently only supports single document per Experian Credit Check
    props.downloadExperianCreditCheckDocument({
      legalEntityId: props.legalEntityId,
      experianCreditCheckId: props.experianCreditCheckId,
      versionId: null,
    });
  };

  const handleSubmit = () => {
    props.hideModal(DownloadExperianDocumentsModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Documents"
      visible
      className={styles.container}
      submitText="Close"
      onSubmit={handleSubmit}
      submitVariant={"outline-primary"}
    >
      {props.documents.map(document => (
        <DocumentDownloadCard
          key={document.name}
          fileName={[document.name, document.metadata.extension].join("")}
          fileSize={document.size}
          uploadDate={document.metadata.date_uploaded}
          onDownload={() => handleDownload()}
        />
      ))}
    </ConfirmationModal>
  );
}

DownloadExperianDocumentsModal.propTypes = {
  legalEntityId: PropTypes.string.isRequired,
  experianCreditCheckId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    lastModified: PropTypes.number,
  })).isRequired,
  downloadExperianCreditCheckDocument: PropTypes.func.isRequired,
  getExperianCreditCheckDocuments: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

DownloadExperianDocumentsModal.displayName = "DownloadExperianDocumentsModal";
