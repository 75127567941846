import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import path from "path";
import { Close, ExpandMoreRounded, GetApp } from "@material-ui/icons";
import { Table2 } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./DocumentList.module.scss";

export default class DocumentList extends Component {

  static propTypes = {
    loading: PropTypes.bool,
    documents: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      lastModified: PropTypes.number.isRequired,
      metadata: PropTypes.shape({
        user: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        documentType: PropTypes.string,
      }),
      versions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired,
        lastModified: PropTypes.number.isRequired,
        versionId: PropTypes.string.isRequired,
        isLatest: PropTypes.bool.isRequired,
        metadata: PropTypes.shape({
          user: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
          }),
          documentType: PropTypes.string,
        }),
      })),
    })),
    onDownloadClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
  }

  state = {
    groups: {},
  }

  componentWillReceiveProps(props) {
    if (props.documents) {
      this.setState({
        groups: props.documents?.reduce((r, v) => {
          const p = path.basename(v.name, path.extname(v.name));

          if (!r[p]) {
            r[p] = [];
          }

          if (v.versions) {
            return r;
          }

          return {
            ...r,
            [p]: [...r[p], v],
          };
        }, {}),
      });
    }
  }

  render() {
    const { loading } = this.props;
    const { groups } = this.state;
    const groupNames = Object.keys(groups);
    const expandable = g => _.chain(g)
      .slice(0, g[0].expanded ? g.length : 1)
      .sort((a,b) => b.metadata.date_uploaded - a.metadata.date_uploaded)
      .value();

    return (
      <Table2
        className={styles.table}
        loading={loading}
        data={groupNames}
        rowSelectable={group => groups[group].length > 1}
        onRowClick={this.handleRowClicked}
      >
        <Table2.Column
          className={styles.nameColumn}
          name="name"
          value={(group) => {
            const documentGroup = groups[group];

            if (documentGroup.length > 1) {
              return expandable(documentGroup).map(row => (
                <div
                  key={`${row.name}_${row.version}`}
                  className={classnames(styles.name, styles.expandedRow)}
                >
                  {`${documentGroup[0].metadata.document_type || group} v${row.version}`}
                </div>
              ));
            }

            return (
              <div className={styles.name}>
                {documentGroup[0].metadata.document_type || documentGroup[0].name}
              </div>
            );
          }}
        >
          Document
        </Table2.Column>
        <Table2.Column
          className={styles.expandableColumn}
          name="expandable"
          value={group => groups[group].length > 1 && expandable(groups[group]).map((row, i) => (
            <ExpandMoreRounded
              key={`expandable_${i}`}
              className={classnames(
                styles.expandIcon,
                i > 0 && styles.hidden,
                groups[group][0].expanded && styles.expandIconExpanded
              )}
            />
          ))}
        />
        <Table2.Column
          className={styles.uploadedByColumn}
          name="uploadedBy"
          value={group => expandable(groups[group]).map((row, i) => (
            <div
              key={`uploaded_by_${i}`}
              className={classnames(styles.text, styles.expandedRow)}
            >
              {row.metadata && row.metadata.user
                ? `${row.metadata.user.first_name} ${row.metadata.user.last_name}`
                : "-"
              }
            </div>
          ))}
        >
          Uploaded By
        </Table2.Column>
        <Table2.Column
          className={styles.uploadDateColumn}
          name="lastModified"
          value={group => expandable(groups[group]).map((row, i) => (
            <div
              key={i}
              className={classnames(styles.text, styles.expandedRow)}
            >
              {parseDate(row.metadata.date_uploaded)}
            </div>
          ))}
        >
          Upload Date
        </Table2.Column>
        <Table2.Column
          collapsed
          className={styles.action}
          name="download"
          value={group => expandable(groups[group]).map((row, i) => (
            <div
              key={`download_${i}`}
              className={styles.expandedRow}
            >
              <GetApp
                className={styles.downloadIcon}
                onClick={this.handleDownloadClicked.bind(this, row)}
              />
            </div>
          ))}
        />
        <Table2.Column
          collapsed
          className={styles.action}
          name="remove"
          value={group => expandable(groups[group]).map((row, i) => (
            <div
              key={`remove_${i}`}
              className={styles.expandedRow}
            >
              <Close
                className={styles.deleteIcon}
                onClick={this.handleRemoveClicked.bind(this, row)}
              />
            </div>
          ))}
        />
      </Table2>
    );
  }

  handleRowClicked = (row) => {
    const { groups } = this.state;

    groups[row][0].expanded = !groups[row][0].expanded;

    this.setState({ groups });
  }

  handleDownloadClicked = (row, e) => {
    e.stopPropagation();

    const { onDownloadClick } = this.props;

    onDownloadClick && onDownloadClick(row);
  }

  handleRemoveClicked = (row, e) => {
    e.stopPropagation();

    const { onRemoveClick } = this.props;

    onRemoveClick && onRemoveClick(row);
  }

}
