import _ from "lodash";

export default class ConditionalQuestionsFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data, props } = this;
    const errors = {};

    if (_.isEmpty(data?.worker_types)) {
      errors.worker_types = "You must specify at least one worker type";
    }

    if (_.isEmpty(data?.known_as)) {
      errors.known_as = "Known As is required";
    }

    if (_.isEmpty(data?.short_code)) {
      errors.short_code = "Short Code is required";
    }
    else if (String(data?.short_code).length !== 2) {
      errors.short_code = "Short Code must contain two characters";
    }
    else if (props?.newAgency?.shortCodeExists) {
      errors.short_code = "This short code has already been taken";
    }

    if (!data?.agency_status?.value) {
      errors.agency_status = "Agency Status is required";
    }

    if (!_.isNull(data?.is_new_agency) && !data?.is_new_agency && !data?.previous_pension_provider) {
      errors.previous_pension_provider = "Previous Pension Provider is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
