import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_LEGAL_ENTITY_DETAILS = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    id
    name
    company_type
    meta {
      key
      value
    }

    ...on RegisteredCompany {
      company_number
      company_status
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        registered_office_address {
          address_line_1
          address_line_2
          locality
          region
          postal_code
          country
        }
        type
        certificate_of_incorporation_url
      }
      is_registered_charity
      registered_charity_number
    }

    ...on RegisteredCharity {
      registered_charity_number
      registered_charity_info {
        registered_charity_name
        charity_address_line_one
        charity_city
        charity_postcode
        charity_status
      }
    }

    ...on Charity {
      registered_charity_number
      telephone
      website
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }

    ...on Government {
      telephone
      website
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }

    ...on Partnership {
      telephone
      website
      trading_as
      partners {
        id
        title
        first_name
        last_name
        phone_number
        address {
          address_line_one
          address_line_two
          town_or_city
          county
          postcode
        }
        position
      }
    }

    ...on SoleTrader {
      title
      first_name
      last_name
      telephone
      website
      trading_as
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getLegalEntityDetails = ({ legalEntityId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_LEGAL_ENTITY_DETAILS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_LEGAL_ENTITY_DETAILS,
      variables: {
        id: legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_LEGAL_ENTITY_DETAILS_SUCCESS,
      payload: {
        legalEntityDetails: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LEGAL_ENTITY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
