import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import ConditionalQuestionsFormValidator from "./ConditionalQuestionsFormValidator";
import { Button, ButtonGroup, Form, TextLink } from "../../../common/components";
import { List } from "../../../lists/containers";
import { AgencyStatusConverter, CompanyTypeConverter } from "../../../../converters";
import styles from "./ConditionalQuestionsForm.module.scss";

const ALLOWED_AGENCY_STATUSES = [
  "live_active",
  "live_not_active",
];

export default class ConditionalQuestionsForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      worker_types: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      is_new_agency: PropTypes.bool.isRequired,
      is_ledger_buyout: PropTypes.bool.isRequired,
      is_existing_paye_scheme: PropTypes.bool.isRequired,
      known_as: PropTypes.string,
      trading_as: PropTypes.string,
      short_code: PropTypes.string,
    }),
    newAgency: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
    }),
    selectedCompanyProfile: PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      company_number: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      company_status: PropTypes.string.isRequired,
      known_as: PropTypes.string,
      trading_as: PropTypes.string,
    }),
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    checkAgencyShortCode: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    onViewExistingAgencyShortCodes: PropTypes.func.isRequired,
  }

  initialState = {
    worker_types: [],
    is_new_agency: true,
    is_ledger_buyout: false,
    is_existing_paye_scheme: false,
    previous_provider: null,
    agency_status: {
      value: "live_active",
    },
    introduced_via_broker: false,
    known_as: "",
    trading_as: "",
    short_code: "",
    previous_pension_provider: "",
  }

  constructor(props) {
    super(props);

    this.state = {
      busy: false,
      data: { ...this.initialState, ...props.data },
    };
  }

  render() {
    const { className, isSubmitting } = this.props;

    return (
      <Form className={classnames(className, styles.form)}>
        {this.renderRequiredQuestionsSection()}
        <ButtonGroup className={styles.actions}>
          <Button
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            busy={isSubmitting}
            onClick={this.handleSubmit}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Form>
    );
  }

  renderRequiredQuestionsSection = () => {
    const { isSubmitting } = this.props;
    const { data, errors } = this.state;

    return (
      <Form.Section title="Required Questions">
        <Form.Group error={errors?.worker_types}>
          <Form.Label>What kind of workers?</Form.Label>
          <List
            identifier="worker_types"
            value={data.worker_types}
            onChange={this.handleWorkerTypeChange}
          />
        </Form.Group>
        <Form.Group error={errors?.is_new_agency}>
          <Form.Label>Is the agency new or existing?</Form.Label>
          <List
            identifier="agency_new"
            name="is_new_agency"
            value={String(data.is_new_agency)}
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={errors?.is_ledger_buyout}>
          <Form.Label>Ledger Buy-out?</Form.Label>
          <Form.Radio
            value={true}
            name="is_ledger_buyout"
            checked={data.is_ledger_buyout}
            text="Yes"
            disabled={data.is_new_agency}
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="is_ledger_buyout"
            checked={!data.is_ledger_buyout}
            text="No"
            disabled={data.is_new_agency}
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={errors?.previous_provider}>
          <Form.Label>Previous Provider (Optional)</Form.Label>
          <Form.Value size="medium">
            <List
              className={styles.previousProviders}
              identifier="previous_provider"
              value={data.previous_provider?.value}
              disabled={data.is_new_agency}
              onChange={this.handlePreviousProviderChange}
              optionsParser={options => _.sortBy(options, option => option.label.toLowerCase())}
              fluid
            />
          </Form.Value>
        </Form.Group>
        <Form.Group error={errors?.is_existing_paye_scheme}>
          <Form.Label>Existing PAYE Scheme?</Form.Label>
          <Form.Radio
            value={true}
            name="is_existing_paye_scheme"
            checked={data.is_existing_paye_scheme}
            text="Yes"
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="is_existing_paye_scheme"
            checked={!data.is_existing_paye_scheme}
            text="No"
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={errors?.previous_pension_provider}>
          <Form.Label>Previous Pension Provider</Form.Label>
          <Form.Value>
            <List
              identifier="previous_pension_company"
              name="previous_pension_provider"
              value={data.previous_pension_provider?.value}
              highlightError={errors?.previous_pension_provider}
              onChange={this.handlePreviousPensionProviderChange}
              disabled={data.is_new_agency}
            />
          </Form.Value>
        </Form.Group>
        <Form.Group error={errors?.introduced_via_broker}>
          <Form.Label>Has the agency been introduced through a broker?</Form.Label>
          <Form.Radio
            value={true}
            name="introduced_via_broker"
            checked={data.introduced_via_broker}
            text="Yes"
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="introduced_via_broker"
            checked={!data.introduced_via_broker}
            text="No"
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={errors?.known_as}>
          <Form.Label>Known As</Form.Label>
          <Form.Input
            name="known_as"
            value={data.known_as}
            disabled={isSubmitting}
            onChange={this.handleInputChange}
            maxLength={30}
            highlightError={errors?.known_as}
          />
        </Form.Group>
        <Form.Group error={errors?.trading_as}>
          <Form.Label>Trading As (Optional)</Form.Label>
          <Form.Input
            name="trading_as"
            value={data.trading_as}
            maxLength={255}
            disabled={isSubmitting}
            onChange={this.handleInputChange}
            highlightError={errors?.trading_as}
          />
        </Form.Group>
        <Form.Group error={errors?.short_code}>
          <Form.Label>Short Code</Form.Label>
          <Form.Input
            name="short_code"
            value={data.short_code}
            maxLength={2}
            disabled={isSubmitting}
            onChange={this.handleShortCodeChange}
            highlightError={errors?.short_code}
          />
          <TextLink
            className={styles.link}
            text="View existing Short Codes"
            onClick={this.handleViewExistingShortCodesClicked}
          />
        </Form.Group>
        <Form.Group error={errors?.agency_status}>
          <Form.Label>Agency Status</Form.Label>
          <Form.Value size="medium">
            <List
              className={styles.agencyStatuses}
              identifier="agency_status"
              value={data.agency_status?.value}
              onChange={this.handleAgencyStatusChange}
              onReady={this.handleAgencyStatusReady}
              fluid
              highlightError={errors?.agency_status}
              optionsParser={options => options.filter((option) => {
                return ALLOWED_AGENCY_STATUSES.includes(option.value);
              })}
            />
          </Form.Value>
        </Form.Group>
      </Form.Section>
    );
  }

  canSubmit = () => {
    const { newAgency, isSubmitting } = this.props;

    return !newAgency.loading && !isSubmitting;
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const stateValue = name === "short_code"
      ? value.toUpperCase()
      : value;

    this.setState({
      data: {
        ...this.state.data,
        [name]: stateValue,
      },
    });
  }

  handleWorkerTypeChange = (workerTypeId) => {
    const workerTypes = this.state.data.worker_types;

    this.setState({
      data: {
        ...this.state.data,
        worker_types: workerTypes.includes(workerTypeId)
          ? workerTypes.filter(workerType => workerType !== workerTypeId)
          : [...workerTypes, workerTypeId],
      },
    });
  }

  handlePreviousProviderChange = (_e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        previous_provider: item,
      },
    });
  }

  handlePreviousPensionProviderChange = (_e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        previous_pension_provider: item,
      },
    });
  }

  handleShortCodeChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    }, this.validateShortCode);
  }

  handleAgencyStatusChange = (_e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        agency_status: item,
      },
    });
  }

  handleAgencyStatusReady = (listProps) => {
    // Only set the state if we haven't already.
    if (!this.state.data?.agency_status?.id) {
      this.setState({
        data: {
          ...this.state.data,
          agency_status: listProps.data?.values[0],
        },
      });
    }
  }

  handleViewExistingShortCodesClicked = () => {
    const { data } = this.state;
    const { onViewExistingAgencyShortCodes } = this.props;

    onViewExistingAgencyShortCodes && onViewExistingAgencyShortCodes(data);
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = async () => {
    const { data } = this.state;
    const { selectedCompanyProfile, onContinue } = this.props;

    let is_existing_paye_scheme = data.is_existing_paye_scheme;
    let is_ledger_buyout = data.is_ledger_buyout;
    let previous_provider_id = +data.previous_provider?.id;
    let previous_pension_provider_id = +data.previous_pension_provider?.id;

    if (data.is_new_agency) {
      is_existing_paye_scheme = null;
      is_ledger_buyout = null;
      previous_provider_id = null;
      previous_pension_provider_id = null;
    }

    const validator = new ConditionalQuestionsFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onContinue && onContinue({
      agency_info: {
        name: data.known_as,
        exact_legal_name: selectedCompanyProfile.company_name,
        company_number: selectedCompanyProfile.company_number,
        type: CompanyTypeConverter.from(selectedCompanyProfile.type),
        status: AgencyStatusConverter.from(selectedCompanyProfile.company_status),
        known_as: data.known_as,
        trading_as: data.trading_as,
        short_code: data.short_code,
        is_new_agency: data.is_new_agency,
        is_existing_paye_scheme,
        is_ledger_buyout,
        previous_provider_id,
        introduced_via_broker: data.introduced_via_broker,
        agency_status_id: +data.agency_status.id,
        previous_pension_provider_id,
      },
      worker_types: data.worker_types,
    });
  }

  validateShortCode = _.debounce(async () => {
    const { data } = this.state;
    const { checkAgencyShortCode } = this.props;

    checkAgencyShortCode({
      shortCode: data.short_code,
    });
  }, 200);
}
