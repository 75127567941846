import React, { Component } from "react";
import PropTypes from "prop-types";
import CharityLegalEntityForm from "./CharityLegalEntityForm/CharityLegalEntityForm";
import CharityLegalEntityFormValidator from "./CharityLegalEntityForm/CharityLegalEntityFormValidator";
import GovernmentLegalEntityForm from "./GovernmentLegalEntityForm/GovernmentLegalEntityForm";
import GovernmentLegalEntityFormValidator from "./GovernmentLegalEntityForm/GovernmentLegalEntityFormValidator";
import PartnershipLegalEntityForm from "./PartnershipLegalEntityForm/PartnershipLegalEntityForm";
import PartnershipLegalEntityFormValidator from "./PartnershipLegalEntityForm/PartnershipLegalEntityFormValidator";
import SoleTraderLegalEntityForm from "./SoleTraderLegalEntityForm/SoleTraderLegalEntityForm";
import SoleTraderLegalEntityFormValidator from "./SoleTraderLegalEntityForm/SoleTraderLegalEntityFormValidator";
import { Button, ButtonGroup, UnsavedChangesModal } from "../../../common/components";
import styles from "./NonRegisteredLegalEntityForm.module.scss";

export default class NonRegisteredLegalEntityForm extends Component {

  static propTypes = {
    companyType: PropTypes.string,
    legalEntity: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      block: PropTypes.func.isRequired,
    }),
    mode: PropTypes.oneOf(["edit", "add"]).isRequired,
    errors: PropTypes.object,
    readOnly: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
  }

  static defaultProps = {
    mode: "add",
  }

  state = {
    isDirty: false,
    nextLocation: null,
    showUnsavedChangesModal: false,
    data: null,
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (props.mode === "edit" && props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }

    return null;
  }

  componentDidMount() {
    const { history, onSubmit } = this.props;

    window.onbeforeunload = this.handlePageLeave;

    if (history && onSubmit) {
      this.unblock = history.block((nextLocation) => {
        const { isDirty } = this.state;

        if (!isDirty) return true;

        this.setState({
          showUnsavedChangesModal: true,
          nextLocation: nextLocation,
        });

        return false;
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.unblock && this.unblock();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyType !== prevProps.companyType) {
      this.setState({
        errors: {},
      });
    }
  }

  render() {
    return (
      <>
        {this.renderForm()}
        {(this.props.onCancel || this.props.onSubmit) && (
          <div className={styles.actions}>
            <ButtonGroup>
              {this.props.onCancel && (
                <Button
                  variant="outline-danger"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>
              )}
              {this.props.onSubmit && (
                <Button
                  variant="primary"
                  onClick={this.handleSubmitClicked}
                  disabled={!this.state.data}
                >
                  Save Company
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}
        <UnsavedChangesModal
          visible={this.state.showUnsavedChangesModal}
          onClose={this.handleCloseSavedChangesModal}
          onGoBackClick={this.handleCloseSavedChangesModal}
          onLeaveClick={this.handleLeaveClicked}
        />
      </>
    );
  }

  renderForm = () => {
    const { errors } = this.state;

    switch (this.props.companyType) {
      case "charity":
        return (
          <CharityLegalEntityForm
            {...this.props}
            errors={errors}
            onChange={this.handleChange}
            onLoad={this.props.onLoad}
          />
        );

      case "government":
        return (
          <GovernmentLegalEntityForm
            {...this.props}
            errors={errors}
            onChange={this.handleChange}
            onLoad={this.props.onLoad}
          />
        );

      case "partnership":
        return (
          <PartnershipLegalEntityForm
            {...this.props}
            errors={errors}
            onChange={this.handleChange}
            onLoad={this.props.onLoad}
          />
        );

      case "sole_trader":
        return (
          <SoleTraderLegalEntityForm
            {...this.props}
            errors={errors}
            onChange={this.handleChange}
            onLoad={this.props.onLoad}
          />
        );

      default:
        return null;
    }
  }

  handleChange = (data) => {
    const { onChange } = this.props;

    this.setState({
      isDirty: true,
      data,
    }, () => onChange && onChange(data));
  }

  handleSubmitClicked = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    if (this.validate()) {
      this.setState({
        isDirty: false,
      }, () => onSubmit && onSubmit(data));
    }
  }

  // Block when leaving when there are changes

  handleCloseSavedChangesModal = () => {
    this.setState({
      showUnsavedChangesModal: false,
    });
  }

  handleLeaveClicked = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.setState({
      isDirty: false,
      showUnsavedChangesModal: false,
    });

    window.onbeforeunload = null;
    this.unblock();

    history.push(nextLocation.pathname);
  }

  // Validate the data on the form

  validate = () => {
    const validator = this.getValidator();

    if (!validator) return true;

    const result = validator.validate();

    this.setState({ errors: result.errors });

    return result.success;
  }

  getValidator = () => {
    const { companyType } = this.props;
    const { data } = this.state;

    switch (companyType) {
      case "charity":
        return new CharityLegalEntityFormValidator(data);

      case "government":
        return new GovernmentLegalEntityFormValidator(data);

      case "partnership":
        return new PartnershipLegalEntityFormValidator(data);

      case "sole_trader":
        return new SoleTraderLegalEntityFormValidator(data);

      default:
        return null;
    }
  }

}
