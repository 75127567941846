
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { ChangePasswordForm, PasswordChangeSuccessfulModal } from "../../components";
import { TextBlock } from "../../../common/components";
import Logo from "../../../../assets/images/logo.png";
import styles from "./ChangePasswordModal.module.scss";

export default function ChangePasswordModal(props) {
  if (props.success) {
    return (
      <PasswordChangeSuccessfulModal
        visible={props.visible}
        onClose={props.onCancel}
      />
    );
  }

  return (
    <Modal
      className={styles.container}
      dimmer={{
        className: styles.dimmer,
      }}
      open={props.visible}
      centered
    >
      <div className={styles.header}>
        <img src={Logo} className={styles.logo} />
        <div className={styles.title}>
          Change your password
        </div>
        <div className={styles.subtitle}>
          Please enter your new password below.
        </div>
        <TextBlock
          className={styles.warning}
          content="Password must be at least 8 characters, with at least one uppercase, lower case, and special character."
        />
        {props.error && (
          <div className={styles.error}>
            {props.error}
          </div>
        )}
      </div>
      <ChangePasswordForm
        formClassName={styles.form}
        submitting={props.submitting}
        onCancel={props.onCancel}
        onSubmit={props.onSubmit}
      />
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
