import * as types from "./types";

const initialState = {
  loading: true,
  documents: [],
  folders: [],
  hasNextPage: false,
  agencyDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  agencyOnboardingDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  prospectDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  prospectOnboardingDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  error: "",
  documentTypes: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AGENCY_DOCUMENTS_PENDING:
      return {
        ...state,
        agencyDocuments: {
          loading: true,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        agencyDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENTS_FAILURE:
      return {
        ...state,
        agencyDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_DOCUMENTS_PENDING:
      return {
        ...state,
        prospectDocuments: initialState.prospectDocuments,
      };

    case types.GET_PROSPECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        prospectDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_PROSPECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        prospectDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.DOWNLOAD_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.DOWNLOAD_DOCUMENTS_SUCCESS:
      return { ...state, loading: false };

    case types.DOWNLOAD_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.REMOVE_DOCUMENT_PENDING:
      return { ...state, loading: true };

    case types.REMOVE_DOCUMENT_SUCCESS:
      return { ...state, loading: false };

    case types.REMOVE_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_AGENCY_DOCUMENT_TYPES_PENDING:
      return { ...state, loading: true };

    case types.GET_AGENCY_DOCUMENT_TYPES_SUCCESS:
      return { ...state, loading: false, documentTypes: action.payload };

    case types.GET_AGENCY_DOCUMENT_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.UPLOAD_AGENCY_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: "",
        agencyDocuments: {
          ...state.agencyDocuments,
          error: null,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyDocuments: {
          ...state.agencyDocuments,
          ...action.payload.getAgencyDocuments,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        agencyDocuments: {
          ...state.agencyDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: "",
        prospectDocuments: {
          ...state.prospectDocuments,
          error: null,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        prospectDocuments: {
          ...state.prospectDocuments,
          ...action.payload.getProspectDocuments,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        prospectDocuments: {
          ...state.prospectDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
      };

    case types.GET_ONBOARDING_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.GET_ONBOARDING_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    case types.GET_ONBOARDING_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_PENDING:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: true,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: null,
        },
      };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_FAILURE:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_PENDING:
      return { ...state, loading: true, error: "" };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_SUCCESS:
      return { ...state, loading: false };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_PENDING:
      return { ...state, loading: true, error: "" };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_SUCCESS:
      return { ...state, loading: false };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_SUCCESS:
      return { ...state, loading: false };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
}
