import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import {
  Form,
  IconButton,
  SeeHistoryTooltip,
  TextLink,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { formatUrl } from "../../../../../utils";
import styles from "./AgencyDetailsSection.module.scss";

export default function AgencyDetailsSection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="Agency Details"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <div className={styles.cols}>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Agency Known As Name</Form.Label>
            <Form.ValueInline>{props.knownAs || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Trading As</Form.Label>
            <SeeHistoryTooltip variant="compact" data={props.companyHistory?.trading_as}>
              <Form.ValueInline>{props.tradingAs || "-"}</Form.ValueInline>
            </SeeHistoryTooltip>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Industry Sector</Form.Label>
            <Form.Value className={styles.industrySector}>
              <List
                identifier="industry_sectors"
                value={props.industrySectors}
                pillVariant="compact"
                readOnly
                inline
              />
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Previous Provider</Form.Label>
            <Form.ValueInline>
              {props.previousProvider?.label || "No previous provider"}
            </Form.ValueInline>
          </Form.Group>
        </div>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Agency Status</Form.Label>
            <Form.ValueInline>
                Prospect
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Agency Website URL</Form.Label>
            <Form.ValueInline>
              {props.website ?
                <TextLink
                  className={styles.link}
                  target="_blank"
                  href={formatUrl(props.website)}
                  text={props.website}
                />
                :
                "-"
              }
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>LinkedIn Page</Form.Label>
            <Form.ValueInline>
              {props.linkedIn || "-"}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Twitter</Form.Label>
            <Form.ValueInline>
              {props.twitter || "-"}
            </Form.ValueInline>
          </Form.Group>
        </div>
      </div>
    </Form.Card>
  );
}

AgencyDetailsSection.propTypes = {
  companyHistory: PropTypes.shape({
    trading_as: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  knownAs: PropTypes.string,
  industrySectors: PropTypes.arrayOf(PropTypes.string),
  previousProvider: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  tradingAs: PropTypes.string,
  website: PropTypes.string,
  linkedIn: PropTypes.string,
  twitter: PropTypes.string,
  onEditClick: PropTypes.func,
};

