import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

import { createCreditApplicationComment } from "./actions";

const CREATE_CREDIT_APPLICATION = gql`
mutation($input: CreateCreditApplicationInput!) {
  createCreditApplication(input: $input) {
    id
    legal_entity {
      id
    }
    created_at
    date_of_request
    date_of_result
    source
    credit_limit_requested
    approved_credit_excluding_vat
    use_discretionary_limit
    completed_at
    credit_requested_by
    status
    comments {
      id
      comment
      type
      created_by {
        first_name
        last_name
      }
      created_at
    }
    chases {
      name
      date_of_chase
      comments
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {number} obj.amountNeeded
 * @param {string} obj.requestedBy
 * @param {Date} obj.dateOfRequest
 * @param {string} obj.source
 * @param {string} obj.comment (optional)
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const createCreditApplication = ({
  legalEntityId,
  amountNeeded,
  requestedBy,
  dateOfRequest,
  source,
  comment,
  redirectUrl,
}) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_CREDIT_APPLICATION_PENDING });

    const response = await apolloClient.query({
      query: CREATE_CREDIT_APPLICATION,
      variables: {
        input: {
          legal_entity_id: legalEntityId,
          amount_needed: parseInt(amountNeeded, 10),
          requested_by: requestedBy,
          date_of_request: dateOfRequest,
          source,
        },
      },
    });

    if (!response.data.createCreditApplication) {
      throw response.errors[0].message;
    }

    if (comment) {
      await createCreditApplicationComment(
        response.data.createCreditApplication.id,
        comment
      )(dispatch);
    }

    dispatch({
      type: types.CREATE_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.createCreditApplication,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }

  } catch (err) {
    dispatch({
      type: types.CREATE_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
