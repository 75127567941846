import gql from "graphql-tag";
import {
  addExperianCreditCheckForAgency,
  showCreditDistributedModal,
  showInsufficientUndistributedCreditModal,
} from "./";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const VERIFY_AGENCY_CUSTOMER = gql`
mutation($input: VerifyAgencyCustomerInput!) {
  verifyAgencyCustomer(input: $input) {
    id
    allocated_credit

    agency {
      id
      name
    }

    legal_entity {
      id
      name
      undistributed_credit_limit
    }

    agency_customer_request {
      verified_at
      verified_by {
        first_name
        last_name
      }
      verify_mismatch_reason {
        label
      }
      verify_mismatch_reason_details
    }

    customer_accounts {
      id
      display_name
      allocated_credit
    }

    credit_requests(
      pager: { page: 1, limit: 1 }
      sort_order: [{ field: "created_at", order: "DESC" }]
    ) {
      id
      credit_requested
      credit_distributed
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.agencyCustomerRequestId
 * @param {string} obj.verifyMismatchReasonId
 * @param {string} obj.verifyMismatchReasonDetails
 * @param {Boolean} obj.companyFound
 * @param {number} obj.experianCreditResult
 * @param {number} obj.experianDateOfResult
 * @param {object} obj.experianCreditReport
 * @returns
 */
export const verifyAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: VERIFY_AGENCY_CUSTOMER,
      variables: {
        input: {
          agency_customer_request_id: obj.agencyCustomerRequestId,
          verify_mismatch_reason_id: obj.verifyMismatchReasonId,
          verify_mismatch_reason_details: obj.verifyMismatchReasonDetails,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_SUCCESS,
      payload: {
        agencyCustomerRequestId: obj.agencyCustomerRequestId,
        data: response.data.verifyAgencyCustomer,
      },
    });

    // Only called if we also needed to verify the
    // Agency Customer with an initial Experian credit check result
    // We check with the date of the result as this is the only value that
    // is always there whether the company was found or not
    if (obj.experianDateOfResult) {
      dispatch(addExperianCreditCheckForAgency({
        agencyCustomerId: response.data.verifyAgencyCustomer.id,
        companyFound: obj.companyFound,
        experianCreditResult: obj.experianCreditResult,
        experianDateOfResult: obj.experianDateOfResult,
        experianCreditReport: obj.experianCreditReport,
      }));
    }
    else if (response.data.verifyAgencyCustomer.credit_requests[0].credit_distributed > 0) {
      dispatch(showCreditDistributedModal({
        agencyId: response.data.verifyAgencyCustomer.agency.id,
        agencyName: response.data.verifyAgencyCustomer.agency.name,
        legalEntityId: response.data.verifyAgencyCustomer.legal_entity.id,
        legalEntityName: response.data.verifyAgencyCustomer.legal_entity.name,
        creditRequestId: response.data.verifyAgencyCustomer.credit_requests[0].id,
        distributedCredit: response.data.verifyAgencyCustomer.credit_requests[0].credit_distributed,
      }));
    }
    else {
      dispatch(showInsufficientUndistributedCreditModal({
        legalEntityName: response.data.verifyAgencyCustomer.legal_entity.name,
        requestedAmount: response.data.verifyAgencyCustomer.credit_requests[0].credit_requested,
        undistributedCredit: response.data.verifyAgencyCustomer.legal_entity.undistributed_credit_limit,
      }));
    }
  }
  catch (err) {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
