import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AppInfo } from "./modules/common/components";
import { ErrorBoundary } from "./modules/errors/components";
import { ModalManager } from "./modules/modals/containers";
import { SignInPage, ResetPasswordPage } from "./modules/sign-in";
import { SignUpPage } from "./modules/sign-up";
import { actions } from "./modules/currentUser/duck";
import AdminApp from "./AdminApp";
import AuthenticatedRoute from "./AuthenticatedRoute";
import config from "./config";
import styles from "./App.module.scss";

class App extends Component {

  static propTypes = {
    history: PropTypes.shape({
      go: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
    getCurrentUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      user: PropTypes.object.isRequired,
    }),
  }

  componentWillMount() {
    this.props.getCurrentUser();
  }

  render() {
    const { loading, user } = this.props.user;

    if (loading) {
      return <h1>Loading...</h1>;
    }

    return (
      <ErrorBoundary
        onGoHomeClick={this.handleGoHomeClicked}
        onReloadClick={this.handleReloadClicked}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <Switch>
              <Route exact path="/signup" component={SignUpPage} />
              <Route exact path="/sign-in" component={SignInPage} />
              <Route exact path="/reset-password" component={ResetPasswordPage} />
              <AuthenticatedRoute exact path="/" render={() => <Redirect to="/dashboard"/>} user={user} />
              <AuthenticatedRoute path="/" render={() => <AdminApp user={user} />} user={user} />
              <AuthenticatedRoute path="/dashboard" render={() => <AdminApp user={user} />} user={user} />
            </Switch>
          </div>
          <footer className={styles.footer}>
            <AppInfo className={styles.appInfo} buildNumber={config.REACT_APP_BUILD_NUMBER} />
          </footer>
          <ModalManager />
        </div>
      </ErrorBoundary>
    );
  }

  handleGoHomeClicked = () => {
    const { history } = this.props;

    history.push("/");
  }

  handleReloadClicked = () => {
    const { history } = this.props;

    history.go(0);
  }
}

const mapStateToProps = state => ({
  user: state.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
