import React from "react";
import PropTypes from "prop-types";
import { EditButton, Form } from "../../../../common/components";
import styles from "./ContactsSection.module.scss";

export default function ContactsSection({
  contract,
}) {
  return (
    <Form.Section title="Contacts" className={styles.compactHeading} rightAccessory={<EditButton />}>
      {contract.contacts.map((contact, index) => (
        <Form.Group key={index} className={styles.contact}>
          <div className={styles.title}>
            Contact {index + 1}
          </div>
          <div className={styles.line}>
            <div className={styles.label}>
              Name
            </div>
            <div className={styles.value}>
              {[contact.first_name, contact.last_name].join(" ")}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>
              Email
            </div>
            <div className={styles.value}>
              {contact.email}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>
              Primary Phone Number
            </div>
            <div className={styles.value}>
              {contact.primary_number}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>
              Secondary Phone Number
            </div>
            <div className={styles.value}>
              {contact.secondary_number}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>
              Job Role
            </div>
            <div className={styles.value}>
              {contact.job_role}
            </div>
          </div>
        </Form.Group>
      ))}
    </Form.Section>
  );

}

ContactsSection.propTypes = {
  contract: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string,
      primary_number: PropTypes.string,
      secondary_number: PropTypes.string,
      job_role: PropTypes.string,
    })),
  }),
};
