import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form, TextLink } from "../../../../common/components";
import { formatUrl } from "../../../../../utils";
import styles from "./GovernmentLegalEntityForm.module.scss";

export default class GovernmentLegalEntityForm extends Component {

  static propTypes = {
    legalEntity: PropTypes.shape({
      title: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      telephone: PropTypes.string,
      website: PropTypes.string,
      name: PropTypes.string.isRequired,
      initial_credit_limit_requested: PropTypes.number.isRequired,
      company_address: {
        address_line_one: PropTypes.string.isRequired,
        address_line_two: PropTypes.string,
        town_or_city: PropTypes.string.isRequired,
        county: PropTypes.string.isRequired,
        postcode: PropTypes.string.isRequired,
      },
    }),
    errors: PropTypes.object,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
  }

  initialState = {
    name: "",
    telephone: "",
    website: "",
    company_address: {
      address_line_one: "",
      address_line_two: "",
      town_or_city: "",
      county: "",
      postcode: "",
    },
  }

  constructor(props) {
    super(props);
    const legalEntityData = _.pick(props.legalEntity, _.keys(this.initialState));
    this.state = { ...this.initialState, ...legalEntityData };
    props.onLoad && props.onLoad(this.state);
  }

  render() {
    const { readOnly, errors } = this.props;

    return (
      <Form className={styles.container}>
        <Form.Section title={readOnly ? "Company Details" : ""}>
          {readOnly && (
            <Form.Group>
              <Form.Label>Company Type</Form.Label>
              <Form.Input
                value="Government"
                readOnly={readOnly}
              />
            </Form.Group>
          )}
          <Form.Group error={errors?.name}>
            <Form.Label>Company Name</Form.Label>
            <Form.Input
              placeholder="Enter Company Name..."
              name="name"
              value={this.state.name}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.name}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.telephone}>
            <Form.Label size="small" note={readOnly || "(Optional)"}>Phone Number</Form.Label>
            <Form.Input
              size="small"
              placeholder="Enter Phone Number..."
              name="telephone"
              value={this.state.telephone}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.telephone}
              maxLength={30}
            />
          </Form.Group>
          <Form.Group error={errors?.website}>
            <Form.Label note={readOnly || "(Optional)"}>Website URL</Form.Label>
            {readOnly && this.state.website ? (
              <Form.Value>
                <TextLink
                  className={styles.link}
                  target="_blank"
                  href={formatUrl(this.state.website)}
                  text={this.state.website}
                />
              </Form.Value>
            ) : (
              <Form.Input
                placeholder="Enter Website URL..."
                name="website"
                value={this.state.website}
                readOnly={readOnly}
                onChange={this.handleInputChange}
                highlightError={errors?.website}
                maxLength={255}
              />
            )}
          </Form.Group>
        </Form.Section>
        <Form.Section title="Company Address">
          <Form.Group error={errors?.company_address?.address_line_one}>
            <Form.Label>Address Line 1</Form.Label>
            <Form.Input
              placeholder="Address Line 1..."
              name="address_line_one"
              value={this.state.company_address.address_line_one}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.address_line_one}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.address_line_two}>
            <Form.Label note={readOnly || "(Optional)"}>Address Line 2</Form.Label>
            <Form.Input
              placeholder="Address Line 2..."
              name="address_line_two"
              value={this.state.company_address.address_line_two}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.address_line_two}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.town_or_city}>
            <Form.Label>Town / City</Form.Label>
            <Form.Input
              placeholder="Town / City..."
              name="town_or_city"
              value={this.state.company_address.town_or_city}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.town_or_city}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.county}>
            <Form.Label>County</Form.Label>
            <Form.Input
              placeholder="County..."
              name="county"
              value={this.state.company_address.county}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.county}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.company_address?.postcode}>
            <Form.Label size="small">Postcode</Form.Label>
            <Form.Input
              size="small"
              placeholder="Postcode..."
              name="postcode"
              value={this.state.company_address.postcode}
              readOnly={readOnly}
              onChange={this.handleCompanyAddressChange}
              highlightError={errors?.company_address?.postcode}
              maxLength={8}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => this.publishChange());
  }

  handleCompanyAddressChange = (e) => {
    this.setState({
      company_address: {
        ...this.state.company_address,
        [e.target.name]: e.target.value,
      },
    }, () => this.publishChange());
  }

  publishChange = () => {
    const { onChange } = this.props;

    onChange && onChange(this.state);
  }

}
