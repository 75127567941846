import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SEARCH_COMPANIES_HOUSE = gql`
fragment SearchResults on CompaniesHouseSearchResults {
  total
  data {
    name
    company_number
    company_status
    incorporated_date
    address_snippet
  }
  existing_legal_entities {
    id
    company_number
    agency_customer_requests(filter_options: $filterOptions) {
      id
      verified_at
    }
  }
}

query(
  $namePager: Pager
  $numberPager: Pager
  $nameSearch: CompaniesHouseSearch
  $numberSearch: CompaniesHouseSearch
  $filterOptions: AgencyCustomerRequestFilterOptions
) {
  searchByName: searchCompaniesHouse(pager: $namePager, search: $nameSearch) {
    ...SearchResults
  }

  searchByNumber: searchCompaniesHouse(pager: $numberPager, search: $numberSearch) {
    ...SearchResults
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.registeredCompanyNumber
 * @param {string} obj.exactLegalName
 * @param {number} obj.page
 * @param {number} obj.limit
 * @param {string} obj.agencyId
 */
export const searchCompaniesHouse = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_PENDING,
    });

    const response = await apolloClient.query({
      query: SEARCH_COMPANIES_HOUSE,
      variables: {
        namePager: {
          page: obj.page,
          limit: obj.limit,
        },
        numberPager: {
          page: 0,
          limit: 25,
        },
        nameSearch: {
          name: obj.exactLegalName,
        },
        numberSearch: {
          company_number: obj.registeredCompanyNumber,
        },
        filterOptions: {
          agency_id: obj.agencyId,
        },
      },
    });
    let companiesHouseResults = response.data;
    if (companiesHouseResults.searchByName === null) {
      companiesHouseResults.searchByName = { total: 0, data: [] };
    }
    if (companiesHouseResults.searchByNumber === null) {
      companiesHouseResults.searchByNumber = { total: 0, data: [] };
    }

    const searchByNumberResults = companiesHouseResults.searchByNumber.data.filter((result) => {
      return result.company_number.includes(obj.registeredCompanyNumber);
    });

    companiesHouseResults.searchByNumber = {
      ...companiesHouseResults.searchByNumber,
      total: searchByNumberResults.length,
      data: searchByNumberResults,
    };

    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_SUCCESS,
      payload: {
        companiesHouseResults,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
