import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_COMPANY_DETAILS = gql`
mutation(
  $id: ID!
  $input: UpdateLegalEntityInput!
) {
  updateLegalEntity(id: $id, input: $input) {
    id
    ...on RegisteredCompany {
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        type
      }
      is_registered_charity
      registered_charity_number
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {boolean} obj.isRegisteredCharity
 * @param {string} obj.registeredCharityNumber
 * @param {string} obj.redirectUrl
 * @returns
 */
export const updateCompanyDetails = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_COMPANY_DETAILS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_COMPANY_DETAILS,
      variables: {
        id: obj.legalEntityId,
        input: {
          registered: {
            is_registered_charity: obj.isRegisteredCharity,
            registered_charity_number: obj.isRegisteredCharity ? obj.registeredCharityNumber : null,
          },
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_COMPANY_DETAILS_SUCCESS,
      payload: {
        data: response.data.updateLegalEntity,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_COMPANY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
