import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { ExpandMore } from "@material-ui/icons";
import {
  CompleteOnboardingTaskConfirmationModal,
  Subtask,
} from "../";
import { Alert, Button, CollapseContainer, ConditionalWrapper, TextBlock, UnsavedChangesModal } from "../../../common/components";
import {
  isSubtaskEnabled,
  isSubtaskRequired,
  isSubtaskValid,
  isSubtaskVisible,
  subtaskHasValue,
} from "../../checks";
import { onboarding } from "../../../../utils";
import styles from "./AgencySubtaskList.module.scss";

export default class AgencySubtaskList extends Component {

  static propTypes = {
    isSaving: PropTypes.bool,
    isCompleting: PropTypes.bool,
    disabled: PropTypes.bool,
    completed: PropTypes.bool.isRequired,
    className: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      block: PropTypes.func.isRequired,
    }).isRequired,
    subtasks: PropTypes.arrayOf(PropTypes.shape({
      group: PropTypes.string,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      name: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      completed_at: PropTypes.number,
      field: PropTypes.shape({
        document_type: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.any,
        maskOptions: PropTypes.any,
        minLength: PropTypes.number,
      }).isRequired,
      tracking: PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
      }).isRequired,
      depends_on: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })).isRequired,
      collapsable: PropTypes.bool,
    })),
    comments: PropTypes.arrayOf(PropTypes.shape({
      comment: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
      created_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
    })),
    errorMessage: PropTypes.string,
    onAddTracking: PropTypes.func,
    onTrackingDelete: PropTypes.func,
    onProgressChange: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCompleteClick: PropTypes.func,
    onDownload: PropTypes.func,
    onCommentSelected: PropTypes.func,
  }

  static defaultProps = {
    subtasks: [],
    comments: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      formIsDirty: false,
      canComplete: false,
      showUnsavedChangesModal: false,
      nextLocation: "",
      ...(props.subtasks || []).reduce((r, v) => ({
        ...r,
        [v.field.name]: onboarding.parseSubtaskValue(v),
      }), {}),
      isCollapsed: (props.subtasks || []).map(task => ({ name: task.name, collapsed: false })),
      savingAlertVisible: false,
      showSaveConfirmationModal: false,
    };
  }

  componentDidMount() {
    this.checkCanComplete();
    this.unblock = this.props.history.block((nextLocation) => {
      if (this.state.formIsDirty) {
        this.setState({
          showUnsavedChangesModal: true,
          nextLocation: nextLocation,
        });
        return false;
      }
      return true;
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.subtasks, this.props.subtasks)) {
      this.setState({
        savingAlertVisible: true,
      }, () => this.checkCanComplete());
      setTimeout(() => {
        this.setState({ savingAlertVisible: false });
      }, 4000);
    }
  }

  componentWillUnmount() {
    this.unblock();
  }

  render() {
    const { className, subtasks, comments, errorMessage, isSaving, isCompleting, disabled, completed, onCommentSelected } = this.props;
    const { showUnsavedChangesModal, savingAlertVisible, isCollapsed, showSaveConfirmationModal } = this.state;

    return (
      <div className={classnames(styles.container, disabled && styles.disabled, className)}>
        {!completed && <div className={styles.header}>
          <div className={styles.leftHeaderFlex}>
            <div className={styles.taskList}>Subtasks</div>
            <div className={styles.required}>* - Required</div>
          </div>
        </div>}
        <div className={`${styles.subtasks} ${completed && styles.completed}`}>
          <div className={styles.subtasksContent}>
            {subtasks?.map((subtask, i) => (
                <>
                {this.renderSubtaskGroupHeader(subtask, i, isSubtaskVisible(subtask, this.state))}
                {subtask.description && isSubtaskVisible(subtask, this.state) && (
                  <TextBlock content={subtask.description} />
                )}
                <ConditionalWrapper
                  condition={false}
                  wrapper={children => <CollapseContainer collapsed={isCollapsed.find(task => task.name === subtask.name).collapsed}>{children}</CollapseContainer>}
                >
                  <Subtask
                    key={subtask.name}
                    className={styles.subtask}
                    {...subtask}
                    required={isSubtaskRequired(subtask, this.state)}
                    value={this.state[subtask.field.name]}
                    field={{
                      ...subtask.field,
                      value: this.state[subtask.field.name],
                    }}
                    error={!completed && this.state.validationErrors?.[subtask.field.name]}
                    relatedComments={comments.filter(comment =>
                      comment.related_subtasks?.some(relatedSubtask =>
                        relatedSubtask.field_name === subtask.field.name)
                    )}
                    dueDate={subtask.due_date}
                    disabled={!isSubtaskEnabled(subtask, this.state)}
                    hidden={!isSubtaskVisible(subtask, this.state)}
                    readOnly={completed}
                    saving={isSaving}
                    onChange={(name, value) => this.handleSubtaskChanged(name, value)}
                    onListChange={this.handleSubtaskListChanged}
                    onAddTracking={this.handleAddTracking}
                    onTrackingDelete={this.handleTrackingDelete}
                    onCommentSelected={onCommentSelected}
                    onDownload={this.handleDownload}
                    onDelete={this.handleDocumentDelete}
                    onInputListAdd={this.handleInputListAdd}
                    onInputListRemove={this.handleInputListRemove}
                    onInputListChange={this.handleInputListChange}
                    isCollapsed={this.state.isCollapsed}
                  />
                </ConditionalWrapper>
              </>
            ))}
          </div>
        </div>
        {!completed && (
          <div className={styles.footer}>
            {errorMessage && (
              <Alert className={styles.errorAlert} variant="error" showClose={false}>
                {errorMessage}
              </Alert>
            )}
            {savingAlertVisible && (
              <Alert className={styles.savingAlert} variant="info" showClose={false}>
                Saved
              </Alert>
            )}
            {!errorMessage && !savingAlertVisible && (
              // Empty so that we force the action buttons to the right
              <div />
            )}
            <div className={styles.buttonContainer}>
              <Button
                disabled={isCompleting || isSaving || disabled}
                busy={isSaving}
                className={styles.taskButton}
                variant="secondary"
                onClick={this.handleSaveClicked}
              >
                Save
              </Button>
              <Button
                disabled={isSaving || disabled || !this.state.canComplete}
                busy={isCompleting}
                className={styles.taskButton}
                variant="primary"
                onClick={this.handleCompleteClicked}
              >
                Complete
              </Button>
            </div>
          </div>
        )}
        <UnsavedChangesModal
          visible={showUnsavedChangesModal}
          onClose={this.handleCloseSavedChangesModal}
          onGoBackClick={this.handleCloseSavedChangesModal}
          onLeaveClick={this.handleLeaveClicked}
        />
        <CompleteOnboardingTaskConfirmationModal
          visible={showSaveConfirmationModal}
          onCancelClick={this.handleCancelCompleteConfirmationModal}
          onCompleteClick={this.handleConfirmCompleteConfirmationModal}
        />
      </div>
    );
  }

  handleDocumentDelete = (type) => {
    this.setState({
      [type]: null,
    });
  }

  handleDownload = (type) => {
    const { onDownload } = this.props;

    onDownload && onDownload(type);
  }

  renderSubtaskGroupHeader = (subtask, index, visible) => {
    const { subtasks } = this.props;
    const { isCollapsed } = this.state;

    if (index > 0 && !subtask.group && subtasks[index-1].group) return <hr className={styles.groupSeparator} />;
    if (index > 0 && subtask.group === subtasks[index-1].group) return null;
    if (!subtask.group || !visible) return null;
    const taskIsCollapsed = isCollapsed.find(task => task.name === subtask.name).collapsed;

    return (
      <>
        {index > 0 && <hr className={styles.groupSeparator} />}
        <div className={classnames(styles.groupHeader, subtask.collapsable && styles.collapsable)} onClick={() => this.handleExpandToggle(subtask.name)}>
          {subtask.group}
          {subtask.collapsable && false &&
            <span className={styles.icon}>
              <ExpandMore fontSize="inherit" className={classnames(styles.expandIcon, taskIsCollapsed ? styles.collapse : styles.expand)} />
            </span>
          }
        </div>
      </>
    );
  }

  handleExpandToggle = (name) => {
    this.setState({
      isCollapsed: this.state.isCollapsed.map(task => ({
        ...task,
        collapsed: name === task.name ? !task.collapsed : task.collapsed,
      })),
    });
  }

  handleAddTracking = (data) => {
    const { onAddTracking } = this.props;

    onAddTracking && onAddTracking(data);
  }

  handleTrackingDelete = (id) => {
    const { onTrackingDelete } = this.props;

    onTrackingDelete && onTrackingDelete(id);
  }

  handleSaveClicked = async () => {
    const { subtasks, onSaveClick } = this.props;
    const data = _.pick(this.state, subtasks.map(subtask => subtask.field.name));

    const updatedFormData = onSaveClick && await onSaveClick(data);

    this.setState({
      ...updatedFormData,
      formIsDirty: false,
    });
  }

  handleCompleteClicked = async () => {
    this.setState({
      showSaveConfirmationModal: true,
    });
  }

  handleSubtaskChanged = (name, value) => {
    const changes = this.getSubtaskDependencyChanges(name, value);

    this.setState({
      formIsDirty: true,
      [name]: value,
      ...changes,
    }, () => this.checkCanComplete());
  }

  handleSubtaskListChanged = (name, item) => {
    this.setState({
      formIsDirty: true,
      [name]: _.find(_.castArray(this.state[name]), { id: item.id })
        ? this.state[name].filter(v => v && v.id !== item.id)
        : [..._.castArray(this.state[name]), item],
    }, () => this.checkCanComplete());
  }

  handleInputListAdd = (name) => {
    this.setState({
      formIsDirty: true,
      [name]: [
        ...this.state[name],
        "",
      ],
    }, () => this.checkCanComplete());
  }

  handleInputListRemove = (name, index) => {
    const listItems = this.state[name].filter((e, i) => {
      return i !== index;
    });

    this.setState({
      formIsDirty: true,
      [name]: listItems,
    }, () => this.checkCanComplete());
  }

  handleInputListChange = (name, index, value) => {
    const updatedListItems = this.state[name].reduce((items, item, i) => [
      ...items,
      i === index ? value : item,
    ], []);

    this.setState({
      formIsDirty: true,
      [name]: updatedListItems,
    }, () => this.checkCanComplete());
  }

  handleCloseSavedChangesModal = () => {
    this.setState({
      showUnsavedChangesModal: false,
    });
  }

  handleLeaveClicked = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.setState({
      isDirty: false,
      showUnsavedChangesModal: false,
    });

    window.onbeforeunload = null;
    this.unblock();

    history.push(nextLocation.pathname);
  }

  handleCancelCompleteConfirmationModal = () => {
    this.setState({
      showSaveConfirmationModal: false,
    });
  }

  handleConfirmCompleteConfirmationModal = () => {
    const { subtasks, onCompleteClick } = this.props;
    const data = _.pick(this.state, subtasks.map(subtask => subtask.field.name));

    this.setState({
      formIsDirty: false,
      showSaveConfirmationModal: false,
    }, () => onCompleteClick(data));
  }


  checkCanComplete = () => {
    const { subtasks, onProgressChange } = this.props;
    let validationErrors = {};
    const requiredSubtasks = subtasks.filter(subtask => isSubtaskRequired(subtask, this.state));
    const stateRequiredSubtasksKeys = Object.keys(this.state).filter(stateSubtaskKey => requiredSubtasks.find(subtask => subtask.field.name === stateSubtaskKey));
    const completedRequiredSubtasks = stateRequiredSubtasksKeys.filter((subtaskKey) => {
      const subtask = requiredSubtasks.find(subtask => subtask.field.name === subtaskKey);
      const hasValue = subtaskHasValue(subtask.field.type, subtaskKey, this.state);
      const isValid = isSubtaskValid({
        field: subtask.field,
        subtaskKey,
        state: this.state,
      });

      validationErrors = {
        ...validationErrors,
        ...isValid.validationErrors,
      };

      return subtask.field.allowFalse ||
        (hasValue && isValid.success) ||
        !isSubtaskVisible(subtask, this.state) ||
        !isSubtaskEnabled(subtask, this.state);
    });

    const inaccessibleRequiredSubtasks = requiredSubtasks
      .filter(subtask => !isSubtaskVisible(subtask, this.state) || !isSubtaskEnabled(subtask, this.state));
    const disabledSubtasks = subtasks
      .filter(subtask => !isSubtaskEnabled(subtask, this.state))
      .reduce((r, v) => ({
        ...r,
        [v.field.name]: this.getDefaultValueForFieldType(v.field.type),
      }), {});

    const unresolvedTrackers = subtasks
      .filter((subtask) => {
        if (!isSubtaskVisible(subtask, this.state)) return false;
        if (!isSubtaskEnabled(subtask, this.state)) return false;
        if (!subtask.tracking?.enabled) return false;
        if (!subtask.tracking?.entries?.length) return false;
        return !subtask.tracking.entries.some(x => x.type === "resolve");
      });

    this.setState({
      canComplete: completedRequiredSubtasks.length === requiredSubtasks.length && unresolvedTrackers.length === 0,
      validationErrors,
      ...disabledSubtasks,
    }, () => {
      onProgressChange && onProgressChange({
        requiredSubtasksTotal: requiredSubtasks.length - inaccessibleRequiredSubtasks.length,
        requiredSubtasksCompleted: completedRequiredSubtasks.length - inaccessibleRequiredSubtasks.length,
      });
    });
  }

  getDefaultValueForFieldType = (type) => {
    switch (type) {
      case "checkbox":
        return false;

      case "date":
        return null;

      default:
        return "";
    }
  }

  getSubtaskDependencyChanges = (name, value) => {
    if (name === "mi_report_grouping_required" && value === "no") {
      return {
        mi_split_by: [],
      };
    }

    if (name === "applying_registered_vat" && !value) {
      return {
        vat_registration_number_received: false,
        proof_of_vat_number_application: null,
        vat_registration_number: null,
        vat_period_ending_date: null,
        vat_frequency: null,
        vat_certificate: null,
      };
    }

    return {};
  }
}
