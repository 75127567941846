import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../common/components";
import { AgencySearchBox } from "../../../../agencies/containers";
import { upToNearestThousand } from "../../../../../utils";
import styles from "./ContractDetailsStep.module.scss";
import { TradingStyleSelect } from "modules/credit-limit-management/containers";
import TradingStyleInput from "../../TradingStyleInput";

export default class ContractDetailsStep extends Component {
  static propTypes = {
    contractDetails: PropTypes.shape({
      contract_name: PropTypes.string,
      legal_entity_name: PropTypes.string.isRequired,
      estimated_credit: PropTypes.number,
      agency_id: PropTypes.string,
      agency_name: PropTypes.string,
      start_date: PropTypes.number,
      end_date: PropTypes.number,
      trading_style: PropTypes.string,
      trading_style_id: PropTypes.string,
      trading_style_name: PropTypes.string,
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }).isRequired,
    validation: PropTypes.shape({
      contract_name: PropTypes.string,
      estimated_credit: PropTypes.number,
      agency_id: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      address_line_one: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onAgencySelected: PropTypes.func.isRequired,
    onTradingStyleSelected: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { contractDetails, onChange, validation, onAgencySelected, onTradingStyleSelected } = this.props;

    return (
      <div className={styles.container}>
        <Form>
          <Form.Section className={styles.section} title="Contract Details">
            <Form.Group>
              <Form.Label>Legal Entity</Form.Label>
              <Form.Input
                name="legal_entity"
                value={contractDetails?.legal_entity_name}
                placeholder="Legal Entity"
                size="large"
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Pick Existing Trading Style</Form.Label>
              <Form.Value size="large">
                <TradingStyleSelect
                  name="trading_style_id"
                  value={contractDetails?.trading_style_id || ""}
                  onItemSelected={onTradingStyleSelected}
                  agencyId={contractDetails?.agency_id}
                />
              </Form.Value>
              <TradingStyleInput
                name="trading_style"
                value={contractDetails?.trading_style || ""}
                addButtonLabel="Add New Trading Style"
                removeButtonLabel="Remove"
                agencyId={contractDetails?.agency_id}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group error={validation.agency_id}>
              <Form.Label>Agency</Form.Label>
              <Form.Value size="large">
                <AgencySearchBox
                  name="agency_id"
                  onItemSelected={onAgencySelected}
                  value={contractDetails?.agency_name}
                  highlightError={validation.agency_id}
                />
              </Form.Value>
            </Form.Group>
            <Form.Group error={validation.contract_name}>
              <Form.Label>Contract Title</Form.Label>
              <Form.Input
                name="contract_name"
                value={contractDetails?.contract_name || ""}
                onChange={onChange}
                placeholder="Enter Contract Title..."
                size="large"
                maxLength={255}
                highlightError={validation.contract_name}
              />
            </Form.Group>
            <Form.FlexRow className={styles.row} align="top">
              <Form.Group error={validation.start_date}>
                <Form.Label size="small">Start Date</Form.Label>
                <Form.Date
                  name="start_date"
                  value={contractDetails?.start_date}
                  onChange={onChange}
                  size="small"
                  highlightError={validation.start_date}
                />
              </Form.Group>
              <Form.Group className={styles.inlineGroup} error={validation.end_date}>
                <Form.Label size="small" note="(Optional)">End Date</Form.Label>
                <Form.Date
                  name="end_date"
                  size="small"
                  value={contractDetails?.end_date}
                  onChange={onChange}
                  highlightError={validation.end_date}
                />
              </Form.Group>
            </Form.FlexRow>
            <Form.Group error={validation.estimated_credit}>
              <Form.Label>Estimated Credit Needed</Form.Label>
              <Form.Number
                size="small"
                name="estimated_credit"
                value={contractDetails?.estimated_credit || 1000}
                placeholder="Set Amount..."
                prefix="£"
                onChange={onChange}
                onBlur={() => onChange({ target: { name: "estimated_credit", value: upToNearestThousand(contractDetails?.estimated_credit) } })}
                formatNumber
              />
            </Form.Group>
          </Form.Section>
          <Form.Section title="Add Site Address">
            <Form.Group error={validation.address_line_one}>
              <Form.Label note={this.isAddressOptional() && "(Optional)"}>Address Line 1</Form.Label>
              <Form.Input
                name="address_line_one"
                placeholder="Address Line 1..."
                value={contractDetails?.address_line_one || ""}
                onChange={onChange}
                maxLength={255}
                highlightError={validation.address_line_one}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label note="(Optional)">Address Line 2</Form.Label>
              <Form.Input
                name="address_line_two"
                placeholder="Address Line 2..."
                value={contractDetails?.address_line_two || ""}
                onChange={onChange}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={validation.town_or_city}>
              <Form.Label note={this.isAddressOptional() && "(Optional)"}>Town / City</Form.Label>
              <Form.Input
                name="town_or_city"
                placeholder="Town / City..."
                value={contractDetails?.town_or_city || ""}
                onChange={onChange}
                maxLength={255}
                highlightError={validation.town_or_city}
              />
            </Form.Group>
            <Form.Group error={validation.county}>
              <Form.Label note={this.isAddressOptional() && "(Optional)"}>County</Form.Label>
              <Form.Input
                name="county"
                placeholder="County"
                value={contractDetails?.county || ""}
                onChange={onChange}
                maxLength={255}
                highlightError={validation.county}
              />
            </Form.Group>
            <Form.Group error={validation.postcode}>
              <Form.Label note={this.isAddressOptional() && "(Optional)"}>Postcode</Form.Label>
              <Form.Input
                name="postcode"
                placeholder="Postcode"
                value={contractDetails?.postcode || ""}
                onChange={onChange}
                maxLength={50}
                highlightError={validation.postcode}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </div>
    );
  }

  isAddressOptional = () => {
    const { contractDetails } = this.props;

    return [
      contractDetails.address_line_one,
      contractDetails.town_or_city,
      contractDetails.county,
      contractDetails.postcode,
    ].every(x => x.length === 0);
  }

}
