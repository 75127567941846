import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_PROSPECT = gql`
query(
  $id: ID!
  $onboarding_tasks_filter_options: OnboardingTasksFilterOptions!
) {
  getProspect(id: $id) {
    id
    known_as
    trading_as

    is_new_agency
    is_existing_paye_scheme
    is_ledger_buyout
    introduced_via_broker

    previous_provider {
      value
    }

    worker_types {
      id
    }

    companies_house_info {
      company_name
      company_number
      company_status
      company_status_detail
      type

      accounts {
        last_accounts {
          made_up_to
        }
      }

      previous_company_names {
        ceased_on
        effective_from
        name
      }

      registered_office_address {
        address_line_1
        address_line_2
        locality
        postal_code
      }
    }

    contractual_agreement_onboarding_task: onboarding_task(filter_options: $onboarding_tasks_filter_options) {
      completed_at
    }
  }
}
`;

export const getProspectToBeConverted = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_TO_BE_CONVERTED_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT,
      variables: {
        id,
        onboarding_tasks_filter_options: {
          task_name: "Contractual Agreement",
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PROSPECT_TO_BE_CONVERTED_SUCCESS,
      payload: {
        prospect: response.data.getProspect,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECT_TO_BE_CONVERTED_FAILURE,
      payload: {
        error,
      },
    });
  }
};
