import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { NonRegisteredLegalEntityForm } from "../../components";
import {
  Button,
  ButtonGroup,
  Heading,
  Form,
} from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./NewNonRegisteredCompanyPage.module.scss";

export class NewNonRegisteredCompanyPage extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    addLegalEntity: PropTypes.func.isRequired,
  }

  state = {
    companyType: null,
  }

  render() {
    return (
      <div className={styles.container}>
        <Heading title="Add New Non-Registered Company" />
        <Form className={styles.form}>
          <Form.Section title="Company Details">
            <Form.Group>
              <Form.Label>Company Type</Form.Label>
              <Form.Value size="small">
                <List
                  identifier="non_registered_company_type"
                  placeholder="Select Legal Entity Type..."
                  value={this.state.companyType}
                  onChange={this.handleCompanyTypeChanged}
                />
              </Form.Value>
            </Form.Group>
          </Form.Section>
        </Form>
        {this.state.companyType ? (
          <NonRegisteredLegalEntityForm
            companyType={this.state.companyType}
            history={this.props.history}
            onCancel={this.handleCancelClicked}
            onSubmit={this.handleSubmitClicked}
          />
        ) : (
          <ButtonGroup className={styles.buttonContainer}>
            <Button
              className={styles.secondaryButton}
              variant="outline-danger"
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {}}
              disabled
            >
              Save Company
            </Button>
          </ButtonGroup>
        )}
      </div>
    );
  }

  handleCompanyTypeChanged = (e) => {
    this.setState({
      companyType: e.target.value,
    });
  }

  handleCancelClicked = () => {
    const { history } = this.props;

    history.push("/credit-limit-management");
  }

  handleSubmitClicked = async (data) => {
    const { addLegalEntity } = this.props;
    const { companyType } = this.state;

    await addLegalEntity({
      ...data,
      company_type: companyType,
    });
  }

}

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewNonRegisteredCompanyPage);
