import _ from "lodash";

export default class ContactValidator {
  constructor(contact, props) {
    this.contact = contact;
    this.props = props;
  }

  validate() {
    const { contact } = this;
    const errors = {
      contactNumbers: [],
    };
    const telephoneNumber = this._getCombinedPhoneNumbers(contact);

    if (_.isEmpty(contact?.firstName)) {
      errors.firstName = "First Name is required";
    }

    if (_.isEmpty(contact?.lastName)) {
      errors.lastName = "Last Name is required";
    }

    if (_.isEmpty(telephoneNumber) && _.isEmpty(contact?.emailAddress)) {
      errors.telephoneNumber = errors.emailAddress = "A Telephone Number or Email Address is required";

      if (contact?.contactNumbers?.length > 0) {
        errors.contactNumbers.push({
          index: 0,
          phoneNumberType: errors.telephoneNumber,
          phoneNumber: errors.telephoneNumber,
        });
      }
    }

    if (!_.isEmpty(contact?.emailAddress) && !_.includes(contact?.emailAddress, "@")) {
      errors.emailAddress = "Email Address is not valid";
    }

    if (!errors.telephoneNumber) {
      _.each(contact?.contactNumbers, (contactNumber, index) => {
        const contactErrors = {};

        if (!contactNumber.phoneNumberType) {
          contactErrors.phoneNumberType = "Phone Number Type is required";
        }

        if (index > 0 && _.isEmpty(contactNumber.phoneNumber)) {
          contactErrors.phoneNumber = "Phone Number is required";
        }

        if (!_.isEmpty(contactErrors)) {
          errors.telephoneNumber = "Phone Number is required";
          errors.contactNumbers.push({ index, ...contactErrors });
        }
      });
    }

    if (this._isDuplicate()) {
      errors.error = "This contact already exists";
    }
    else if (!this._hasPrimaryContact()) {
      errors.error = "No primary contact has been specified";
    }

    if (errors.contactNumbers.length === 0) {
      delete errors.contactNumbers;
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  _getCombinedPhoneNumbers = (contact) => {
    return contact?.contactNumbers
      ?.map(c => c.phoneNumber)
      ?.join("");
  }

  _isDuplicate = () => {
    const { contact, props } = this;
    const telephoneNumber = this._getCombinedPhoneNumbers(contact);

    if (_.isEmpty(telephoneNumber) && _.isEmpty(contact?.emailAddress)) {
      return false;
    }

    if (!_.isEmpty(telephoneNumber) && !_.isEmpty(contact?.emailAddress)) {
      return _.some(props?.contacts, c =>
        this._isEqual(c.firstName, contact?.firstName) &&
        this._isEqual(c.lastName, contact?.lastName) &&
        this._isEqual(c.emailAddress, contact?.emailAddress) &&
        this._hasSameContactNumber(c.contactNumbers, contact?.contactNumbers) &&
        c.id !== contact?.id
      );
    }

    if (!_.isEmpty(telephoneNumber)) {
      return _.some(props?.contacts, c =>
        this._isEqual(c.firstName, contact?.firstName) &&
        this._isEqual(c.lastName, contact?.lastName) &&
        this._hasSameContactNumber(c.contactNumbers, contact?.contactNumbers) &&
        c.id !== contact?.id
      );
    }

    if (!_.isEmpty(contact?.emailAddress)) {
      return _.some(props?.contacts, c =>
        this._isEqual(c.firstName, contact?.firstName) &&
        this._isEqual(c.lastName, contact?.lastName) &&
        this._isEqual(c.emailAddress, contact?.emailAddress) &&
        c.id !== contact?.id
      );
    }

    return false;
  }

  _hasPrimaryContact = () => {
    const { props } = this;

    return _.isEmpty(props?.contacts) || _.some(props?.contacts, c => c.primaryContact);
  }

  _isEqual = (s1, s2) => {
    const parse = s => _.chain(s).replace(/\s+/g, "").lowerCase().trim().value();

    return parse(s1) === parse(s2);
  }

  _hasSameContactNumber = (contactNumbers, otherContactNumbers) => {
    return _.some(contactNumbers, contactNumber => !!otherContactNumbers.find(otherContactNumber =>
      contactNumber.isPreferredNumber && otherContactNumber.isPreferredNumber &&
      contactNumber.phoneNumberType?.id === otherContactNumber.phoneNumberType?.id &&
      contactNumber.phoneNumber === otherContactNumber.phoneNumber
    ));
  }
}
