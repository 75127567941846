import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { tempest } from "../../../../utils";

const UPDATE_AGENCY_PREFERENCES = gql`
mutation(
  $agency_id: ID!
  $input: UpdateAgencyPreferencesInput!
) {
  updateAgencyPreferences(agency_id: $agency_id, input: $input) {
    payroll_frequency
    default_payslip_preference
    default_customer_vat_rate
  }
}
`;

const GET_AGENCY = gql`
query(
  $agency_id: ID!
) {
  getAgency(id: $agency_id) {
    tempest_mappings {
      tempest_id
    }
    transfers(transfer_type: INITIAL_TRANSFER) {
      created_at
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.data
 * @param {string} obj.data.payroll_frequency
 * @param {string} obj.data.default_payslip_preference
 * @param {string} obj.data.default_customer_vat_rate
 * @param {string} obj.redirectUrl
 *
 */
export const updateAgencyPreferences = ({ agencyId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_PREFERENCES_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_PREFERENCES,
      variables: {
        agency_id: agencyId,
        input: {
          payroll_frequency: data.payroll_frequency,
          default_payslip_preference: data.default_payslip_preference,
          default_customer_vat_rate: data.default_customer_vat_rate,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const agencyResponse = await apolloClient.query({
      query: GET_AGENCY,
      variables: {
        agency_id: agencyId,
      },
    });

    // No need to throw here. The main aim was to update
    // the agency preferences, which has been done if we
    // have got to this point.
    if (agencyResponse.errors) {
      // eslint-disable-next-line no-console
      console.error("Failed to get agency information", agencyResponse.errors);
    }

    const employerRefs = tempest.getEmployerRefs(agencyId, data.payroll_frequency);
    const hasAlreadyTransferred = agencyResponse.data.getAgency.transfers.length > 0;
    const hasAllTempestMappings = employerRefs
      .every(employerRef => agencyResponse.data.getAgency.tempest_mappings.find(x => x.tempest_id === employerRef));
    const needsTransferring = hasAlreadyTransferred && !hasAllTempestMappings;

    dispatch({
      type: types.UPDATE_AGENCY_PREFERENCES_SUCCESS,
      payload: {
        data: response.data.updateAgencyHolidayPayInfo,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl, {
        needsTransferring,
      }));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_PREFERENCES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
