import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../modules/currentUser/duck";
import AdminApp from "./AdminApp";

const mapStateToProps = state => ({
  ...state.errors,
  ...state.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onChangePassword: actions.changePassword,
  onLogout: actions.logout,
  setChangePasswordModalVisibility: actions.setChangePasswordModalVisibility,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);
