import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

export default function TransferTypeLabel(props) {
  const getColor = () => {
    switch (props.transferType) {
      case "initial_transfer":
        return "blue";

      case "agency_update":
        return "orange";

      default:
        return "";
    }
  };

  return (
    <Label color={getColor()}>
      {props.transferType}
    </Label>
  );
}

TransferTypeLabel.propTypes = {
  transferType: PropTypes.string,
};
