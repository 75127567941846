import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dropdown, Table } from "semantic-ui-react";
import {
  AttachMoney,
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  History,
  InsertComment,
  MoreVert,
} from "@material-ui/icons";
import { TextLink } from "../../../../common/components";
import { parseDate, toMoney } from "../../../../../utils";
import styles from "./RequestHistoryRow.module.scss";
import RequestCommunications from "../RequestCommunications/RequestCommunications";

export default function RequestHistoryRow(props) {
  const isStriped = props.index % 2 === 1;

  const renderResult = () => {
    switch (props.requestHistory.status) {
      case "pending":
        return (
          <div className={styles.actionItem}>
            <History className={styles.pendingIcon} />
            Pending
          </div>
        );

      case "fully_approved":
        return (
          <div className={styles.actionItem}>
            <CheckCircle className={styles.approvedIcon} />
            Fully Approved
          </div>
        );

      case "partially_approved":
        return (
          <div className={styles.actionItem}>
            <CheckCircleOutline className={styles.approvedIcon} />
            Part Approved
          </div>
        );

      case "declined":
        return (
          <div className={styles.actionItem}>
            <CancelOutlined className={styles.declinedIcon} />
            Declined
          </div>
        );

      default:
        return null;
    }
  };

  const renderActionMenu = (request) => {
    return (
      <Dropdown.Menu>
        {request.credit_distributed === null && ( // Pending request
          <>
            <Dropdown.Item onClick={() => props.handleDistributeCreditRequest(request)}>
              <div className={styles.actionItem}>
                <AttachMoney className={styles.distributeIcon} />
                Distribute
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.handleDeclineCreditRequest(request.id)}>
              <div className={styles.actionItem}>
                <CancelOutlined className={styles.declinedIcon} />
                Decline
              </div>
            </Dropdown.Item>
          </>
        )}
        {request.credit_distributed !== null && // Distributed request
          request.date_communicated === null && (
          <Dropdown.Item onClick={() => props.handleCommunicateToAgencyRequest({
            agencyId: request.agency.id,
            legalEntityId: props.legalEntityId,
            creditRequestId: request.id,
          })}>
            <div className={styles.actionItem}>
              <InsertComment className={styles.communicateIcon} />
              Communicate
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    );
  };

  return (
    <>
      <Table.Row
        key={`request_history_${props.requestHistory.id}`}
        className={classnames(isStriped && styles.stripe)}
      >
        <Table.Cell className={styles.agencyNameCell}>
          <TextLink
            className={styles.agencyName}
            text={props.requestHistory.agency.name}
            to={`/agencies/${props.requestHistory.agency.id}`}
          />
        </Table.Cell>
        <Table.Cell>{parseDate(props.requestHistory.created_at)}</Table.Cell>
        <Table.Cell textAlign="right">
          {_.isNull(props.requestHistory.credit_requested)
            ? "-"
            : toMoney(props.requestHistory.credit_requested)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {_.isNull(props.requestHistory.credit_distributed)
            ? "-"
            : toMoney(props.requestHistory.credit_distributed)}
        </Table.Cell>
        <Table.Cell>{renderResult()}</Table.Cell>
        <Table.Cell>
          {props.requestHistory.date_communicated
            ? parseDate(props.requestHistory.date_communicated)
            : "-"}
        </Table.Cell>
        <Table.Cell>
          <RequestCommunications
            communications={props.requestHistory.credit_request_communications}
          />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Dropdown
            trigger={<MoreVert className={styles.actionMenuIcon} />}
            icon={null}
            direction="left"
            floating
            disabled={
              props.requestHistory.date_communicated ||
              !props.requestHistory.is_agency_customer_verified
            }
          >
            {renderActionMenu(props.requestHistory)}
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    </>
  );
}

RequestHistoryRow.propTypes = {
  index: PropTypes.number.isRequired,
  legalEntityId: PropTypes.string.isRequired,
  requestHistory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    credit_requested: PropTypes.number,
    credit_distributed: PropTypes.number,
    date_communicated: PropTypes.string,
    credit_request_communications: PropTypes.object,
    status: PropTypes.string,
    is_agency_customer_verified: PropTypes.bool,
    created_at: PropTypes.number,
  }).isRequired,
  handleCommunicateToAgencyRequest: PropTypes.func.isRequired,
  handleDeclineCreditRequest: PropTypes.func.isRequired,
  handleDistributeCreditRequest: PropTypes.func.isRequired,
};
