import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormLabel.module.scss";

export default function FormLabel({
  className,
  htmlFor,
  note,
  size,
  fluid,
  inline,
  required,
  children,
  ...otherProps
}) {
  return (
    <div className={
      classnames(
        styles.container,
        styles[size],
        fluid && styles.fluid,
        inline && styles.inline,
        className
      )}
    {...otherProps}
    >
      <label htmlFor={htmlFor}>
        {children}{required && <span className={styles.required}>*</span>}
      </label>
      {note && (
        <div className={styles.note}>
          {note}
        </div>
      )}
    </div>
  );
}

FormLabel.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  note: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fluid: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
};

FormLabel.defaultProps = {
  size: "medium",
  required: false,
};
