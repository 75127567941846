import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./DistributeForm.module.scss";
import { toMoney } from "../../../../../../utils";
import { Form } from "../../../../../common/components";

export default function DistributeForm (props) {
  const [accounts, setAccounts] = useState([]);

  // Save the full list of standardised account objects to use for lookup
  useEffect(() => {
    const accounts = normaliseAccounts();
    setAccounts(accounts);
  }, [props.legalEntity, props.agencyCustomers]);

  /**
   * @param  {string} context=null (optional) The field that wants the accounts
   */
  const normaliseAccounts = (context = null) => {
    const { agencyCustomers, legalEntity } = props;
    if (!agencyCustomers?.data || !legalEntity?.data) return [];

    const accounts = [];

    // Add undistributed if it's not already selected
    if (!alreadySelected(legalEntity.data.id, context)) {
      accounts.push({
        id: legalEntity.data.id,
        type: "legalEntity",
        name: "Undistributed",
        credit: props.legalEntityUndistributed,
      });
    }

    // Move on to adding the agency customers
    const sortedAgencyCustomers = _.sortBy(agencyCustomers.data, agencyCustomer => agencyCustomer.agency.name.toLowerCase());
    sortedAgencyCustomers.forEach((agencyCustomer) => {
      let customerAccounts = [];

      // Sort the active accounts (ie. not "Reserved") alphabetically
      const reserved = agencyCustomer.customer_accounts.find(account => account.display_name === "Reserved");
      const activeAccounts = agencyCustomer.customer_accounts.filter(account => account.display_name !== "Reserved");
      const sortedActiveAccounts = activeAccounts.sort((a, b) => a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase()));

      // Add "Reserved" to the top of each agency's customers
      if (!alreadySelected(reserved.id, context)) {
        customerAccounts.push({
          id: reserved.id,
          type: "customerAccount",
          name: reserved.display_name,
          agency: agencyCustomer.agency.name,
          credit: reserved.allocated_credit,
        });
      }

      // Add the rest of the agency's customers
      sortedActiveAccounts.forEach((customerAccount) => {
        if (!alreadySelected(customerAccount.id, context)) {
          customerAccounts.push({
            id: customerAccount.id,
            type: "customerAccount",
            name: customerAccount.display_name,
            agency: agencyCustomer.agency.name,
            credit: customerAccount.allocated_credit,
          });
        }
      });

      // If the agency doesn't have any customers don't add it.
      // This will occur if it only had "reserved" and this has been selected in the other field.
      if (customerAccounts.length > 0) {
        customerAccounts.unshift({
          id: `agency_${agencyCustomer.agency.id}`,
          type: "agency",
          name: agencyCustomer.agency.name,
        });
      }

      // Add this agency and its customers to the accounts array we're building.
      customerAccounts.forEach(account => accounts.push(account));
    });

    return accounts;
  };

  // An account selection field doesn't want to show an account if it's selected
  // in the other account selection field.
  const alreadySelected = (optionId, context) => {
    if (context === "to") return props.formData.fromAccount?.id === optionId;
    if (context === "from") return props.formData.toAccount?.id === optionId;
    return false;
  };

  // Build the array for the <Form.Select/>.
  const buildOptions = (accounts) => {
    const options = [];

    accounts.forEach((account) => {
      switch (account.type) {
        case "legalEntity":
          options.push({
            key: account.id,
            value: account.id,
            label: <div>Undistributed <span className={styles.accountCredit}>({toMoney(account.credit)})</span></div>,
          });
          break;

        case "agency":
          options.push({
            key: `agency_${account.id}`,
            content: <div className={styles.agency}>{account.name}</div>,
            disabled: true,
          });
          break;

        case "customerAccount":
          // label shows when selected. Content shows in the dropdown.
          options.push({
            key: account.id,
            value: account.id,
            label: (
              <div>
                {account.name}{" "}<span className={styles.accountCredit}>({toMoney(account.credit)})</span>
              </div>
            ),
            content: (
              <div className={styles.customerAccount}>
                {account.name}{" "}<span className={styles.accountCredit}>({toMoney(account.credit)})</span>
              </div>
            ),
          });
          break;

        default:
          break;
      }
    });

    return options;
  };

  // Capture the event and replace the value with the full account object.
  const handleAccountSelection = (e) => {
    e.target.value = accounts.find(account => account.id === e.target.value);
    props.onChange(e);
  };

  return (
    <>
      <Form.Group inline className={styles.group}>
        <Form.Label inline required>From</Form.Label>
        <Form.Value>
          <Form.Select
            className={styles.dropdown}
            name="fromAccount"
            placeholder="Select account to distribute from..."
            options={buildOptions(normaliseAccounts("from"))}
            fluid
            value={props.formData.fromAccount?.id}
            onChange={handleAccountSelection}
            disabled={props.busy}
          />
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline required>To</Form.Label>
        <Form.Value>
          <Form.Select
            name="toAccount"
            placeholder="Select account to distribute to..."
            options={buildOptions(normaliseAccounts("to"))}
            fluid
            value={props.formData.toAccount?.id}
            onChange={handleAccountSelection}
            disabled={props.busy}
          />
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group} error={props.errors.distributeAmount}>
        <Form.Label inline required>Distribute amount (Excl. VAT)</Form.Label>
        <Form.Number
          name="distributeAmount"
          value={props.formData.distributeAmount}
          placeholder="eg. £10,000"
          prefix="£"
          onChange={props.onChange}
          highlightError={props.errors.distributeAmount}
          disabled={props.busy}
          formatNumber
        />
      </Form.Group>
    </>
  );
}

DistributeForm.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  busy: PropTypes.bool.isRequired,
  legalEntityUndistributed: PropTypes.number.isRequired,
  legalEntity: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  agencyCustomers: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      agency: PropTypes.shape({
        name: PropTypes.string,
      }),
      customer_accounts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        display_name: PropTypes.string,
        allocated_credit: PropTypes.number,
      }),),
    }),),
  }).isRequired,
};
