import * as types from "./types";


//TODO: split these into 2 seperate reducers!
const initialState = {
  loading: true,
  resetInProgress: false,
  user: {
    attributes: null,
  },
  error: "",
  changePassword: {
    visible: false,
    submitting: false,
    success: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          submitting: true,
          success: null,
          error: null,
        },
      };

    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          visible: true,
          submitting: false,
          success: true,
          error: null,
        },
      };

    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          visible: true,
          submitting: false,
          success: false,
          error: String(action.payload.error),
        },
      };

    case types.GET_CURRENT_USER_PENDING:
      return { ...state, loading: true };

    case types.GET_CURRENT_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.user };

    case types.GET_CURRENT_USER_FAILURE:
      return { ...state, loading: false };

    case types.LOGOUT:
      return { ...initialState, loading: false };

    case types.SET_CHANGE_PASSWORD_MODAL_VISIBILITY:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          visible: action.payload.isVisible,
          success: false,
        },
      };

    default:
      return state;
  }
}
