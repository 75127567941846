import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import NewRegisteredProspectSearchPage from "./NewRegisteredProspectSearchPage";

const mapStateToProps = state => ({
  ...state.prospects,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onSearch: actions.searchCompaniesHouse,
  }, dispatch),
  onGoToExisting: ({ id }) => {
    dispatch(push(`/agencies/${id}`));
  },
  onCancel: () => {
    dispatch(push("/prospects"));
  },
  onSubmit: ({ companyNumber }) => {
    dispatch(push(`/onboarding/new/prospects/${companyNumber}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRegisteredProspectSearchPage);
