import * as types from "./types";
import config from "../../../config";

const { DEFAULT_ITEMS_PER_PAGE } = config;

const initialState = {
  loading: true,
  busy: false,
  agencies: [],
  agency: null,
  agencyContacts: {
    loading: true,
    data: [],
    error: null,
    submitting: false,
  },
  agencyHistory: [],
  agencyMiReportGroupings: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  backOfficeContacts: {
    loading: true,
    data: [],
    error: null,
    submitting: false,
  },
  backOfficeContact: {
    loading: true,
    data: null,
    error: null,
    history: [],
  },
  companyInfo: {
    loading: true,
    agency: null,
  },
  companyHistory: {
    loading: true,
    registered_address: [],
    contract_start_date: [],
    legal_name: [],
    trading_as: [],
  },
  holidayPayInfo: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  payrollInfo: {
    loading: true,
    data: null,
    workerTypes: [],
    error: null,
    hasNewOnboardingTasks: null,
  },
  payrollHistory: {
    loading: true,
    data: null,
    error: null,
  },
  workerTypes: {
    loading: true,
    data: [],
    error: null,
  },
  companiesHouseResults: {
    searchByName: {
      total: 0,
      data: [],
    },
    searchByNumber: {
      total: 0,
      data: [],
    },
  },
  selectedCompanyProfile: {
    loading: true,
    data: null,
    error: null,
  },
  companyCertUrl: {
    loading: true,
    data: null,
    error: null,
  },
  agencyDisabledFields: {
    loading: true,
    data: [],
    error: null,
  },
  pagination: {
    page: 1,
    limit: DEFAULT_ITEMS_PER_PAGE,
    pages: 1,
    total: 0,
  },
  error: null,
  message: null,
  agencySearch: {
    loading: false,
    data: [],
    error: null,
    pending: false,
  },
  agencyDetails: {
    loading: false,
    data: null,
    error: null,
  },
  agencyTradingAddress: {
    loading: false,
    data: null,
    error: null,
  },
  agencyBankInformation: {
    loading: false,
    data: null,
    error: null,
  },
  agencyContractInformation: {
    loading: false,
    data: null,
    error: null,
  },
  agencyVatInformation: {
    loading: false,
    data: null,
    error: null,
  },
  ui: {
    submitting: false,
  },
  branch: {
    loading: true,
    data: null,
    error: null,
  },
  agencyPreferences: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  autoEnrolmentPensionInfo: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  hmrcInfo: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  payrollGeneral: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  payrollTransferValidation: {
    loading: false,
    data: null,
    error: null,
  },
  transfers: {
    loading: true,
    data: [],
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AGENCIES_PENDING:
      return {
        ...initialState,
        pagination: state.pagination,
      };

    case types.GET_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        agencies: action.payload.agencies,
        pagination: action.payload.pagination,
      };

    case types.GET_AGENCIES_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_AGENCY_PENDING:
      return { ...state, loading: true, agency: null };

    case types.GET_AGENCY_SUCCESS:
      return { ...state, loading: false, agency: action.payload.agency };

    case types.GET_AGENCY_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_AGENCY_TRANSFER_INFORMATION_PENDING:
      return state;

    case types.GET_AGENCY_TRANSFER_INFORMATION_SUCCESS:
      return {
        ...state,
        agency: {
          ...state.agency,
          ...action.payload.data,
        },
      };

    case types.GET_AGENCY_TRANSFER_INFORMATION_FAILURE:
      return state;

    case types.GET_AGENCY_CONTACTS_PENDING:
      return {
        ...state,
        agencyContacts: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_AGENCY_CONTACTS_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          loading: false,
          data: action.payload.agencyContacts,
          error: null,
        },
      };

    case types.GET_AGENCY_CONTACTS_FAILURE:
      return {
        ...state,
        agencyContacts: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_HISTORY_PENDING:
      return { ...state, loading: true, agencyHistory: [] };

    case types.GET_AGENCY_HISTORY_SUCCESS:
      return { ...state, loading: false, agencyHistory: action.payload.revisions };

    case types.GET_AGENCY_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_SELECTED_COMPANY_PROFILE_PENDING:
      return { ...state, loading: true, error: null };

    case types.GET_SELECTED_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        selectedCompanyProfile: {
          data: action.payload.selectedCompanyProfile,
          loading: false,
        },
        companiesHouseResults: { searchByName: { total: 0, data: [] }, searchByNumber: { total: 0, data: [] } },
      };

    case types.GET_SELECTED_COMPANY_PROFILE_FAILURE:
      return { ...state, loading: false, selectedCompanyProfile: {}, error: action.payload.error };


    case types.GET_COMPANY_CERT_URL_PENDING:
      return {
        ...state,
        companyCertUrl: {
          loading: true,
          error: null,
        },
      };

    case types.GET_COMPANY_CERT_URL_SUCCESS:
      return {
        ...state,
        companyCertUrl: {
          loading: false,
          data: action.payload.url,
        },
      };

    case types.GET_COMPANY_CERT_URL_FAILURE:
      return {
        ...state,
        companyCertUrl: {
          loading: true,
          error: action.payload.error,
        },
      };

    case types.SEARCH_AGENCIES_PENDING:
      return { ...state, loading: true };

    case types.SEARCH_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        agencies: action.payload.agencies,
        pagination: action.payload.pagination,
      };

    case types.SEARCH_AGENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        agencies: initialState.agencies,
        pagination: initialState.pagination,
        error: action.payload.error,
      };

    case types.DISABLE_AGENCIES_PENDING:
      return { ...state, busy: true };

    case types.DISABLE_AGENCIES_SUCCESS:
      return { ...state, busy: false };

    case types.DISABLE_AGENCIES_FAILURE:
      return { ...state, busy: false, error: action.payload.error };

    case types.SAVE_AGENCY_PENDING:
      return { ...state, busy: true };

    case types.SAVE_AGENCY_SUCCESS:
      return { ...state, busy: false, agency: action.payload.agency, message: action.payload.message, error: null };

    case types.SAVE_AGENCY_FAILURE:
      return { ...state, busy: false, error: action.payload.error, message: null };

    case types.SET_PAGINATION:
      return { ...state, pagination: action.payload.pagination };

    case types.GET_COMPANY_INFO_PENDING:
      return { ...state, companyInfo: initialState.companyInfo };

    case types.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: {
          loading: false,
          agency: action.payload.agency,
          certificateOfIncorporationDocument: action.payload.certificateOfIncorporationDocument,
          contractDocument: action.payload.contractDocument,
          vatDocument: action.payload.vatDocument,
          branches: action.payload.agency.branches,
        },
        companyHistory: action.payload.history,
      };

    case types.GET_COMPANY_INFO_FAILURE:
      return { ...state, companyInfo: { loading: false, error: action.payload.error } };

    case types.GET_COMPANY_HISTORY_PENDING:
      return { ...state, companyHistory: initialState.companyHistory };

    case types.GET_COMPANY_HISTORY_SUCCESS:
      return { ...state, companyHistory: { loading: false, ...action.payload.revisions } };

    case types.GET_COMPANY_HISTORY_FAILURE:
      return { ...state, companyHistory: { loading: false, error: action.payload.error } };

    case types.GET_PAYROLL_HISTORY_PENDING:
      return {
        ...state,
        payrollHistory: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PAYROLL_HISTORY_SUCCESS:
      return {
        ...state,
        payrollHistory: {
          loading: false,
          data: action.payload.history,
          error: null,
        },
      };

    case types.GET_PAYROLL_HISTORY_FAILURE:
      return {
        ...state,
        payrollHistory: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_PAYROLL_INFO_PENDING:
      return { ...state, payrollInfo: initialState.payrollInfo };

    case types.GET_PAYROLL_INFO_SUCCESS:
      return {
        ...state,
        payrollInfo: {
          loading: false,
          data: {
            ...action.payload.payrollInfo,
            pensionDocument: action.payload.pensionDocument,
            vatDocument: action.payload.vatDocument,
          },
          workerTypes: action.payload.workerTypes,
        },
      };

    case types.GET_PAYROLL_INFO_FAILURE:
      return { ...state, payrollInfo: { loading: false, error: action.payload.error } };

    case types.GET_WORKER_TYPES_PENDING:
      return { ...state, workerTypes: initialState.workerTypes };

    case types.GET_WORKER_TYPES_SUCCESS:
      return {
        ...state,
        workerTypes: {
          loading: false,
          data: action.payload.workerTypes,
        },
      };

    case types.GET_WORKER_TYPES_FAILURE:
      return {
        ...state,
        workerTypes: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.ADD_AGENCY_CONTACT_PENDING:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: true,
        },
      };

    case types.ADD_AGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          data: [
            ...state.agencyContacts.data,
            action.payload.agencyContact,
          ],
        },
      };

    case types.ADD_AGENCY_CONTACT_FAILURE:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.UPDATE_AGENCY_CONTACT_PENDING:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          data: state.agencyContacts.data.map((contact) => {
            return contact.id !== action.payload.agencyContact.id
              ? contact
              : action.payload.agencyContact;
          }),
        },
      };

    case types.UPDATE_AGENCY_CONTACT_FAILURE:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.ARCHIVE_AGENCY_CONTACT_PENDING:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: true,
        },
      };

    case types.ARCHIVE_AGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          data: state.agencyContacts.data.map((contact) => {
            return contact.id !== action.payload.agencyContact.id
              ? contact
              : action.payload.agencyContact;
          }),
        },
      };

    case types.ARCHIVE_AGENCY_CONTACT_FAILURE:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.DELETE_AGENCY_CONTACT_PENDING:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: true,
        },
      };

    case types.DELETE_AGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          data: state.agencyContacts.data.filter((contact) => {
            return contact.id !== action.payload.agencyContact.id;
          }),
        },
      };

    case types.DELETE_AGENCY_CONTACT_FAILURE:
      return {
        ...state,
        agencyContacts: {
          ...state.agencyContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.GET_BACK_OFFICE_CONTACTS_PENDING:
      return { ...state, backOfficeContacts: initialState.backOfficeContacts };

    case types.GET_BACK_OFFICE_CONTACTS_SUCCESS:
      return { ...state, backOfficeContacts: { loading: false, data: action.payload.data } };

    case types.GET_BACK_OFFICE_CONTACTS_FAILURE:
      return { ...state, backOfficeContacts: { loading: false, error: action.payload.error } };

    case types.GET_AGENCY_DISABLED_FIELDS_PENDING:
      return {
        ...state,
        agencyDisabledFields: initialState.agencyDisabledFields,
      };

    case types.GET_AGENCY_DISABLED_FIELDS_SUCCESS:
      return {
        ...state,
        agencyDisabledFields: {
          loading: false,
          data: action.payload.agencyDisabledFields,
        },
      };

    case types.GET_AGENCY_DISABLED_FIELDS_FAILURE:
      return {
        ...state,
        agencyDisabledFields: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.AGENCY_SEARCH_PENDING:
      return {
        ...state,
        agencySearch: {
          ...state.agencySearch,
          loading: true,
          error: null,
          data: [],
          pending: true,
        },
      };

    case types.AGENCY_SEARCH_SUCCESS:
      return {
        ...state,
        agencySearch: {
          loading: false,
          data: action.payload.data,
          error: null,
          pending: false,
        },
      };

    case types.AGENCY_SEARCH_FAILURE:
      return {
        ...state,
        agencySearch: {
          loading: false,
          data: [],
          error: action.payload.error,
          pending: false,
        },
      };

    case types.AGENCY_SEARCH_CLEAR:
      return {
        ...state,
        agencySearch: {
          loading: false,
          data: [],
          error: null,
          pending: true,
        },
      };

    case types.GET_AGENCY_MI_REPORT_GROUPINGS_PENDING:
      return {
        ...state,
        agencyMiReportGroupings: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_AGENCY_MI_REPORT_GROUPINGS_SUCCESS:
      return {
        ...state,
        agencyMiReportGroupings: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case types.GET_AGENCY_MI_REPORT_GROUPINGS_FAILURE:
      return {
        ...state,
        agencyMiReportGroupings: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_PENDING:
      return {
        ...state,
        agencyMiReportGroupings: {
          ...state.agencyMiReportGroupings,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_SUCCESS:
      return {
        ...state,
        agencyMiReportGroupings: {
          ...state.agencyMiReportGroupings,
          data: action.payload,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_FAILURE:
      return {
        ...state,
        agencyMiReportGroupings: {
          ...state.agencyMiReportGroupings,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_BACK_OFFICE_CONTACTS_PENDING:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          submitting: true,
        },
      };

    case types.UPDATE_BACK_OFFICE_CONTACTS_SUCCESS:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          data: action.payload.data,
          error: action.payload.errors?.length > 0 ? action.payload.errors.join("\n") : null,
          submitting: false,
        },
      };

    case types.UPDATE_BACK_OFFICE_CONTACTS_FAILURE:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_DETAILS_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        agency: {
          ...state.agency,
          name: action.payload.agency.known_as,
        },
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_DETAILS_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_CONTRACT_INFORMATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_CONTRACT_INFORMATION_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_CONTRACT_INFORMATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_TRADING_ADDRESS_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_TRADING_ADDRESS_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_TRADING_ADDRESS_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_BANK_INFORMATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_BANK_INFORMATION_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_BANK_INFORMATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_VAT_INFORMATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_VAT_INFORMATION_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_VAT_INFORMATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.GET_AGENCY_DETAILS_PENDING:
      return {
        ...state,
        agencyDetails: {
          loading: true,
          data: null,
        },
      };

    case types.GET_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          data: action.payload.agency,
        },
      };

    case types.GET_AGENCY_DETAILS_FAILURE:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_TRADING_ADDRESS_PENDING:
      return {
        ...state,
        agencyTradingAddress: {
          loading: true,
          data: null,
        },
      };

    case types.GET_AGENCY_TRADING_ADDRESS_SUCCESS:
      return {
        ...state,
        agencyTradingAddress: {
          loading: false,
          data: action.payload.agency,
        },
      };

    case types.GET_AGENCY_TRADING_ADDRESS_FAILURE:
      return {
        ...state,
        agencyTradingAddress: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_BANK_INFORMATION_PENDING:
      return {
        ...state,
        agencyBankInformation: {
          loading: true,
          data: null,
        },
      };

    case types.GET_AGENCY_BANK_INFORMATION_SUCCESS:
      return {
        ...state,
        agencyBankInformation: {
          loading: false,
          data: action.payload.banking,
        },
      };

    case types.GET_AGENCY_BANK_INFORMATION_FAILURE:
      return {
        ...state,
        agencyBankInformation: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_CONTRACT_INFORMATION_PENDING:
      return {
        ...state,
        agencyContractInformation: {
          loading: true,
          data: null,
        },
      };

    case types.GET_AGENCY_CONTRACT_INFORMATION_SUCCESS:
      return {
        ...state,
        agencyContractInformation: {
          loading: false,
          data: action.payload.data,
        },
      };

    case types.GET_AGENCY_CONTRACT_INFORMATION_FAILURE:
      return {
        ...state,
        agencyContractInformation: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_VAT_INFORMATION_PENDING:
      return {
        ...state,
        agencyVatInformation: {
          loading: true,
          data: null,
        },
      };

    case types.GET_AGENCY_VAT_INFORMATION_SUCCESS:
      return {
        ...state,
        agencyVatInformation: {
          loading: false,
          data: action.payload.data,
        },
      };

    case types.GET_AGENCY_VAT_INFORMATION_FAILURE:
      return {
        ...state,
        agencyVatInformation: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_BRANCH_PENDING:
      return {
        ...state,
        branch: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BRANCH_SUCCESS:
      return {
        ...state,
        branch: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BRANCH_FAILURE:
      return {
        ...state,
        branch: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_BRANCH_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.GET_AGENCY_PREFERENCES_PENDING:
      return {
        ...state,
        agencyPreferences: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AGENCY_PREFERENCES_SUCCESS:
      return {
        ...state,
        agencyPreferences: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AGENCY_PREFERENCES_FAILURE:
      return {
        ...state,
        agencyPreferences: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_PREFERENCES_PENDING:
      return {
        ...state,
        agencyPreferences: {
          ...state.holidayPayInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_PREFERENCES_SUCCESS:
      return {
        ...state,
        agencyPreferences: {
          ...state.holidayPayInfo,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_PREFERENCES_FAILURE:
      return {
        ...state,
        agencyPreferences: {
          ...state.holidayPayInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_AUTO_ENROLMENT_PENSION_INFO_PENDING:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AUTO_ENROLMENT_PENSION_INFO_SUCCESS:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AUTO_ENROLMENT_PENSION_INFO_FAILURE:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_PENDING:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          ...state.autoEnrolmentPensionInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_SUCCESS:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          ...state.autoEnrolmentPensionInfo,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_FAILURE:
      return {
        ...state,
        autoEnrolmentPensionInfo: {
          ...state.autoEnrolmentPensionInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_HMRC_INFO_PENDING:
      return {
        ...state,
        hmrcInfo: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HMRC_INFO_SUCCESS:
      return {
        ...state,
        hmrcInfo: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HMRC_INFO_FAILURE:
      return {
        ...state,
        hmrcInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_HMRC_INFO_PENDING:
      return {
        ...state,
        hmrcInfo: {
          ...state.hmrcInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_HMRC_INFO_SUCCESS:
      return {
        ...state,
        hmrcInfo: {
          ...state.hmrcInfo,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_HMRC_INFO_FAILURE:
      return {
        ...state,
        hmrcInfo: {
          ...state.hmrcInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_GENERAL_PENDING:
      return {
        ...state,
        payrollGeneral: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_GENERAL_SUCCESS:
      return {
        ...state,
        payrollGeneral: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_GENERAL_FAILURE:
      return {
        ...state,
        payrollGeneral: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_PENDING:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_SUCCESS:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_FAILURE:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_PENDING:
      return {
        ...state,
        holidayPayInfo: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_SUCCESS:
      return {
        ...state,
        holidayPayInfo: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_FAILURE:
      return {
        ...state,
        holidayPayInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_PENDING:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_SUCCESS:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_FAILURE:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.VALIDATE_PAYROLL_TRANSFER_PENDING:
      return {
        ...state,
        payrollTransferValidation: {
          ...state.payrollTransferValidation,
          loading: true,
          error: null,
        },
      };

    case types.VALIDATE_PAYROLL_TRANSFER_SUCCESS:
      return {
        ...state,
        payrollTransferValidation: {
          ...state.payrollTransferValidation,
          loading: false,
          data: action.payload.data.payrollTransferValidation,
        },
      };

    case types.VALIDATE_PAYROLL_TRANSFER_FAILURE:
      return {
        ...state,
        payrollTransferValidation: {
          ...state.payrollTransferValidation,
          loading: false,
          data: action.payload.error,
        },
      };

    case types.GET_TRANSFERS_PENDING:
      return {
        ...state,
        transfers: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        transfers: {
          loading: false,
          data: action.payload.transfers,
          error: null,
        },
      };

    case types.GET_TRANSFERS_FAILURE:
      return {
        ...state,
        transfers: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
