import React, { Component } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "../../../common/components";
import Logo from "../../../../assets/images/logo.png";
import styles from "./ErrorBoundary.module.scss";

export default class ErrorBoundary extends Component {

  static propTypes = {
    children: PropTypes.oneOf([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    onGoHomeClick: PropTypes.func.isRequired,
    onReloadClick: PropTypes.func.isRequired,
    onErrorCaught: PropTypes.func,
  }

  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    const { onErrorCaught } = this.props;

    onErrorCaught && onErrorCaught(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return this.renderErrorPage();
    }

    return children;
  }

  renderErrorPage = () => {
    const { onGoHomeClick, onReloadClick } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.error}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={Logo} />
          </div>
          <h1 className={styles.title}>
            Could not load the page
          </h1>
          <h3 className={styles.subtitle}>
            Something unexpected happened, sorry about that!
          </h3>
          <ButtonGroup className={styles.actions}>
            <Button
              className={styles.button}
              variant="primary"
              onClick={onReloadClick}
            >
              Reload the page
            </Button>
            <Button
              className={styles.button}
              variant="secondary"
              onClick={onGoHomeClick}
            >
              Go Home
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }

}
