import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const REMOVE_PROSPECT_DOCUMENT = gql`
mutation($input: ProspectDocumentUrlInput!) {
  removeProspectDocument(input: $input)
}
`;

export const removeProspectDocument = ({ prospectId, document }) => async (dispatch) => {
  try {
    dispatch({
      type: types.REMOVE_DOCUMENT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: REMOVE_PROSPECT_DOCUMENT,
      variables: {
        input: {
          prospect_id: prospectId,
          document_type: document.name,
          version_id: document.versionId,
        },
      },
    });

    if (!response.data.removeProspectDocument) {
      throw `Failed to remove ${document.name}`;
    }

    dispatch({
      type: types.REMOVE_DOCUMENT_SUCCESS,
      payload: {
        document,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.REMOVE_DOCUMENT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
