import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DOCUMENTS = gql`
query($input: AgencyDocumentsInput!) {
  getAgencyDocuments(input: $input) {
    documents {
      name
      size
      lastModified
      versions {
        name
        size
        lastModified
        versionId
        isLatest
        metadata {
          user {
            first_name
            last_name
          }
          document_type
          date_uploaded
        }
      }
      metadata {
        user {
          first_name
          last_name
        }
        document_type
      }
    }
    folders
    hasNextPage
  }
}
`;

export const getAgencyDocuments = agency_id => async (dispatch) => {
  try {
    dispatch({ type: types.GET_AGENCY_DOCUMENTS_PENDING });

    const response = await apolloClient.query({
      query: GET_AGENCY_DOCUMENTS,
      variables: {
        input: {
          agency_id,
        },
      },
    });

    dispatch({
      type: types.GET_AGENCY_DOCUMENTS_SUCCESS,
      payload: response.data.getAgencyDocuments,
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
