import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "./DashboardPage.scss";

class DashboardPage extends Component {

    static propTypes = {
      user: PropTypes.shape({
        attributes: PropTypes.shape ({
          name: PropTypes.string.isRequired,
        }),
      }).isRequired,
    };

    render() {
      let timeOfDay = this.getGreetingTime(moment());
      return (
        <div className="DashboardPage">
          <p className="name-hello">{timeOfDay}, {this.props.user.attributes.name}</p>
          <p className="going-on">Here’s what’s going on with the team today</p>
          <div className="content">
            <p className="tbc">Content TBC</p>
          </div>
        </div>
      );
    }

    getGreetingTime = (currentTime) => {
      if (!currentTime || !currentTime.isValid()) { return "Hello"; }

      const splitAfternoon = 12; // 24hr time to split the afternoon
      const splitEvening = 17; // 24hr time to split the evening
      const currentHour = parseFloat(currentTime.format("HH"));

      if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return "Good Afternoon";
      } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return "Good Evening";
      }
      // Between dawn and noon
      return "Good Morning";
    }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
});

export default connect(mapStateToProps, null)(DashboardPage);
