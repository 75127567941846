import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_ONBOARDING_TASK = gql`
query($prospect_onboarding_task_id: ID!) {
  getProspectOnboardingTask(prospect_onboarding_task_id: $prospect_onboarding_task_id) {
    id
    status
    definition {
      subtasks {
        group
        description
        name
        required
        field
        collapsable
        tracking {
          enabled
          entries {
            id
            field_name
            type
            entry
            tracked_at
            created_at
            updated_at
          }
        }
        depends_on
        due_date
      }
    }
    comments {
      id
      comment
      created_at
      created_by {
        first_name
        last_name
      }
      related_subtasks {
        field_name
        label
      }
    }
  }
}
`;

export const getProspectTask = ({
  prospectOnboardingTaskId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_TASK_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT_ONBOARDING_TASK,
      variables: {
        prospect_onboarding_task_id: prospectOnboardingTaskId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const task = response.data.getProspectOnboardingTask;
    const prospectTask = {
      ...task,
      definition: {
        ...task.definition,
        subtasks: task.definition.subtasks.map(subtask => ({
          ...subtask,
          field: JSON.parse(subtask.field),
          depends_on: JSON.parse(subtask.depends_on || "[]"),
        })),
      },
    };

    dispatch({
      type: types.GET_PROSPECT_TASK_SUCCESS,
      payload: {
        prospectTask,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECT_TASK_FAILURE,
      payload: {
        error,
      },
    });
  }
};
