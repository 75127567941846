import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "semantic-ui-react";
import { Close, InfoOutlined } from "@material-ui/icons";
import { Icon as CustomIcon } from "../../../common/components";
import { formatBytes } from "../../../../utils";
import uploadIcon from "../../../../assets/images/experian_report_upload.svg";
import styles from "./ExperianCreditReportUpload.module.scss";

export default function ExperianCreditReportUpload(props) {
  const inputRef = useRef();
  const [hovering, setHovering] = useState(false);

  const selectFile = (file) => {
    props.onChange({
      target: {
        name: props.name,
        value: file,
      },
    });
  };

  const dragOver = (e) => {
    e.preventDefault();
    setHovering(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setHovering(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHovering(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setHovering(false);
    selectFile(e.dataTransfer.files[0]);
  };

  const handleFileSelected = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    selectFile(e.target.files[0]);
  };

  if (props.value) {
    return (
      <div
        className={classnames(
          props.className,
          styles.container,
          props.disabled && styles.disabled,
        )}
      >
        <div className={styles.fileContainer}>
          <Icon className={styles.fileIcon} name="file" />
          <div className={styles.file}>
            <div className={styles.filename}>
              {props.value.name}
            </div>
            {props.error ? (
              <div className={styles.errorContainer}>
                <InfoOutlined className={styles.errorIcon} />
                <div className={styles.errorText}>
                  <strong>Upload failed:</strong> <span>{props.error}</span>
                </div>
              </div>
            ) : (
              <div className={styles.size}>
                {formatBytes(props.value.size)}
              </div>
            )}
          </div>
        </div>
        <Close
          className={styles.removeIcon}
          onClick={() => props.onChange({
            target: {
              name: props.name,
              value: null,
            },
          })}
        />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        props.className,
        styles.placeholderContainer,
        hovering && styles.hovering,
        props.disabled && styles.disabled,
      )}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}

    >
      <CustomIcon className={styles.uploadIcon} icon={uploadIcon} />
      <div className={styles.placeholder}>
        Drag and drop the Experian credit report here,{" "}
        or <span className={styles.link} onClick={() => inputRef.current.click()}>browse...</span>
      </div>
      <input
        className={styles.hidden}
        ref={inputRef}
        type="file"
        onChange={handleFileSelected}
      />
    </div>
  );
}

ExperianCreditReportUpload.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
