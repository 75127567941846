import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EditCreditApplicationModalValidator from "./EditCreditApplicationModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import styles from "./EditCreditApplicationModal.module.scss";

export default function EditCreditApplicationModal(props) {
  const [state, setState] = useState({
    creditLimitRequested: null,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.getCreditApplicationForApproval({
      legalEntityId: props.legalEntityId,
      creditApplicationId: props.creditApplicationId,
    });
  }, []);

  useEffect(() => {
    setState({ creditLimitRequested: props.creditLimitRequested });
  }, [props]);

  const handleSubmit = () => {
    const validator = new EditCreditApplicationModalValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.updateCreditApplication({
      creditApplicationId: props.creditApplicationId,
      creditLimitRequested: state.creditLimitRequested,
    });
    props.hideModal(EditCreditApplicationModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(EditCreditApplicationModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Edit Credit Application"
      visible={props.visible}
      submitText="Update Credit Application"
      submitDisabled={props.submitting || props.loading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Edit details for pending credit application.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors?.creditLimitRequested}>
            <Form.Label className={styles.label} inline required>
              Requested credit amount
            </Form.Label>
            <Form.Number
              name="requestedCreditAmount"
              value={state.creditLimitRequested}
              size="small"
              prefix="£"
              disabled={props.submitting || props.loading}
              highlightError={errors?.creditLimitRequested}
              formatNumber
              onChange={e => setState({ creditLimitRequested: parseInt(e.target.value, 10) })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

EditCreditApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string,
  creditLimitRequested: PropTypes.number,
  getCreditApplicationForApproval: PropTypes.func.isRequired,
  updateCreditApplication: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

EditCreditApplicationModal.displayName = "EditCreditApplicationModal";
