import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Label } from "semantic-ui-react";
import styles from "./DataMismatchCount.module.scss";

export default function DataMismatchCount(props) {
  useEffect(() => {
    props.getDataMismatchCount();
  }, []);

  return props.count > 0 && (
    <Label
      className={classnames(styles.container, props.className)}
      circular
      size="tiny"
    >
      {props.count}
    </Label>
  );
}

DataMismatchCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  getDataMismatchCount: PropTypes.func.isRequired,
};
