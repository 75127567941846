import _ from "lodash";

export default class AgencyDetailsFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.known_as)) {
      errors = _.set(errors, "known_as", "Known As is required");
    }

    if (!this.isFieldDisabled("vat_registration_number")) {
      if (_.isEmpty(data?.vat_number)) {
        errors = _.set(errors, "vat_number", "VAT Number is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  isFieldDisabled = (fieldName) => {
    return this.props?.data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
