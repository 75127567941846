import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DECLINE_CREDIT_REQUEST = gql`
mutation($id: ID!) {
  declineCreditRequest(id: $id) {
    id
    agency {
      id
      name
    }
    credit_requested
    credit_distributed
    date_communicated
    status
    created_at
    credit_request_communications {
      id
      address_type
      contact {
        first_name
        last_name
        email
      }
    }
  }
}
`;

export const declineCreditRequest = id => async (dispatch) => {
  try {
    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_PENDING,
    });

    const response = await apolloClient.query({
      query: DECLINE_CREDIT_REQUEST,
      variables: {
        id,
      },
    });

    if (!response.data.declineCreditRequest) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_SUCCESS,
      payload: {
        data: response.data.declineCreditRequest,
      },
    });
  } catch (err) {
    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
