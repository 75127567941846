import React, { useState } from "react";
import PropTypes from "prop-types";
import { getUsersName } from "../../../../../utils";
import styles from "./RequestCommunications.module.scss";

export default function RequestCommunications (props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { communications } = props;

  if (!communications?.length) {
    return "-";
  }

  const parseAddressType = (addressType) => {
    if (addressType == "cc") {
      return "CC | ";
    }

    if (addressType == "bcc") {
      return "BCC | ";
    }

    return "";
  };

  const contacts = [];
  const addressTypes = ["to", "cc", "bcc"];

  for (const addressType of addressTypes) {
    communications.forEach((communication) => {
      if (communication.address_type == addressType) {
        contacts.push(
          <div key={"communication_" + communication.id}>
            <div>{parseAddressType(communication.address_type)}{getUsersName(communication.contact)}</div>
            <div className={styles.email}>{communication.contact.email}</div>
          </div>
        );
      }
    });
  }

  if (isExpanded) {
    return (
      <div>
        {contacts}
        <div className={styles.clickable} onClick={() => setIsExpanded(false)}>Show fewer</div>
      </div>
    );
  }

  return (
    <div>
      {contacts[0]}
      {contacts.length > 1 && (
        <div className={styles.clickable} onClick={() => setIsExpanded(true)}>Show more</div>
      )}
    </div>
  );
}

RequestCommunications.propTypes= {
  communications: PropTypes.arrayOf(
    PropTypes.shape({
      address_type: PropTypes.oneOf(["to", "cc", "bcc"]),
      contact: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
};
