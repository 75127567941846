import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { PartnerList } from "../../../components";
import { Form, TextLink } from "../../../../common/components";
import { formatUrl, upToNearestThousand } from "../../../../../utils";
import styles from "./PartnershipLegalEntityForm.module.scss";

const EMPTY_PARTNER = {
  id: null,
  first_name: "",
  last_name: "",
  phone_number: "",
  address: {
    address_line_one: "",
    address_line_two: "",
    town_or_city: "",
    county: "",
    postcode: "",
  },
  meta: {
    active: true,
  },
};

export default class PartnershipLegalEntityForm extends Component {

  static propTypes = {
    legalEntity: PropTypes.shape({
      name: PropTypes.string.isRequired,
      trading_as: PropTypes.string,
      website: PropTypes.string,
      initial_credit_limit_requested: PropTypes.number.isRequired,
      partners: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        phone_number: PropTypes.string,
        address: PropTypes.shape({
          address_line_one: PropTypes.string.isRequired,
          address_line_two: PropTypes.string,
          town_or_city: PropTypes.string.isRequired,
          county: PropTypes.string.isRequired,
          postcode: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }),
    errors: PropTypes.object,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
  }

  initialState = {
    name: "",
    trading_as: "",
    website: "",
    initial_credit_limit_requested: 1000,
    partners: [EMPTY_PARTNER, EMPTY_PARTNER].map((partner, index) => ({ ...partner, position: index })),
  }

  constructor(props) {
    super(props);
    const legalEntityData = _.pick(props.legalEntity, _.keys(this.initialState));
    this.state = { ...this.initialState, ...legalEntityData };
    props.onLoad && props.onLoad(this.state);
  }

  render() {
    const { readOnly, errors } = this.props;

    return (
      <Form className={styles.container}>
        <Form.Section title={readOnly ? "Company Details" : ""}>
          {readOnly && (
            <Form.Group>
              <Form.Label>Company Type</Form.Label>
              <Form.Input
                value="Partnership"
                readOnly={readOnly}
              />
            </Form.Group>
          )}
          <Form.Group error={errors?.name}>
            <Form.Label>Partnership Name</Form.Label>
            <Form.Input
              placeholder="Enter Partnership Name..."
              name="name"
              value={this.state.name}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.name}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.trading_as}>
            <Form.Label note={readOnly || "(Optional)"}>Trading As</Form.Label>
            <Form.Input
              placeholder="Enter Trading As Name..."
              name="trading_as"
              value={this.state.trading_as}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              highlightError={errors?.trading_as}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group error={errors?.website}>
            <Form.Label note={readOnly || "(Optional)"}>Website URL</Form.Label>
            {readOnly && this.state.website ? (
              <Form.Value>
                <TextLink
                  className={styles.link}
                  target="_blank"
                  href={formatUrl(this.state.website)}
                  text={this.state.website}
                />
              </Form.Value>
            ) : (
              <Form.Input
                placeholder="Enter Website URL..."
                name="website"
                value={this.state.website}
                readOnly={readOnly}
                onChange={this.handleInputChange}
                highlightError={errors?.website}
                maxLength={255}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Estimated Credit Needed</Form.Label>
            <Form.Number
              size="small"
              name="initial_credit_limit_requested"
              value={this.state.initial_credit_limit_requested || 1000}
              placeholder="Set Amount..."
              prefix="£"
              readOnly={readOnly}
              onChange={this.handleInputChange}
              onBlur={() => this.handleInputChange({ target: { name: "initial_credit_limit_requested", value: upToNearestThousand(this.state.initial_credit_limit_requested) } })}
              formatNumber
            />
          </Form.Group>
        </Form.Section>
        <Form.Section>
          <PartnerList readOnly={readOnly} onAddClick={this.handleAddPartnerClicked}>
            {_.chain(this.state.partners)
              .uniqBy(partner => partner.position)
              .sortBy(partner => partner.position)
              .map((partner, index) => (
                <PartnerList.Item
                  key={`partner_${index}`}
                  index={partner.position}
                  active={!!partner.meta?.active}
                  partner={partner}
                  errors={errors?.partners && errors.partners[index]}
                  readOnly={readOnly}
                  onClick={this.handlePartnerItemClicked}
                  onRemoveClick={index > 1 ? this.handleRemovePartnerClicked : undefined}
                  onChange={this.handlePartnerChanged}
                />
              ))
              .value()
            }
          </PartnerList>
        </Form.Section>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => this.publishChange());
  }

  handleAddPartnerClicked = () => {
    const partners = [...this.state.partners, EMPTY_PARTNER].map((partner, index) => ({
      ...partner,
      position: index,
    }));

    this.setState({
      partners,
    }, () => this.publishChange());
  }

  handlePartnerItemClicked = (index) => {
    const partners = this.state.partners.map((partner) => {
      return partner.position === index
        ? { ...partner, meta: { ...partner.meta, active: !partner.meta?.active } }
        : partner;
    });

    this.setState({
      partners,
    });
  }

  handleRemovePartnerClicked = (index) => {
    const partners = this.state.partners.filter((_partner, i) => {
      return i !== index;
    });

    this.setState({
      partners,
    }, () => this.publishChange());
  }

  handlePartnerChanged = (index, data) => {
    const partners = this.state.partners.map((partner, i) => {
      return i === index ? data : partner;
    });

    this.setState({
      partners,
    }, () => this.publishChange());
  }

  publishChange = () => {
    const { onChange, legalEntity } = this.props;
    const { partners } = this.state;

    onChange && onChange({
      ...this.state,
      partners: partners.map(partner => _.omit(partner, ["meta", legalEntity ? "" : "id"])),
    });
  }

}
