import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./CreditDeclinedModal.module.scss";
import { Modal, Form } from "../../../common/components";

export default class CreditApprovedModal extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  initialState = {
    formSubmitting: false,
    formData: {
      completedText: "",
      useDiscretionaryLimit: false,
    },
  };

  state = this.initialState;

  render() {
    const { formSubmitting, formData } = this.state;
    const { useDiscretionaryLimit, completedText } = formData;
    const { visible, source } = this.props;

    return (
      <Modal
        title="Credit Declined"
        visible={visible}
        primaryButtonDisabled={formSubmitting}
        onSubmit={this.handleCreditDeclinedClicked}
        onCancel={this.handleCancelModalClicked}
        submitText="Save"
      >
        <Form>
          <Form.Section>
            <Form.Group>
              <Form.Label className={styles.discretionaryLabel}>Is the {source} allowing use of discretionary limit?</Form.Label>
              <Form.Radio
                className={styles.discretionaryRadio}
                name="discretionary"
                text="Yes"
                value="Yes"
                onChange={this.handleDiscretionaryChange}
                checked={useDiscretionaryLimit}
              />
              <Form.Radio
                name="discretionary"
                text="No"
                value="No"
                onChange={this.handleDiscretionaryChange}
                checked={!useDiscretionaryLimit}
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                placeholder="Why was credit declined?"
                name="completedText"
                value={completedText}
                onChange={this.handleFormChange}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </Modal>
    );
  }

  handleFormChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleDiscretionaryChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        useDiscretionaryLimit: e.target.value === "Yes",
      },
    });
  }

  handleCreditDeclinedClicked = _.throttle(async () => {
    const { formData } = this.state;
    const { onSubmit } = this.props;

    this.setState({
      formSubmitting: true,
    });

    onSubmit && await onSubmit(formData);

    this.setState(this.initialState);
  }, 1000)

  handleCancelModalClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();

    this.setState(this.initialState);
  }
}
