import _ from "lodash";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import * as GQL_TYPES from "../gqltypes";
import { SortOrderConverter } from "../../../../converters";
import { PaginatedResponseFormatter } from "../../../../formatters";
import { legalEntities } from "../../../../utils";

export const getLegalEntities = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.GET_LEGAL_ENTITIES_PENDING,
    });
    const state = await getState();
    const pager = {
      page: state.creditLimitManagement.pagination.page,
      limit: state.creditLimitManagement.pagination.limit,
    };
    const response = await apolloClient.query({
      query: GQL_TYPES.GET_LEGAL_ENTITIES,
      variables: {
        pager,
      },
    });
    const pagination = PaginatedResponseFormatter.format(
      response.data.getLegalEntities,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.GET_LEGAL_ENTITIES_SUCCESS,
      payload: {
        legalEntities: response.data.getLegalEntities.data,
        ...pagination,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LEGAL_ENTITIES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const searchLegalEntities = ({
  pager: { page, limit },
  searchOptions,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.SEARCH_LEGAL_ENTITIES_PENDING,
    });
    if (searchOptions.credit_suspended === false) {
      delete searchOptions["credit_suspended"];
    }
    const pager = {
      page: page || 1,
      limit: limit || 0,
    };
    const response = await apolloClient.query({
      query: GQL_TYPES.SEARCH_LEGAL_ENTITIES,
      variables: {
        pager,
        search_options: searchOptions,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.searchLegalEntities,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.SEARCH_LEGAL_ENTITIES_SUCCESS,
      payload: {
        legalEntities: response.data.searchLegalEntities.data,
        ...pagination,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.SEARCH_LEGAL_ENTITIES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const setPagination = pagination => (dispatch) => {
  dispatch({ type: types.SET_PAGINATION, payload: { pagination } });
};

export const addLegalEntity = ({
  name,
  company_type,
  ...otherData
}) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_LEGAL_ENTITIES_PENDING });

    const type = _.isEmpty(otherData.company_number)
      ? company_type
      : "registered";

    if (company_type === "sole_trader") {
      name = legalEntities.soleTraderNameGenerator(otherData.first_name, otherData.last_name, otherData.trading_as);
    }

    const response = await apolloClient.query({
      query: GQL_TYPES.ADD_LEGAL_ENTITY,
      variables: {
        input: {
          name,
          company_type,
          [type]: otherData,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({ type: types.ADD_LEGAL_ENTITIES_SUCCESS });

    dispatch(push(`/credit-limit-management/legal-entities/${response.data.addLegalEntity.id}`));
  }
  catch (err) {
    dispatch({
      type: types.ADD_LEGAL_ENTITIES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const updateLegalEntity = (id, {
  name,
  company_type,
  ...otherData
}) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_LEGAL_ENTITY_PENDING });

    const type = _.isEmpty(otherData.company_number)
      ? company_type
      : "registered";

    if (company_type === "sole_trader") {
      name = legalEntities.soleTraderNameGenerator(otherData.first_name, otherData.last_name, otherData.trading_as);
    }

    const response = await apolloClient.query({
      query: GQL_TYPES.UPDATE_LEGAL_ENTITY,
      variables: {
        id,
        input: {
          name,
          company_type,
          [type]: otherData,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_LEGAL_ENTITY_SUCCESS,
      payload: {
        legalEntityDetails: response.data.updateLegalEntity,
      },
    });

    dispatch(push(`/credit-limit-management/legal-entities/${id}/details`));
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_LEGAL_ENTITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getCreditDetails = id => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDIT_DETAILS_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CREDIT_DETAILS,
      variables: {
        id,
      },
    });

    dispatch({
      type: types.GET_CREDIT_DETAILS_SUCCESS,
      payload: {
        creditDetails: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const createCreditApplicationComment = (credit_application_id, comment) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_CREDIT_APPLICATION_COMMENT_PENDING });
    const input = { credit_application_id, comment };
    const commentResponse = await apolloClient.mutate({ mutation: GQL_TYPES.CREATE_CREDIT_APPLICATION_COMMENT, variables: { input } });
    dispatch({ type: types.CREATE_CREDIT_APPLICATION_COMMENT_SUCCESS, payload:{ comment: commentResponse.data.createCreditApplicationComment } });
  }
  catch (error) {
    dispatch({ type: types.CREATE_CREDIT_APPLICATION_COMMENT_FAILURE, payload: { error } });
  }
};

export const getExperianCreditChecks = legalEntityId => async (dispatch) => {
  try {
    dispatch({ type: types.GET_EXPERIAN_CREDIT_CHECKS_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_EXPERIAN_CREDIT_CHECKS,
      variables: {
        legal_entity_id: legalEntityId,
        pager: {
          page: 1,
          limit: 99999,
        },
      },
    });

    if (!response.data.getExperianCreditChecks) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECKS_SUCCESS,
      payload: {
        experianCreditChecks: response.data.getExperianCreditChecks,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECKS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getLatestExperianCreditCheck = legalEntityId => async (dispatch) => {
  try {
    dispatch({ type: types.GET_EXPERIAN_CREDIT_CHECKS_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_LATEST_EXPERIAN_CREDIT_CHECK,
      variables: {
        legal_entity_id: legalEntityId,
        pager: {
          page: 1,
          limit: 1,
        },
      },
    });

    if (!response.data.getExperianCreditChecks) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECKS_SUCCESS,
      payload: {
        experianCreditChecks: response.data.getExperianCreditChecks,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECKS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getContracts = ({
  legalEntityId,
  pager,
  sortOrder,
  searchText,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTRACTS_PENDING,
      payload: {
        legalEntityId,
      },
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CONTRACTS,
      variables: {
        legal_entity_id: legalEntityId,
        pager,
        sort_order: SortOrderConverter(sortOrder),
        search_options: _.isEmpty(searchText) ? null : {
          agency: searchText,
          title: searchText,
          created_by: searchText,
        },
      },
    });

    if (!response.data.getContracts) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.getContracts,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.GET_CONTRACTS_SUCCESS,
      payload: {
        data: response.data.getContracts.data,
        ...pagination,
        sortOrder,
        searchText,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTRACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getContract = contractId => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTRACT_PENDING,
      payload: {
        contractId,
      },
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CONTRACT,
      variables: {
        contract_id: contractId,
      },
    });

    if (!response.data.getContract) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CONTRACT_SUCCESS,
      payload: {
        data: {
          ...response.data.getContract,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTRACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getCreditActivity = legalEntityId => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDIT_ACTIVITY_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CREDIT_ACTIVITY,
      variables: {
        legal_entity_id: legalEntityId,
        pager: {
          page: 1,
          limit: 100,
        },
        sort_order: [{
          field: "created_at",
          order: "desc",
        }],
      },
    });

    if (!response.data.getCreditActivity) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CREDIT_ACTIVITY_SUCCESS,
      payload: {
        data: response.data.getCreditActivity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_ACTIVITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getCreditApplications = legal_entity_id => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDIT_APPLICATIONS_PENDING });
    const creditApplications = await apolloClient.query({
      query: GQL_TYPES.GET_CREDIT_APPLICATIONS,
      variables: {
        legal_entity_id,
        pager: {
          page: 1,
          limit: 100,
        },
        sort_order: [{
          field: "created_at",
          order: "desc",
        }],
        include_completed: true,
      },
    });
    dispatch({
      type: types.GET_CREDIT_APPLICATIONS_SUCCESS,
      payload:{
        creditApplications: creditApplications.data.getLegalEntityCreditApplications.data,
      },
    });
  }
  catch (error) {
    dispatch({ type: types.GET_CREDIT_APPLICATIONS_FAILURE, payload: { error } });
  }
};

export const getCreditApplication = (legal_entity_id, credit_application_id) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDIT_APPLICATION_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CREDIT_APPLICATION,
      variables: {
        legal_entity_id,
        credit_application_id,
      },
    });

    if (!response.data.getLegalEntityCreditApplication) {
      // Return to the credit management tab if the credit application is not found (to prevent rendering errors)
      // TODO: dispatch notification event with why the user was redirected
      dispatch(push(`/credit-limit-management/legal-entities/${legal_entity_id}/credit-management`));
      return;
    }

    dispatch({
      type: types.GET_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.getLegalEntityCreditApplication,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const cancelCreditApplication = ({ credit_application_id, notes = "" }) => async (dispatch) => {
  try {
    dispatch({ type: types.CANCEL_CREDIT_APPLICATION_PENDING });
    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.CANCEL_CREDIT_APPLICATION,
      variables: {
        input: {
          credit_application_id,
          notes,
        },
      },
    });

    if (!response.data.cancelCreditApplication) {
      throw "Could not cancel credit application.";
    }
    dispatch({
      type: types.CANCEL_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.cancelCreditApplication,
      },
    });

  } catch (error) {
    dispatch({
      type: types.CANCEL_CREDIT_APPLICATION_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const createScheduledCreditAction = ({ legal_entity_id, type, source, new_credit_limit, scheduled_at, scheduled_by, comment }) => async (dispatch) => {
  try {
    dispatch({
      type: types.CREATE_SCHEDULED_CREDIT_ACTION_PENDING,
    });
    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.CREATE_SCHEDULED_CREDIT_ACTION,
      variables: {
        input: {
          legal_entity_id,
          type,
          source,
          new_credit_limit: !_.isNil(new_credit_limit)
            ? parseInt(new_credit_limit)
            : null,
          scheduled_at,
          scheduled_by,
          comment,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CREATE_SCHEDULED_CREDIT_ACTION_SUCCESS,
      payload: {
        scheduledCreditAction: response.data.createScheduledCreditAction,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.CREATE_SCHEDULED_CREDIT_ACTION_FAILURE,
      payload: {
        error,
      },
    });
  }
};



export const getScheduledCreditActions = legalEntityId => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTIONS_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_SCHEDULED_CREDIT_ACTIONS,
      variables: {
        legal_entity_id: legalEntityId,
        pager: {
          page: 1,
          limit: 1000,
        },
        sort_order: [{
          field: "created_at", order: "desc",
        }],
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTIONS_SUCCESS,
      payload: {
        scheduledCreditActions: response.data.getScheduledCreditActions.data,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTIONS_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const getScheduledCreditAction = scheduledCreditActionId => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTION_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_SCHEDULED_CREDIT_ACTION,
      variables: {
        scheduled_credit_action_id: scheduledCreditActionId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTION_SUCCESS,
      payload: {
        scheduledCreditAction: response.data.getScheduledCreditAction,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_SCHEDULED_CREDIT_ACTION_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const cancelScheduledAction = ({ scheduledCreditActionId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.CANCEL_SCHEDULED_CREDIT_ACTION_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.CANCEL_SCHEDULED_CREDIT_ACTION,
      variables: {
        scheduled_credit_action_id: scheduledCreditActionId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CANCEL_SCHEDULED_CREDIT_ACTION_SUCCESS,
      payload: response.data.cancelScheduledCreditAction,
    });
  }
  catch (error) {
    dispatch({
      type: types.CANCEL_SCHEDULED_CREDIT_ACTION_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const createContract = input => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_CONTRACT_PENDING });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.CREATE_CONTRACT,
      variables: {
        input,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CREATE_CONTRACT_SUCCESS,
      payload: {
        contract: response.data.addContract,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.CREATE_CONTRACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getContractContacts = ({
  legalEntityId,
  agencyId,
  searchText,
  sortOrder,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTRACT_CONTACTS_PENDING,
    });

    if (_.isEmpty(searchText)) {
      dispatch({
        type: types.GET_CONTRACT_CONTACTS_SUCCESS,
        payload: {
          data: [],
        },
      });
      return;
    }

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_CONTRACT_CONTACTS,
      variables: {
        legal_entity_id: legalEntityId,
        agency_id: agencyId,
        pager: {
          page: 1,
          limit: 100,
        },
        search_options: _.isEmpty(searchText) ? null : {
          first_name: searchText,
          last_name: searchText,
          email: searchText,
          job_role: searchText,
        },
        sort_order: SortOrderConverter(sortOrder),
      },
    });

    dispatch({
      type: types.GET_CONTRACT_CONTACTS_SUCCESS,
      payload: {
        data: response.data.getContractContacts.data,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTRACT_CONTACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getTradingStyles = ({
  legalEntityId,
  agencyId,
  searchText,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_TRADING_STYLES_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_TRADING_STYLES,
      variables: {
        legal_entity_id: legalEntityId,
        agency_id: agencyId,
        search_options: _.isEmpty(searchText) ? null : {
          name: searchText,
        },
      },
    });

    dispatch({
      type: types.GET_TRADING_STYLES_SUCCESS,
      payload: {
        data: response.data.getTradingStyles,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_TRADING_STYLES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
