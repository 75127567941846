import React from "react";
import PropTypes from "prop-types";
import { Edit, Lock } from "@material-ui/icons";
import { DocumentDownload, Form, IconButton } from "../../../../common/components";
import { DOCUMENT_TYPES } from "../../../../documents";
import { parseDate } from "../../../../../utils";
import { tooltips } from "../../../../../constants";
import styles from "./AutoEnrolmentPensionsSection.module.scss";

export default function AutoEnrolmentPensionsSection(props) {
  const documents = [
    {
      name: DOCUMENT_TYPES.PENSION_DOCUMENT,
      file: props.pensionDocument,
    },
  ];

  return (
    <Form.Card
      className={styles.container}
      title="Auto-Enrolment Pensions"
      rightAccessory={(
        props.isNewAgency
          ?
          <IconButton
            className={styles.locked}
            icon={<Lock />}
            tooltip={tooltips.NOT_AVAILABLE_TO_PROSPECTS_TOOLTIP}
          />
          :
          <IconButton
            icon={<Edit />}
            tooltip="Edit"
            disabled={!props.onEditClick}
            onClick={props.onEditClick}
          />
      )}
    >
      <div className={styles.contentWrapper}>
        {!props.isNewAgency && <Form.Group inline>
          <Form.Label inline>Previous Pension Provider</Form.Label>
          <Form.ValueInline>{props.previousPensionProvider || "-"}</Form.ValueInline>
        </Form.Group>}
        <Form.Group inline>
          <Form.Label inline>Pension Company</Form.Label>
          <Form.ValueInline>{props.pensionCompany || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Pension Scheme</Form.Label>
          <Form.ValueInline>{props.pensionScheme || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Staging Date</Form.Label>
          <Form.ValueInline>{parseDate(props.stagingDate) || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Default Postponement Months</Form.Label>
          <Form.ValueInline>{props.defaultPostponementMonths || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Eligible Job Holder Postponement Months</Form.Label>
          <Form.ValueInline>{props.eligibleJobHolderPostponementMonths || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label inline>Employer Pension Reference</Form.Label>
          <Form.ValueInline>{props.employerPensionReference || "-"}</Form.ValueInline>
        </Form.Group>
        <DocumentDownload
          documents={documents.filter(doc => doc.file)}
          onDownload={props.onDownload}
          variant="footer"
        />
      </div>
    </Form.Card>
  );
}

AutoEnrolmentPensionsSection.propTypes = {
  isNewAgency: PropTypes.bool,
  defaultPostponementMonths: PropTypes.string,
  eligibleJobHolderPostponementMonths: PropTypes.string,
  employerPensionReference: PropTypes.string,
  pensionCompany: PropTypes.string,
  pensionDocument: PropTypes.string,
  pensionScheme: PropTypes.string,
  stagingDate: PropTypes.string,
  previousPensionProvider: PropTypes.string,
  onDownload: PropTypes.func,
  onEditClick: PropTypes.func,
};
