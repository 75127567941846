import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_CREDIT_APPLICATION = gql`
mutation($input: UpdateCreditApplicationInput!) {
  updateCreditApplication(input:$input) {
    id
    reference_number
    source
    credit_limit_requested
    credit_requested_by
    use_discretionary_limit
    approved_credit_excluding_vat
    completed_at
    status
    created_at

    created_by {
        id
        first_name
        last_name
    }

    comments {
      id
      created_by {
        first_name
        last_name
      }
      comment
      type
      created_at
    }

    legal_entity {
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }

    experian_credit_check {
      experian_credit_result
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 * @param {number} obj.creditLimitRequested
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const updateCreditApplication = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_CREDIT_APPLICATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_CREDIT_APPLICATION,
      variables: {
        input: {
          credit_application_id: obj.creditApplicationId,
          credit_limit_requested: parseInt(obj.creditLimitRequested, 10),
        },
      },
    });

    dispatch({
      type: types.UPDATE_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.updateCreditApplication,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
