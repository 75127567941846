import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { ExperianCreditReportUpload } from "../../";
import { Form, Modal } from "../../../../common/components";
import styles from "./InputManualLimitModal.module.scss";

export default function InputManualLimitModal (props) {
  const canComplete = () => {
    if (props.data.companyFound && _.isNil(props.data.newCreditLimit)) return false;
    if (!props.data.dateOfResult) return false;
    if (props.data.companyFound && !props.data.experianCreditReport) return false;
    return true;
  };

  return (
    <Modal
      title="Experian Result"
      subtitle="Complete the form below to record the Experian credit limit."
      visible={props.visible}
      busy={props.loading}
      className={classnames(styles.container, props.className)}
      submitText="Complete"
      onSubmit={props.onSubmit}
      cancelText="Complete later"
      cancelVariant="secondary"
      onCancel={props.onCancel}
      buttonPosition="middle"
      primaryButtonDisabled={!canComplete()}
    >
      <Form requiredKey>
        <Form.Group inline className={styles.group}>
          <Form.Label inline required>Were you able to find the company on Experian?</Form.Label>
          <div>
            <Form.Radio
              value={true}
              name="companyFound"
              checked={props.data.companyFound}
              text="Yes"
              onChange={props.onRadioChange}
            />
            <Form.Radio
              value={false}
              name="companyFound"
              checked={!props.data.companyFound}
              text="No"
              onChange={props.onRadioChange}
            />
          </div>
        </Form.Group>
        {props.data.companyFound && (
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>Result amount (Excl. VAT)</Form.Label>
            <Form.Number
              className={styles.numberInput}
              name="newCreditLimit"
              value={props.data.newCreditLimit}
              placeholder="eg. £10,000"
              prefix="£"
              onChange={props.onChange}
              disabled={props.loading}
              formatNumber
            />
          </Form.Group>
        )}
        <Form.Group inline className={styles.group} error={props.errors.dateOfResult}>
          <Form.Label inline required className={styles.dateLabel}>
            Date result obtained
          </Form.Label>
          <Form.Value>
            <Form.Date
              name="dateOfResult"
              size="small"
              value={props.data.dateOfResult}
              onChange={props.onChange}
              disabled={props.loading}
              highlightError={props.errors.dateOfResult}
              fluid
            />
          </Form.Value>
        </Form.Group>
        {props.data.companyFound && (
        <>
          <Form.Group inline>
            <Form.Label inline required>
              Experian credit report<br />
              (Maximum 5GB)
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <ExperianCreditReportUpload
              name="experianCreditReport"
              value={props.data.experianCreditReport}
              onChange={props.onChange}
              disabled={props.loading}
              error={props.errors.experianCreditReport}
            />
          </Form.Group>
          </>
        )}
      </Form>
    </Modal>
  );
}

InputManualLimitModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    companyFound: PropTypes.bool,
    newCreditLimit: PropTypes.number,
    dateOfResult: PropTypes.number,
    experianCreditReport: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
