import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Divider, Icon, Table } from "semantic-ui-react";
import { Edit } from "@material-ui/icons";
import { ConfirmationModal, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./VerifyAgencyCustomerModal.module.scss";

export default function VerifyAgencyCustomerModal(props) {
  const [state, setState] = useState({
    mismatchReasonValue: null,
    mismatchReasonDetails: null,
  });
  const [mismatchReasons, setMismatchReasons] = useState([]);

  useEffect(() => {
    if (!isRegisteredCompanyNumberMatch() && isExactLegalNameMatch()) {
      setState({ ...state, mismatchReasonValue: "typo_on_company_number" });
    }
    else if (isRegisteredCompanyNumberMatch() && !isExactLegalNameMatch()) {
      setState({ ...state, mismatchReasonValue: "user_omitted_the_word_limited_ltd" });
    }
    else if (!isRegisteredCompanyNumberMatch() && !isExactLegalNameMatch()) {
      setState({ ...state, mismatchReasonValue: "other" });
    }
  }, []);

  const getSubmitText = () => {
    return props.data.hasBeenCreditChecked
      ? "Verify"
      : "Proceed to Credit Check";
  };

  const renderMatch = (isMatch) => {
    return isMatch
      ? <Icon name="check circle outline" color="green" size="large" />
      : <Icon name="times circle outline" color="red" size="large" />;
  };

  const isRegisteredCompanyNumberMatch = () => {
    const source = props.data?.enteredByUser?.registeredCompanyNumber;
    const target = props.data?.selectedFromCompaniesHouse?.registeredCompanyNumber;

    return source === target;
  };

  const isExactLegalNameMatch = () => {
    const source = props.data?.enteredByUser?.exactLegalName?.toUpperCase();
    const target = props.data?.selectedFromCompaniesHouse?.exactLegalName?.toUpperCase();

    return source === target;
  };

  const canSubmit = () => {
    if (!isRegisteredCompanyNumberMatch() || !isExactLegalNameMatch()) {
      if (!state.mismatchReasonValue) {
        return false;
      }

      if (state.mismatchReasonValue === "other") {
        return state.mismatchReasonDetails?.trim()?.length > 0;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    const data = {
      ...state,
      mismatchReasonId: mismatchReasons.find(x => x.value === state.mismatchReasonValue)?.id,
    };

    props.onSubmit(data);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title={`Verify Legal Entity for ${props.data.agencyName}`}
      submitText={getSubmitText()}
      cancelText="Cancel"
      submitDisabled={!canSubmit()}
      visible={props.visible}
      onSubmit={!props.loading && handleSubmit}
      onCancel={!props.loading && handleCancel}
    >
      {props.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <div className={styles.line}>
            Please verify that the selected Legal Entity is correct.
          </div>
          <Table
            className={styles.table}
            basic="very"
            striped
          >
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell className={styles.headerCell}>
                Registered Company Number
              </Table.HeaderCell>
              <Table.HeaderCell  className={styles.headerCell}>
                Exact Legal Name
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>
                  Entered by user
                </Table.Cell>
                <Table.Cell>
                  {props.data?.enteredByUser?.registeredCompanyNumber}
                </Table.Cell>
                <Table.Cell>
                  {props.data?.enteredByUser?.exactLegalName}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>
                  Selected from Companies House
                </Table.Cell>
                <Table.Cell>
                  {props.data?.selectedFromCompaniesHouse?.registeredCompanyNumber}
                </Table.Cell>
                <Table.Cell>
                  {props.data?.selectedFromCompaniesHouse?.exactLegalName}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>
                  Match?
                </Table.Cell>
                <Table.Cell>
                  {renderMatch(isRegisteredCompanyNumberMatch())}
                </Table.Cell>
                <Table.Cell>
                  {renderMatch(isExactLegalNameMatch())}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider className={styles.divider} />
          <div
            className={styles.changeCompaniesHouseSelectionLink}
            onClick={props.onChangeCompaniesHouseSelectionClick}
          >
            <Edit className={styles.changeCompaniesHouseSelectionIcon} />
            Change Companies House Selection
          </div>
          {(!isRegisteredCompanyNumberMatch() || !isExactLegalNameMatch()) && (
            <Form className={styles.mismatchReason}>
              <Form.Group inline>
                <Form.Label className={styles.label} inline>
                  Reason for verifying a mis-matched Legal Entity
                </Form.Label>
                <List
                  identifier="verify_mismatch_reasons"
                  value={state.mismatchReasonValue}
                  onReady={list => setMismatchReasons(list.data?.values)}
                  onChange={e => setState({
                    ...state,
                    mismatchReasonValue: e.target.value,
                    mismatchReasonDetails: e.target.value === "other" ? state.mismatchReasonDetails : null,
                  })}
                />
              </Form.Group>
              {state.mismatchReasonValue === "other" && (
                <Form.Group inline>
                  <Form.Label className={styles.moreDetailsLabel} inline>
                    More details
                  </Form.Label>
                  <Form.TextArea
                    className={styles.textArea}
                    value={state.mismatchReasonDetails}
                    rows={6}
                    maxLength={255}
                    onChange={e => setState({ ...state, mismatchReasonDetails: e.target.value })}
                  />
                </Form.Group>
              )}
            </Form>
          )}
        </>
      )}
    </ConfirmationModal>
  );
}

VerifyAgencyCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    agencyName: PropTypes.string.isRequired,
    enteredByUser: PropTypes.shape({
      registeredCompanyNumber: PropTypes.string,
      exactLegalName: PropTypes.string,
    }),
    selectedFromCompaniesHouse: PropTypes.shape({
      registeredCompanyNumber: PropTypes.string,
      exactLegalName: PropTypes.string,
    }),
    hasBeenCreditChecked: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeCompaniesHouseSelectionClick: PropTypes.func.isRequired,
};

VerifyAgencyCustomerModal.displayName = "VerifyAgencyCustomerModal";
