import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Grid, Header } from "semantic-ui-react";
import { RequestHistoryList } from "../../components";
import styles from "./RequestHistoryTab.module.scss";

export default class RequestHistoryTab extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    creditRequests: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
    getCreditRequests: PropTypes.func.isRequired,
    showDeclineCreditRequestModal: PropTypes.func.isRequired,
    showDistributeCreditRequestModal: PropTypes.func.isRequired,
    showCommunicateToAgencyModal: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getCreditRequests } = this.props;

    await getCreditRequests({ legalEntityId });
  }

  render() {
    return (
      <Grid className={styles.container}>
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <Header>Request History</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {this.props.creditRequests.loading ? (
              <ActivityIndicator />
            ) : (
              <RequestHistoryList
                data={this.props.creditRequests.data}
                legalEntityId= {this.props.legalEntityId}
                handleCommunicateToAgencyRequest={this.props.showCommunicateToAgencyModal}
                handleDeclineCreditRequest={this.props.showDeclineCreditRequestModal}
                handleDistributeCreditRequest={this.props.showDistributeCreditRequestModal}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}
