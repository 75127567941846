import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Table } from "semantic-ui-react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { StatusLabel, TextLink } from "modules/common/components";
import styles from "./AgencyRow.module.scss";
import { getUsersName, parseDate } from "../../../../../utils";

export default function AgencyRow({ index, agency, expanded, onRowClick }) {
  const isStriped = index % 2 === 1;

  const getVariantForStatus = (agencyStatus) => {
    switch (agencyStatus) {
      case "Live (Active)":
        return "live";

      case "Live (Not Active)":
        return "dormant";

      case "Left":
        return "closed";

      default:
        return "Unknown";
    }
  };

  return (
    <>
      <Table.Row
        key={`agency_${agency.id}`}
        className={classnames(
          styles.agencyRow,
          isStriped && styles.stripe,
          expanded && styles.expandedRow,
        )}
        onClick={() => onRowClick()}
      >
        <Table.Cell>
          <TextLink
            className={styles.agencyName}
            text={agency.known_as}
            to={`/agencies/${agency.id}`}
          />
        </Table.Cell>
        <Table.Cell>
          {agency.exact_legal_name}
        </Table.Cell>
        <Table.Cell>
          {agency.short_code?.toUpperCase()}
        </Table.Cell>
        <Table.Cell>
          {agency.worker_types?.map(({ name }) => name).join(", ")}
        </Table.Cell>
        <Table.Cell>
          <StatusLabel
            status={agency.agency_status?.label}
            variant={getVariantForStatus(agency.agency_status?.label)}
          />
        </Table.Cell>
        <Table.Cell textAlign="right" verticalAlign="middle">
          {expanded
            ? <ExpandLess className={styles.icon} />
            : <ExpandMore className={styles.icon} />
          }
        </Table.Cell>
      </Table.Row>

      {expanded && (
        <Table.Row
          key={`agency_${agency.id}`}
          className={classnames(
            styles.agencyRowExpanded,
            isStriped && styles.stripe,
            expanded && styles.expandedRow,
          )}
          onClick={() => onRowClick()}
        >
          <Table.Cell>
            <div>
              <div className={styles.label}>Last Payroll</div>
              <div>{parseDate(agency.last_payslip_date) || "-"}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div>
              <div className={styles.label}>Payroll Account Manager</div>
              <div>{getUsersName(agency.payroll_account_manager) || "-"}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div>
              <div className={styles.label}>Credit Controller</div>
              <div>{getUsersName(agency.credit_controller) || "-"}</div>
            </div>
          </Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

AgencyRow.propTypes = {
  index: PropTypes.number.isRequired,
  agency: PropTypes.shape({
    id: PropTypes.string.isRequired,
    known_as: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    exact_legal_name: PropTypes.string.isRequired,
    worker_types: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
    }).isRequired,
    agency_status: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    last_payslip_date: PropTypes.string,
    payroll_account_manager: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    credit_controller: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  expanded: PropTypes.bool,
  onRowClick: PropTypes.func.isRequired,
};
