import { showCommunicateToAgencyModal } from "./";
import { CreditDistributedModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {string} obj.legalEntityId
 * @param {string} obj.creditRequestId
 * @param {string} obj.agencyId
 * @param {string} obj.agencyName
 * @param {string} obj.legalEntityName
 * @param {number} obj.distributedCredit
 *
 * @returns
 */
export const showCreditDistributedModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(CreditDistributedModal.displayName, {
    agencyName: obj.agencyName,
    legalEntityName: obj.legalEntityName,
    distributedCredit: obj.distributedCredit,
    onSubmit: () => {
      dispatch(showCommunicateToAgencyModal({
        agencyId: obj.agencyId,
        legalEntityId: obj.legalEntityId,
        creditRequestId: obj.creditRequestId,
      }));

      dispatch(modalActions.hideModal(CreditDistributedModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(CreditDistributedModal.displayName));
    },
  }));
};
