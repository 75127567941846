import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { List } from "semantic-ui-react";
import { Edit } from "@material-ui/icons";
import { DateOfNextReviewTooltip } from "../../../../components";
import { IconButton } from "../../../../../common/components";
import { getUsersName, parseDate, toMoney } from "../../../../../../utils";
import styles from "./CreditChecks.module.scss";

export default function CreditChecks(props) {
  const getMoneyValue = (value) => {
    if (_.isNil(value)) {
      return "-";
    }

    return toMoney(value);
  };

  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <List divided verticalAlign="middle">
      <List.Item>
        <List.Content floated="right" className={styles.value}>
          {
            props.latestExperianReport?.status === "company_not_found" ?
              "Company Not Found" :
              getMoneyValue(props.latestExperianReport?.result_excluding_vat)
          }
        </List.Content>
        <List.Content className={styles.content}>
          Experian result (Excl. VAT)
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content floated="right" className={styles.value}>
          {parseDate(props.latestExperianReport?.date_of_result) || "-"}
        </List.Content>
        <List.Content className={styles.content}>
          Date of latest Experian check
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content floated="right" className={styles.value}>
          {
            props.latestInsurerReport?.status === "company_not_found" ?
              "Company Not Found" :
              getMoneyValue(props.latestInsurerReport?.result_excluding_vat)
          }
        </List.Content>
        <List.Content className={styles.content}>
            Insurer result (Excl. VAT)
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content floated="right" className={styles.value}>
          {parseDate(props.latestInsurerReport?.date_of_result) || "-"}
        </List.Content>
        <List.Content className={styles.content}>
          Date of latest Insurer result
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content floated="right" className={styles.nextReviewDate}>
          <IconButton
            className={styles.editNextReviewDateButton}
            icon={<Edit />}
            onClick={props.onEditDateOfNextReview}
          />
          {parseDate(props.nextReviewDate?.next_review_date) || "-"}
        </List.Content>
        <List.Content className={styles.content}>
          Date of Next Review
          {props.nextReviewDate && (
            <DateOfNextReviewTooltip
              className={styles.dateOfNextReviewTooltip}
              text="See Latest Update"
              updatedBy={getUsersName(props.nextReviewDate?.created_by)}
              updatedAt={props.nextReviewDate?.created_at}
              comment={props.nextReviewDate?.comment}
            />
          )}
        </List.Content>
      </List.Item>
      {/* So we can get the last underline */}
      <List.Item />
    </List>
  );
}

CreditChecks.propTypes = {
  loading: PropTypes.bool,
  latestExperianReport: PropTypes.shape({
    result_excluding_vat: PropTypes.number.isRequired,
    date_of_result: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }),
  latestInsurerReport: PropTypes.shape({
    result_excluding_vat: PropTypes.number.isRequired,
    date_of_result: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }),
  nextReviewDate: PropTypes.shape({
    next_review_date: PropTypes.number.isRequired,
    created_by: PropTypes.object.isRequired,
    created_at: PropTypes.number.isRequired,
    comment: PropTypes.string,
  }),
  onEditDateOfNextReview: PropTypes.func.isRequired,
};
