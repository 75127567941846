import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ExpandMore } from "@material-ui/icons";
import { Squares as ActivityIndicator } from "react-activity";
import { CollapseContainer } from "../../../common/components";
import styles from "./OnboardingInformation.module.scss";

export default function OnboardingInformation(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
        <h1 className={styles.title}>
          Initial Onboarding Information
        </h1>
        {props.loading ? (
          <ActivityIndicator />
        ) : (
          <span className={styles.icon}>
            <ExpandMore
              className={classnames(
                styles.expandIcon,
                isExpanded ? styles.collapse : styles.expand
              )}
              fontSize="inherit"
            />
          </span>
        )}
      </div>
      <CollapseContainer collapsed={!isExpanded}>
        <div className={styles.flexContainer}>
          <div className={styles.question}>What kind of workers?</div>
          <div className={styles.information}>{_.join(props.workerTypes, ", ") || "-"}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.question}>Is the agency new or existing?</div>
          <div className={styles.information}>{props.isNewAgency ? "New" : "Existing"}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.question}>Existing PAYE scheme?</div>
          <div className={styles.information}>{props.isExistingPayeScheme ? "Yes" : "No"}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.question}>Introduced via Broker?</div>
          <div className={styles.information}>{props.introducedViaBroker ? "Yes" : "No"}</div>
        </div>
      </CollapseContainer>
    </div>
  );
}

OnboardingInformation.propTypes = {
  loading: PropTypes.bool,
  isNewAgency: PropTypes.bool,
  isExistingPayeScheme: PropTypes.bool,
  introducedViaBroker: PropTypes.bool,
  workerTypes: PropTypes.arrayOf(PropTypes.string),
};
