import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../../duck";
import { ExperianResponse, CreditApprovedModal, CreditCancelledModal, CreditDeclinedModal } from "../../../components";
import { Button, ButtonGroup, CommentsSection, Modal, Form } from "../../../../common/components";
import { CreditApplicationCommentTypeConverter } from "../../../../../converters";
import { capitalize, parseDate, toMoney } from "../../../../../utils";
import { EXCLUSIVE_VAT_LABEL } from "../../../../../config";
import styles from "./CreditApplication.module.scss";

const initialState = {
  showLeaveCommentModal: false,
  showCreditApprovedModal: false,
  showCreditDeclinedModal: false,
  showCreditCancelledModal: false,
  commentText: "",
  isSaving: false,
};

export class CreditApplication extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    creditApplicationId: PropTypes.string.isRequired,
    creditApplication: PropTypes.shape({
      id: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      credit_limit_requested: PropTypes.number.isRequired,
      credit_requested_by: PropTypes.string,
      status: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
      legal_entity: PropTypes.shape({
        initial_credit_limit_requested: PropTypes.number.isRequired,
        companies_house_info: PropTypes.shape({
          accounts: PropTypes.shape({
            next_due: PropTypes.string.isRequired,
          }),
        }),
      }).isRequired,
      experian_credit_check: PropTypes.shape({
        experian_response: PropTypes.object.isRequired,
      }).isRequired,
      comments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        created_by: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
      })),
    }),
    creditDetails: PropTypes.shape({
      initial_credit_limit_requested: PropTypes.number,
    }),
    getCreditApplication: PropTypes.func.isRequired,
    getCreditDetails: PropTypes.func.isRequired,
    createCreditApplicationComment: PropTypes.func.isRequired,
    completeCreditApplication: PropTypes.func.isRequired,
    cancelCreditApplication: PropTypes.func.isRequired,
  }

  state = initialState;

  async componentDidMount() {
    const { legalEntityId, creditApplicationId, getCreditApplication, getCreditDetails } = this.props;

    await getCreditDetails(legalEntityId);
    await getCreditApplication(legalEntityId, creditApplicationId);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  leaveCommentClicked = () => {
    this.setState({ showLeaveCommentModal: true });
  }

  handleSubmitComment = async () => {
    if (!this.state.isSaving) {
      this.setState({ isSaving: true });
      await this.props.createCreditApplicationComment(this.props.creditApplication.id, this.state.commentText);
      this.setState(initialState);
    }
  }

  handleCancelModalClicked = () => {
    this.setState(initialState);
  }

  handleCreditApprovedClicked = async (data) => {
    const { legalEntityId, creditApplication, completeCreditApplication } = this.props;

    await completeCreditApplication({
      creditApplicationId: creditApplication.id,
      amountApproved: data.approvedAmount,
      status: "accepted",
      notes: data.completedText,
      useDiscretionaryLimit: false,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/credit-management`,
    });
  }

  handleCreditDeclinedClicked = async (data) => {
    const { legalEntityId, creditApplication, completeCreditApplication } = this.props;

    await completeCreditApplication({
      creditApplicationId: creditApplication.id,
      amountApproved: data.approvedAmount,
      status: "declined",
      notes: data.completedText,
      useDiscretionaryLimit: false,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/credit-management`,
    });
  }

  handleCreditCancelledClicked = async (data) => {
    const { legalEntityId, creditApplication, cancelCreditApplication } = this.props;

    await cancelCreditApplication({
      legal_entity_id: legalEntityId,
      credit_application_id: creditApplication.id,
      notes: data.completedText,
    });
  }

  render() {
    const { creditApplication } = this.props;

    return creditApplication
      ? (
        <div className={styles.container}>
          <div className={styles.topFlex}>
            <h2 className={styles.mainHeader}>Credit Application</h2>
            <ButtonGroup className={styles.buttonContainer}>
              {this.renderCreditApprovedButtonAndModal()}
              {this.renderCreditDeclinedButtonAndModal()}
              {this.renderCreditCancelledButtonAndModal()}
              <Button variant="secondary" className={styles.button} onClick={this.handleCloseClicked}>Close</Button>
            </ButtonGroup>
          </div>
          <div className={styles.content}>
            <div className={styles.requestDetailsContainer}>
              <h2 className={styles.header}>Request Details</h2>
              <div className={styles.requestDetailsSection}>
                <div className={styles.requestDetailItem}>
                  <p className={styles.requestDetailKey}>Submitted Date</p>
                  <p className={styles.requestDetailValue}>
                    {parseDate(creditApplication.created_at)}
                  </p>
                </div>
                <div className={styles.requestDetailItem}>
                  <p className={styles.requestDetailKey}>Amount Applying For ({EXCLUSIVE_VAT_LABEL})</p>
                  <p className={styles.requestDetailValue}>
                    {toMoney(creditApplication.credit_limit_requested)}
                  </p>
                </div>
                <div className={styles.requestDetailItem}>
                  <p className={styles.requestDetailKey}>Requested By</p>
                  <p className={styles.requestDetailValue}>
                    {creditApplication.credit_requested_by}
                  </p>
                </div>
                <div className={styles.requestDetailItem}>
                  <p className={styles.requestDetailKey}>Source</p>
                  <p className={styles.requestDetailValue}>
                    {creditApplication.source}
                  </p>
                </div>
                <div className={styles.requestDetailItem}>
                  <p className={styles.requestDetailKey}>Status</p>
                  <p className={styles.requestDetailValue}>
                    {capitalize(creditApplication.status)}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className={styles.header}>Experian Results</h2>
              <ExperianResponse
                className={styles.experianResponse}
                initialRequestedAmount={creditApplication.legal_entity.initial_credit_limit_requested}
                nextAccountsDueDate={creditApplication.legal_entity.companies_house_info?.accounts?.next_due}
                result={creditApplication.experian_credit_check}
              />
            </div>
            <div className={styles.recentActivity}>
              {this.renderCommentSection()}
            </div>
          </div>
        </div>
      )
      : <ActivityIndicator />;
  }

  renderCreditApprovedButtonAndModal = () => {
    const { showCreditApprovedModal } = this.state;
    const { creditApplication } = this.props;

    return creditApplication.status === "pending" && (
      <>
        <Button
          className={styles.primaryButton}
          variant="primary"
          onClick={this.handleApprovedButtonClicked}
        >
          Approved
        </Button>
        <CreditApprovedModal
          visible={showCreditApprovedModal}
          approvedAmount={creditApplication.credit_limit_requested}
          onSubmit={this.handleCreditApprovedClicked}
          onCancel={this.handleCancelModalClicked}
        />
      </>
    );
  }

  renderCreditDeclinedButtonAndModal = () => {
    const { creditApplication } = this.props;

    return creditApplication.status === "pending" && (
      <>
        <Button
          className={styles.declinedButton}
          variant="secondary"
          onClick={this.handleDeclinedButtonClicked}
        >
          Declined
        </Button>
        <CreditDeclinedModal
          source={creditApplication.source}
          visible={this.state.showCreditDeclinedModal}
          onSubmit={this.handleCreditDeclinedClicked}
          onCancel={this.handleCancelModalClicked}
        />
      </>
    );
  }

  renderCreditCancelledButtonAndModal = () => {
    const { creditApplication } = this.props;

    return creditApplication.status === "pending" && (
      <>
        <Button
          className={styles.button}
          variant="outline-danger"
          onClick={this.handleCancelApplicationClicked}
        >
          Cancel Application
        </Button>
        <CreditCancelledModal
          visible={this.state.showCreditCancelledModal}
          onSubmit={this.handleCreditCancelledClicked}
          onCancel={this.handleCancelModalClicked}
        />
      </>
    );
  }

  renderCommentSection = () => {
    const { commentText } = this.state;
    const { creditApplication } = this.props;

    const creditApplicationComments = creditApplication?.comments
      ? creditApplication.comments.map(comment => ({
        ...comment,
        type: CreditApplicationCommentTypeConverter.to(comment.type),
      }))
      : [];

    return (
      <>
        <CommentsSection
          title="Recent Activity"
          comments={creditApplicationComments}
          commentsEnabled={creditApplication.status === "pending"}
          onLeaveComment={this.leaveCommentClicked}
        />
        {creditApplication.status === "pending" && (
          <Modal
            title="Leave Comment"
            visible={this.state.showLeaveCommentModal}
            onSubmit={this.handleSubmitComment}
            onCancel={this.handleCancelModalClicked}
            submitText="Save">
            <Form>
              <Form.TextArea
                placeholder="Click to leave a comment"
                name="commentText"
                value={commentText}
                onChange={this.onChange}
              />
            </Form>
          </Modal>
        )}
      </>
    );
  }

  handleCloseClicked = () => {
    const { history, legalEntityId } = this.props;

    history.push(`/credit-limit-management/legal-entities/${legalEntityId}/credit-management`);
  }

  handleApprovedButtonClicked = () => {
    this.setState({
      showCreditApprovedModal: true,
    });
  }

  handleDeclinedButtonClicked = () => {
    this.setState({
      showCreditDeclinedModal: true,
    });
  }

  handleCancelApplicationClicked = () => {
    this.setState({
      showCreditCancelledModal: true,
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  creditApplicationId: ownProps.match.params.creditApplicationId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditApplication);
