import React from "react";
import PropTypes from "prop-types";
import { RadioButton } from "../../";
import styles from "./FormRadio.module.scss";

export default function FormRadio({
  value,
  name,
  text,
  checked,
  disabled,
  readOnly,
  onChange,
  ...otherProps
}) {
  return (
    <RadioButton
      value={value}
      name={name}
      className={styles.container}
      onClick={onChange}
      checked={checked}
      text={text}
      disabled={disabled}
      readOnly={readOnly}
      {...otherProps}
    />
  );
}

FormRadio.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};
