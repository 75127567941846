import { CommunicateToAgencyModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {string} obj.legalEntityId
 * @param {string} obj.creditRequestId
 *
 * @returns
 */
export const showCommunicateToAgencyModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(CommunicateToAgencyModal.displayName, {
    agencyId: obj.agencyId,
    legalEntityId: obj.legalEntityId,
    creditRequestId: obj.creditRequestId,
  }));
};
