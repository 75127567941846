import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_COMPANY_DETAILS = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    id
    ...on RegisteredCompany {
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        type
      }
      is_registered_charity
      registered_charity_number
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getCompanyDetails = ({ legalEntityId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_COMPANY_DETAILS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_COMPANY_DETAILS,
      variables: {
        id: legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_COMPANY_DETAILS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_COMPANY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
