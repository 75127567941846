import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Edit } from "@material-ui/icons";
import {
  DocumentDownloadButton,
  Form,
  IconButton,
  SeeHistoryTooltip,
  TextLink,
} from "../../../../common/components";
import { DOCUMENT_TYPES } from "../../../../documents";
import { CompanyTypeConverter } from "../../../../../converters";
import { capitalize, parseDate } from "../../../../../utils";
import styles from "./CompanyDetailsSection.module.scss";

export default function CompanyDetailsSection(props) {
  return (
    <Form.Card
      className={classnames(props.className, styles.container)}
      title="Company Details"
      history={props.data?.company_number && (
        <div className={styles.companiesHouseLinkWrapper}>
          <TextLink
            className={styles.link}
            text="View on Companies House"
            target="_blank"
            href={`https://beta.companieshouse.gov.uk/company/${props.data.company_number}`}
          />
        </div>
      )}
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <div className={styles.cols}>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Registered Company Number</Form.Label>
            <Form.Value>
              {props.data?.company_number || "-"}
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Exact Legal Name</Form.Label>
            <Form.Value>
              <SeeHistoryTooltip variant="compact" data={props.revisions?.name}>
                {props.data?.name || "-"}
              </SeeHistoryTooltip>
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Last Account Period End On</Form.Label>
            <Form.Value>
              {parseDate(props.data?.companies_house_info?.accounts?.last_accounts?.made_up_to) || "-"}
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Next Accounts Due By</Form.Label>
            <Form.Value>
              {parseDate(props.data?.companies_house_info?.accounts?.next_made_up_to) || "-"}
              {props.data?.company_number && (
                <span className={styles.filingHistoryLinkWrapper}>
                  <TextLink
                    className={styles.link}
                    target="_blank"
                    href={`https://find-and-update.company-information.service.gov.uk/company/${props.data.company_number}/filing-history`}
                    text="Filing History"
                  />
                </span>
              )}
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Company Type</Form.Label>
            <Form.Value>
              {CompanyTypeConverter.to(props.data?.company_type) || "-"}
            </Form.Value>
          </Form.Group>
        </div>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Company Status</Form.Label>
            <Form.Value>
              {capitalize(props.data?.companies_house_info?.company_status) || "-"}
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Status Detail</Form.Label>
            <Form.Value>
              {props.data?.companies_house_info?.company_status_detail || "-"}
            </Form.Value>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Is the company a charity?</Form.Label>
            <Form.Value>
              {props.data?.is_registered_charity ? "Yes" : "No"}
            </Form.Value>
          </Form.Group>
          {props.data?.is_registered_charity && (
            <Form.Group inline>
              <Form.Label>Registered Charity Number</Form.Label>
              <Form.Value>
                {props.data?.registered_charity_number || "-"}
              </Form.Value>
            </Form.Group>
          )}
        </div>
      </div>
      {props.data?.companies_house_info?.certificate_of_incorporation_url && (
        <Form.Section
          className={styles.cardFooter}
          title={(
            <h3>Documents</h3>
          )}
        >
          <div className={styles.documents}>
            <DocumentDownloadButton
              text={DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION}
              onClick={() => {
                window.open(
                  props.data?.companies_house_info?.certificate_of_incorporation_url,
                  "_blank"
                );
              }}
            />
          </div>
        </Form.Section>
      )}
    </Form.Card>
  );
}

CompanyDetailsSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    company_number: PropTypes.string,
    company_type: PropTypes.string,
    name: PropTypes.string,
    is_registered_charity: PropTypes.bool,
    registered_charity_number: PropTypes.string,
    companies_house_info: PropTypes.shape({
      company_status: PropTypes.string.isRequired,
      company_status_detail: PropTypes.string,
      accounts: PropTypes.shape({
        next_made_up_to: PropTypes.string,
        last_accounts: PropTypes.shape({
          made_up_to: PropTypes.string,
        }),
      }),
    }),
  }),
  revisions: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  onEditClick: PropTypes.func,
};
