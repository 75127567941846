import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import { CompanyTypeConverter } from "../../../../../converters";
import { capitalize, parseDate } from "../../../../../utils";
import styles from "./CompanyDetailsForm.module.scss";

export default class CompanyDetailsForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      companies_house_info: PropTypes.shape({
        accounts: PropTypes.shape({
          last_accounts: PropTypes.shape({
            made_up_to: PropTypes.string,
          }),
        }),
        company_name: PropTypes.string,
        company_number: PropTypes.string,
        company_status: PropTypes.string,
        company_status_detail: PropTypes.string,
        type: PropTypes.string,
      }),
      is_registered_charity: PropTypes.bool,
      registered_charity_number: PropTypes.string,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        is_registered_charity: props.data?.is_registered_charity,
        registered_charity_number: props.data?.registered_charity_number,
      },
    };
  }

  render() {
    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="Company Details" titleClassName={styles.title}>
              <Form.Group inline wide>
                <Form.Label inline>Registered Company Number</Form.Label>
                <Form.Input
                  value={this.props.data?.companies_house_info?.company_number}
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline>Exact Legal Name</Form.Label>
                <Form.Input
                  value={this.props.data?.companies_house_info?.company_name}
                  size="large"
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline>Last Account Period End On</Form.Label>
                <Form.Input
                  value={parseDate(this.props.data?.companies_house_info.accounts?.last_accounts?.made_up_to) || "-"}
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline>Company Type</Form.Label>
                <Form.Input
                  value={CompanyTypeConverter.to(this.props.data?.companies_house_info?.type) || "-"}
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline>Company Status</Form.Label>
                <Form.Input
                  value={capitalize(this.props.data?.companies_house_info?.company_status) || "-"}
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline>Status Detail</Form.Label>
                <Form.Input
                  value={this.props.data?.companies_house_info?.company_status_detail}
                  tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
                  showLock
                  disabled
                />
              </Form.Group>
              <Form.Group className={styles.radioGroup} inline wide>
                <Form.Label className={styles.radioLabel} inline>Is the company a charity?</Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="is_registered_charity"
                    checked={this.state.data.is_registered_charity}
                    text="Yes"
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="is_registered_charity"
                    checked={!this.state.data.is_registered_charity}
                    text="No"
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              {this.state.data.is_registered_charity && (
                <Form.Group inline wide>
                  <Form.Label inline>Registered Charity Number</Form.Label>
                  <Form.Input
                    name="registered_charity_number"
                    value={this.state.data.registered_charity_number}
                    maxLength={10}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              )}
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    onSubmit && onSubmit(data);
  }

}
