import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import AddContactsStep from "./AddContactsStep";
import ContractDetailsStep from "./ContractDetailsStep";
import InvoicingDetailsStep from "./InvoicingDetailsStep";
import { MultiStepForm, UnsavedChangesModal } from "../../../common/components";
import { validateField } from "../../../../utils";

export default class ContractForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      block: PropTypes.func.isRequired,
    }).isRequired,
    legalEntity: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    busy: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
  }

  state = {
    isDirty: false,
    showUnsavedChangesModal: false,
    nextLocation: null,
    contractDetails: {
      complete: false,
      data: {
        contract_name: "",
        legal_entity_name: this.props.legalEntity.name,
        estimated_credit: 1000,
        agency_id: "",
        agency_name: "",
        start_date: moment().valueOf(),
        end_date: null,
        address_line_one: "",
        address_line_two: "",
        town_or_city: "",
        county: "",
        postcode: "",
        trading_style_id: "",
        trading_style_name: "",
        trading_style: "",
      },
    },
    invoicingDetails: {
      complete: false,
      data: {
        po_number_required: false,
        default_po_number: "",
        self_billing: false,
        self_billing_org: "",
        invoice_email_address: "",
        invoice_email_addresses: [],
        invoice_telephone_number: "",
        invoice_frequency: "weekly",
        invoice_preference: "email",
        invoice_fao: "",
        invoice_address_same_as_site_address: false,
        address_line_one: "",
        address_line_two: "",
        town_or_city: "",
        county: "",
        postcode: "",
        payment_terms_type: "",
        payment_terms_value: "",
        timesheet_groupings: "",
        vat_type: "",
      },
    },
    contacts: {
      complete: false,
      data: [],
    },
    validation: {
      contractDetails: {
        errors: {
          contract_name: null,
          agency_id: null,
          estimated_credit: null,
          start_date: null,
          address_line_one: null,
          town_or_city: null,
          county: null,
          postcode: null,
        },
        rules: [
          {
            field: "contract_name",
            rules: [
              {
                type: "required",
                message: "Please enter a Contract Title",
              },
            ],
          },
          {
            field: "agency_id",
            rules: [
              {
                type: "required",
                message: "Please select an Agency",
              },
            ],
          },
          {
            field: "estimated_credit",
            rules: [
              {
                type: "required",
                message: "Please select an Amount",
              },
            ],
          },
          {
            field: "start_date",
            rules: [
              {
                type: "required",
                message: "Please select a Start Date",
              },
            ],
          },
          {
            field: "end_date",
            rules: [
              {
                type: "greaterThanFieldValue",
                allowNull: true,
                message: "End Date must be after Start Date",
                compareTo: "start_date",
              },
            ],
          },
          {
            field: "address_line_one",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the first line of the Address",
                dependsOn: ["address_line_two", "town_or_city", "county", "postcode"],
              },
            ],
          },
          {
            field: "town_or_city",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the Town or City",
                dependsOn: ["address_line_one", "address_line_two", "county", "postcode"],
              },
            ],
          },
          {
            field: "county",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the County",
                dependsOn: ["address_line_one", "address_line_two", "town_or_city", "postcode"],
              },
            ],
          },
          {
            field: "postcode",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the Postcode",
                dependsOn: ["address_line_one", "address_line_two", "town_or_city", "county"],
              },
            ],
          },
        ],
      },
      invoicingDetails: {
        errors: {
          default_po_number: null,
          self_billing_org: null,
          invoice_email_address: null,
          invoice_email_addresses: null,
          invoice_telephone_number: null,
          invoice_frequency: null,
          invoice_preference: null,
          invoice_fao: null,
          address_line_one: null,
          town_or_city: null,
          county: null,
          postcode: null,
          payment_terms_type: null,
          payment_terms_value: null,
          timesheet_groupings: null,
          vat_type: null,
        },
        rules: [
          {
            field: "self_billing_org",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter a Self Billing Organisation",
                dependsOn: "self_billing",
                condition: true,
              },
            ],
          },
          {
            field: "invoice_email_address",
            rules: [
              {
                type: "required",
                message: "Please enter an Email Address",
              },
            ],
          },
          {
            field: "invoice_preference",
            rules: [
              {
                type: "requiredwhere",
                message: "Please select an Invoice Preference",
                dependsOn: ["self_billing"],
                condition: false,
              },
            ],
          },
          {
            field: "invoice_fao",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter an Invoice FAO",
                dependsOn: ["self_billing"],
                condition: false,
              },
            ],
          },
          {
            field: "invoice_telephone_number",
            rules: [
              {
                type: "required",
                message: "Please enter a Telephone Number",
              },
            ],
          },
          {
            field: "payment_terms_type",
            rules: [
              {
                type: "required",
                message: "Please select your Payment Terms",
              },
            ],
          },
          {
            field: "payment_terms_value",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter a value",
                dependsOn: ["payment_terms_type"],
                condition: ["days_after_invoice", "day_of_month_after"],
              },
            ],
          },
          {
            field: "timesheet_groupings",
            rules: [
              {
                type: "required",
                message: "Please select your Timesheet Groupings",
              },
            ],
          },
          {
            field: "vat_type",
            rules: [
              {
                type: "required",
                message: "Please select your VAT Type",
              },
            ],
          },
          {
            field: "address_line_one",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the first line of the Address",
                dependsOn: ["address_line_two", "town_or_city", "county", "postcode"],
              },
            ],
          },
          {
            field: "address_line_one",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter the first line of the Address",
                dependsOn: "invoice_preference",
                condition: ["post", "both"],
              },
            ],
          },
          {
            field: "town_or_city",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the Town or City",
                dependsOn: ["address_line_one", "address_line_two", "county", "postcode"],
              },
            ],
          },
          {
            field: "town_or_city",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter the Town or City",
                dependsOn: "invoice_preference",
                condition: ["post", "both"],
              },
            ],
          },
          {
            field: "county",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the County",
                dependsOn: ["address_line_one", "address_line_two", "town_or_city", "postcode"],
              },
            ],
          },
          {
            field: "county",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter the County",
                dependsOn: "invoice_preference",
                condition: ["post", "both"],
              },
            ],
          },
          {
            field: "postcode",
            rules: [
              {
                type: "requiredif",
                message: "Please enter the Postcode",
                dependsOn: ["address_line_one", "address_line_two", "town_or_city", "county"],
              },
            ],
          },
          {
            field: "postcode",
            rules: [
              {
                type: "requiredwhere",
                message: "Please enter the Postcode",
                dependsOn: "invoice_preference",
                condition: ["post", "both"],
              },
            ],
          },
        ],
      },
    },
  }

  componentDidMount() {
    window.onbeforeunload = this.handlePageLeave;

    this.unblock = this.props.history.block((nextLocation) => {
      const { isDirty } = this.state;

      if (!isDirty) return true;

      this.setState({
        showUnsavedChangesModal: true,
        nextLocation: nextLocation,
      });

      return false;
    });
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.unblock();
  }

  render() {
    const {
      showUnsavedChangesModal,
      contractDetails,
      invoicingDetails,
      contacts,
      validation,
    } = this.state;
    const { legalEntity, busy } = this.props;
    const siteAddress = {
      address_line_one: contractDetails.data.address_line_one,
      address_line_two: contractDetails.data.address_line_two,
      town_or_city: contractDetails.data.town_or_city,
      county: contractDetails.data.county,
      postcode: contractDetails.data.postcode,
    };

    return (
      <>
        <MultiStepForm onSaveClick={this.handleSaveClick} onCancelClick={this.handleCancelClick} busy={busy}>
          <MultiStepForm.Step title="Contract Details" onValidate={() => this.handleValidateStep("contractDetails")} complete={contractDetails.complete}>
            <ContractDetailsStep
              legalEntity={legalEntity}
              contractDetails={contractDetails.data}
              validation={validation.contractDetails.errors}
              onChange={this.handleContractDetailsStepChanged}
              onAgencySelected={this.handleAgencySelected}
              onTradingStyleSelected={this.handleTradingStyleSelected}
            />
          </MultiStepForm.Step>
          <MultiStepForm.Step title="Invoicing Details" onValidate={() => this.handleValidateStep("invoicingDetails")} complete={invoicingDetails.complete}>
            <InvoicingDetailsStep
              invoicingDetails={invoicingDetails.data}
              siteAddress={siteAddress}
              onChange={this.handleInvoicingDetailsStepChanged}
              onRadioButtonChange={this.handleInvoicingDetailsStepRadioButtonChanged}
              onToggleInvoiceAddress={this.handleToggleInvoiceAddress}
              onEmailChange={this.handleEmailChange}
              onEmailAdded={this.handleEmailAdded}
              onEmailRemoved={this.handleEmailRemoved}
              validation={validation.invoicingDetails.errors}
            />
          </MultiStepForm.Step>
          <MultiStepForm.Step title="Add Contacts" onValidate={() => true}>
            <AddContactsStep
              legalEntityId={legalEntity.id}
              agencyId={contractDetails.data.agency_id}
              contacts={contacts.data}
              onAddContact={this.handleAddContact}
              onRemoveContact={this.handleRemoveContact}
            />
          </MultiStepForm.Step>
        </MultiStepForm>
        <UnsavedChangesModal
          visible={showUnsavedChangesModal}
          onClose={this.handleCloseSavedChangesModal}
          onGoBackClick={this.handleCloseSavedChangesModal}
          onLeaveClick={this.handleLeaveClicked}
        />
      </>
    );
  }

  handleContractDetailsStepChanged = (e) => {
    const { contractDetails, invoicingDetails } = this.state;

    const newData = {
      ...contractDetails.data,
      [e.target.name]: e.target.value,
    };

    this.setState({
      isDirty: true,
      contractDetails: {
        data: newData,
      },
    });

    if (invoicingDetails.data.invoice_address_same_as_site_address) {
      this.setState({
        invoicingDetails: {
          data: {
            ...invoicingDetails.data,
            address_line_one: newData.address_line_one,
            address_line_two: newData.address_line_two,
            town_or_city: newData.town_or_city,
            county: newData.county,
            postcode: newData.postcode,
            invoice_address_same_as_site_address: true,
          },
        },
      });
    }
  }

  handleValidateStep = (step) => {
    const { validation } = this.state;
    const { data } = this.state[step];

    const newErrors = validation[step].rules.reduce((errors, rule) => ({
      ...errors,
      [rule.field]: validateField(rule, data),
    }), {});

    const isValid = _.values(newErrors).every(_.isNull);

    const customErrors = step === "invoicingDetails"
      ? this.validateInvoicingDetails()
      : null;

    this.setState({
      validation: {
        ...validation,
        [step]: {
          ...validation[step],
          errors: {
            ...validation[step].errors,
            ...newErrors,
            ...customErrors,
          },
        },
      },
      [step]: {
        complete: isValid,
        data: data,
      },
    });

    return isValid && (!customErrors || _.values(customErrors).every(_.isNull));
  }

  handleAgencySelected = (agency) => {
    const { contractDetails } = this.state;

    this.setState({
      isDirty: true,
      contractDetails: {
        ...contractDetails,
        data: {
          ...contractDetails.data,
          agency_id: agency.id,
          agency_name: agency.name,
        },
      },
    });
  }

  handleTradingStyleSelected = (style) => {
    const { contractDetails } = this.state;

    this.setState({
      isDirty: true,
      contractDetails: {
        ...contractDetails,
        data: {
          ...contractDetails.data,
          trading_style_id: style.id,
          trading_style_name: style.name,
        },
      },
    });
  }

  handleInvoicingDetailsStepChanged = (e) => {
    const { invoicingDetails } = this.state;
    const newData = {
      ...invoicingDetails.data,
      [e.target.name]: e.target.value,
    };

    this.setInvoicingState(newData);
  }

  handleInvoicingDetailsStepRadioButtonChanged = (e) => {
    const { invoicingDetails } = this.state;
    const newData = {
      ...invoicingDetails.data,
      [e.target.name]: e.target.value === "true",
    };

    this.setInvoicingState(newData);
  }

  setInvoicingState = (data) => {
    this.setState({
      isDirty: true,
      invoicingDetails: {
        data: data,
      },
    });
  }

  handleAddContact = (contact) => {
    const { contacts } = this.state;
    const alreadyExists = contacts.data.some(x =>
      x.first_name === contact.first_name &&
      x.last_name === contact.last_name &&
      x.email === contact.email &&
      x.primary_number === contact.primary_number
    );

    if (alreadyExists) return;

    const newData = contacts.data.concat(contact);

    this.setState({
      isDirty: true,
      contacts: {
        data: newData,
      },
    });
  }

  handleRemoveContact = (contact) => {
    const { contacts } = this.state;

    const newData = _.reject(contacts.data, contact);

    this.setState({
      isDirty: true,
      contacts: {
        data: newData,
      },
    });
  }

  validateInvoicingDetails = () => {
    const { invoicingDetails } = this.state;

    const uniqueEmails = _.uniqBy(invoicingDetails.data.invoice_email_addresses, "email_address");
    const emailCheck =
      uniqueEmails.length === invoicingDetails.data.invoice_email_addresses.length &&
      !invoicingDetails.data.invoice_email_addresses.some(x => x.email_address === invoicingDetails.data.invoice_email_address);

    return emailCheck
      ? { invoice_email_addresses: null }
      : { invoice_email_addresses: "All email addresses must be unique" };
  }

  getSiteAddress = () => {
    const { data } = this.state.contractDetails;

    return {
      address_line_one: data.address_line_one,
      address_line_two: data.address_line_two,
      town_or_city: data.town_or_city,
      county: data.county,
      postcode: data.postcode,
    };
  }

  handleToggleInvoiceAddress = (e) => {
    const { invoicingDetails, contractDetails } = this.state;

    const newData = e.target.value === "true" ?
      {
        ...invoicingDetails.data,
        [e.target.name]: e.target.value === "true",
        address_line_one: contractDetails.data.address_line_one,
        address_line_two: contractDetails.data.address_line_two,
        town_or_city: contractDetails.data.town_or_city,
        county: contractDetails.data.county,
        postcode: contractDetails.data.postcode,
      } :
      {
        ...invoicingDetails.data,
        [e.target.name]: e.target.value === "true",
        address_line_one: "",
        address_line_two: "",
        town_or_city: "",
        county: "",
        postcode: "",
      };

    this.setInvoicingState(newData);
  }

  handleEmailAdded = () => {
    const { data } = this.state.invoicingDetails;
    const newEmail = { email_address: "", copy_preference: "CC", type: "invoice" };

    const newData = {
      ...data,
      invoice_email_addresses: [
        ...data.invoice_email_addresses,
        newEmail,
      ],
    };

    this.setInvoicingState(newData);
  }

  handleEmailRemoved = (index) => {
    const { data } = this.state.invoicingDetails;

    const emails = data.invoice_email_addresses.filter((e, i) => {
      return i !== index;
    });

    const newData = {
      ...data,
      invoice_email_addresses: emails,
    };

    this.setInvoicingState(newData);
  }

  handleEmailChange = (e, index) => {
    const { data } = this.state.invoicingDetails;

    const updatedEmailData = data.invoice_email_addresses.reduce((emails, email, i) => [
      ...emails,
      i === index ? { ...email, [e.target.name]: e.target.value } : email,
    ], []);

    const newData = {
      ...data,
      invoice_email_addresses: updatedEmailData,
    };

    this.setInvoicingState(newData);
  }

  handleCancelClick = async () => {
    const { onCancelClick } = this.props;

    await onCancelClick();
  }

  handleSaveClick = async () => {
    const { legalEntity, onSaveClick } = this.props;
    const { data: contract } = this.state.contractDetails;
    const { data: invoice } = this.state.invoicingDetails;

    const newContract = {
      legal_entity_id: legalEntity.id,
      trading_style_id: contract.trading_style_id ? parseInt(contract.trading_style_id) : null,
      trading_style: contract.trading_style_id || !contract.trading_style ?
        null :
        {
          name: contract.trading_style,
          legal_entity_id: legalEntity.id,
          agency_id: contract.agency_id,
        },
      contacts: this.state.contacts.data,
      agency_id: contract.agency_id,
      start_date: contract.start_date,
      end_date: contract.end_date,
      estimated_credit: parseFloat(contract.estimated_credit),
      title: contract.contract_name,
      self_billing: invoice.self_billing,
      self_billing_org: invoice.self_billing_org,
      po_number_required: invoice.po_number_required,
      default_po_number: invoice.default_po_number,
      invoice_email_address: invoice.invoice_email_address,
      email_addresses: invoice.invoice_email_addresses.filter(e => e.email_address.length > 0),
      invoice_telephone_number: invoice.invoice_telephone_number,
      invoice_frequency: invoice.invoice_frequency,
      invoice_preference: invoice.invoice_preference,
      invoice_fao: invoice.invoice_fao,
      payment_terms: {
        type: invoice.payment_terms_type,
        value: parseInt(invoice.payment_terms_value),
      },
      timesheet_groupings: invoice.timesheet_groupings,
      vat_type: invoice.vat_type,
      invoice_address_same_as_site_address: invoice.invoice_address_same_as_site_address,
      invoice_address: {
        address_line_one: invoice.address_line_one,
        address_line_two: invoice.address_line_two,
        town_or_city: invoice.town_or_city,
        county: invoice.county,
        postcode: invoice.postcode,
      },
      site_address: {
        address_line_one: contract.address_line_one,
        address_line_two: contract.address_line_two,
        town_or_city: contract.town_or_city,
        county: contract.county,
        postcode: contract.postcode,
      },
    };

    this.setState(
      { isDirty: false },
      async () => await onSaveClick(newContract)
    );
  }

  // Block when leaving when there are changes

  handleCloseSavedChangesModal = () => {
    this.setState({
      showUnsavedChangesModal: false,
    });
  }

  handleLeaveClicked = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.setState({
      isDirty: false,
      showUnsavedChangesModal: false,
    });

    window.onbeforeunload = null;
    this.unblock();

    history.push(nextLocation.pathname);
  }
}
