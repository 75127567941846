import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_DETAILS = gql`
mutation(
  $agency_id: ID!,
  $agency_details: UpdateAgencyInput,
  $industry_sectors: [String]!
) {
  updateAgency(id: $agency_id, agency_input: $agency_details) {
    id
    known_as
    agency_status {
      id
      label
      value
    }
  }

  updateAgencyIndustrySectors(id: $agency_id, industry_sectors: $industry_sectors) {
    name
  }
}
`;

export const updateAgencyDetails = (agencyId, agencyData) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_AGENCY_DETAILS,
      variables: {
        agency_id: agencyId,
        agency_details: agencyData.agencyDetails,
        industry_sectors: agencyData.industrySectors,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_SUCCESS,
      payload: {
        agency: response.data.updateAgency,
      },
    });

    dispatch(push(`/agencies/${agencyId}/agency`));
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_AGENCY_DETAILS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
