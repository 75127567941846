import React from "react";
import PropTypes from "prop-types";
import DataMismatchRow from "./DataMismatchRow/DataMismatchRow";
import styles from "./DataMismatchList.module.scss";

export default function DataMismatchList(props) {
  const sortedMismatches = (props.data || [])
    .sort((a, b) => {
      const agencyA = a.agency.known_as.toLowerCase();
      const agencyB = b.agency.known_as.toLowerCase();
      if (agencyA < agencyB) {
        return -1;
      }
      if (agencyA > agencyB) {
        return 1;
      }
      return 0;
    });

  return (
    <div className={styles.container}>
      {sortedMismatches.map((mismatch, index) => (
        <DataMismatchRow
          key={`mismatch_${index}`}
          agency={mismatch.agency}
          mismatches={mismatch.mismatches}
        />
      ))}
    </div>
  );
}

DataMismatchList.propTypes = {
  data: PropTypes.array.isRequired,
};
