import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Table, Popup } from "semantic-ui-react";
import {
  ArrowDropDownRounded,
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  Close,
  Delete,
  Edit,
  Error,
  Folder,
  HelpOutline,
  History,
  Home,
  MoreVert,
  Repeat,
  ThumbDown,
  ThumbUp,
  Visibility,
} from "@material-ui/icons";
import { options } from "../../../../constants";
import { TextLink } from "../../../common/components";
import { CreditApplicationCommentTooltip } from "../../components";
import styles from "./CreditApplicationList.module.scss";
import { parseDate, toMoney } from "../../../../utils";

export default function CreditApplicationList(props) {
  const renderStatusIcon = (datum) => {
    switch (datum.source) {
      case "Broker":
        return renderBrokerIcon(datum.status);
      case "Insurer":
      case "Experian":
      case "Back Office":
        return renderIcon(datum.status);
    }
  };

  const renderBrokerIcon = (iconName) => {
    switch (iconName) {
      case "pending":
        return <History className={styles.pendingIcon} />;

      case "accepted":
        return <ThumbUp className={styles.thumbUpIcon} />;

      case "declined":
        return <ThumbDown className={styles.thumbDownIcon} />;

      case "cancelled":
        return <Close className={styles.cancelledIcon} />;
    }
  };

  const renderIcon = (iconName) => {
    switch (iconName) {
      case "details":
        return <Visibility className={styles.detailsIcon}/>;

      case "edit":
        return <Edit className={styles.editIcon}/>;

      case "accepted":
        return <CheckCircle className={styles.acceptedIcon} />;

      case "partially_accepted":
        return <CheckCircleOutline className={styles.acceptedIcon} />;

      case "declined":
        return <CancelOutlined className={styles.declinedIcon} />;

      case "cancelled":
        return <Close className={styles.cancelledIcon} />;

      case "documents":
        return <Folder className={styles.documentsIcon} />;

      case "pending":
        return <History className={styles.pendingIcon} />;

      case "decrease":
        return <ArrowDropDownRounded className={styles.decreaseIcon} />;

      case "removal":
        return <Delete className={styles.removalIcon} />;

      case "housekeeping":
        return <Home className={styles.housekeepingIcon} />;

      case "scheduled":
        return <History className={styles.scheduledIcon} />;

      case "company_not_found":
        return <HelpOutline className={styles.notFoundIcon} />;

      case "thumbup":
        return <ThumbUp className={styles.thumbUpIcon} />;

      case "thumbdown":
        return <ThumbDown className={styles.thumbDownIcon} />;

      default:
        return null;
    }
  };

  const renderActionMenu = (datum) => {
    return (
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => props.history.push(datum.viewLink)}>
          <div className={styles.actionItem}>
            {renderIcon("details")}
            See Details
          </div>
        </Dropdown.Item>
        {(datum.status === "pending" || datum.isScheduled) &&
          <Dropdown.Divider/>
        }
        {datum.status === "pending" && datum.source === "Insurer" &&
          <>
            <Dropdown.Item onClick={() => props.onEditClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("edit")}
                Edit
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.onFullyApproveClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("accepted")}
                Fully Approve
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.onPartApproveClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("partially_accepted")}
                Part Approve
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.onDeclineClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("declined")}
                Decline
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.onCompanyNotFoundClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("company_not_found")}
                Company Not Found
              </div>
            </Dropdown.Item>
          </>
        }
        {datum.status === "pending" && datum.source === "Broker" &&
          <>
            <Dropdown.Item onClick={() => props.onGoToInsurerClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("thumbup")}
                Go to Insurer
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.onUseDiscretionaryLimitClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("thumbdown")}
                Use Discretionary Limit
              </div>
            </Dropdown.Item>
          </>
        }
        {(datum.status === "pending") && datum.source === "Insurer" &&
          <Dropdown.Item onClick={() => props.onCancelCreditApplicationClick(datum)}>
            <div className={styles.actionItem}>
              {renderIcon("cancelled")}
              Cancel
            </div>
          </Dropdown.Item>
        }
        {(datum.status === "pending") && datum.source === "Broker" &&
          <Dropdown.Item onClick={() => props.onCancelBrokerRequestClick(datum)}>
            <div className={styles.actionItem}>
              {renderIcon("cancelled")}
              Cancel
            </div>
          </Dropdown.Item>
        }
        {(datum.isScheduled) &&
          <Dropdown.Item onClick={() => props.onCancelScheduledCreditActionClick(datum)}>
            <div className={styles.actionItem}>
              {renderIcon("cancelled")}
              Cancel
            </div>
          </Dropdown.Item>
        }
        {(datum.source === "Experian") &&
          <>
            <Dropdown.Divider/>
            <Dropdown.Item onClick={() => props.onDownloadExperianDocumentsClick(datum)}>
              <div className={styles.actionItem}>
                {renderIcon("documents")}
                Show Documents
              </div>
            </Dropdown.Item>
          </>
        }
      </Dropdown.Menu>
    );
  };

  if (props.data.length === 0) {
    return <div className={styles.noResults}>No Credit Applications available</div>;
  }

  return (
    <Table
      basic
      className={styles.container}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
          Date Applied
          </Table.HeaderCell>
          <Table.HeaderCell>
          Source
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            <div className={styles.amountRequested}>
              <span>Amount Requested</span>
              <span>(Excl. VAT)</span>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
          Result <span>(Excl. VAT)</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.resultDate}>
          Result Date
          </Table.HeaderCell>
          <Table.HeaderCell>
          Status
          </Table.HeaderCell>
          <Table.HeaderCell>
            {/* This is for the action menu and chase button*/}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.data && props.data.map((datum) => {
          const approvalComment = datum.comments
            ?.filter(comment => [
              "accepted",
              "partially_accepted",
              "declined",
              "cancelled",
              "company_not_found",
            ].includes(comment.type))
            ?.sort((a, b) => b - a)[0];
          return (
            <Table.Row key={datum.key}>
              <Table.Cell>
                {datum.dateApplied ? (
                  <TextLink
                    className={styles.dateApplied}
                    text={parseDate(datum.dateApplied)}
                    to={datum.viewLink}
                  />
                ) : (
                  <span>-</span>
                )}
              </Table.Cell>
              <Table.Cell>{datum.source}</Table.Cell>
              <Table.Cell textAlign="right">
                {
                  datum.amountRequested
                    ? toMoney(datum.amountRequested)
                    : "-"
                }
              </Table.Cell>
              <Table.Cell textAlign="right">
                {
                  !_.isNil(datum.result)
                    ? toMoney(datum.result)
                    : "-"
                }
              </Table.Cell>
              <Table.Cell className={styles.resultDate}>
                <div className={styles.statusCell}>
                  {datum.isScheduled && renderIcon("scheduled")}
                  {
                    datum.isCancelled
                      ? <>{renderIcon("cancelled")}Cancelled</>
                      : parseDate(datum.resultDate) || "-"
                  }
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.statusCell}>
                  <div className={styles.iconContainer}>
                    {renderStatusIcon(datum)}
                  </div>
                  <div>
                    <div className={styles.multiline}>
                      {["Insurer", "Experian", "Back Office"].includes(datum.source) &&
                      <>
                        {
                          options.CREDIT_APPLICATION_STATUS_OPTIONS?.find(
                            option => option.value === datum.status
                          )?.label || datum.status
                        }

                      </>
                      }
                      {datum.source === "Broker" &&
                      <>
                        {
                          options.BROKER_CREDIT_APPLICATION_STATUS_OPTIONS?.find(
                            option => option.value === datum.status
                          )?.label || datum.status
                        }
                      </>
                      }
                    </div>
                    {approvalComment && (
                      <div className={styles.multiline}>
                        <CreditApplicationCommentTooltip
                          text="See Comment"
                          comment={approvalComment.comment}
                          created_at={approvalComment.created_at}
                          created_by={approvalComment.created_by}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <div className={styles.actionMenu}>
                  {datum.status === "pending" && (
                    <div
                      className={styles.chaseContainer}
                    >
                      <Repeat
                        className={styles.chaseIcon}
                        onClick={() => props.onChaseCreditApplicationClick(datum)}
                      />
                      {datum.chases?.length > 0 && (
                        <Popup
                          trigger={<Error className={styles.chasedIcon} />}
                          on="hover"
                          position="bottom left"
                          eventsEnabled
                          wide
                        >
                          <Popup.Content className={styles.lastChased}>
                            <div>Last chased:</div>
                            <div>{datum.chases[0].name}</div>
                            <div><strong>{parseDate(datum.chases[0].date_of_chase)}</strong></div>
                          </Popup.Content>
                        </Popup>
                      )}
                    </div>
                  )}
                  <Dropdown
                    trigger={(
                      <MoreVert/>
                    )}
                    icon={null}
                    direction="left"
                    floating
                  >
                    {renderActionMenu(datum)}
                  </Dropdown>
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

CreditApplicationList.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    dateApplied: PropTypes.number.isRequired,
    source: PropTypes.string.isRequired,
    amountRequested: PropTypes.number,
    result: PropTypes.number,
    resultDate: PropTypes.number,
    useDiscretionaryLimit: PropTypes.bool,
    status: PropTypes.string.isRequired,
    viewLink: PropTypes.string,
    isScheduled: PropTypes.bool,
    isCancelled: PropTypes.bool,
    comments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      comment: PropTypes.string,
      type: PropTypes.string,
      created_by: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      created_at: PropTypes.string,
    }),),
  })),
  onCancelBrokerRequestClick: PropTypes.func.isRequired,
  onCancelCreditApplicationClick: PropTypes.func.isRequired,
  onCancelScheduledCreditActionClick: PropTypes.func.isRequired,
  onChaseCreditApplicationClick: PropTypes.func.isRequired,
  onCompanyNotFoundClick: PropTypes.func.isRequired,
  onDeclineClick: PropTypes.func.isRequired,
  onDownloadExperianDocumentsClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onFullyApproveClick: PropTypes.func.isRequired,
  onGoToInsurerClick: PropTypes.func.isRequired,
  onPartApproveClick: PropTypes.func.isRequired,
  onUseDiscretionaryLimitClick: PropTypes.func.isRequired,
};

CreditApplicationList.defaultProps = {
  data: [],
};
