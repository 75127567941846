import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./RadioButton.module.scss";

export default function RadioButton({
  className,
  children,
  name,
  value,
  onClick,
  text,
  checked,
  defaultChecked,
  disabled,
  readOnly,
}) {
  const handleClick = (e) => {
    e.stopPropagation();

    onClick && onClick(e);
  };

  return (
    <label className={classnames(styles.container, className)}>
      {children}
      <input
        type="radio"
        name={name}
        value={value}
        onClick={handleClick}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        readOnly={readOnly}
      />
      <span
        className={classnames(
          styles.checkMark,
          disabled && styles.disabled,
          readOnly && styles.readOnly,
        )}
      ></span>
      {text && (
        <div
          className={classnames(
            styles.text,
            disabled && styles.disabled,
            readOnly && styles.readOnly,
          )}
        >
          {text}
        </div>
      )}
    </label>
  );
}

RadioButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};
