import React from "react";
import PropTypes from "prop-types";
import * as LookupTable from "../../lookup-table";

export default function ModalManager(props) {
  const renderModal = (componentName, index) => {
    const component = LookupTable[componentName];

    if (!component) {
      // eslint-disable-next-line no-console
      console.error("Modal not found", componentName);

      return null;
    }

    return React.createElement(component, {
      key: `ModalManager_${index}`,
      visible: true,
      ...props.modals[componentName],
    });
  };

  return (
    <React.Fragment>
      {Object.keys(props.modals).map(renderModal)}
    </React.Fragment>
  );
}

ModalManager.propTypes = {
  modals: PropTypes.object,
};
