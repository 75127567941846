import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCY_CONTACTS = gql`
query(
  $agency_id: ID!
  $sort_order: [SortOrder]
) {
  getAgency(id: $agency_id) {
    id

    contacts(sort_order: $sort_order) {
      id
      name
      first_name
      last_name
      preferred_name
      email
    }

    credit_controller {
      id
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @returns
 */
export const getAgencyContacts = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CONTACTS_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_AGENCY_CONTACTS,
      variables: {
        agency_id: obj.agencyId,
        sort_order: SortOrderConverter({
          first_name: "asc",
        }),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_CONTACTS_SUCCESS,
      payload: {
        data: response.data.getAgency.contacts,
        creditController: response.data.getAgency.credit_controller,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CONTACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
