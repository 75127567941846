import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "semantic-ui-react";
import { GetApp } from "@material-ui/icons";
import { formatBytes, parseDate } from "../../../../utils";
import styles from "./DocumentDownloadCard.module.scss";

export default function DocumentDownloadCard(props) {
  return (
    <div
      className={classnames(
        props.className,
        styles.container,
        props.disabled && styles.disabled,
      )}
    >
      <div className={styles.fileContainer}>
        <Icon className={styles.fileIcon} name="file" />
        <div className={styles.file}>
          <div className={styles.fileName}>
            {props.fileName}
          </div>
          <div className={styles.fileDetails}>
            <div className={styles.fileSize}>
              {formatBytes(props.fileSize)}
            </div>
            <div className={styles.uploadDate}>
              Uploaded {parseDate(props.uploadDate)}
            </div>
          </div>
        </div>
      </div>
      <GetApp className={styles.downloadIcon} onClick={props.onDownload} />
    </div>
  );
}

DocumentDownloadCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  uploadDate: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
};
