import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal } from "semantic-ui-react";
import { Button } from "../";
import styles from "./UnsavedChangesModal.module.scss";

export default function UnsavedChangesModal({
  className,
  visible,
  onLeaveClick,
  onGoBackClick,
  ...otherProps
}) {
  return (
    <Modal
      {...otherProps}
      className={classnames(styles.container, className)}
      open={visible}
      dimmer={{
        className: styles.dimmer,
      }}
      centered
    >
      <Modal.Header className={styles.title}>
        Leave without saving?
      </Modal.Header>
      <Modal.Content className={styles.caption}>
        <div>If you leave now, your progress will not be saved</div>
        <div>Are you sure you want to leave?</div>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <Button variant="danger" onClick={onLeaveClick}>Leave</Button>
        <Button variant="outline-primary" onClick={onGoBackClick}>Go Back</Button>
      </Modal.Actions>
    </Modal>
  );
}

UnsavedChangesModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onLeaveClick: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
};
