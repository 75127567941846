import React, { Component } from "react";
import PropTypes from "prop-types";
import { DataMismatchList } from "../../components";
import { Heading } from "../../../common/components";
import styles from "./DataMismatchesPage.module.scss";

export default class DataMismatchesPage extends Component {

  static propTypes = {
    dataMismatches: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array.isRequired,
    }).isRequired,
    getDataMismatches: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    await this.props.getDataMismatches();
  }

  render() {
    return (
      <div>
        <Heading
          title="Data Mis-matches"
        />
        <div className={styles.container}>
          <DataMismatchList data={this.props.dataMismatches?.data} />
        </div>
      </div>
    );
  }
}
