import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { ContractContactSearch } from "../../../containers";
import { Button, Form, Modal, Table2, TextLink } from "../../../../common/components";
import styles from "./AddContactsStep.module.scss";

export default class AddContactsStep extends Component {
  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    agencyId: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string,
      primary_number: PropTypes.string,
      secondary_number: PropTypes.string,
      job_role: PropTypes.string,
    })),
    onAddContact: PropTypes.func.isRequired,
    onRemoveContact: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contacts: [],
  }

  initialState = {
    addNewContactModelVisible: false,
    newContact: {
      first_name: "",
      last_name: "",
      email: "",
      primary_number: "",
      secondary_number: "",
      job_role: "",
    },
  }

  constructor() {
    super();

    this.state = this.initialState;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { legalEntityId, agencyId, contacts, onRemoveContact } = this.props;
    const sortedContacts = _.sortBy(contacts, "first_name");

    return (
      <>
        <Form className={styles.container}>
          <Form.Section title="Add Contacts">
            <div className={styles.top}>
              <div className={styles.searchContainer}>
                <ContractContactSearch
                  legalEntityId={legalEntityId}
                  agencyId={agencyId}
                  onItemSelected={this.handleItemSelected}
                />
              </div>
              <Button onClick={this.showAddNewContactModal}>Add New Contact</Button>
            </div>
            <Table2 data={sortedContacts}>
              <Table2.Column
                name="name"
                value={row => [row.first_name, row.last_name].join(" ")}
              >
                Name
              </Table2.Column>
              <Table2.Column
                name="email"
                value={row => row.email}
                className={styles.emailCell}
              >
                Email
              </Table2.Column>
              <Table2.Column
                name="job_role"
                value={row => row.job_role}
              >
                Job Role
              </Table2.Column>
              <Table2.Column
                collapsed
                value={row => (
                  <TextLink
                    className={styles.link}
                    text="Remove"
                    onClick={() => onRemoveContact(row)}
                  />
                )}
              />
            </Table2>
            {contacts.length === 0 && (
              <div className={styles.empty}>Search for a contact to add to the contract</div>
            )}
          </Form.Section>
        </Form>
        {this.renderAddNewContactModal()}
      </>
    );
  }

  renderAddNewContactModal = () => {
    const { addNewContactModelVisible, newContact } = this.state;

    const disabled =
      _.isEmpty(newContact.first_name) ||
      _.isEmpty(newContact.last_name) ||
      [newContact.email, newContact.primary_number].every(_.isEmpty);

    return (
      <Modal
        title="Adding New Contact"
        subtitle="In order to add a new contact please provide at least a primary number or email"
        visible={addNewContactModelVisible}
        primaryButtonDisabled={disabled}
        onSubmit={this.handleSubmitAddNewContact}
        onCancel={this.handleCancelAddNewContact}
        submitText="Add"
      >
        <Form>
          <Form.Section>
            <Form.FlexRow>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Input
                  name="first_name"
                  placeholder="First Name..."
                  value={newContact.first_name}
                  onChange={this.handleNewContactChange}
                  maxLength={100}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Input
                  name="last_name"
                  placeholder="Last Name..."
                  value={newContact.last_name}
                  onChange={this.handleNewContactChange}
                  maxLength={100}
                />
              </Form.Group>
            </Form.FlexRow>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Input
                className={styles.fluidInput}
                name="email"
                placeholder="Email..."
                value={newContact.email}
                onChange={this.handleNewContactChange}
                maxLength={255}
              />
            </Form.Group>
            <Form.FlexRow>
              <Form.Group>
                <Form.Label>Primary Number</Form.Label>
                <Form.Input
                  name="primary_number"
                  placeholder="Primary Number..."
                  value={newContact.primary_number}
                  onChange={this.handleNewContactChange}
                  maxLength={50}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Secondary Number</Form.Label>
                <Form.Input
                  name="secondary_number"
                  placeholder="Secondary Number..."
                  value={newContact.secondary_number}
                  onChange={this.handleNewContactChange}
                  maxLength={50}
                />
              </Form.Group>
            </Form.FlexRow>
            <Form.Group>
              <Form.Label>Job Role</Form.Label>
              <Form.Input
                className={styles.fluidInput}
                name="job_role"
                placeholder="Job Role..."
                value={newContact.job_role}
                onChange={this.handleNewContactChange}
                maxLength={255}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </Modal>
    );
  }

  showAddNewContactModal = () => {
    this.setState({ addNewContactModelVisible: true });
  }

  handleItemSelected = async (contractContact) => {
    const { onAddContact } = this.props;

    await onAddContact(contractContact);
  }

  handleNewContactChange = (e) => {
    this.setState({
      newContact: {
        ...this.state.newContact,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmitAddNewContact = async () => {
    const { onAddContact } = this.props;
    const { newContact } = this.state;

    await onAddContact(newContact);

    this.setState(this.initialState);
  }

  handleCancelAddNewContact = () => {
    this.setState({
      addNewContactModelVisible: false,
      newContact: this.initialState.newContact,
    });
  }
}
