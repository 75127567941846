import _ from "lodash";

export default class NewProspectFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data?.knownAs)) {
      errors.knownAs = "Known As is required";
    }

    if (_.isEmpty(data?.contacts)) {
      errors.contacts = "You must add at least one contact";
    }

    if (!data?.isNewAgency) {
      if (!data?.previousPensionProvider) {
        errors.previousPensionProvider = "Previous Pension Provider is required";
      }
    }

    if (!data?.businessDevelopmentManager) {
      errors.businessDevelopmentManager = "Business Development Manager is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
