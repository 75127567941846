import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import { actions } from "../duck";
import { AgencyList } from "../components";
import { Heading, ItemCountSelector, Pager, SearchInput } from "../../common/components";
import { sorting } from "../../../utils";
import styles from "./AgenciesPage.module.scss";

class AgenciesPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    agencies: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      pages: PropTypes.number.isRequired,
    }),
    error: PropTypes.string,
    message: PropTypes.string,
    searchAgencies: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchText: "",
    sortOrder: {
      known_as: "NONE",
      short_code: "NONE",
      status: "NONE",
    },
    expandedAgencies: [],
  }

  async componentWillMount() {
    await this.props.setPagination({
      ...this.props.pagination,
      page: 1,
    });
    await this.searchAgencies();
  }

  render() {
    const { searchText, sortOrder } = this.state;
    const { agencies, pagination } = this.props;

    return (
      <div className={styles.container}>
        <Heading className={styles.heading} title="Agencies" />
        <div className={styles.searchRow}>
          <SearchInput
            name="searchText"
            className={styles.searchInput}
            value={searchText}
            placeholder="Search Agencies"
            onChange={this.handleInputChanged}
            onSearch={this.searchAgencies}
          />
          {this.state.expandedAgencies.length === agencies?.length ? (
            <div
              className={styles.collapseExpand}
              onClick={() => this.setState({ expandedAgencies: [] })}
            >
              <UnfoldLess className={styles.unfoldIcon} />
              Collapse All
            </div>
          ) : (
            <div
              className={styles.collapseExpand}
              onClick={() => this.setState({ expandedAgencies: (agencies || []).map(agency => agency.id) })}
            >
              <UnfoldMore className={styles.unfoldIcon} />
              Expand All
            </div>
          )}
        </div>
        <AgencyList
          agencies={agencies}
          expandedRows={this.state.expandedAgencies}
          onColumnClick={this.handleColumnClick}
          onRowClick={this.handleRowClicked}
          sortOrder={sortOrder}
        />
        <div className={styles.pagination}>
          <Pager
            pageCount={pagination.pages}
            selectedPage={pagination.page}
            onPageChanged={this.handlePageChanged}
          />
          <ItemCountSelector value={pagination.limit} onChange={this.handleItemCountChanged} />
        </div>
      </div>
    );
  }

  handleInputChanged = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    await this.props.setPagination({
      ...this.props.pagination,
      page: 1,
    });
  }

  handleColumnClick = async (name, order) => {
    const newSortOrder = { ...this.state.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    this.setState({
      sortOrder: newSortOrder,
    }, this.searchAgencies);
  }

  handleRowClicked = (expandedAgencies) => {
    this.setState({ expandedAgencies });
  }

  handlePageChanged = async (n) => {
    await this.props.setPagination({
      ...this.props.pagination,
      page: n,
    });
    await this.searchAgencies();
  }

  handleItemCountChanged = async (e) => {
    await this.props.setPagination({
      ...this.props.pagination,
      limit: e.value,
    });
    await this.searchAgencies();
  }

  searchAgencies = async () => {
    const { searchText, sortOrder } = this.state;
    const { pagination: { page, limit } } = this.props;

    await this.props.searchAgencies({
      pager: { page, limit },
      searchOptions: {
        short_code: searchText,
        known_as: searchText,
      },
      sortOrder,
    });
  }
}

const mapStateToProps = state => ({
  ...state.agencies,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesPage);
