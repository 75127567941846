import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_CONTACT_INFORMATION = gql`
mutation(
  $id: ID!
  $input: UpdateLegalEntityInput!
) {
  updateLegalEntity(id: $id, input: $input) {
    id
    meta {
      key
      value
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.telephone
 * @param {string} obj.website
 * @param {string} obj.redirectUrl
 * @returns
 */
export const updateContactInformation = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_CONTACT_INFORMATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_CONTACT_INFORMATION,
      variables: {
        id: obj.legalEntityId,
        input: {
          registered: {
            telephone: obj.telephone,
            website: obj.website,
          },
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_CONTACT_INFORMATION_SUCCESS,
      payload: {
        data: response.data.updateLegalEntity,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_CONTACT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
