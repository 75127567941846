import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SUSPEND_CREDIT = gql`
mutation($id: ID!) {
  suspendCredit(id: $id) {
    id
    credit_suspended
    credit_suspended_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} legalEntityId
 * @returns
 */
export const suspendCredit = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: SUSPEND_CREDIT,
      variables: {
        id: obj.legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_SUCCESS,
      payload: response.data.suspendCredit,
    });
  }
  catch (err) {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
