import React, { Component } from "react";
import ContactsSection from "./ContactsSection/ContactsSection";
import ContractDetailsSection from "./ContractDetailsSection/ContractDetailsSection";
import InvoicingDetailsSection from "./InvoicingDetailsSection/InvoicingDetailsSection";
import styles from "./ContractDetailForm.module.scss";

export default class ContractDetailForm extends Component {
  static propTypes = {
    contract: {
      ...ContactsSection.propTypes.contract,
      ...ContractDetailsSection.propTypes.contract,
      ...InvoicingDetailsSection.propTypes.contract,
    },
  }

  render() {
    const { contract } = this.props;

    return (
      <div className={styles.container}>
        <ContractDetailsSection contract={contract} />
        <InvoicingDetailsSection contract={contract} />
        <ContactsSection contract={contract} />
      </div>
    );
  }

}
