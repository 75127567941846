import React, { Component } from "react";
import PropTypes from "prop-types";
import { ResetPasswordCodeForm, ResetPasswordForm } from "../../components";
import { TextBlock, TextLink } from "../../../common/components";
import PanelImage from "../../../../assets/images/password@2x.jpg";
import Logo from "../../../../assets/images/logo.png";
import styles from "./ResetPasswordPage.module.scss";

export default class ResetPasswordPage extends Component {

  static propTypes = {
    resetPasswordState: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      isInProgress: PropTypes.bool.isRequired,
      email: PropTypes.string,
      error: PropTypes.string,
    }),
    onSendCode: PropTypes.func.isRequired,
    onVerifyCode: PropTypes.func.isRequired,
  }

  render() {
    const { resetPasswordState } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img className={styles.image} src={PanelImage} alt="decorative side panel" />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.content}>
            <img src={Logo} className={styles.logo} alt="logo"/>
            <div className={styles.title}>
              Reset your password
            </div>
            {resetPasswordState.isInProgress && (
              <>
                <div className={styles.subtitle}>
                  Almost done, just enter your new password below.
                </div>
                <TextBlock
                  className={styles.warning}
                  content="Password must be at least 8 characters, with at least one uppercase, lower case, and special character."
                />
              </>
            )}
            {resetPasswordState.error && (
              <div className={styles.error}>
                {resetPasswordState.error}
              </div>
            )}
            {resetPasswordState.isInProgress ? (
              <ResetPasswordCodeForm
                className={styles.form}
                submitting={resetPasswordState.loading}
                email={resetPasswordState.email}
                error={resetPasswordState.error}
                onSubmitVerificationCode={this.handleSubmitVerificationCode}
              />
            ) : (
              <ResetPasswordForm
                className={styles.form}
                submitting={resetPasswordState.loading}
                error={resetPasswordState.error}
                onSendVerificationCode={this.handleSendVerificationCode}
              />
            )}
          </div>
          <div className={styles.footer}>
            Return to <TextLink className={styles.link} to="/sign-in" text="Sign In" />
          </div>
        </div>
      </div>
    );
  }

  handleSendVerificationCode = (email) => {
    const { onSendCode } = this.props;

    onSendCode(email);
  }

  handleSubmitVerificationCode = (email, newPassword, verificationCode) => {
    const { onVerifyCode } = this.props;

    onVerifyCode(email, newPassword, verificationCode);
  }
}
