import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import NewRegisteredProspectPage from "./NewRegisteredProspectPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  companyNumber: ownProps.match.params.companyNumber,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onSubmit: actions.addProspect,
  }, dispatch),
  onCancel: () => {
    dispatch(push("/prospects"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRegisteredProspectPage);
