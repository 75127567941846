import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import HmrcOnlineFormValidator from "./HmrcOnlineFormValidator";
import {
  Alert,
  Button,
  ButtonGroup,
  DisabledFieldLock,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./HmrcOnlineForm.module.scss";

const EDIT_DISABLED_FIELD_CHECK = "hmrc_online";

export default class HmrcOnlineForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      is_existing_paye_scheme: PropTypes.bool,
      paye_scheme_reference: PropTypes.string,
      accounts_office_reference: PropTypes.string,
      claiming_employers_ni_allowance: PropTypes.bool,
      small_employers_relief: PropTypes.bool,
      intermediary_reporting: PropTypes.bool,
      apprenticeship_levy: PropTypes.bool,
      worker_types: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })),
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        agency_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        is_existing_paye_scheme: !!props.data?.is_existing_paye_scheme,
        paye_scheme_reference: props.data?.paye_scheme_reference,
        accounts_office_reference: props.data?.accounts_office_reference,
        claiming_employers_ni_allowance: !!props.data?.claiming_employers_ni_allowance,
        small_employers_relief: !!props.data?.small_employers_relief,
        intermediary_reporting: (this.hasLimitedWorkers() || this.hasUmbrellaWorkers()) && !!props.data?.intermediary_reporting,
        apprenticeship_levy: !!props.data?.apprenticeship_levy,
      },
      errors: {},
    };
  }

  render() {
    const isDisabled = this.isEditDisabled();

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={12}>
          {isDisabled && (
            <Alert className={styles.onboardingAlert} variant="info" showClose={false}>
              <div className={styles.onboardingAlertContent}>
                <DisabledFieldLock
                  className={styles.lockIcon}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onClick={() => this.handleDisabledFieldClick(EDIT_DISABLED_FIELD_CHECK)}
                />
                The Onboarding Task for this has not been completed yet.
              </div>
            </Alert>
          )}
          <Form className={styles.form}>
            <Form.Section title="HMRC Online" titleClassName={styles.title}>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.existing_paye_scheme}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Existing PAYE Scheme
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="is_existing_paye_scheme"
                    checked={this.state.data.is_existing_paye_scheme}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="is_existing_paye_scheme"
                    checked={!this.state.data.is_existing_paye_scheme}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.paye_scheme_reference}
              >
                <Form.Label inline>
                  PAYE Scheme Reference
                </Form.Label>
                <Form.Input
                  name="paye_scheme_reference"
                  value={this.state.data.paye_scheme_reference}
                  maxLength={14}
                  disabled={isDisabled}
                  highlightError={this.state.errors?.paye_scheme_reference}
                  onChange={this.handlePayeRefChange}
                  maskOptions={{
                    mask: "999/**********",
                    maskChar: null,
                  }}
                />
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.accounts_office_reference}
              >
                <Form.Label inline>
                  Accounts Office Reference
                </Form.Label>
                <Form.Input
                  name="accounts_office_reference"
                  value={this.state.data.accounts_office_reference}
                  maxLength={255}
                  disabled={isDisabled}
                  highlightError={this.state.errors?.accounts_office_reference}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.claiming_employers_ni_allowance}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Back Office to apply for Employment Allowance?
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="claiming_employers_ni_allowance"
                    checked={this.state.data.claiming_employers_ni_allowance}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="claiming_employers_ni_allowance"
                    checked={!this.state.data.claiming_employers_ni_allowance}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.small_employers_relief}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Entitled to Small Employers Relief?
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="small_employers_relief"
                    checked={this.state.data.small_employers_relief}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="small_employers_relief"
                    checked={!this.state.data.small_employers_relief}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              {(this.hasLimitedWorkers() || this.hasUmbrellaWorkers()) && (
                <Form.Group
                  className={classnames(
                    styles.radioGroup,
                    this.props.submitting && styles.submitting
                  )}
                  inline
                  wide
                  error={this.state.errors?.intermediary_reporting}
                >
                  <Form.Label className={styles.radioLabel} inline>
                    Back Office to do Employment Intermediary Reporting (EIR)?
                  </Form.Label>
                  <div>
                    <Form.Radio
                      value={true}
                      name="intermediary_reporting"
                      checked={this.state.data.intermediary_reporting}
                      text="Yes"
                      disabled={isDisabled}
                      onChange={this.handleRadioChange}
                    />
                    <Form.Radio
                      value={false}
                      name="intermediary_reporting"
                      checked={!this.state.data.intermediary_reporting}
                      text="No"
                      disabled={isDisabled}
                      onChange={this.handleRadioChange}
                    />
                  </div>
                </Form.Group>
              )}
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.apprenticeship_levy}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Does the Apprenticeship Levy apply?
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="apprenticeship_levy"
                    checked={this.state.data.apprenticeship_levy}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="apprenticeship_levy"
                    checked={!this.state.data.apprenticeship_levy}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting || isDisabled}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handlePayeRefChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const data = { ...this.state.data };

    // We should only hit this if someone removes the disabled attribute from
    // the Submit button manually...
    if (this.isEditDisabled()) {
      return;
    }

    const validator = new HmrcOnlineFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit({
      ...data,
    }, this.props);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick(disabledField);
  }

  isEditDisabled = () => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === EDIT_DISABLED_FIELD_CHECK);
  }

  hasLimitedWorkers = () => {
    return this.props.data?.worker_types?.some(x => x.name === "Limited");
  }

  hasUmbrellaWorkers = () => {
    return this.props.data?.worker_types?.some(x => x.name === "Umbrella");
  }
}
