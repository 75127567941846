import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink, Route, Switch } from "react-router-dom";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import Logo from "../assets/images/logo.png";
import { Toaster, UserDropDown } from "../modules/common/components";
import { DataMismatchCount } from "../modules/data-mismatches/containers";
import * as AgenciesModule from "../modules/agencies";
import * as CreditLimitManagementModule from "../modules/credit-limit-management";
import * as CurrentUserModule from "../modules/currentUser";
import * as DashboardModule from "../modules/dashboard";
import * as DataMismatchesModule from "../modules/data-mismatches";
import * as GroupManagementModule from "../modules/group-management";
import * as OnboardingModule from "../modules/onboarding";
import * as ProspectsModule from "../modules/prospects";
import * as SystemModule from "../modules/system";
import * as UserManagementModule from "../modules/user-management";
import "./AdminApp.scss";

export default class AdminApp extends PureComponent {

  static propTypes = {
    user: PropTypes.shape({
      attributes: PropTypes.shape ({
        name: PropTypes.string.isRequired,
      }),
      metadata: PropTypes.shape({
        department: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }).isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.string,
    ),
    changePassword: PropTypes.shape({
      visible: PropTypes.bool,
      submitting: PropTypes.bool,
      error: PropTypes.string,
    }),
    onChangePassword: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    setChangePasswordModalVisibility: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="AdminApp">
        {this.renderHeader()}
        {this.renderSidebar()}
        {this.renderContent()}
        {this.renderModals()}
      </div>
    );
  }

  renderHeader = () => {
    const { user, errors, onLogout } = this.props;

    return (
      <div className="Header">
        <img className="Logo" src={Logo} alt="Optia" />
        <Toaster errors={errors} />
        <div>
          <div className="Splitter" />
          <UserDropDown
            className="UserDropDown"
            user={user}
            onChangePassword={() => this.handleChangePasswordModalVisiblityChange(true)}
            onLogout={onLogout}
          />
        </div>
      </div>
    );
  }

  renderSidebar = () => {
    const { user } = this.props;

    return (
      <div className="Sidebar">
        <div className="menu">
          <NavLink to="/dashboard" className="item" activeClassName="active">Dashboard</NavLink>
          <hr className="separator" />
          <NavLink to="/prospects" className="item" activeClassName="active">Prospects</NavLink>
          <NavLink to="/agencies" className="item" activeClassName="active">Agencies</NavLink>
          <NavLink to="/onboarding" className="item" activeClassName="active">Onboarding</NavLink>
          <NavLink to="/data-mismatches" className="item" activeClassName="active">
            Data Mis-matches <DataMismatchCount className="data-mismatch" />
          </NavLink>
          <hr className="separator" />
          <NavLink to="/customer-accounts" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">Customers</NavLink>
          <NavLink to="/credit-limit-management" className="item" activeClassName="active">Credit Limit Management</NavLink>
          <NavLink to="/disabled" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">Credit Control</NavLink>
          <hr className="separator" />
          <NavLink to="/workers" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">Workers</NavLink>
          <NavLink to="/disabled" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">Suppliers</NavLink>
          <hr className="separator" />
          <NavLink to="/reports" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">Reports</NavLink>
          {user.metadata.department?.name === "IT" && (
            <>
              <hr className="separator" />
              <NavLink to="/manage" className="item" activeClassName="active">User Management</NavLink>
              <NavLink to="/system" className="item" activeClassName="active">System</NavLink>
            </>
          )}
        </div>
        <div className="support">
          <div className="title">Support</div>
          <ul className="items">
            <li className="item"><a href="">Help?</a></li>
          </ul>
        </div>
      </div>
    );
  }

  renderContent = () => {
    const { user } = this.props;

    return (
      <div className="Content">
        <ReactTooltip place="right" />
        <Route exact path="/dashboard" component={DashboardModule.DashboardPage} />
        <Route exact path="/prospects" component={ProspectsModule.ProspectsPage} />
        <Route path="/prospects/:prospectId" component={ProspectsModule.ProspectPage} />
        <Route exact path="/convert-to-agency/:prospectId" component={ProspectsModule.ConvertToAgencyPage} />
        <Route exact path="/agencies" component={AgenciesModule.AgenciesPage} />
        <Switch>
          <Route path="/agencies/:agencyId/edit" component={AgenciesModule.AgencyEditPage} />
          <Route path="/agencies/:agencyId" component={AgenciesModule.AgencyPage} />
        </Switch>
        <Route exact path="/credit-limit-management" component={CreditLimitManagementModule.CreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/legal-entities" component={CreditLimitManagementModule.LegalEntitiesPage} />
        <Route exact path="/credit-limit-management/perform-credit-check" component={CreditLimitManagementModule.PerformCreditCheckPage} />
        <Route exact path="/credit-limit-management/search" component={CreditLimitManagementModule.SearchCreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/search/:legalEntityId" component={CreditLimitManagementModule.SearchCreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/non-registered-company/new" component={CreditLimitManagementModule.NewNonRegisteredCompanyPage} />
        <Route exact path="/credit-limit-management/registered-company/new" component={CreditLimitManagementModule.NewRegisteredCompanyPage} />
        <Route path="/credit-limit-management/legal-entities/:legalEntityId" component={CreditLimitManagementModule.LegalEntityPage} />
        <Route path="/credit-limit-management/task-list" component={CreditLimitManagementModule.TaskListPage} />
        <Route exact path="/data-mismatches" component={DataMismatchesModule.DataMismatchesPage} />
        <Route exact path="/manage" component={UserManagementModule.UserManagementLandingPage} />
        <Route exact path="/manage/users" component={UserManagementModule.UsersPage} />
        <Route exact path="/manage/users/new" component={UserManagementModule.NewUserPage} />
        <Route exact path="/manage/users/edit/:id" component={UserManagementModule.EditUserPage} />
        <Route exact path="/manage/groups" component={GroupManagementModule.GroupManagementLandingPage} />
        <Route exact path="/manage/groups/new" component={GroupManagementModule.NewUserGroupPage} />
        <Route exact path="/manage/groups/edit/:id" component={GroupManagementModule.EditUserGroupPage} />
        <Route exact path="/onboarding" component={OnboardingModule.OnboardingLandingPage} />
        <Route exact path="/onboarding/new/prospects" component={ProspectsModule.NewRegisteredProspectSearchPage} />
        <Route exact path="/onboarding/new/prospects/:companyNumber" component={ProspectsModule.NewRegisteredProspectPage} />
        <Route exact path="/onboarding/new/existing-short-codes" component={OnboardingModule.ExistingShortCodesPage} />
        <Route exact path="/onboarding/agencies" component={OnboardingModule.ViewAsAgencyPage} />
        <Route path="/onboarding/agencies/:agencyId" component={OnboardingModule.AgencyTasksPage} />
        <Route exact path="/onboarding/tasks" component={OnboardingModule.ViewAsTaskPage} />
        <Route exact path="/onboarding/tasks/:onboardingTaskId" component={OnboardingModule.TaskPage} />
        <Route path="/onboarding/tasks/:onboardingTaskId/:agencyId" component={OnboardingModule.TaskPage} />
        <Route exact path="/onboarding/prospects" component={OnboardingModule.ViewAsProspectPage} />
        <Route path="/onboarding/prospects/:prospectId" component={OnboardingModule.ProspectTasksPage} />
        {user.metadata.department?.name === "IT" && (
          <>
            <Route exact path="/system" component={SystemModule.SystemLandingPage} />
            <Route exact path="/system/data-export" component={SystemModule.DataExportPage} />
            <Route exact path="/system/data-import" component={SystemModule.DataImportPage} />
          </>
        )}
      </div>
    );
  };

  renderModals = () => {
    return (
      <>
        <CurrentUserModule.ChangePasswordModal
          {...this.props.changePassword}
          onCancel={() => this.handleChangePasswordModalVisiblityChange(false)}
          onSubmit={this.props.onChangePassword}
        />
      </>
    );
  }

  handleChangePasswordModalVisiblityChange = (isVisible) => {
    const { setChangePasswordModalVisibility } = this.props;

    setChangePasswordModalVisibility({ isVisible });
  }
}
