import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Form, SeeHistoryTooltip, TextLink } from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import { CompanyTypeConverter } from "../../../../../converters";
import { capitalize, formatUrl, parseDate } from "../../../../../utils";
import styles from "./RegisteredLegalEntityForm.module.scss";

const VALID_FIELDS = [
  "company_number",
  "name",
  "credit_limit_including_vat",
  "initial_credit_limit_requested",
  "initial_credit_requested_by",
  "trading_as",
  "telephone",
  "website",
  "registered_charity_number",
  "registered_charity_name",
  "charity_address_line_one",
  "charity_city",
  "charity_postcode",
  "charity_status",
];

export default class RegisteredLegalEntityForm extends Component {

  static propTypes = {
    legalEntity: PropTypes.shape({
      name: PropTypes.string,
      initial_credit_limit_requested: PropTypes.number.isRequired,
      initial_credit_requested_by: PropTypes.string,
      meta: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string,
      })),
      companies_house_info: PropTypes.shape({
        company_name: PropTypes.string.isRequired,
        company_number: PropTypes.string.isRequired,
        accounts: PropTypes.shape({
          last_accounts:PropTypes.shape({
            made_up_to: PropTypes.string,
          }).isRequired,
        }),
        company_status: PropTypes.string.isRequired,
        company_status_detail: PropTypes.string,
        registered_office_address: PropTypes.shape({
          address_line_1: PropTypes.string.isRequired,
          locality: PropTypes.string,
          postal_code: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
      registered_charity_number: PropTypes.string,
    }),
    legalEntityHistory: PropTypes.shape({
      name: PropTypes.shape({
        value: PropTypes.string.isRequired,
        updated_at: PropTypes.number.isRequired,
      }),
      address: PropTypes.shape({
        value: PropTypes.string.isRequired,
        updated_at: PropTypes.number.isRequired,
      }),
    }),
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
  }

  state = {
    company_number: "",
    company_name: "",
    last_account_period_end_on: "",
    company_type: "",
    company_status: "",
    company_status_detail: "",
    is_registered_charity: false,
    registered_charity_number: "",
    registered_office_address_line_1: "",
    registered_office_address_locality: "",
    registered_office_address_postal_code: "",
    telephone: "",
    website: "",
    initial_credit_limit_requested: 0,
    initial_credit_requested_by: "",
  }

  constructor(props) {
    super(props);

    const meta = (props.legalEntity?.meta || []).reduce((r, v) => ({
      ...r,
      [v.key]: v.value,
    }), {});

    this.state = {
      company_number: props.legalEntity?.company_number || "",
      company_name: props.legalEntity?.companies_house_info?.company_name || props.legalEntity?.name || "",
      last_account_period_end_on: props.legalEntity?.companies_house_info?.accounts?.last_accounts?.made_up_to || "",
      company_type: props.legalEntity?.companies_house_info?.type || props.legalEntity?.company_type || "",
      company_status: props.legalEntity?.companies_house_info?.company_status || props.legalEntity?.company_status || "",
      company_status_detail: props.legalEntity?.companies_house_info?.company_status_detail || "",
      is_registered_charity: !!props.legalEntity?.registered_charity_number,
      registered_charity_number: props.legalEntity?.registered_charity_number || "",
      registered_office_address_line_1: props.legalEntity?.companies_house_info?.registered_office_address.address_line_1 || "",
      registered_office_address_locality: props.legalEntity?.companies_house_info?.registered_office_address.locality || "",
      registered_office_address_postal_code: props.legalEntity?.companies_house_info?.registered_office_address.postal_code || "",
      telephone: meta.telephone || "",
      website: meta.website || "",
      initial_credit_limit_requested: props.legalEntity?.initial_credit_limit_requested || 0,
      initial_credit_requested_by: props.legalEntity?.initial_credit_requested_by || "",
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;

    onLoad && onLoad(_.pick(this.state, VALID_FIELDS));
  }

  render() {
    const { legalEntity, legalEntityHistory, readOnly } = this.props;

    return (
      <Form>
        <Form.Section title="Legal Entity">
          <Form.Group>
            <Form.Label>Registered Company Number</Form.Label>
            <div className={styles.companyNumberContainer}>
              <Form.Input
                size="small"
                value={this.state.company_number}
                disabled={!readOnly}
                readOnly={readOnly}
                showLock={!readOnly}
                tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
              />
              <Button variant="secondary" className={styles.moreInfo} onClick={this.handleMoreInfoClicked}>More Info</Button>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <SeeHistoryTooltip data={legalEntityHistory?.name}>
                Exact Legal Name
              </SeeHistoryTooltip>
            </Form.Label>
            <Form.Input
              size="medium"
              value={this.state.company_name}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Account Period End On</Form.Label>
            <Form.Input
              size="medium"
              value={parseDate(this.state.last_account_period_end_on)}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Input
              size="medium"
              value={CompanyTypeConverter.to(this.state.company_type)}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company Status</Form.Label>
            <Form.Input
              size="medium"
              value={capitalize(this.state.company_status)}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Status Detail</Form.Label>
            <Form.Input
              size="medium"
              value={this.state.company_status_detail}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Is this a charity?</Form.Label>
            <Form.FlexRow>
              <Form.Radio
                className={styles.inlineRadio}
                name="is_registered_charity"
                text="Yes"
                value={true}
                readOnly={readOnly}
                onChange={this.handleIsRegisteredCharityChange}
                checked={this.state.is_registered_charity}
              />
              <Form.Radio
                className={styles.inlineRadio}
                name="is_registered_charity"
                text="No"
                value={false}
                readOnly={readOnly}
                onChange={this.handleIsRegisteredCharityChange}
                checked={!this.state.is_registered_charity}
              />
            </Form.FlexRow>
          </Form.Group>
          <Form.Group>
            <Form.Label>Registered Charity Number</Form.Label>
            <Form.Input
              size="small"
              name="registered_charity_number"
              value={this.state.registered_charity_number}
              disabled={!readOnly && !this.state.is_registered_charity}
              readOnly={readOnly}
              onChange={this.handleInputChange}
              maxLength={10}
            />
          </Form.Group>
        </Form.Section>
        <Form.Section title="Registered Address">
          <Form.Group>
            <Form.Label>
              <SeeHistoryTooltip data={legalEntityHistory?.address}>
                  Address
              </SeeHistoryTooltip>
            </Form.Label>
            <Form.Input
              size="large"
              value={this.state.registered_office_address_line_1}
              disabled={!readOnly}
              readOnly={readOnly}
              showLock={!readOnly}
              tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
            />
          </Form.Group>
          <div className={styles.flexInput}>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Input
                size="small"
                value={this.state.registered_office_address_locality}
                disabled={!readOnly}
                readOnly={readOnly}
                showLock={!readOnly}
                tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Postcode</Form.Label>
              <Form.Input
                size="small"
                value={this.state.registered_office_address_postal_code}
                disabled={!readOnly}
                readOnly={readOnly}
                showLock={!readOnly}
                tooltip={!readOnly && tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
              />
            </Form.Group>
          </div>
        </Form.Section>
        <Form.Section title="Communication">
          <Form.Group>
            <Form.Label>Telephone Number</Form.Label>
            <Form.Input
              size="small"
              name="telephone"
              value={this.state.telephone}
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Website</Form.Label>
            {readOnly && this.state.website ? (
              <Form.Value>
                <TextLink
                  className={styles.link}
                  target="_blank"
                  href={formatUrl(this.state.website)}
                  text={this.state.website}
                />
              </Form.Value>
            ) : (
              <Form.Input
                placeholder="Enter Website URL..."
                name="website"
                value={this.state.website}
                readOnly={readOnly}
                onChange={this.handleInputChange}
                maxLength={255}
              />
            )}
          </Form.Group>
        </Form.Section>
        <Form.Section title="Credit">
          <Form.Group>
            <Form.Label>Estimated Credit Needed</Form.Label>
            <Form.Number
              size="small"
              name="initial_credit_limit_requested"
              value={this.state.initial_credit_limit_requested}
              onChange={this.handleInputChange}
              readOnly={readOnly}
              disabled={!readOnly && legalEntity?.initial_credit_limit_requested > 0}
              prefix="£"
              formatNumber
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Credit Requested By</Form.Label>
            <Form.Input
              size="medium"
              name="initial_credit_requested_by"
              value={this.state.initial_credit_requested_by}
              onChange={this.handleInputChange}
              placeholder="Agency Name"
              readOnly={readOnly}
              disabled={!readOnly && legalEntity?.initial_credit_limit_requested > 0}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    );
  }

  handleInputChange = (e) => {
    const { onChange } = this.props;

    this.setState({
      [e.target.name]: e.target.value,
    }, () => onChange && onChange(_.pick(this.state, VALID_FIELDS)));
  }

  handleIsRegisteredCharityChange = (e) => {
    const { onChange } = this.props;

    this.setState({
      [e.target.name]: e.target.value === "true",
      registered_charity_number: "",
    }, () => onChange && onChange(_.pick(this.state, VALID_FIELDS)));
  }

  handleMoreInfoClicked = () => {
    const { company_number } = this.state;

    const url = `https://beta.companieshouse.gov.uk/company/${company_number}`;

    window.open(url, "_blank");
  }

}
