import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Popup, Table } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { ErrorOutline } from "@material-ui/icons";
import { SortIndicator, TextLink } from "../../../common/components";
import { parseDate, toMoney } from "../../../../utils";
import { CompanyTypeFormatter } from "../../../../formatters";
import styles from "./LegalEntityList.module.scss";

export default function LegalEntityList(props) {
  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table
      className={styles.table}
      basic
    >
      <Table.Header>
        <Table.HeaderCell
          className={styles.sortableHeader}
        >
          <div>
            Legal Entity
            <SortIndicator
              className={styles.sortIndicator}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* For the icons */}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
        >
          <div>
            Credit Limit
            <SortIndicator
              className={styles.sortIndicator}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
        >
          <div>
            Undistributed Credit
            <SortIndicator
              className={styles.sortIndicator}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
        >
          <div>
            Total Debt
            <SortIndicator
              className={styles.sortIndicator}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
        >
          <div>
            Exposure
            <SortIndicator
              className={styles.sortIndicator}
            />
          </div>
        </Table.HeaderCell>
      </Table.Header>

      {props.legalEntities.length === 0 ? (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="6" className={styles.noResults}>
              There are no Legal Entities available to view.
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      ) : (
        <Table.Body>
          {props.legalEntities.map((legalEntity) => {
            const hasCreditLimit = !_.isNull(legalEntity.credit_limit_including_vat);

            return (
              <Table.Row key={`legalEntity_${legalEntity.id}`}>
                <Table.Cell className={styles.detailsCell}>
                  <div>
                    <TextLink
                      className={styles.legalEntityName}
                      text={legalEntity.name}
                      to={`/credit-limit-management/legal-entities/${legalEntity.id}`}
                    />
                    <div className={styles.companyDetails}>
                      {CompanyTypeFormatter.format(legalEntity.__typename)}
                      {legalEntity.company_number && (
                        <> | {legalEntity.company_number}</>
                      )}
                      {legalEntity.registered_charity_number && !legalEntity.company_number && (
                        <> | {legalEntity.registered_charity_number}</>
                      )}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {legalEntity.company_status === "inactive" && (
                    <Popup
                      trigger={<ErrorOutline className={styles.inactiveIcon} />}
                      on="hover"
                      position="bottom left"
                      eventsEnabled
                    >
                      <Popup.Content className={styles.lastChased}>
                        This Legal Entity has been marked as <strong>inactive</strong> since <strong>{parseDate(legalEntity.inactive_since)}</strong>
                      </Popup.Content>
                    </Popup>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {hasCreditLimit ? toMoney(legalEntity.credit_limit_including_vat) : "£-"}
                </Table.Cell>
                <Table.Cell>
                  {hasCreditLimit ? toMoney(legalEntity.undistributed_credit_limit) : "£-"}
                </Table.Cell>
                <Table.Cell>
                  {toMoney(legalEntity.total_debt || 0)}
                </Table.Cell>
                <Table.Cell>
                  {toMoney(legalEntity.exposure || 0)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      )}
    </Table>
  );
}

LegalEntityList.propTypes = {
  loading: PropTypes.bool.isRequired,
  legalEntities: PropTypes.arrayOf(PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    credit_limit_excluding_vat: PropTypes.number,
    credit_limit_including_vat: PropTypes.number,
    undistributed_credit_limit: PropTypes.number,
    total_debt: PropTypes.number,
    exposure: PropTypes.number,
    credit_suspended: PropTypes.bool.isRequired,
    inactive_since: PropTypes.number,
  })).isRequired,
};
