import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import { Form } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./InvoicingDetailsStep.module.scss";
import EmailAddressInput from "../../EmailAddressInput";

const INVOICE_FREQUENCY = [
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
];

const TIMESHEET_GROUPINGS = [
  { label: "Client", value: "client" },
  { label: "Timesheet", value: "timesheet" },
  { label: "PO Number", value: "po_number" },
  { label: "Worker", value: "worker" },
  { label: "Contact", value: "contact" },
  { label: "Worker Per Site", value: "worker_per_site" },
  { label: "Location", value: "location" },
  { label: "Contract", value: "contract" },
];

const VAT_TYPES = [
  { label: "Standard", value: "standard" },
  { label: "Exempt", value: "exempt" },
  { label: "Zero rated", value: "zero_rated" },
];

export default class InvoicingDetailsStep extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onRadioButtonChange: PropTypes.func.isRequired,
    onToggleInvoiceAddress: PropTypes.func.isRequired,
    invoicingDetails: PropTypes.shape({
      self_billing: PropTypes.bool.isRequired,
      po_number_required: PropTypes.bool.isRequired,
      default_po_number: PropTypes.string,
      self_billing_org: PropTypes.string,
      invoice_email_address: PropTypes.string.isRequired,
      invoice_telephone_number: PropTypes.string.isRequired,
      invoice_frequency: PropTypes.string,
      invoice_preference: PropTypes.string,
      invoice_fao: PropTypes.string,
      invoice_address_same_as_site_address: PropTypes.bool,
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
      payment_terms_type: PropTypes.string.isRequired,
      payment_terms_value: PropTypes.string,
      timesheet_groupings: PropTypes.string.isRequired,
      vat_type: PropTypes.string.isRequired.isRequired,
    }).isRequired,
    siteAddress: PropTypes.shape({
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }),
    validation: PropTypes.shape({
      self_billing_org: PropTypes.string,
      invoice_email_address: PropTypes.string,
      invoice_email_addresses: PropTypes.string,
      invoice_telephone_number: PropTypes.string,
      invoice_frequency: PropTypes.string,
      invoice_preference: PropTypes.string,
      invoice_fao: PropTypes.string,
      payment_terms_type: PropTypes.string,
      payment_terms_value: PropTypes.string,
      timesheet_groupings: PropTypes.string,
      vat_type: PropTypes.string,
      address_line_one: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }),
    onEmailAdded: PropTypes.func.isRequired,
    onEmailRemoved: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { invoicingDetails, onChange, onRadioButtonChange, onToggleInvoiceAddress, siteAddress, onEmailAdded, onEmailRemoved, onEmailChange, validation } = this.props;
    const DATE_RANGE = [...Array(32).keys()].slice(1);
    const DATE_RANGE_VALUES = DATE_RANGE.map(d => ({ value: d.toString(), label: d.toString() }));
    const DATE_RANGE_NOMINAL = DATE_RANGE.map(d => ({ value: d.toString(), label: moment(`2020-01-${d}`).format("Do") }));

    return (
      <div className={styles.container}>
        <Form>
          <Form.Section className={styles.formSection} title="Invoicing Details">
            <h3 className={styles.sectionHeader}>Invoice Preferences</h3>
            <Form.Group>
              <Form.Label>PO Number Required?</Form.Label>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.inlineRadio}
                  name="po_number_required"
                  text="Yes"
                  value={true}
                  onChange={onRadioButtonChange}
                  checked={invoicingDetails?.po_number_required}
                />
                <Form.Radio
                  className={styles.inlineRadio}
                  name="po_number_required"
                  text="No"
                  value={false}
                  onChange={onRadioButtonChange}
                  checked={!invoicingDetails?.po_number_required}
                />
              </Form.FlexRow>
            </Form.Group>
            <Form.Group>
              <Form.Label note="(Optional)">Default PO Number</Form.Label>
              <Form.Input
                name="default_po_number"
                value={invoicingDetails?.default_po_number || ""}
                onChange={onChange}
                placeholder="Default PO Number..."
                disabled={!invoicingDetails?.po_number_required}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Self Billing?</Form.Label>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.inlineRadio}
                  name="self_billing"
                  text="Yes"
                  value={true}
                  onChange={onRadioButtonChange}
                  checked={invoicingDetails?.self_billing}
                />
                <Form.Radio
                  className={styles.inlineRadio}
                  name="self_billing"
                  text="No"
                  value={false}
                  onChange={onRadioButtonChange}
                  checked={!invoicingDetails?.self_billing}
                />
              </Form.FlexRow>
            </Form.Group>
            <Form.Group error={validation.self_billing_org}>
              <Form.Label>Self Billing Organisation</Form.Label>
              <Form.Input
                name="self_billing_org"
                value={invoicingDetails?.self_billing_org || ""}
                onChange={onChange}
                placeholder="Add Self Billing Organisation..."
                size="large"
                disabled={!invoicingDetails.self_billing}
                maxLength={255}
                highlightError={validation.self_billing_org}
              />
            </Form.Group>
            <Form.Group error={validation.invoice_email_address || validation.invoice_email_addresses} className={styles.emailGroup}>
              <Form.Label>Invoice Email Address</Form.Label>
              <Form.Input
                name="invoice_email_address"
                value={invoicingDetails?.invoice_email_address || ""}
                onChange={onChange}
                placeholder="Add Invoice Email Address..."
                size="large"
                highlightError={validation.invoice_email_address}
                maxLength={255}
              />
            </Form.Group>
            <EmailAddressInput
              emailAddresses={invoicingDetails?.invoice_email_addresses}
              addButtonLabel="Add Another Email Address"
              removeButtonLabel="Remove"
              onChange={onEmailChange}
              onAdd={onEmailAdded}
              onRemove={onEmailRemoved}
            />
            <Form.FlexRow align="top">
              <Form.Group error={validation.invoice_telephone_number}>
                <Form.Label size="small">Invoice Telephone Number</Form.Label>
                <Form.Input
                  name="invoice_telephone_number"
                  value={invoicingDetails?.invoice_telephone_number || ""}
                  onChange={onChange}
                  placeholder="Phone Number..."
                  size="small"
                  highlightError={validation.invoice_telephone_number}
                  maxLength={50}
                />
              </Form.Group>
              <Form.Group error={validation.invoice_frequency} className={styles.inlineGroup}>
                <Form.Label size="small">Invoice Frequency</Form.Label>
                <Form.Select
                  className={styles.inlineSelect}
                  name="invoice_frequency"
                  value={invoicingDetails?.invoice_frequency || "weekly"}
                  options={INVOICE_FREQUENCY}
                  onChange={onChange}
                />
              </Form.Group>
            </Form.FlexRow>
            <Form.Group error={validation.invoice_preference}>
              <Form.Label>Invoice Preference</Form.Label>
              <Form.Value>
                <List
                  className={styles.select}
                  identifier="invoice_preferences"
                  name="invoice_preference"
                  placeholder="Invoice Preference..."
                  value={invoicingDetails?.invoice_preference}
                  onChange={onChange}
                  disabled={invoicingDetails?.self_billing}
                />
              </Form.Value>
            </Form.Group>
            <Form.Group error={validation.invoice_fao}>
              <Form.Label>Invoice FAO</Form.Label>
              <Form.Input
                name="invoice_fao"
                value={invoicingDetails?.invoice_fao || ""}
                onChange={onChange}
                placeholder="Invoice FAO..."
                size="large"
                disabled={invoicingDetails?.self_billing}
                highlightError={validation.invoice_fao}
                maxLength={255}
              />
            </Form.Group>
            <h3 className={styles.sectionHeader}>Invoice Address</h3>
            <Form.Group>
              <Form.Label>Same as Site Address?</Form.Label>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.inlineRadio}
                  name="invoice_address_same_as_site_address"
                  text="Yes"
                  value={true}
                  onChange={onToggleInvoiceAddress}
                  checked={invoicingDetails?.invoice_address_same_as_site_address}
                  disabled={!siteAddress?.address_line_one || invoicingDetails?.self_billing || invoicingDetails?.invoice_preference === "email"}
                />
                <Form.Radio
                  className={styles.inlineRadio}
                  name="invoice_address_same_as_site_address"
                  text="No"
                  value={false}
                  onChange={onToggleInvoiceAddress}
                  checked={!invoicingDetails?.invoice_address_same_as_site_address}
                  disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_preference === "email"}
                />
              </Form.FlexRow>
            </Form.Group>
            <Form.Group error={validation.address_line_one}>
              <Form.Label size="large">Address Line 1</Form.Label>
              <Form.Input
                name="address_line_one"
                placeholder="Address Line 1..."
                value={invoicingDetails?.address_line_one || ""}
                onChange={onChange}
                disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_address_same_as_site_address || invoicingDetails?.invoice_preference === "email"}
                size="large"
                highlightError={validation.address_line_one}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label size="large" note="(Optional)">Address Line 2</Form.Label>
              <Form.Input
                name="address_line_two"
                placeholder="Address Line 2..."
                value={invoicingDetails?.address_line_two || ""}
                onChange={onChange}
                disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_address_same_as_site_address || invoicingDetails?.invoice_preference === "email"}
                size="large"
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={validation.town_or_city}>
              <Form.Label size="large">Town / City</Form.Label>
              <Form.Input
                name="town_or_city"
                placeholder="Town / City..."
                value={invoicingDetails?.town_or_city || ""}
                onChange={onChange}
                disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_address_same_as_site_address || invoicingDetails?.invoice_preference === "email"}
                size="large"
                highlightError={validation.town_or_city}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={validation.county}>
              <Form.Label size="large">County</Form.Label>
              <Form.Input
                name="county"
                placeholder="County..."
                value={invoicingDetails?.county || ""}
                onChange={onChange}
                disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_address_same_as_site_address || invoicingDetails?.invoice_preference === "email"}
                size="large"
                highlightError={validation.county}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={validation.postcode}>
              <Form.Label size="small">Postcode</Form.Label>
              <Form.Input
                name="postcode"
                placeholder="Postcode..."
                value={invoicingDetails?.postcode || ""}
                onChange={onChange}
                disabled={invoicingDetails?.self_billing || invoicingDetails?.invoice_address_same_as_site_address || invoicingDetails?.invoice_preference === "email"}
                size="small"
                highlightError={validation.postcode}
                maxLength={255}
              />
            </Form.Group>
            <h3 className={styles.sectionHeader}>Payment Terms</h3>
            <Form.Group error={validation.payment_terms_type || validation.payment_terms_value}>
              <Form.Label>Payment Due On:</Form.Label>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.selectRadio}
                  name="payment_terms_type"
                  text=""
                  value="days_after_invoice"
                  onChange={onChange}
                  checked={invoicingDetails.payment_terms_type === "days_after_invoice"}
                />
                <Form.Select
                  className={classnames(
                    styles.date,
                    validation.payment_terms_value && invoicingDetails.payment_terms_type === "days_after_invoice" && styles.error,
                  )}
                  name="payment_terms_value"
                  value={invoicingDetails.payment_terms_type === "days_after_invoice" ? invoicingDetails?.payment_terms_value : ""}
                  options={DATE_RANGE_VALUES}
                  onChange={onChange}
                  size="large"
                  disabled={invoicingDetails.payment_terms_type !== "days_after_invoice"}
                />
                <Form.Label className={styles.inlineLabel}>Day(s) after invoice</Form.Label>
              </Form.FlexRow>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.selectRadio}
                  name="payment_terms_type"
                  text=""
                  value="day_of_month_after"
                  onChange={onChange}
                  checked={invoicingDetails.payment_terms_type === "day_of_month_after"}
                />
                <Form.Select
                  className={classnames(
                    styles.date,
                    validation.payment_terms_value && invoicingDetails.payment_terms_type === "day_of_month_after" && styles.error,
                  )}
                  name="payment_terms_value"
                  value={invoicingDetails.payment_terms_type === "day_of_month_after" ? invoicingDetails?.payment_terms_value : ""}
                  options={DATE_RANGE_NOMINAL}
                  onChange={onChange}
                  size="large"
                  disabled={invoicingDetails.payment_terms_type !== "day_of_month_after"}
                />
                <Form.Label className={styles.inlineLabel}>Day of the month, following invoice</Form.Label>
              </Form.FlexRow>
              <Form.FlexRow>
                <Form.Radio
                  className={styles.selectRadio}
                  name="payment_terms_type"
                  text="On day of invoice (immediate)"
                  value="invoice_date"
                  onChange={onChange}
                  checked={invoicingDetails.payment_terms_type === "invoice_date"}
                />
              </Form.FlexRow>
            </Form.Group>
            <h3 className={styles.sectionHeader}>Timesheet Groupings</h3>
            <Form.Group error={validation.timesheet_groupings}>
              <Form.FlexRow>
                <Form.Label className={styles.autoLabel}>One invoice per</Form.Label>
                <Form.Select
                  className={classnames(
                    styles.inlineSelect,
                    validation.timesheet_groupings && styles.error,
                  )}
                  name="timesheet_groupings"
                  placeholder="Select Grouping..."
                  value={invoicingDetails?.timesheet_groupings || ""}
                  options={TIMESHEET_GROUPINGS}
                  onChange={onChange}
                />
              </Form.FlexRow>
            </Form.Group>
            <h3 className={styles.sectionHeader}>VAT Type</h3>
            <Form.Group error={validation.vat_type}>
              <Form.Label>VAT Type</Form.Label>
              <Form.Select
                className={classnames(
                  styles.selectSmall,
                  validation.vat_type && styles.error,
                )}
                name="vat_type"
                placeholder="Select VAT Type..."
                value={invoicingDetails?.vat_type || ""}
                options={VAT_TYPES}
                onChange={onChange}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </div>
    );
  }
}
