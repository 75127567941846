import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import AgencyBranchFormValidator from "./AgencyBranchFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./AgencyBranchForm.module.scss";

export default class AgencyBranchForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    agencyBranch: PropTypes.shape({
      branch_name: PropTypes.string.isRequired,
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }).isRequired,
    disabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        address_line_one: props.agencyBranch?.address_line_one,
        address_line_two: props.agencyBranch?.address_line_two,
        town_or_city: props.agencyBranch?.town_or_city,
        county: props.agencyBranch?.county,
        postcode: props.agencyBranch?.postcode,
      },
      errors: {},
    };
  }

  render() {
    const { disabledFields, onDisabledFieldClick, submitting, agencyBranch } = this.props;
    const { data, errors } = this.state;

    return (
      <div className={styles.container}>
        <Form className={styles.form}>
          <Form.Section title={`Branches - ${agencyBranch.branch_name}`} underlined>
            <Form.Group inline wide error={errors?.address_line_one}>
              <Form.Label inline>Address Line 1</Form.Label>
              <Form.Input
                name="address_line_one"
                value={data.address_line_one}
                placeholder="Address Line 1..."
                size="large"
                maxLength={255}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch_details")}
                showLock={disabledFields.includes("branch_details")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch_details")}
                highlightError={errors?.address_line_one}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.address_line_two}>
              <Form.Label inline note="(Optional)">Address Line 2</Form.Label>
              <Form.Input
                name="address_line_two"
                value={data.address_line_two}
                placeholder="Address Line 2..."
                size="large"
                maxLength={255}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch_details")}
                showLock={disabledFields.includes("branch_details")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch_details")}
                highlightError={errors?.address_line_two}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.town_or_city}>
              <Form.Label inline>Town / City</Form.Label>
              <Form.Input
                name="town_or_city"
                value={data.town_or_city}
                placeholder="Town / City..."
                size="large"
                maxLength={255}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch_details")}
                showLock={disabledFields.includes("branch_details")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch_details")}
                highlightError={errors?.town_or_city}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.county}>
              <Form.Label inline>County</Form.Label>
              <Form.Input
                name="county"
                value={data.county}
                placeholder="County..."
                size="large"
                maxLength={255}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch_details")}
                showLock={disabledFields.includes("branch_details")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch_details")}
                highlightError={errors?.county}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.postcode}>
              <Form.Label inline>Postcode</Form.Label>
              <Form.Input
                name="postcode"
                value={data.postcode}
                placeholder="Postcode..."
                size="small"
                maxLength={8}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch_details")}
                showLock={disabledFields.includes("branch_details")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch_details")}
                highlightError={errors?.postcode}
              />
            </Form.Group>
          </Form.Section>
        </Form>
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleSaveClicked}
              busy={submitting}
              disabled={submitting || !_.isEmpty(disabledFields)}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </>
        </ButtonGroup>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClicked = () => {
    const { data } = this.state;
    const { onSave } = this.props;

    const validator = new AgencyBranchFormValidator(data, this.props);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onSave && onSave({
      ...data,
    });
  }
}
