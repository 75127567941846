import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { EditButton, Form, TextLink } from "../../../../common/components";
import { formatNumber, parseDate } from "../../../../../utils";
import styles from "./ContractDetailsSection.module.scss";

export default function ContractDetailsSection({
  contract,
}) {
  return (
    <>
      <Form.Section title="Contract Details" className={styles.compactHeading} rightAccessory={<EditButton />}>
        <Form.Group>
          <Form.Label>Legal Entity</Form.Label>
          <Form.Value>
            <TextLink
              className={styles.link}
              target="_blank"
              to={`/credit-limit-management/legal-entities/${contract.legal_entity.id}`}
              text={contract.legal_entity.name}
            />
          </Form.Value>
        </Form.Group>
        <Form.Group>
          <Form.Label>Trading Style</Form.Label>
          <Form.Value>
            {contract.trading_style?.name || "None"}
          </Form.Value>
        </Form.Group>
        <Form.Group>
          <Form.Label>Agency</Form.Label>
          <Form.Value>
            <TextLink
              className={styles.link}
              target="_blank"
              to={`/agencies/${contract.agency.id}`}
              text={contract.agency.name}
            />
          </Form.Value>
        </Form.Group>
        <Form.Group>
          <Form.Label>Contract Title</Form.Label>
          <Form.Value>
            {contract.title}
          </Form.Value>
        </Form.Group>
        <Form.FlexRow>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Value>
              {parseDate(contract.start_date)}
            </Form.Value>
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Value>
              {parseDate(contract.end_date) || "-"}
            </Form.Value>
          </Form.Group>
        </Form.FlexRow>
        <Form.Group>
          <Form.Label>Credit Needed</Form.Label>
          <Form.Value>
            <span className={styles.currency}>{"£"}</span>{formatNumber(contract.estimated_credit)}
          </Form.Value>
        </Form.Group>
        <Form.Group>
          <Form.Label>Site Address</Form.Label>
          <Form.Group>
            <Form.Value>
              {contract.site_address.address_line_one ? _.compact([
                contract.site_address?.address_line_one,
                contract.site_address?.address_line_two,
                contract.site_address?.town_or_city,
                contract.site_address?.county,
                contract.site_address?.postcode,
              ]).map((address, i) => (
                <div key={i} className={styles.addressLine}>{address}</div>
              )) : "-"}
            </Form.Value>
          </Form.Group>
        </Form.Group>
      </Form.Section>
    </>
  );

}

ContractDetailsSection.propTypes = {
  contract: PropTypes.shape({
    agency: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    legal_entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    trading_style: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    start_date: PropTypes.number.isRequired,
    end_date: PropTypes.number,
    estimated_credit: PropTypes.number.isRequired,
    site_address: PropTypes.shape({
      address_line_one: PropTypes.string.isRequired,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }),
  }),
};
