import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AgencyDetailsSection from "./AgencyDetailsSection/AgencyDetailsSection";
import BankInformationSection from "./BankInformationSection/BankInformationSection";
import BranchesSection from "./BranchesSection/BranchesSection";
import ContractInformationSection from "./ContractInformationSection/ContractInformationSection";
import CompaniesHouseDetailsSection from "./CompaniesHouseDetailsSection/CompaniesHouseDetailsSection";
import CompaniesHouseRegisteredAddressSection from "./CompaniesHouseRegisteredAddressSection/CompaniesHouseRegisteredAddressSection";
import TradingAddressSection from "./TradingAddressSection/TradingAddressSection";
import styles from "./CompanyView.module.scss";

export default function CompanyView(props) {
  return (
    <div className={styles.container}>
      <div className={styles.prospectProfile}>
        <div className={styles.agencyDetail}>
          <AgencyDetailsSection
            companyHistory={props.companyHistory}
            industrySectors={props.companyInfo?.prospect?.industry_sectors.map(industrySector => industrySector.name)}
            knownAs={props.companyInfo?.prospect?.known_as}
            previousProvider={props.companyInfo?.prospect?.previous_provider}
            tradingAs={props.companyInfo?.prospect?.trading_as}
            website={props.companyInfo?.prospect?.website}
            linkedIn={props.companyInfo?.prospect?.linked_in}
            twitter={props.companyInfo?.prospect?.twitter}
            onEditClick={props.onEditAgencyDetailsClick}
          />
        </div>
        <div className={styles.companiesHouseDetail}>
          <CompaniesHouseDetailsSection
            certificateOfIncorporation={props.companyInfo?.certificateOfIncorporationDocument}
            companyHistory={props.companyHistory}
            companyNumber={props.companyInfo?.prospect?.company_number}
            companyStatus={props.companyInfo?.prospect?.companies_house_info?.company_status}
            companyStatusDetail={props.companyInfo?.prospect?.companies_house_info?.company_status_detail}
            companyType={props.companyInfo?.prospect?.companies_house_info?.type}
            dateOfCreation={props.companyInfo?.prospect?.companies_house_info?.date_of_creation}
            lastAccountsMadeUpTo={props.companyInfo?.prospect?.companies_house_info?.accounts?.last_accounts?.made_up_to}
            legalName={props.companyInfo?.prospect?.legal_name}
            officers={props.companyInfo?.prospect?.officers?.items}
            onDownload={props.onDownload}
          />
        </div>
        <div className={classnames(styles.row, styles.addresses)}>
          <div className={styles.companiesHouseAddress}>
            <CompaniesHouseRegisteredAddressSection
              companyHistory={props.companyHistory}
              registeredOfficeAddress={props.companyInfo?.prospect?.companies_house_info?.registered_office_address}
            />
          </div>
          <div className={styles.tradingAddress}>
            <TradingAddressSection
              companyHistory={props.companyHistory}
              tradingAddress={props.companyInfo?.prospect?.trading_address}
              onEditClick={props.onEditTradingAddressClick}
            />
          </div>
        </div>
        <div className={classnames(styles.row, styles.contractBank)}>
          <div className={styles.contractInfo}>
            <ContractInformationSection
              companyHistory={props.companyHistory}
              contract={props.companyInfo?.contractDocument}
              contractFee={props.companyInfo?.prospect?.contract?.fee}
              contractFundingType={props.companyInfo?.prospect?.contract?.funding_type}
              contractStartDate={props.companyInfo?.prospect?.contract?.start_date}
              dateFirstTraded={props.companyInfo?.prospect?.contract?.date_first_traded}
              introducedViaBroker={props.companyInfo?.prospect?.introduced_via_broker}
              ledgerBuyout={props.companyInfo?.prospect?.is_ledger_buyout}
              lengthOfContract={{
                length: props.companyInfo?.prospect?.contract?.contract_length,
                period: props.companyInfo?.prospect?.contract?.contract_period,
              }}
              onEditClick={props.onEditContractInformationClick}
              onDownload={props.onDownload}
            />
          </div>
          <div className={styles.bankInfo}>
            <BankInformationSection
              accountNumber={props.companyInfo?.prospect?.banking?.account_number}
              bankName={props.companyInfo?.prospect?.banking?.bank_name}
              branch={props.companyInfo?.prospect?.banking?.branch}
              companyHistory={props.companyHistory}
              payeeName={props.companyInfo?.prospect?.banking?.payee_name}
              sortCode={props.companyInfo?.prospect?.banking?.sort_code}
              onDownload={props.onDownload}
              onEditClick={props.onEditBankInformationClick}
            />
          </div>
        </div>
        {!_.isEmpty(props.companyInfo?.prospect?.branches) &&
          <div className={styles.branches}>
            <BranchesSection
              branches={props.companyInfo?.prospect?.branches}
              onEditClick={props.onEditBranchClick}
            />
          </div>
        }
      </div>
    </div>
  );
}

CompanyView.propTypes = {
  companyInfo: PropTypes.shape({
    prospect: PropTypes.shape({
      id: PropTypes.string.isRequired,
      known_as: PropTypes.string.isRequired,
      company_number: PropTypes.string.isRequired,
      legal_name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      status_details: PropTypes.string,
      officers: PropTypes.shape({
        items: PropTypes.shape({
          name: PropTypes.string.isRequired,
          officer_role: PropTypes.string.isRequired,
        }),
      }),
      companies_house_info: PropTypes.shape({
        accounts: PropTypes.shape({
          last_accounts: PropTypes.shape({
            made_up_to: PropTypes.string,
          }),
        }),
        registered_office_address: PropTypes.shape({
          address_line_1: PropTypes.string,
          locality: PropTypes.string,
          postal_code: PropTypes.string,
        }),
        company_status: PropTypes.string,
        company_status_detail: PropTypes.string,
        type: PropTypes.string,
        date_of_creation: PropTypes.string,
      }),
      contract: PropTypes.shape({
        start_date: PropTypes.instanceOf(Date),
        contract_length: PropTypes.number,
        contract_period: PropTypes.string,
        date_first_traded: PropTypes.instanceOf(Date),
        fee: PropTypes.number,
        funding_type: PropTypes.string,
      }),
      banking: PropTypes.shape({
        prospect_id: PropTypes.string,
        sort_code: PropTypes.string,
        account_number: PropTypes.string,
        bank_name: PropTypes.string,
        branch: PropTypes.string,
        payee_name: PropTypes.string,
      }),
      website: PropTypes.string,
      trading_as: PropTypes.string,
      introduced_via_broker: PropTypes.bool,
      is_ledger_buyout: PropTypes.bool,
      trading_address: PropTypes.shape({
        address_line_one: PropTypes.string,
        address_line_two: PropTypes.string,
        town_or_city: PropTypes.string,
        county: PropTypes.string,
        postcode: PropTypes.string,
      }).isRequired,
      previous_provider: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      industry_sectors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
      branches: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        address_line_one: PropTypes.string,
        address_line_two: PropTypes.string,
        town_or_city: PropTypes.string,
        county: PropTypes.string,
        postcode: PropTypes.string,
        branch: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    certificateOfIncorporationDocument: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    contractDocument: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    vatDocument: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  companyHistory: PropTypes.shape({
    registered_address: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    contract_start_date: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    legal_name: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    trading_as: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    sort_code: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    account_number: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  onLoad: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onEditAgencyDetailsClick: PropTypes.func.isRequired,
  onEditBankInformationClick: PropTypes.func.isRequired,
  onEditBranchClick: PropTypes.func.isRequired,
  onEditContractInformationClick: PropTypes.func.isRequired,
  onEditTradingAddressClick: PropTypes.func.isRequired,
};
