import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { CheckCircle, WarningRounded, Flag } from "@material-ui/icons";
import { Table2, TextLink, Icon } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./CreditActivityList.module.scss";
import unreport from "../../../../assets/images/unreport-blue.svg";


export default class CreditActivityList extends PureComponent {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      legal_entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      action: PropTypes.string.isRequired,
      via: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
      created_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
      document_id: PropTypes.string,
    })),
  }

  static defaultProps = {
    data: [],
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.container}>
        <Table2 data={data}>
          <Table2.Column
            className={styles.dateColumn}
            value={row => (
              <span className={styles.submitted}>
                {parseDate(row.created_at)}
              </span>
            )}
          >
            Date
          </Table2.Column>
          <Table2.Column
            value={row => (
              <span className={styles.action}>
                <span className={styles.actionIcon}>{this.renderActionIcon(row.action)}</span>
                <span className={styles.value}>{row.action}</span>
              </span>
            )}
          >
            <span className={styles.actionColumnText}>Action</span>
          </Table2.Column>
          <Table2.Column
            className={styles.moneyColumn}
            value={row => (
              <span className={styles.value}>
                {row.via}
              </span>
            )}
          >
            Source
          </Table2.Column>
          <Table2.Column
            value={row => (
              <span className={styles.value}>
                {`${row.created_by.first_name} ${row.created_by.last_name}`}
              </span>
            )}
          >
            Name
          </Table2.Column>
          <Table2.Column
            value={row => row.type === "CreditApplication" && (
              <TextLink
                className={styles.link}
                text="View"
                to={`/credit-limit-management/legal-entities/${row.legal_entity.id}/credit-management/credit-applications/${row.document_id}`}
              />
            )}
          />
        </Table2>
      </div>
    );
  }

  renderActionIcon = (action) => {

    if (action.includes("Reported"))
      return <Flag className={styles.flag} />;

    if (action.includes("Unreported"))
      return <Icon icon={unreport} />;

    if (action === "Suspended")
      return <WarningRounded className={styles.suspendedIcon} />;

    if (action === "Unsuspended")
      return <CheckCircle className={styles.unsuspendedIcon} />;

    return null;
  }

}
