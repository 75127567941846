import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../common/components";
import styles from "./LegalEntityAlreadyExistsModal.module.scss";

export default function LegalEntityAlreadyExistsModal(props) {
  return (
    <ConfirmationModal
      className={classnames(props.className, styles.container)}
      dimmerClassName={styles.dimmer}
      title="Legal Entity already exists"
      submitText="Okay"
      visible={props.visible}
      onSubmit={props.onSubmit}
    >
      This legal entity already exists within Optia, but not for this agency.{" "}
      A new request will be created for {props.agencyName}
    </ConfirmationModal>
  );
}

LegalEntityAlreadyExistsModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  agencyName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
