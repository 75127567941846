import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { SearchBox } from "../../../common/components";
import styles from "./TradingStyleSearchBox.module.scss";

export class TradingStyleSearchBox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    tradingStyles: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    legalEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    agencyId: PropTypes.string,
    getTradingStyles: PropTypes.func.isRequired,
    onItemSelected: PropTypes.func.isRequired,
  }

  state = {
    value: "",
  }

  render() {
    const { tradingStyles, agencyId } = this.props;
    const { value } = this.state;

    return (
      <SearchBox
        className={styles.container}
        loading={tradingStyles.loading}
        value={value}
        results={tradingStyles.data.map(item => ({
          ...item,
          title: item.name,
        }))}
        onSearchChange={this.handleSearchChanged}
        onResultSelect={this.handleResultSelected}
        resultRenderer={this.renderResultItem}
        fluid
        disabled={!agencyId}
      />
    );
  }

  renderResultItem = ({ id, name }) => {
    return <span key={id}>{name}</span>;
  }

  handleSearchChanged = async (_e, { value }) => {
    this.setState({ value }, this.runSearch);
  }

  handleResultSelected = (_e, { result }) => {
    const { name, onItemSelected } = this.props;

    this.setState({ value: result.name }, () => {
      onItemSelected({
        target: {
          name,
          value: result.id,
        },
      });
    });
  }

  runSearch = async () => {
    const { getTradingStyles, legalEntity, agencyId } = this.props;
    const { value } = this.state;

    await getTradingStyles({
      searchText: value,
      legalEntityId: legalEntity.id,
      agencyId: agencyId,
    });
  }
}

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TradingStyleSearchBox);
