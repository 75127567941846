import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UNREPORT_INSURER_REPORTING_ACTIVITY = gql`
mutation($input: UnreportInsurerReportingActivityInput!) {
  unreportInsurerReportingActivity(input: $input) {
    id
    legal_entity {
      id
    }
    reported_by
    reported_at
    report_reason {
      label
    }
    created_at
    updated_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.insurerReportingActivityId
 * @param {string} obj.unreportedBy
 * @param {number} obj.unreportedAt
 * @param {string} obj.unreportedComment
 * @returns
 */
export const unreportInsurerReportingActivity = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UNREPORT_INSURER_REPORTING_ACTIVITY_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UNREPORT_INSURER_REPORTING_ACTIVITY,
      variables: {
        input: {
          id: obj.insurerReportingActivityId,
          unreported_by: obj.unreportedBy,
          unreported_at: obj.unreportedAt,
          unreported_comment: obj.unreportedComment,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UNREPORT_INSURER_REPORTING_ACTIVITY_SUCCESS,
      payload: {
        insurerReportingActivity: response.data.unreportInsurerReportingActivity,
      },
    });
  } catch (err) {
    dispatch({
      type: types.UNREPORT_INSURER_REPORTING_ACTIVITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
