import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ContractForm from "../../components/ContractForm";
import { actions as agencyActions } from "../../../agencies/duck";

export class CreateContract extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    legalEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    busy: PropTypes.bool.isRequired,
    createContract: PropTypes.func.isRequired,
  }
  render() {
    const { history, legalEntity, busy } = this.props;

    if (legalEntity) {
      return (
        <ContractForm
          history={history}
          legalEntity={legalEntity}
          onCancelClick={this.handleCancelClick}
          onSaveClick={this.handleSaveClick}
          busy={busy}
        />
      );
    }

    return <ActivityIndicator />;
  }

  handleSaveClick = async (newContract) => {
    const { createContract, legalEntityId, history } = this.props;

    await createContract(newContract);

    history.push(`/credit-limit-management/legal-entities/${legalEntityId}/contract-management`);
  }

  handleCancelClick = () => {
    const { history, legalEntityId } = this.props;

    history.push(`/credit-limit-management/legal-entities/${legalEntityId}/contract-management`);
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
});
const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, ...agencyActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateContract);
