import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions as agencyActions } from "../../agencies/duck";
import { actions as documentActions } from "../../documents/duck";
import { actions } from "../duck";
import { CommentsSection } from "../../common/components";
import { AgencySubtaskList, TaskCommentModal } from "../components";
import { bytesToGigabytes } from "../../../utils";
import styles from "./AgencyTaskPage.module.scss";

class AgencyTaskPage extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyTask: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      isSaving: PropTypes.bool.isRequired,
      isCompleting: PropTypes.bool.isRequired,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        definition: PropTypes.shape({
          subtasks: PropTypes.array.isRequired,
        }).isRequired,
        completed_at: PropTypes.number,
        status: PropTypes.oneOf(["not_started", "in_progress", "done"]),
        comments: PropTypes.arrayOf(PropTypes.shape({
          comment: PropTypes.string.isRequired,
          created_at: PropTypes.number.isRequired,
          created_by: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
          }).isRequired,
        })),
      }),
    }),
    agencyOnboardingDocuments: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          size: PropTypes.string.isRequired,
          lastModified: PropTypes.number.isRequired,
          versions: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              size: PropTypes.string.isRequired,
              lastModified: PropTypes.number.isRequired,
              versionId: PropTypes.string.isRequired,
              metadata: PropTypes.shape({
                user: PropTypes.shape({
                  first_name: PropTypes.string.isRequired,
                  last_name: PropTypes.string.isRequired,
                }),
                document_type: PropTypes.string,
              }),
            }),
          ),
          metadata: PropTypes.shape({
            user: PropTypes.shape({
              first_name: PropTypes.string.isRequired,
              last_name: PropTypes.string.isRequired,
            }),
            document_type: PropTypes.string,
          }),
        })
      ),
    }),
    getAgencyTask: PropTypes.func.isRequired,
    getAgencyDocuments: PropTypes.func.isRequired,
    getAgencyOnboardingDocuments: PropTypes.func.isRequired,
    addAgencyTaskTrackingEntry: PropTypes.func.isRequired,
    deleteTrackingEntry: PropTypes.func.isRequired,
    addAgencyTaskComment: PropTypes.func.isRequired,
    agencyOnboardingTaskProgressChanged: PropTypes.func.isRequired,
    saveAgencyOnboardingTask: PropTypes.func.isRequired,
    completeAgencyOnboardingTask: PropTypes.func.isRequired,
    uploadAgencyOnboardingDocument: PropTypes.func.isRequired,
    uploadAgencyDocument: PropTypes.func.isRequired,
    downloadAgencyOnboardingDocument: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isSaving: false,
    isCompleting: false,
  }

  state = {
    showLeaveCommentModal: false,
    canComplete: false,
    busy: false,
    commentFilter: [],
    errors: [],
  }

  commentRefs = {};

  async componentDidMount() {
    const { agencyId, taskId, getAgencyTask, getAgencyDocuments, getAgencyOnboardingDocuments } = this.props;

    await Promise.all([
      getAgencyTask({
        agencyId,
        agencyOnboardingTaskId: taskId,
      }),
      getAgencyDocuments(agencyId),
      getAgencyOnboardingDocuments(agencyId),
    ]);
  }

  render() {
    const { showLeaveCommentModal, busy, errors } = this.state;
    const { agencyTask } = this.props;

    if (agencyTask.loading) {
      return <ActivityIndicator />;
    }

    const task = agencyTask.data;
    const orderedComments = this.getOrderedComments();

    return (
      <div className={styles.container}>
        <AgencySubtaskList
          history={this.props.history}
          completed={task.status === "done"}
          className={styles.subtasks}
          subtasks={task.definition.subtasks}
          comments={orderedComments}
          errorMessage={_.first(errors)}
          disabled={busy}
          isSaving={agencyTask.isSaving}
          isCompleting={agencyTask.isCompleting}
          onAddTracking={this.handleAddTracking}
          onProgressChange={this.handleProgressChanged}
          onSaveClick={this.handleOnSave}
          onCompleteClick={this.handleOnComplete}
          onDownload={this.handleDocumentDownload}
          onDelete={this.handleDocumentDelete}
          onTrackingDelete={this.handleTrackingDelete}
          onCommentSelected={this.handleCommentSelected}
        />
        <div className={styles.comments}>
          <TaskCommentModal
            visible={showLeaveCommentModal}
            subtasks={task.definition.subtasks.map(subtask => ({
              field_name: subtask.field.name,
              label: subtask.name,
            }))}
            onSubmit={this.handleSubmitComment}
            onCancel={this.handleCancelComment}
          />
          <CommentsSection
            title="Comments"
            commentsEnabled
            comments={orderedComments}
            commentRefs={this.commentRefs}
            filterOptions={task.definition.subtasks.map(subtask => ({
              key: subtask.field.name,
              value: subtask.field.name,
              label: subtask.name,
            }))}
            filterPlaceholder="Filter comments by subtask"
            filterClassName={styles.filter}
            onFilter={this.handleCommentFilter}
            onFilterClear={this.handleCommentFilterClear}
            onLeaveComment={() => this.setState({ showLeaveCommentModal: true })}
          />
        </div>
      </div>
    );
  }

  handleSubmitComment = async ({ comment, subtasks }) => {
    const { addAgencyTaskComment, taskId } = this.props;

    await addAgencyTaskComment({
      taskId,
      comment,
      subtasks,
    });

    this.setState({
      showLeaveCommentModal: false,
    });
  }

  handleCancelComment = () => {
    this.setState({
      showLeaveCommentModal: false,
      commentText: "",
    });
  }

  handleCommentFilter = async (values) => {
    this.setState({
      commentFilter: values,
    });
  }

  handleCommentFilterClear = async () => {
    this.setState({
      commentFilter: [],
    });
  }

  handleCommentSelected = (id) => {
    this.commentRefs[id].current.scrollIntoView({ behavior: "smooth" });
  }

  handleAddTracking = async (data) => {
    const { addAgencyTaskTrackingEntry, taskId } = this.props;

    await addAgencyTaskTrackingEntry({
      ...data,
      taskId,
    });
  }

  handleTrackingDelete = (id) => {
    const { deleteTrackingEntry } = this.props;

    deleteTrackingEntry({
      trackingEntryId: id,
    });
  }

  handleProgressChanged = (data) => {
    this.props.agencyOnboardingTaskProgressChanged({
      agencyOnboardingTaskId: this.props.taskId,
      ...data,
    });
  }

  handleOnSave = async (data) => {
    const { taskId, agencyTask, saveAgencyOnboardingTask } = this.props;

    this.setState({ busy: true, errors: [] });
    const uploadResult = await this.uploadOnboardingDocuments(agencyTask.data, data);

    if (!uploadResult.errors) {
      await saveAgencyOnboardingTask({
        agencyOnboardingTaskId: agencyTask.data.id,
        taskId,
        data: uploadResult.data,
      });
    }

    this.setState({ busy: false, errors: uploadResult.errors });

    return uploadResult.data;
  }

  handleOnComplete = async (data) => {
    const { taskId, agencyTask, completeAgencyOnboardingTask } = this.props;

    this.setState({ busy: true, errors: [] });
    const uploadResult = await this.uploadOnboardingDocuments(agencyTask.data, data);

    if (!uploadResult.errors) {
      await completeAgencyOnboardingTask({
        agencyOnboardingTaskId: agencyTask.data.id,
        taskId,
        data: uploadResult.data,
      });
    }

    this.setState({ busy: false, errors: uploadResult.errors });
  }

  handleDocumentDownload = (type) => {
    const { agencyOnboardingDocuments, agencyId, downloadAgencyOnboardingDocument } = this.props;

    const document = agencyOnboardingDocuments.documents.find(document => (document?.metadata?.document_type || document?.name) === type);

    if (!document) {
      // eslint-disable-next-line no-console
      console.error(`Document not found: ${type}`);
      return;
    }

    const version = document.versions.length > 0
      ? document.versions[0].versionId
      : null;

    downloadAgencyOnboardingDocument(agencyId, type, version);
  }

  getOrderedComments = () => {
    const { commentFilter } = this.state;
    const { agencyTask } = this.props;

    if (agencyTask.loading) {
      return [];
    }

    const orderedComments = _.chain(agencyTask.data?.comments)
      .map(comment => ({
        ...comment,
        tags: (comment.related_subtasks || []).map(subtask => (
          { id: subtask.field_name, label: subtask.label }
        )),
      }))
      .filter(comment => commentFilter.length === 0 || comment.tags.some(tag => commentFilter.includes(tag.id)))
      .orderBy(["created_at"], ["desc"])
      .value();

    orderedComments.forEach((comment) => {
      this.commentRefs[comment.id] = React.createRef();
    });

    return orderedComments;
  }

  uploadOnboardingDocuments = async (task, data) => {
    const { agencyId, uploadAgencyOnboardingDocument, uploadAgencyDocument, getAgencyDocuments, getAgencyOnboardingDocuments } = this.props;

    const uploadFields = task.definition.subtasks
      .filter(subtask => subtask.field.type === "document")
      .map(subtask => subtask.field.name);
    const errors = uploadFields
      .filter(documentType => data[documentType]?.dirty)
      .filter(documentType => bytesToGigabytes(data[documentType].data.size) >= 5)
      .map((documentType) => {
        const subtask = task.definition.subtasks.find(subtask => subtask.field.name === documentType);

        return `${subtask.name} exceeds the maximum file size of 5GB`;
      });

    // Do not upload anything if we can't upload one of the files
    if (errors.length > 0) {
      return { data, errors };
    }

    const updatedData = {
      ...data,
    };

    for (const type of uploadFields) {
      if (data[type]?.dirty) {
        const subtask = task.definition.subtasks.find(subtask => subtask.field.name === type);

        updatedData[type] = await uploadAgencyOnboardingDocument(
          data[type].data,
          agencyId,
          subtask.field.document_type
        );

        await uploadAgencyDocument(data[type].data, agencyId, subtask.field.document_type);
      }
    }

    await getAgencyDocuments(agencyId);
    await getAgencyOnboardingDocuments(agencyId);

    return { data: updatedData, errors: null };
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  ...state.agencies,
  ...state.documents,
  agencyId: ownProps.match.params.agencyId,
  taskId: ownProps.match.params.taskId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...agencyActions,
  ...documentActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTaskPage);
