import { InsurerApplicationCreatedModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {number} obj.requestedAmount the amount of credit requested
 */
export const showInsurerApplicationCreatedModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(InsurerApplicationCreatedModal.displayName, {
    requestedAmount: obj.requestedAmount,
  }));
};
