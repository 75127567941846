import _ from "lodash";
import gql from "graphql-tag";
import { push } from "connected-react-router";
import { showVerifyAgencyCustomerModal } from "./";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_CUSTOMER_REQUEST = gql`
query(
  $legal_entity_id: ID!
  $agency_customer_request_id: ID!
) {
  getLegalEntity(id: $legal_entity_id) {
    id
    name

    ...on RegisteredCompany {
      company_number
      experian_credit_checks(
        pager: { page: 1, limit: 1 }
        sort_order: [{ field: "created_at", order: "desc"}]
      ) {
        id
        experian_credit_result
        approved_credit
        created_at
      }
    }

    agency_customer_request(id: $agency_customer_request_id) {
      id
      registered_company_number
      exact_legal_name
      credit_limit_requested
      created_at

      agency {
        id
        name

        extended {
          logo_url
        }
      }
    }
  }
}
`;


/**
 *
 * @param {object} creditLimitManagementTask
 * @param {string} creditLimitManagementTask.id
 * @param {string} creditLimitManagementTask.task_type
 * @param {object} creditLimitManagementTask.data
 * @param {string} creditLimitManagementTask.data.agency_id
 * @param {string} creditLimitManagementTask.data.legal_entity_id
 * @param {string} creditLimitManagementTask.data.agency_customer_request_id
 * @returns
 */
export const handleAgencyCustomerVerificationRequiredTask = creditLimitManagementTask => async (dispatch) => {
  try {
    const response = await apolloClient.query({
      query: GET_AGENCY_CUSTOMER_REQUEST,
      variables: {
        legal_entity_id: creditLimitManagementTask.data.legal_entity_id,
        agency_customer_request_id: creditLimitManagementTask.data.agency_customer_request_id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const legalEntity = response.data.getLegalEntity;
    const agencyCustomerRequest = legalEntity.agency_customer_request;
    const hasBeenCreditChecked = !_.isEmpty(legalEntity.experian_credit_checks);

    dispatch(push(`/credit-limit-management/legal-entities/${creditLimitManagementTask.data.legal_entity_id}/credit-management`));
    dispatch(showVerifyAgencyCustomerModal({
      legalEntityId: legalEntity.id,
      agencyCustomerRequestId: agencyCustomerRequest.id,
      agencyId: agencyCustomerRequest.agency.id,
      agencyName: agencyCustomerRequest.agency.name,
      enteredByUser: {
        registeredCompanyNumber: agencyCustomerRequest.registered_company_number,
        exactLegalName: agencyCustomerRequest.exact_legal_name,
      },
      selectedFromCompaniesHouse: {
        registeredCompanyNumber: legalEntity.company_number,
        exactLegalName: legalEntity.name,
      },
      hasBeenCreditChecked,
    }));
  }
  catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to handle agency customer verification required task", err);
  }
};
