import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AUTO_ENROLMENT_PENSION_INFO = gql`
mutation(
  $agency_id: ID!
  $input: UpdateAgencyAutoEnrolmentPensionInfoInput!
) {
  updateAgencyAutoEnrolmentPensionInfo(agency_id: $agency_id, input: $input) {
    pension_company {
      id
      value
    }
    pension_scheme_weekly
    pension_scheme_monthly
    staging_date
    payment_source_reference_weekly
    payment_source_reference_monthly
    default_postponement_months
    eligible_job_holder_postponement_months
    employer_pension_reference
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.data
 * @param {number} obj.data.pension_company_id
 * @param {string} obj.data.pension_scheme_weekly
 * @param {string} obj.data.pension_scheme_monthly
 * @param {Date} obj.data.staging_date
 * @param {string} obj.data.payment_source_reference_weekly
 * @param {string} obj.data.payment_source_reference_monthly
 * @param {number} obj.data.default_postponement_months
 * @param {number} obj.data.eligible_job_holder_postponement_months
 * @param {string} obj.data.employer_pension_reference
 * @param {object} obj.data.previous_pension_provider
 * @param {number} obj.data.previous_pension_provider.id
 * @param {string} obj.redirectUrl
 *
 */
export const updateAutoEnrolmentPensionInfo = ({ agencyId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_AUTO_ENROLMENT_PENSION_INFO,
      variables: {
        agency_id: agencyId,
        input: {
          pension_company_id: data.pension_company_id,
          pension_scheme_weekly: data.pension_scheme_weekly,
          pension_scheme_monthly: data.pension_scheme_monthly,
          staging_date: data.staging_date,
          payment_source_reference_weekly: data.payment_source_reference_weekly,
          payment_source_reference_monthly: data.payment_source_reference_monthly,
          default_postponement_months: data.default_postponement_months,
          eligible_job_holder_postponement_months: data.eligible_job_holder_postponement_months,
          employer_pension_reference: data.employer_pension_reference,
          previous_pension_provider_id: parseInt(data.previous_pension_provider.id),
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_SUCCESS,
      payload: {
        data: response.data.updateAgencyAutoEnrolmentPensionInfo,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AUTO_ENROLMENT_PENSION_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
