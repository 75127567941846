
import React from "react";
import PropTypes from "prop-types";
import { Divider, Modal } from "semantic-ui-react";
import { Button } from "../../../common/components";
import Logo from "../../../../assets/images/logo.png";
import styles from "./PasswordChangeSuccessfulModal.module.scss";

export default function PasswordChangeSuccessfulModal(props) {
  return (
    <Modal
      className={styles.container}
      dimmer={{
        className: styles.dimmer,
      }}
      open={props.visible}
      centered
    >
      <div className={styles.header}>
        <img src={Logo} className={styles.logo} />
        <div className={styles.title}>
          Password successfully changed!
        </div>
      </div>
      <Divider />
      <div className={styles.actions}>
        <Button variant="primary" onClick={props.onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
}

PasswordChangeSuccessfulModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
