import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { EditButton, Form } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./InvoicingDetailsSection.module.scss";

const INVOICE_FREQUENCY_OPTIONS = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

const PAYMENT_TERM_OPTIONS = [
  { value: "days_after_invoice" },
  { value: "day_of_month_after" },
  { value: "invoice_date" },
];

const TIMESHEET_GROUPING_OPTIONS = [
  { label: "Client", value: "client" },
  { label: "Contact", value: "contact" },
  { label: "Contract", value: "contract" },
  { label: "Location", value: "location" },
  { label: "PO Number", value: "po_number" },
  { label: "Timesheet", value: "timesheet" },
  { label: "Worker", value: "worker" },
  { label: "Worker Per Site", value: "worker_per_site" },
];

const VAT_TYPE_OPTIONS = [
  { label: "Standard", value: "standard" },
  { label: "Exempt", value: "exempt" },
  { label: "Zero-rated", value: "zero_rated" },
];

export default function InvoicingDetailsSection({
  contract,
}) {
  return (
    <Form.Section title="Invoicing Details" className={styles.compactHeading} rightAccessory={<EditButton />}>
      <div className={styles.columns}>
        <div>
          <Form.Section title="Invoice Preferences" variant="h3">
            <Form.Group>
              <Form.Label>PO Number Required?</Form.Label>
              <Form.Value>
                {contract.po_number_required ? "Yes" : "No"}
              </Form.Value>
            </Form.Group>
            {contract.po_number_required && (
              <Form.Group>
                <Form.Label>Default PO Number</Form.Label>
                <Form.Value>
                  {contract.default_po_number}
                </Form.Value>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Self Billing?</Form.Label>
              <Form.Value>
                {contract.self_billing ? "Yes" : "No"}
              </Form.Value>
            </Form.Group>
            {contract.self_billing && (
              <Form.Group>
                <Form.Label>Self Billing Organisation</Form.Label>
                <Form.Value>
                  {contract.self_billing_org}
                </Form.Value>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Invoice Email Address</Form.Label>
              <Form.Value>
                {contract.invoice_email_address}
                {contract.invoice_email_addresses.map((email, i) => (
                  <div key={i} className={styles.email}>{email.copy_preference.toUpperCase()}: {email.email_address}</div>
                ))}
              </Form.Value>
            </Form.Group>
            <Form.Group>
              <Form.Label>Invoice Telephone Number</Form.Label>
              <Form.Value>
                {contract.invoice_telephone_number}
              </Form.Value>
            </Form.Group>
            <Form.Group>
              <Form.Label>Invoice Frequency</Form.Label>
              <Form.Value>
                {INVOICE_FREQUENCY_OPTIONS.find(x => x.value === contract.invoice_frequency)?.label}
              </Form.Value>
            </Form.Group>
            {contract.self_billing || (
              <>
                <Form.Group>
                  <Form.Label>Invoice Preference</Form.Label>
                  <Form.Value>
                    <List
                      identifier="invoice_preferences"
                      value={contract.invoice_preference}
                      // eslint-disable-next-line react/prop-types
                      customRenderer={props => props.data.values?.find(x => x.value === props.value)?.label || ""}
                    />
                  </Form.Value>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Invoice FAO</Form.Label>
                  <Form.Value>
                    {contract.invoice_fao}
                  </Form.Value>
                </Form.Group>
              </>
            )}
          </Form.Section>
          <Form.Label>Invoice Address</Form.Label>
          <Form.Group>
            <Form.Value>
              {contract.invoice_address.address_line_one ? _.compact([
                contract.invoice_address?.address_line_one,
                contract.invoice_address?.address_line_two,
                contract.invoice_address?.town_or_city,
                contract.invoice_address?.county,
                contract.invoice_address?.postcode,
              ]).map((address, i) => (
                <div key={i} className={styles.addressLine}>{address}</div>
              )) : ""}
            </Form.Value>
          </Form.Group>
        </div>
        <div>
          <Form.Section title="Payment Terms" variant="h3">
            <Form.Group>
              <Form.Label>Payment Due On</Form.Label>
              <Form.Value>
                {contract.payment_terms?.type === "day_of_month_after" && (
                  <>{moment(contract.payment_terms.value, "DD").format("Do")} day of the month, following invoice</>
                )}
                {contract.payment_terms?.type === "days_after_invoice" && (
                  <>{contract.payment_terms.value} days after invoice</>
                )}
                {contract.payment_terms?.type === "invoice_date" && (
                  <>Day of invoice (immediate)</>
                )}
              </Form.Value>
            </Form.Group>
          </Form.Section>
          <Form.Section title="Timesheet Groupings" variant="h3">
            <Form.Group>
              <Form.Label>Timesheet Groupings</Form.Label>
              <Form.Value>
                One Invoice Per <strong>{TIMESHEET_GROUPING_OPTIONS.find(x => x.value === contract.timesheet_groupings)?.label}</strong>
              </Form.Value>
            </Form.Group>
          </Form.Section>
          <Form.Section title="VAT Type" variant="h3">
            <Form.Group>
              <Form.Label>VAT Type</Form.Label>
              <Form.Value>
                {VAT_TYPE_OPTIONS.find(x => x.value === contract.vat_type)?.label}
              </Form.Value>
            </Form.Group>
          </Form.Section>
        </div>
      </div>
    </Form.Section>
  );
}

InvoicingDetailsSection.propTypes = {
  contract: PropTypes.shape({
    invoice_email_address: PropTypes.string,
    invoice_email_addresses: PropTypes.arrayOf(PropTypes.shape({
      copy_preference: PropTypes.string,
      email_address: PropTypes.string,
    })),
    invoice_telephone_number: PropTypes.string,
    invoice_frequency: PropTypes.oneOf(INVOICE_FREQUENCY_OPTIONS.map(x => x.value)).isRequired,
    po_number_required: PropTypes.bool,
    default_po_number: PropTypes.string,
    self_billing: PropTypes.bool,
    self_billing_org: PropTypes.string,
    invoice_preference: PropTypes.string.isRequired,
    invoice_fao: PropTypes.string,
    invoice_address: PropTypes.shape({
      address_line_one: PropTypes.string.isRequired,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }),
    payment_terms: PropTypes.shape({
      type: PropTypes.oneOf(PAYMENT_TERM_OPTIONS.map(x => x.value)),
      value: PropTypes.number,
    }),
    timesheet_groupings: PropTypes.oneOf(TIMESHEET_GROUPING_OPTIONS.map(x => x.value)).isRequired,
    vat_type: PropTypes.oneOf(VAT_TYPE_OPTIONS.map(x => x.value)).isRequired,
  }),
};
