import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { actions } from "../../duck";
import NewRegisteredCompanyPage from "./NewRegisteredCompanyPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  initialQuestions: ownProps.location?.state?.data,
  registeredCompanyNumber: ownProps.location?.state?.data?.registeredCompanyNumber || ownProps.location?.state?.data?.companyRegistrationNumber,
  exactLegalName: ownProps.location?.state?.data?.exactLegalName,
  ...ownProps.location?.state?.data,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onCancel: (initialQuestions) => {
    dispatch(push("/credit-limit-management/perform-credit-check", {
      data: initialQuestions,
    }));
  },
  onGoBack: () => {
    dispatch(goBack());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRegisteredCompanyPage);
