import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DOWNLOAD_AGENCY_ONBOARDING_DOCUMENT = gql`
query($input: AgencyDocumentUrlInput!) {
  getAgencyOnboardingDocumentDownloadUrl(input: $input) {
    url
  }
}
`;

export const downloadAgencyOnboardingDocument = (agency_id, document_type, version_id) => async (dispatch) => {
  try {
    dispatch({
      type: types.DOWNLOAD_ONBOARDING_DOCUMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: DOWNLOAD_AGENCY_ONBOARDING_DOCUMENT,
      variables: {
        input: {
          agency_id,
          document_type,
          version_id,
        },
      },
    });
    const url = response.data.getAgencyOnboardingDocumentDownloadUrl.url;

    window.open(url, "_blank");

    dispatch({
      type: types.DOWNLOAD_ONBOARDING_DOCUMENTS_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.DOWNLOAD_ONBOARDING_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
