import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const REMOVE_AGENCY_DOCUMENT = gql`
mutation($input: AgencyDocumentUrlInput!) {
  removeAgencyDocument(input: $input)
}
`;

export const removeAgencyDocument = (agency_id, document) => async (dispatch) => {
  try {
    dispatch({
      type: types.REMOVE_DOCUMENT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: REMOVE_AGENCY_DOCUMENT,
      variables: {
        input: {
          agency_id,
          document_type: document.name,
          version_id: document.versionId,
        },
      },
    });

    if (!response.data.removeAgencyDocument) {
      throw `Failed to remove ${document.name}`;
    }

    dispatch({
      type: types.REMOVE_DOCUMENT_SUCCESS,
      payload: {
        document,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.REMOVE_DOCUMENT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
