import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import styles from "./AgencyHeading.module.scss";

export default class AgencyHeading extends PureComponent {

  static propTypes = {
    className: PropTypes.any,
    loading: PropTypes.bool,
    agency: PropTypes.shape({
      shortCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      operaInfo: PropTypes.shape({
        version: PropTypes.string,
        company: PropTypes.string,
      }),
    }).isRequired,
  }

  render() {
    return this.props.loading ? (
      <div className={classnames(styles.container, this.props.className)}>
        <ActivityIndicator />
      </div>
    ) : (
      <div className={classnames(styles.container, this.props.className)}>
        <img className={styles.logo} src={this.props.agency.logoUrl || `https://eu.ui-avatars.com/api/?name=${this.props.agency.name.split(" ").join("+")}`} />
        <div className={styles.headerContent}>
          <div className={styles.headerPrimary}>
            <div className={styles.name}>{this.props.agency.name}</div>
            <div className={styles.shortCode}>({this.props.agency.shortCode})</div>
          </div>
          {this.renderOperaInfo()}
        </div>
      </div>
    );
  }

  renderOperaInfo = () => {
    const { agency: { operaInfo } } = this.props;

    if (!operaInfo) {
      return null;
    }

    const { version, company } = operaInfo;

    return version && company && (
      <div className={styles.operaInfo}>
        System {version} | Company {company}
      </div>
    );
  }

}
