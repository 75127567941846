export const GET_LEGAL_ENTITIES_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITIES_PENDING";
export const GET_LEGAL_ENTITIES_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITIES_SUCCESS";
export const GET_LEGAL_ENTITIES_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITIES_FAILURE";
export const ADD_LEGAL_ENTITIES_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_LEGAL_ENTITIES_PENDING";
export const ADD_LEGAL_ENTITIES_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_LEGAL_ENTITIES_SUCCESS";
export const ADD_LEGAL_ENTITIES_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_LEGAL_ENTITIES_FAILURE";
export const SEARCH_LEGAL_ENTITIES_PENDING = "CREDIT_LIMIT_MANAGEMENT/SEARCH_LEGAL_ENTITIES_PENDING";
export const SEARCH_LEGAL_ENTITIES_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/SEARCH_LEGAL_ENTITIES_SUCCESS";
export const SEARCH_LEGAL_ENTITIES_FAILURE = "CREDIT_LIMIT_MANAGEMENT/SEARCH_LEGAL_ENTITIES_FAILURE";
export const SET_PAGINATION = "CREDIT_LIMIT_MANAGEMENT/SET_PAGINATION";
export const SEARCH_COMPANIES_HOUSE_PENDING = "CREDIT_LIMIT_MANAGEMENT/SEARCH_COMPANIES_HOUSE_PENDING";
export const SEARCH_COMPANIES_HOUSE_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/SEARCH_COMPANIES_HOUSE_SUCCESS";
export const SEARCH_COMPANIES_HOUSE_FAILURE = "CREDIT_LIMIT_MANAGEMENT/SEARCH_COMPANIES_HOUSE_FAILURE";
export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING";
export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS";
export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE";
export const GET_LEGAL_ENTITY_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_PENDING";
export const GET_LEGAL_ENTITY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_SUCCESS";
export const GET_LEGAL_ENTITY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_FAILURE";
export const GET_LEGAL_ENTITY_DETAILS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_DETAILS_PENDING";
export const GET_LEGAL_ENTITY_DETAILS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_DETAILS_SUCCESS";
export const GET_LEGAL_ENTITY_DETAILS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_DETAILS_FAILURE";
export const GET_LEGAL_ENTITY_HISTORY_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_HISTORY_PENDING";
export const GET_LEGAL_ENTITY_HISTORY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_HISTORY_SUCCESS";
export const GET_LEGAL_ENTITY_HISTORY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_LEGAL_ENTITY_HISTORY_FAILURE";
export const UPDATE_LEGAL_ENTITY_PENDING = "CREDIT_LIMIT_MANAGEMENT/UPDATE_LEGAL_ENTITY_PENDING";
export const UPDATE_LEGAL_ENTITY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UPDATE_LEGAL_ENTITY_SUCCESS";
export const UPDATE_LEGAL_ENTITY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UPDATE_LEGAL_ENTITY_FAILURE";
export const GET_CREDIT_DETAILS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_DETAILS_PENDING";
export const GET_CREDIT_DETAILS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_DETAILS_SUCCESS";
export const GET_CREDIT_DETAILS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_DETAILS_FAILURE";
export const TOGGLE_CREDIT_SUSPENSION_PENDING = "CREDIT_LIMIT_MANAGEMENT/TOGGLE_CREDIT_SUSPENSION_PENDING";
export const TOGGLE_CREDIT_SUSPENSION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/TOGGLE_CREDIT_SUSPENSION_SUCCESS";
export const TOGGLE_CREDIT_SUSPENSION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/TOGGLE_CREDIT_SUSPENSION_FAILURE";
export const CREATE_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_PENDING";
export const CREATE_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_SUCCESS";
export const CREATE_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_FAILURE";
export const CREATE_CREDIT_APPLICATION_COMMENT_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_COMMENT_PENDING";
export const CREATE_CREDIT_APPLICATION_COMMENT_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_COMMENT_SUCCESS";
export const CREATE_CREDIT_APPLICATION_COMMENT_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_APPLICATION_COMMENT_FAILURE";
export const GET_EXPERIAN_CREDIT_CHECKS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECKS_PENDING";
export const GET_EXPERIAN_CREDIT_CHECKS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECKS_SUCCESS";
export const GET_EXPERIAN_CREDIT_CHECKS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECKS_FAILURE";
export const GET_CONTRACTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACTS_PENDING";
export const GET_CONTRACTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACTS_FAILURE";
export const GET_CONTRACT_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_PENDING";
export const GET_CONTRACT_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_FAILURE";
export const GET_CREDIT_ACTIVITY_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_ACTIVITY_PENDING";
export const GET_CREDIT_ACTIVITY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_ACTIVITY_SUCCESS";
export const GET_CREDIT_ACTIVITY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_ACTIVITY_FAILURE";
export const GET_CREDIT_APPLICATIONS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATIONS_PENDING";
export const GET_CREDIT_APPLICATIONS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATIONS_SUCCESS";
export const GET_CREDIT_APPLICATIONS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATIONS_FAILURE";
export const GET_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_PENDING";
export const GET_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_SUCCESS";
export const GET_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_FAILURE";
export const COMPLETE_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/COMPLETE_CREDIT_APPLICATION_PENDING";
export const COMPLETE_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/COMPLETE_CREDIT_APPLICATION_SUCCESS";
export const COMPLETE_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/COMPLETE_CREDIT_APPLICATION_FAILURE";
export const UPDATE_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CREDIT_APPLICATION_PENDING";
export const UPDATE_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CREDIT_APPLICATION_SUCCESS";
export const UPDATE_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CREDIT_APPLICATION_FAILURE";
export const CANCEL_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CANCEL_CREDIT_APPLICATION_PENDING";
export const CANCEL_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CANCEL_CREDIT_APPLICATION_SUCCESS";
export const CANCEL_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CANCEL_CREDIT_APPLICATION_FAILURE";
export const USE_DISCRETIONARY_LIMIT_PENDING = "CREDIT_LIMIT_MANAGEMENT/USE_DISCRETIONARY_LIMIT_PENDING";
export const USE_DISCRETIONARY_LIMIT_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/USE_DISCRETIONARY_LIMIT_SUCCESS";
export const USE_DISCRETIONARY_LIMIT_FAILURE = "CREDIT_LIMIT_MANAGEMENT/USE_DISCRETIONARY_LIMIT_FAILURE";
export const ADD_INSURER_REPORTING_ACTIVITY_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_INSURER_REPORTING_ACTIVITY_PENDING";
export const ADD_INSURER_REPORTING_ACTIVITY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_INSURER_REPORTING_ACTIVITY_SUCCESS";
export const ADD_INSURER_REPORTING_ACTIVITY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_INSURER_REPORTING_ACTIVITY_FAILURE";
export const UNREPORT_INSURER_REPORTING_ACTIVITY_PENDING = "CREDIT_LIMIT_MANAGEMENT/UNREPORT_INSURER_REPORTING_ACTIVITY_PENDING";
export const UNREPORT_INSURER_REPORTING_ACTIVITY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UNREPORT_INSURER_REPORTING_ACTIVITY_SUCCESS";
export const UNREPORT_INSURER_REPORTING_ACTIVITY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UNREPORT_INSURER_REPORTING_ACTIVITY_FAILURE";
export const GET_INSURER_REPORTING_ACTIVITIES_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_INSURER_REPORTING_ACTIVITIES_PENDING";
export const GET_INSURER_REPORTING_ACTIVITIES_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_INSURER_REPORTING_ACTIVITIES_SUCCESS";
export const GET_INSURER_REPORTING_ACTIVITIES_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_INSURER_REPORTING_ACTIVITIES_FAILURE";

export const CREATE_SCHEDULED_CREDIT_ACTION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_CREDIT_ACTION_PENDING";
export const CREATE_SCHEDULED_CREDIT_ACTION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_CREDIT_ACTION_SUCCESS";
export const CREATE_SCHEDULED_CREDIT_ACTION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_CREDIT_ACTION_FAILURE";

export const GET_SCHEDULED_CREDIT_ACTIONS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTIONS_PENDING";
export const GET_SCHEDULED_CREDIT_ACTIONS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTIONS_SUCCESS";
export const GET_SCHEDULED_CREDIT_ACTIONS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTIONS_FAILURE";

export const GET_SCHEDULED_CREDIT_ACTION_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTION_PENDING";
export const GET_SCHEDULED_CREDIT_ACTION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTION_SUCCESS";
export const GET_SCHEDULED_CREDIT_ACTION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_CREDIT_ACTION_FAILURE";

export const CANCEL_SCHEDULED_CREDIT_ACTION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CANCEL_SCHEDULED_CREDIT_ACTION_PENDING";
export const CANCEL_SCHEDULED_CREDIT_ACTION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CANCEL_SCHEDULED_CREDIT_ACTION_SUCCESS";
export const CANCEL_SCHEDULED_CREDIT_ACTION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CANCEL_SCHEDULED_CREDIT_ACTION_FAILURE";

export const CREATE_SCHEDULED_EXPERIAN_ACTION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_EXPERIAN_ACTION_PENDING";
export const CREATE_SCHEDULED_EXPERIAN_ACTION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_EXPERIAN_ACTION_SUCCESS";
export const CREATE_SCHEDULED_EXPERIAN_ACTION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_SCHEDULED_EXPERIAN_ACTION_FAILURE";

export const GET_SCHEDULED_EXPERIAN_ACTIONS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_EXPERIAN_ACTIONS_PENDING";
export const GET_SCHEDULED_EXPERIAN_ACTIONS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_EXPERIAN_ACTIONS_SUCCESS";
export const GET_SCHEDULED_EXPERIAN_ACTIONS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_SCHEDULED_EXPERIAN_ACTIONS_FAILURE";

export const CREATE_CONTRACT_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_CONTRACT_PENDING";
export const CREATE_CONTRACT_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_CONTRACT_FAILURE";
export const GET_CONTRACT_CONTACTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_CONTACTS_PENDING";
export const GET_CONTRACT_CONTACTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_CONTACTS_SUCCESS";
export const GET_CONTRACT_CONTACTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CONTRACT_CONTACTS_FAILURE";
export const GET_TRADING_STYLES_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_TRADING_STYLES_PENDING";
export const GET_TRADING_STYLES_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_TRADING_STYLES_SUCCESS";
export const GET_TRADING_STYLES_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_TRADING_STYLES_FAILURE";
export const AGENCY_PROSPECT_SEARCH_PENDING = "CREDIT_LIMIT_MANAGEMENT/AGENCY_PROSPECT_SEARCH_PENDING";
export const AGENCY_PROSPECT_SEARCH_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/AGENCY_PROSPECT_SEARCH_SUCCESS";
export const AGENCY_PROSPECT_SEARCH_FAILURE = "CREDIT_LIMIT_MANAGEMENT/AGENCY_PROSPECT_SEARCH_FAILURE";
export const AGENCY_PROSPECT_SEARCH_CLEAR = "CREDIT_LIMIT_MANAGEMENT/AGENCY_PROSPECT_SEARCH_CLEAR";
export const AGENCY_CUSTOMER_SEARCH_PENDING = "CREDIT_LIMIT_MANAGEMENT/AGENCY_CUSTOMER_SEARCH_PENDING";
export const AGENCY_CUSTOMER_SEARCH_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/AGENCY_CUSTOMER_SEARCH_SUCCESS";
export const AGENCY_CUSTOMER_SEARCH_FAILURE = "CREDIT_LIMIT_MANAGEMENT/AGENCY_CUSTOMER_SEARCH_FAILURE";

export const ADD_REGISTERED_COMPANY_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_REGISTERED_COMPANY_PENDING";
export const ADD_REGISTERED_COMPANY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_REGISTERED_COMPANY_SUCCESS";
export const ADD_REGISTERED_COMPANY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_REGISTERED_COMPANY_FAILURE";

export const ADD_AGENCY_CUSTOMER_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_AGENCY_CUSTOMER_REQUEST_PENDING";
export const ADD_AGENCY_CUSTOMER_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_AGENCY_CUSTOMER_REQUEST_SUCCESS";
export const ADD_AGENCY_CUSTOMER_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_AGENCY_CUSTOMER_REQUEST_FAILURE";

export const UPDATE_AGENCY_CUSTOMER_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/UPDATE_AGENCY_CUSTOMER_REQUEST_PENDING";
export const UPDATE_AGENCY_CUSTOMER_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UPDATE_AGENCY_CUSTOMER_REQUEST_SUCCESS";
export const UPDATE_AGENCY_CUSTOMER_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UPDATE_AGENCY_CUSTOMER_REQUEST_FAILURE";

export const DELETE_AGENCY_CUSTOMER_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/DELETE_AGENCY_CUSTOMER_REQUEST_PENDING";
export const DELETE_AGENCY_CUSTOMER_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/DELETE_AGENCY_CUSTOMER_REQUEST_SUCCESS";
export const DELETE_AGENCY_CUSTOMER_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/DELETE_AGENCY_CUSTOMER_REQUEST_FAILURE";

export const REASSIGN_AGENCY_CUSTOMER_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/REASSIGN_AGENCY_CUSTOMER_REQUEST_PENDING";
export const REASSIGN_AGENCY_CUSTOMER_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/REASSIGN_AGENCY_CUSTOMER_REQUEST_SUCCESS";
export const REASSIGN_AGENCY_CUSTOMER_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/REASSIGN_AGENCY_CUSTOMER_REQUEST_FAILURE";

export const GET_COMPANY_DETAILS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANY_DETAILS_PENDING";
export const GET_COMPANY_DETAILS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANY_DETAILS_SUCCESS";
export const GET_COMPANY_DETAILS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_COMPANY_DETAILS_FAILURE";

export const UPDATE_COMPANY_DETAILS_PENDING = "CREDIT_LIMIT_MANAGEMENT/UPDATE_COMPANY_DETAILS_PENDING";
export const UPDATE_COMPANY_DETAILS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UPDATE_COMPANY_DETAILS_FAILURE";

export const GET_CONTACT_INFORMATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CONTACT_INFORMATION_PENDING";
export const GET_CONTACT_INFORMATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CONTACT_INFORMATION_SUCCESS";
export const GET_CONTACT_INFORMATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CONTACT_INFORMATION_FAILURE";

export const UPDATE_CONTACT_INFORMATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CONTACT_INFORMATION_PENDING";
export const UPDATE_CONTACT_INFORMATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CONTACT_INFORMATION_SUCCESS";
export const UPDATE_CONTACT_INFORMATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/UPDATE_CONTACT_INFORMATION_FAILURE";

export const GET_CREDIT_OVERVIEW_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_OVERVIEW_PENDING";
export const GET_CREDIT_OVERVIEW_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_OVERVIEW_SUCCESS";
export const GET_CREDIT_OVERVIEW_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_OVERVIEW_FAILURE";

export const GET_AGENCY_CUSTOMER_REQUESTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMER_REQUESTS_PENDING";
export const GET_AGENCY_CUSTOMER_REQUESTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMER_REQUESTS_SUCCESS";
export const GET_AGENCY_CUSTOMER_REQUESTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMER_REQUESTS_FAILURE";

export const GET_AGENCY_CUSTOMERS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMERS_PENDING";
export const GET_AGENCY_CUSTOMERS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMERS_SUCCESS";
export const GET_AGENCY_CUSTOMERS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CUSTOMERS_FAILURE";

export const VERIFY_AGENCY_CUSTOMER_PENDING = "CREDIT_LIMIT_MANAGEMENT/VERIFY_AGENCY_CUSTOMER_PENDING";
export const VERIFY_AGENCY_CUSTOMER_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/VERIFY_AGENCY_CUSTOMER_SUCCESS";
export const VERIFY_AGENCY_CUSTOMER_FAILURE = "CREDIT_LIMIT_MANAGEMENT/VERIFY_AGENCY_CUSTOMER_FAILURE";

export const ADD_EXPERIAN_CREDIT_CHECK_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_PENDING";
export const ADD_EXPERIAN_CREDIT_CHECK_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_SUCCESS";
export const ADD_EXPERIAN_CREDIT_CHECK_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_FAILURE";
export const ADD_EXPERIAN_CREDIT_CHECK_CLEAR = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_CLEAR";
export const ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_PENDING = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_PENDING";
export const ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_SUCCESS";
export const ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_FAILURE = "CREDIT_LIMIT_MANAGEMENT/ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_FAILURE";

export const GET_GLOBAL_DEFAULT_PENDING = "GET_GLOBAL_DEFAULT_PENDING";
export const GET_GLOBAL_DEFAULT_SUCCESS = "GET_GLOBAL_DEFAULT_SUCCESS";
export const GET_GLOBAL_DEFAULT_FAILURE = "GET_GLOBAL_DEFAULT_FAILURE";

export const GET_CREDIT_REQUESTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_REQUESTS_PENDING";
export const GET_CREDIT_REQUESTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_REQUESTS_SUCCESS";
export const GET_CREDIT_REQUESTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_REQUESTS_FAILURE";

export const GET_AGENCY_CONTACTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CONTACTS_PENDING";
export const GET_AGENCY_CONTACTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CONTACTS_SUCCESS";
export const GET_AGENCY_CONTACTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_AGENCY_CONTACTS_FAILURE";

export const SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_PENDING";
export const SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_SUCCESS";
export const SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/SEND_CREDIT_REQUEST_RESULT_NOTIFICATION_FAILURE";

export const DISTRIBUTE_CREDIT_PENDING = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_PENDING";
export const DISTRIBUTE_CREDIT_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_SUCCESS";
export const DISTRIBUTE_CREDIT_FAILURE = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_FAILURE";

export const GET_CREDIT_APPLICATION_FOR_APPROVAL_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_FOR_APPROVAL_PENDING";
export const GET_CREDIT_APPLICATION_FOR_APPROVAL_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_FOR_APPROVAL_SUCCESS";
export const GET_CREDIT_APPLICATION_FOR_APPROVAL_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CREDIT_APPLICATION_FOR_APPROVAL_FAILURE";

export const GET_CURRENT_TASKS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_CURRENT_TASKS_PENDING";
export const GET_CURRENT_TASKS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_CURRENT_TASKS_SUCCESS";
export const GET_CURRENT_TASKS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_CURRENT_TASKS_FAILURE";

export const CREATE_CREDIT_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REQUEST_PENDING";
export const CREATE_CREDIT_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REQUEST_SUCCESS";
export const CREATE_CREDIT_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REQUEST_FAILURE";

export const DECLINE_CREDIT_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/DECLINE_CREDIT_REQUEST_PENDING";
export const DECLINE_CREDIT_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/DECLINE_CREDIT_REQUEST_SUCCESS";
export const DECLINE_CREDIT_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/DECLINE_CREDIT_REQUEST_FAILURE";

export const CREATE_CREDIT_REVIEW_DATE_PENDING = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REVIEW_DATE_PENDING";
export const CREATE_CREDIT_REVIEW_DATE_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REVIEW_DATE_SUCCESS";
export const CREATE_CREDIT_REVIEW_DATE_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CREATE_CREDIT_REVIEW_DATE_FAILURE";

export const DISTRIBUTE_CREDIT_REQUEST_PENDING = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_REQUEST_PENDING";
export const DISTRIBUTE_CREDIT_REQUEST_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_REQUEST_SUCCESS";
export const DISTRIBUTE_CREDIT_REQUEST_FAILURE = "CREDIT_LIMIT_MANAGEMENT/DISTRIBUTE_CREDIT_REQUEST_FAILURE";

export const CHASE_CREDIT_APPLICATION_PENDING = "CREDIT_LIMIT_MANAGEMENT/CHASE_CREDIT_APPLICATION_PENDING";
export const CHASE_CREDIT_APPLICATION_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/CHASE_CREDIT_APPLICATION_SUCCESS";
export const CHASE_CREDIT_APPLICATION_FAILURE = "CREDIT_LIMIT_MANAGEMENT/CHASE_CREDIT_APPLICATION_FAILURE";

export const GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_PENDING = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_PENDING";
export const GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_SUCCESS = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_SUCCESS";
export const GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_FAILURE = "CREDIT_LIMIT_MANAGEMENT/GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_FAILURE";
