import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Divider } from "semantic-ui-react";
import ChangePasswordFormValidator from "./ChangePasswordFormValidator";
import { Button, ButtonGroup, Form } from "../../../common/components";
import styles from "./ChangePasswordForm.module.scss";

export default function ChangePasswordForm(props) {
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = e => setData({
    ...data,
    [e.target.name]: e.target.value,
  });

  const handleSave = () => {
    const validator = new ChangePasswordFormValidator(data);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(data);
  };

  return (
    <div className={classnames(styles.container, props.className)}>
      <Form className={classnames(styles.form, props.formClassName)}>
        <Form.Group error={errors?.currentPassword}>
          <Form.Label>Current Password</Form.Label>
          <Form.Input
            type="password"
            name="currentPassword"
            value={data.currentPassword}
            disabled={props.submitting}
            highlightError={errors?.currentPassword}
            onChange={handleInputChange}
            fluid
          />
        </Form.Group>
        <Form.Group error={errors?.newPassword}>
          <Form.Label>New Password</Form.Label>
          <Form.Input
            type="password"
            name="newPassword"
            value={data.newPassword}
            disabled={props.submitting}
            highlightError={errors?.newPassword}
            onChange={handleInputChange}
            fluid
          />
        </Form.Group>
        <Form.Group error={errors?.confirmPassword}>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Input
            type="password"
            name="confirmPassword"
            value={data.confirmPassword}
            disabled={props.submitting}
            highlightError={errors?.confirmPassword}
            onChange={handleInputChange}
            fluid
          />
        </Form.Group>
      </Form>
      <Divider />
      <ButtonGroup className={styles.actions}>
        <Button
          variant="primary"
          busy={props.submitting}
          disabled={props.submitting}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="outline-danger"
          disabled={props.submitting}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
}

ChangePasswordForm.propTypes = {
  className: PropTypes.string,
  formClassName: PropTypes.string,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
