import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { actions } from "../../duck";
import { ContractList } from "../../components";
import { Button, ItemCountSelector, Pager, SearchInput } from "../../../common/components";
import { sorting } from "../../../../utils";
import styles from "./ContractsLanding.module.scss";

export class ContractsLanding extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    legalEntityId: PropTypes.string.isRequired,
    contracts: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.array.isRequired,
      legalEntityId: PropTypes.string,
      searchText: PropTypes.string.isRequired,
      pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
      }),
      sortOrder: PropTypes.shape({
        agency: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
        title: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
        created_at: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
        created_by: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
      }),
    }).isRequired,
    getContracts: PropTypes.func.isRequired,
  }

  initialState = {
    searchText: "",
    page: 1,
    limit: 25,
    sortOrder: {
      agency: "NONE",
      title: "NONE",
      created_at: "NONE",
      created_by: "NONE",
    },
  }

  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  componentDidMount() {
    const { legalEntityId, contracts } = this.props;

    this.setState(legalEntityId === contracts.legalEntityId ? {
      searchText: contracts.searchText,
      page: contracts.pagination.page,
      limit: contracts.pagination.limit,
      sortOrder: {
        ...this.initialState.sortOrder,
        ...contracts.sortOrder,
      },
    } : this.initialState, this.runSearch);
  }

  render() {
    const { contracts, legalEntityId, match } = this.props;
    const { searchText } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.filterRow}>
          <SearchInput
            className={styles.searchInput}
            name="searchText"
            value={searchText}
            placeholder="Search Contracts"
            onChange={this.handleInputChanged}
            onSearch={this.handleSearch}
          />
          <NavLink to={`${match.url}/create`} className={styles.createContractNavLink}>
            <Button>Create Contract</Button>
          </NavLink>
        </div>
        {!contracts.loading && contracts.data.length === 0 && (
          <span className={styles.noResultsFound}>No Results Found....</span>
        )}
        {contracts.data.length > 0 && (
          <ContractList
            loading={contracts.loading}
            data={contracts.data.map(item => ({
              ...item,
              legal_entity: {
                id: legalEntityId,
              },
            }))}
            sortOrder={contracts.sortOrder}
            onColumnClick={this.handleColumnClicked}
            onItemClick={this.handleItemClicked}
          />
        )}
        {contracts.data.length > 0 && (
          <div className={styles.pagination}>
            <Pager
              pageCount={contracts.pagination.pages}
              selectedPage={contracts.pagination.page}
              onPageChanged={this.handlePageChanged}
            />
            <ItemCountSelector value={contracts.pagination.limit} onChange={this.handleItemCountChanged} />
          </div>
        )}
      </div>
    );
  }

  handleInputChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSearch = () => {
    this.setState({
      page: 1,
    }, this.runSearch);
  }

  handleColumnClicked = (name, sortOrder) => {
    const { contracts } = this.props;
    const newSortOrder = { ...contracts.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: sortOrder });

    this.setState({
      sortOrder: newSortOrder,
    }, this.runSearch);
  }

  handleItemClicked = (item) => {
    const { history, match } = this.props;

    history.push(`${match.url}/contracts/${item.id}`);
  }

  handlePageChanged = (n) => {
    this.setState({
      page: n,
    }, this.runSearch);
  }

  handleItemCountChanged = (e) => {
    this.setState({
      page: 1,
      limit: e.value,
    }, this.runSearch);
  }

  runSearch = async () => {
    const { legalEntityId, getContracts } = this.props;
    const { searchText, page, limit, sortOrder } = this.state;

    await getContracts({
      legalEntityId,
      pager: {
        page,
        limit,
      },
      sortOrder,
      searchText,
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractsLanding);
