import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AgencyDetailsFormValidator from "./AgencyDetailsFormValidator";
import { AgencyStatusUpdateConfirmationModal } from "../../";
import {
  Button,
  ButtonGroup,
  Form,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { tooltips } from "../../../../../constants";
import styles from "./AgencyDetailsForm.module.scss";

export default class AgencyDetailsForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    agencyDetails: PropTypes.shape({
      agencyId: PropTypes.string.isRequired,
      website: PropTypes.string,
      short_code: PropTypes.string.isRequired,
      known_as: PropTypes.string.isRequired,
      trading_as: PropTypes.string,
      agency_status: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      previous_provider: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      industry_sectors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    }).isRequired,
    disabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    history: PropTypes.shape({
      trading_as: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  static initialState = {
    showAgencyStatusUpdateModal: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        agencyId: props.agencyDetails?.id,
        website: props.agencyDetails?.website,
        known_as: props.agencyDetails?.known_as,
        trading_as: props.agencyDetails?.trading_as,
        agency_status: props.agencyDetails?.agency_status,
        previous_provider: props.agencyDetails?.previous_provider,
        industry_sectors: props.agencyDetails?.industry_sectors.map(industrySector => industrySector.name),
      },
      errors: {},
    };
  }

  render() {
    const { agencyDetails, disabledFields, history, onDisabledFieldClick, submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <div className={styles.container}>
        <Form className={styles.form}>
          <Form.Section title="Agency Details" underlined>
            <Form.Group inline wide error={errors?.known_as}>
              <Form.Label inline>Known As</Form.Label>
              <Form.Input
                name="known_as"
                value={data.known_as}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                onChange={this.handleInputChange}
                highlightError={errors?.known_as}
                maxLength={30}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.trading_as}>
              <Form.Label inline>
                <SeeHistoryTooltip variant="compact" data={history?.trading_as}>
                Trading As (Optional)
                </SeeHistoryTooltip>
              </Form.Label>
              <Form.Input
                name="trading_as"
                value={data.trading_as}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("trading_as")}
                showLock={disabledFields?.includes("trading_as")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("trading_as")}
                highlightError={errors?.trading_as}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.short_code}>
              <Form.Label inline>Short Code</Form.Label>
              <Form.Input
                name="short_code"
                value={agencyDetails?.short_code}
                disabled={true}
                showLock={true}
                tooltip={tooltips.CANNOT_CHANGE_SHORT_CODE_FIELD_TOOLTIP}
                size="medium"
                highlightError={errors?.short_code}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.industry_sectors}>
              <Form.Label inline note="(Optional)">Industry Sector</Form.Label>
              {disabledFields?.includes("industry_sectors") ? (
                <Form.Input
                  name="industry_sectors"
                  value={data.industry_sectors?.join(", ")}
                  size="large"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled
                  showLock
                  onLockClick={() => onDisabledFieldClick("industry_sectors")}
                />
              ) : (
                <Form.Value>
                  <List
                    identifier="industry_sectors"
                    value={data.industry_sectors}
                    size="large"
                    onChange={this.handleSelectedValuesChange}
                    highlightError={errors?.industry_sectors}
                  />
                </Form.Value>
              )}
            </Form.Group>
            <Form.Group inline wide error={errors?.agency_status}>
              <Form.Label>Agency Status</Form.Label>
              <Form.Value size="large">
                <List
                  identifier="agency_status"
                  value={data.agency_status?.value}
                  onChange={this.handleItemChange}
                  optionsParser={options => options.filter((option) => {
                    return this.getAllowedAgencyStatuses(agencyDetails?.agency_status?.value).includes(option.value);
                  })}
                  highlightError={errors?.agency_status}
                  fluid
                />
              </Form.Value>
            </Form.Group>
            <Form.Group inline wide error={errors?.previous_provider}>
              <Form.Label inline note="(Optional)">Previous Provider</Form.Label>
              <Form.Value>
                <List
                  identifier="previous_provider"
                  sort_order={{ field: "value", order: "asc" }}
                  value={data.previous_provider?.value}
                  size="large"
                  onChange={this.handleItemChange}
                  highlightError={errors?.previous_provider}
                />
              </Form.Value>
            </Form.Group>
            <Form.Group inline wide error={errors?.website}>
              <Form.Label inline note="(Optional)">Agency Website</Form.Label>
              <Form.Input
                name="website"
                value={data.website}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("website")}
                showLock={disabledFields?.includes("website")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("website")}
                highlightError={errors?.website}
              />
            </Form.Group>
          </Form.Section>
          {this.renderAgencyStatusConfirmationModal()}
        </Form>
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleSaveClicked}
              busy={submitting}
              disabled={submitting}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </>
        </ButtonGroup>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleItemChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleSelectedValuesChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.target.values,
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClicked = () => {
    const { data } = this.state;

    const validator = new AgencyDetailsFormValidator(data, this.props);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    if (this.shouldShowAgencyStatusUpdateWarning(data.agency_status)) {
      this.setState({
        showAgencyStatusUpdateModal: true,
      });
    }
    else {
      this.saveAgency();
    }
  }

  saveAgency = () => {
    const { data } = this.state;
    const { onSave } = this.props;

    onSave && onSave({
      ...data,
    });
  }

  shouldShowAgencyStatusUpdateWarning = (status) => {
    if (status?.value !== "left") return false;

    return status?.value !== this.props.agencyDetails.agency_status?.value;
  }

  renderAgencyStatusConfirmationModal = () => {
    return (
      <AgencyStatusUpdateConfirmationModal
        visible={this.state.showAgencyStatusUpdateModal}
        onCancel={() => {
          this.setState({
            showAgencyStatusUpdateModal: false,
          });
        }}
        onSubmit={() => {
          this.setState({
            showAgencyStatusUpdateModal: false,
          }, this.saveAgency);
        }}
      />
    );
  }

  getAllowedAgencyStatuses = (currentStatus) => {
    switch (currentStatus) {
      case "left":
        return ["left"];

      default:
        return ["live_active", "live_not_active", "left"];
    }
  };
}
