import * as types from "../types";

/**
 *
 * @param {object} obj
 * @param {boolean} obj.isVisible
 */
export const setChangePasswordModalVisibility = ({ isVisible }) => (dispatch)  => {
  dispatch({
    type: types.SET_CHANGE_PASSWORD_MODAL_VISIBILITY,
    payload: {
      isVisible,
    },
  });
};
