import _ from "lodash";

export default class AgencyTradingAddressFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (!this._isFieldDisabled("address_line_one")) {
      if (_.isEmpty(data?.address_line_one)) {
        errors = _.set(errors, "address_line_one", "Address Line 1 is required");
      }

      if (_.isEmpty(data?.town_or_city)) {
        errors = _.set(errors, "town_or_city", "Town / City is required");
      }

      if (_.isEmpty(data?.county)) {
        errors = _.set(errors, "county", "County is required");
      }

      if (_.isEmpty(data?.postcode)) {
        errors = _.set(errors, "postcode", "Postcode is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  _isFieldDisabled = (fieldName) => {
    return this.props?.disabledFields?.includes(fieldName);
  }
}
