import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Add, Remove } from "@material-ui/icons";
import { IconButton, Form } from "../../../common/components";
import styles from "./TradingStyleInput.module.scss";

export default class TradingStyleInput extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    addButtonLabel: PropTypes.string.isRequired,
    removeButtonLabel: PropTypes.string.isRequired,
    agencyId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      insert: props.value.length > 0,
    };
  }

  render() {
    const { addButtonLabel, removeButtonLabel, value, agencyId } = this.props;
    const { insert } = this.state;

    return (
      <div className={styles.container}>
        {insert &&
          <Form.FlexRow className={styles.compactRow}>
            <Form.Input
              className={styles.inlineInput}
              value={value}
              placeholder="New Style Name..."
              onChange={this.handleChange}
              maxLength={255}
            />
            <IconButton
              icon={(<Remove />)}
              iconPosition="left"
              className={styles.removeButton}
              onClick={this.handleToggleInsert}
              text={removeButtonLabel}
            />
          </Form.FlexRow>
        }
        {!insert &&
          <IconButton
            icon={(<Add />)}
            iconPosition="left"
            className={classNames(
              styles.addButton,
              !agencyId && styles.disabled,
            )}
            onClick={this.handleToggleInsert}
            text={addButtonLabel}
            disabled={!agencyId}
          />
        }
      </div>
    );
  }

  handleToggleInsert = () => {
    const { name, onChange } = this.props;
    const { insert } = this.state;

    this.setState({
      insert: !insert,
    });

    onChange({
      target: {
        name,
        value: "",
      },
    });
  };

  handleChange = (e) => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name,
        value: e.target.value,
      },
    });
  };
}
