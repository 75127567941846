import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dropdown, Grid, Popup } from "semantic-ui-react";
import { Archive, Delete, Edit, Email, MoreVert, Phone, StarRounded } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { formatNiNumber } from "../../../../../utils";
import styles from "./ContactListItem.module.scss";

export default function ContactListItem(props) {
  const renderNiNumber = () => {
    return props.contact.niNumber && (
      <div className={styles.contactRow}>
        <Popup
          trigger={<FontAwesomeIcon className={styles.contactIcon} icon={faAddressCard} />}
          offset={[-8, 0]}
        >
          NI Number
        </Popup>
        {formatNiNumber(props.contact.niNumber)}
      </div>
    );
  };

  const renderContactNumbers = () => {
    const contactNumbers = _.orderBy(props.contact.contactNumbers, ["isPreferredNumber"], ["desc"]);

    return contactNumbers.length > 0 && (
      <div className={styles.contactRow}>
        <Popup
          trigger={<Phone className={styles.contactNumberIcon} />}
          offset={[-8, 0]}
        >
          Telephone Number
        </Popup>
        <div className={styles.contactNumbers}>
          {contactNumbers.map((contactNumber, index) => (
            <div
              key={`contactNumber_${index}`}
              className={classnames(
                styles.contactNumber,
                contactNumber.isPreferredNumber && styles.preferred,
              )}
            >
              <div
                className={styles.phoneNumberType}>
                {contactNumber.phoneNumberType.label}:
              </div>
              <div className={styles.phoneNumber}>
                {contactNumber.phoneNumber?.replace(/\s+/g, "")}
              </div>
              <Popup
                trigger={(
                  <StarRounded
                    className={classnames(
                      styles.preferredIcon,
                      contactNumber.isPreferredNumber && styles.preferred,
                    )}
                  />
                )}
                offset={[-8, 0]}
                disabled={!contactNumber.isPreferredNumber}
              >
                Preferred Phone Number
              </Popup>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEmailAddress = () => {
    return props.contact.emailAddress && (
      <div className={styles.contactRow}>
        <Popup
          trigger={<Email className={styles.contactIcon} />}
          offset={[-8, 0]}
        >
          Email Address
        </Popup>
        {props.contact.emailAddress}
      </div>
    );
  };

  const renderActions = () => {
    if (props.readOnly) {
      return null;
    }

    if (props.onArchiveClick) {
      return (
        <Dropdown
          icon={<MoreVert className={styles.actionIcon} />}
          direction="left"
        >
          <Dropdown.Menu>
            {props.onEditClick && (
              <Dropdown.Item onClick={props.onEditClick}>
                <div className={styles.actionRow}>
                  <Edit className={styles.editIcon} />
                  <span className={styles.actionText}>Edit</span>
                </div>
              </Dropdown.Item>
            )}
            {!props.contact.primaryContact && (
              <Dropdown.Item onClick={props.onArchiveClick}>
                <div className={styles.actionRow}>
                  <Archive className={styles.archiveIcon} />
                  <span className={styles.actionText}>Archive</span>
                </div>
              </Dropdown.Item>
            )}
            {!props.contact.primaryContact && props.onDeleteClick && (
              <Dropdown.Item onClick={props.onDeleteClick}>
                <div className={styles.actionRow}>
                  <Delete className={styles.deleteIcon} />
                  <span className={styles.actionText}>Delete</span>
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <div className={styles.archiveActionRow}>
        {props.onEditClick && (
          <Edit className={styles.editIcon} onClick={props.onEditClick} />
        )}
        {props.onDeleteClick && (
          <Delete className={styles.deleteIcon} onClick={props.onDeleteClick} />
        )}
      </div>
    );
  };

  return (
    <Grid.Row className={styles.container} columns={2}>
      <Grid.Column width={14}>
        <div className={styles.contactRow}>
          <span className={styles.fullName}>
            {`${props.contact.firstName} ${props.contact.lastName}`.trim()}
            {props.contact.preferredName && ` (${props.contact.preferredName})`}
          </span>
          {props.contact.jobRole && (
            <span className={styles.jobRole}>
              {props.contact.jobRole}
            </span>
          )}
        </div>
        {renderNiNumber()}
        {renderContactNumbers()}
        {renderEmailAddress()}
        {(!props.readOnly || props.contact.primaryContact) && (
          <div className={styles.contactRow}>
            {props.contact.primaryContact && (
              <span className={styles.primaryContact}>
                Primary Contact
              </span>
            )}
            {!props.contact.primaryContact && props.onMakePrimaryContactClick && (
              <span className={styles.makePrimaryContact} onClick={props.onMakePrimaryContactClick}>
                Make Primary Contact
              </span>
            )}
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={2} textAlign="right">
        {renderActions()}
      </Grid.Column>
    </Grid.Row>
  );
}

ContactListItem.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    preferredName: PropTypes.string,
    jobRole: PropTypes.string,
    telephoneNumber: PropTypes.string,
    contactNumbers: PropTypes.arrayOf(PropTypes.shape({
      phoneNumberType: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      isPreferredNumber: PropTypes.bool.isRequired,
    })),
    emailAddress: PropTypes.string,
    primaryContact: PropTypes.bool,
    niNumber: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  onEditClick: PropTypes.func,
  onArchiveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onMakePrimaryContactClick: PropTypes.func,
};
