import React from "react";
import PropTypes from "prop-types";
import { ErrorOutline } from "@material-ui/icons";
import { Alert, Form } from "../../../../../common/components";
import styles from "./RemoveForm.module.scss";

export default function RemoveForm (props) {
  const isCreditDistributed = () => {
    return props.currentAmountDistributed > 0;
  };

  return (
    <>
      <Form.Group inline className={styles.group} error={props.errors.scheduledFor}>
        <Form.Label inline required>Date to take effect</Form.Label>
        <Form.Date
          name="scheduledFor"
          value={props.formData.scheduledFor}
          onChange={props.onChange}
          size="small"
          highlightError={props.errors.scheduledFor}
          fluid
        />
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline required>Source</Form.Label>
        <Form.Value>
          <Form.Select
            name="source"
            options={props.sourceOptions}
            fluid
            value={props.formData.source}
            onChange={props.onChange}
          />
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline className={styles.commentsLabel}>Comments</Form.Label>
        <Form.Value>
          <Form.TextArea
            placeholder="Enter comments..."
            name="comment"
            value={props.formData.comment}
            onChange={props.onChange}
            className={styles.textArea}
            rows={6}
            maxLength={255}
          />
        </Form.Value>
      </Form.Group>

      {isCreditDistributed() && (
        <Alert variant="error" showClose={false}>
          <div className={styles.error}>
            <ErrorOutline className={styles.errorIcon} />
            <div>
              <strong>Warning:</strong> The Credit Limit will be insufficient for the amount of credit you have distributed. Please check the Customer Accounts.
            </div>
          </div>
        </Alert>
      )}
    </>
  );
}

RemoveForm.propTypes = {
  currentAmountDistributed: PropTypes.number.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  sourceOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  errors: PropTypes.object.isRequired,
};
