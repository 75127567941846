import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { LegalEntityDetailsView, LegalEntityForm } from "../../components";
import styles from "./DetailsTab.module.scss";

export default class DetailsTab extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    legalEntityDetails: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
    }),
    legalEntityHistory: PropTypes.shape({
      data: PropTypes.array,
    }),
    getLegalEntityDetails: PropTypes.func.isRequired,
    getLegalEntityHistory: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getLegalEntityDetails, getLegalEntityHistory } = this.props;

    await getLegalEntityDetails({ legalEntityId });
    await getLegalEntityHistory({ legalEntityId });
  }

  render() {
    if (this.props.legalEntityDetails.loading) {
      return <ActivityIndicator />;
    }

    if (!this.props.legalEntityDetails?.data?.company_number) {
      return (
        <LegalEntityForm
          legalEntity={this.props.legalEntityDetails?.data}
          legalEntityHistory={this.props.legalEntityHistory.data}
          onSubmit={this.handleSubmit}
          onEdit={this.handleEdit}
          onCancel={this.handleCancel}
          readOnly
        />
      );
    }

    return (
      <LegalEntityDetailsView
        className={styles.container}
        legalEntity={this.props.legalEntityDetails?.data}
        legalEntityHistory={this.props.legalEntityHistory.data}
        onEditCompanyDetailsClick={() => this.handleEditClick("company-details")}
        onEditContactInformationClick={() => this.handleEditClick("contact-information")}
      />
    );
  }

  handleSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log("handleSubmit", data);
  }

  handleEdit = () => {
    // eslint-disable-next-line no-console
    console.log("handleEdit");
  }

  handleCancel = () => {
    // eslint-disable-next-line no-console
    console.log("handleCancel");
  }

  handleEditClick = (section) => {
    const { legalEntityId, onEditClick } = this.props;

    onEditClick(legalEntityId, section);
  }

}
