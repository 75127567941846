const MAPPINGS = {
  L: "Live",
  D: "Dormant",
  T: "Test",
};

/**
 * Converts a human-readable string into a value for the API
 *
 * @param {string} value
 */
const from = (value) => {
  return Object.keys(MAPPINGS).find(x => MAPPINGS[x] === value);
};

/**
 * Converts a value from the API into a human-readable string
 *
 * @param {string} value
 */
const to = (value) => {
  return MAPPINGS[value] || "Unknown";
};

export default {
  from,
  to,
};
