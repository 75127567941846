import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_LEGAL_ENTITY = gql`
query($id: ID!, $input: GetHistoryInput!) {
  getLegalEntity(id: $id) {
    id
    name
    company_type
    created_at
    credit_suspended
    credit_suspended_at
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit

    ...on RegisteredCompany {
      company_number
      company_status
      companies_house_info {
        company_name
      }
      experian_credit_checks(pager: { page: 1, limit: 1 }) {
        id
      }
    }
  }
  getHistory(input: $input) {
    created_at
  }
}
`;

export const getLegalEntity = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_LEGAL_ENTITY_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_LEGAL_ENTITY,
      variables: {
        id,
        input: {
          document_id: id,
          model: "LegalEntity",
          fields: ["credit_suspended"],
          limit: 1,
          order: "desc",
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const legalEntity = response.data.getLegalEntity;

    if (legalEntity.credit_suspended) {
      legalEntity.credit_suspended_at = response.data.getHistory[0]?.created_at;
    }

    dispatch({
      type: types.GET_LEGAL_ENTITY_SUCCESS,
      payload: {
        legalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LEGAL_ENTITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
