import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_PROSPECT = gql`
query($id: ID!) {
  getProspect(id: $id) {
    id
    known_as
    status

    is_new_agency
    is_existing_paye_scheme
    introduced_via_broker
    logo_url

    worker_types {
      name
    }

    agency {
      id
    }
  }
}
`;

export const getProspect = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PROSPECT_SUCCESS,
      payload: {
        prospect: response.data.getProspect,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECT_FAILURE,
      payload: {
        error,
      },
    });
  }
};
