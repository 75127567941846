import React, { Component } from "react";
import PropTypes from "prop-types";
import { MoreVert, GetApp, RemoveCircle } from "@material-ui/icons";
import { IconButton } from "../../../common/components";
import styles from "./ActionsBox.module.scss";

export default class ActionsBox extends Component {
  static propTypes = {
    onDownloadClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    canRemove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.actionRef = React.createRef();
  }

  state = { visible: false };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    if (this.actionRef.current && !this.actionRef.current.contains(event.target) && this.state.visible) {
      this.setState({ visible: false });
    }
  }

  render() {
    const { visible } = this.state;
    const downloadClicked = () => {
      this.setState({ visible: false });
      this.props.onDownloadClick();
    };
    const removeClicked = () => {
      this.setState({ visible: false });
      this.props.onRemoveClick();
    };
    return (
      <div className={styles.ActionContainer}>
        <MoreVert className={styles.MoreVert} onClick={() => this.setState({ visible: !visible })} />
        <div className={`${styles.ActionOverlay} ${visible && styles.visible}`} ref={this.actionRef}>
          <IconButton className={`${styles.DownloadButton} ${styles.ActionIconButton}`} icon={(<GetApp />)} text="Download" onClick={downloadClicked} />
          {this.props.canRemove && <IconButton className={`${styles.RemoveButton} ${styles.ActionIconButton}`} icon={(<RemoveCircle />)} text="Remove" onClick={removeClicked} />}
        </div>
      </div>
    );
  }
}
