import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./InsurerApplicationCreatedModal.module.scss";
import { toMoney } from "../../../../utils";

export default function InsurerApplicationCreatedModal (props) {

  const handleSubmit = () => {
    props.hideModal(InsurerApplicationCreatedModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Application Created"
      visible={props.visible}
      className={styles.container}
      submitText="OK"
      onSubmit={handleSubmit}
    >
      <div>
        A new insurer application has been created for the requested amount of {toMoney(props.requestedAmount)}
      </div>
    </ConfirmationModal>
  );
}

InsurerApplicationCreatedModal.propTypes = {
  requestedAmount: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

InsurerApplicationCreatedModal.displayName = "InsurerApplicationCreatedModal";
