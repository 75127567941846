import _ from "lodash";

const validateField = (rule, data) => {
  const value = data[rule.field];

  return rule.rules.map((rule) => {
    switch (rule.type) {
      case "required":
        return !value || value.toString().length === 0
          ? rule.message
          : null;

      case "requiredif": {
        const dependantFields = rule.dependsOn.some(field => data[field].length > 0);
        return dependantFields
          ? value && value.toString().length > 0
            ? null
            : rule.message
          : null;
      }

      case "requiredwhere": {
        return _.castArray(rule.condition).some(v => data[rule.dependsOn] === v)
          ? value && value.toString().length > 0
            ? null
            : rule.message
          : null;
      }

      case "greaterThanFieldValue":
        return rule.allowNull && !value
          ? null
          : value > data[rule.compareTo]
            ? null
            : rule.message;

      default:
        return null;
    }
  }).find(r => r !== null) || null;
};

export default validateField;
