/**
 * Get a string with an appropriate suffix, e.g 125MB, 1.2GB etc.
 *
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string} Returns a string with the appropriate suffix
 */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export default formatBytes;
