import React, { Component } from "react";
import PropTypes from "prop-types";
import { Add, Remove } from "@material-ui/icons";
import { IconButton, Form } from "../../../common/components";
import styles from "./EmailAddressInput.module.scss";

const COPY_PREFERENCES = [
  { value: "CC", label: "CC" },
  { value: "BCC", label: "BCC" },
];

export default class EmailAddressInput extends Component {

  static propTypes = {
    addButtonLabel: PropTypes.string.isRequired,
    removeButtonLabel: PropTypes.string.isRequired,
    emailAddresses: PropTypes.arrayOf(PropTypes.shape({
      email_address: PropTypes.string,
      copy_preference: PropTypes.oneOf([
        "CC", "BCC",
      ]),
    })).isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { addButtonLabel, removeButtonLabel, onAdd, onRemove, emailAddresses, onChange } = this.props;

    return (
      <div className={styles.container}>
        {emailAddresses.map((email, index) => (
          <Form.FlexRow key={index} className={styles.compactRow}>
            <Form.Select
              className={styles.inlineSelect}
              name="copy_preference"
              value={email.copy_preference || ""}
              options={COPY_PREFERENCES}
              onChange={e => onChange(e, index)}
            />
            <Form.Input
              className={styles.inlineInput}
              name="email_address"
              value={email.email_address}
              placeholder="Enter Email Address..."
              onChange={e => onChange(e, index)}
              maxLength={255}
            />
            <IconButton
              icon={(<Remove />)}
              iconPosition="left"
              className={styles.removeButton}
              onClick={() => onRemove(index)}
              text={removeButtonLabel}
            />
          </Form.FlexRow>
        ))}
        <IconButton
          icon={(<Add />)}
          iconPosition="left"
          className={styles.addButton}
          onClick={onAdd}
          text={addButtonLabel}
        />
      </div>
    );
  }
}
