import React from "react";
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";
import * as Tabs from "../../tabs";
import { Button, Heading, TabControl } from "../../../common/components";
import styles from "./TaskListPage.module.scss";

export default function TaskListPage(props) {
  const getDefaultTabIndex = () => {
    if (props.location.pathname.includes("/current-tasks")) return 0;
    props.history.replace(`${props.match.url}/current-tasks`);
    return 0;
  };

  const handleTabChanged = (tab) => {
    switch (tab) {
      case "Current Tasks":
        props.history.push(`${props.match.url}/current-tasks`);
        break;
    }
  };

  return (
    <div className={styles.container}>
      <Heading
        title="Task List"
        rightAccessory={(
          <Button variant="secondary" onClick={props.onBackToCreditLimitManagementClick}>
            Back to Credit Limit Management
          </Button>
        )}
        hideSeparator
      />
      <TabControl
        className={styles.tabControl}
        defaultIndex={getDefaultTabIndex()}
        onTabChanged={handleTabChanged}
      >
        <Link title="Current Tasks" to={`${props.match.url}/details`} />
        <Link title="History" disabled />
      </TabControl>
      <Switch>
        <Route exact path={`${props.match.path}/current-tasks`} component={Tabs.CurrentTasksTab} />
      </Switch>
    </div>
  );
}

TaskListPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onBackToCreditLimitManagementClick: PropTypes.func.isRequired,
};
