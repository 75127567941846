import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { ErrorOutline } from "@material-ui/icons";
import { Alert, Form } from "../../../../../common/components";
import { ExperianCreditReportUpload } from "../../../";
import { parseDate, toMoney } from "../../../../../../utils";
import styles from "./DecreaseForm.module.scss";

export default function DecreaseForm (props) {
  const isNewCreditLessThanDistributed = () => {
    const { formData, currentVatRate, currentAmountDistributed } = props;
    if (formData.newCreditLimit === null) return false;

    const newLimitWithVat = formData.newCreditLimit * (1 + Number(currentVatRate)/100);
    return newLimitWithVat - currentAmountDistributed < 0;
  };

  return (
    <>
      <Form.Group inline className={styles.currentLimitGroup}>
        <Form.Label inline>Current credit limit (Excl. VAT)</Form.Label>
        <Form.Value className={styles.fixedFormNumber}>
          {!_.isNil(props.currentCreditLimit) ? toMoney(props.currentCreditLimit) : "£-"}
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline required>Recording an Experian or Insurer limit</Form.Label>
        <div>
          <Form.Radio
            value="experian"
            name="typeOfCheck"
            checked={props.formData.typeOfCheck === "experian"}
            text="Experian"
            onChange={e => props.onChangeTypeOfCheck(e.target.value)}
          />
          <Form.Radio
            value="insurer"
            name="typeOfCheck"
            checked={props.formData.typeOfCheck === "insurer"}
            text="Insurer limit"
            onChange={e => props.onChangeTypeOfCheck(e.target.value)}
          />
        </div>
      </Form.Group>

      {props.formData.typeOfCheck === "experian" && (
        <Form.Group inline className={styles.group}>
          <Form.Label inline required>Is this as a result of Experian Monitoring?</Form.Label>
          <div>
            <Form.Radio
              value={true}
              name="isExperianMonitoring"
              checked={props.formData.isExperianMonitoring === true}
              text="Yes"
              onChange={props.onRadioChange}
            />
            <Form.Radio
              value={false}
              name="isExperianMonitoring"
              checked={props.formData.isExperianMonitoring === false}
              text="No"
              onChange={props.onRadioChange}
            />
          </div>
        </Form.Group>
      )}

      {props.formData.typeOfCheck !== null && (
        <>
          <Form.Group inline className={styles.group} error={props.errors.newCreditLimit}>
            <Form.Label inline required>Amount reducing credit to (Excl. VAT)</Form.Label>
            <Form.Number
              name="newCreditLimit"
              value={props.formData.newCreditLimit}
              placeholder="eg. £10,000"
              prefix="£"
              onChange={props.onChange}
              onBlur={props.onNewCreditLimitBlur}
              highlightError={props.errors.newCreditLimit}
              formatNumber
            />
          </Form.Group>

          <Form.Group inline className={styles.group} error={props.errors.scheduledFor}>
            <Form.Label inline required>Date to take effect</Form.Label>
            <Form.Value>
              <Form.Date
                name="scheduledFor"
                value={props.formData.scheduledFor}
                onChange={props.onChange}
                size="small"
                highlightError={props.errors.scheduledFor}
                min={parseDate(Date.now(), "YYYY-MM-DD")}
                fluid
              />
            </Form.Value>
          </Form.Group>
        </>
      )}

      {props.formData.typeOfCheck === "experian" && (
        <>
          <Form.Group inline>
            <Form.Label
              className={styles.documentLabel}
              required={!props.formData.isExperianMonitoring}
              inline
            >
              Documented reason for reduction<br />
              (Maximum 5GB)
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <ExperianCreditReportUpload
              name="experianCreditReport"
              value={props.formData.experianCreditReport}
              onChange={props.onChange}
              disabled={props.busy}
              error={props.errors.experianCreditReport}
            />
          </Form.Group>
        </>
      )}

      {props.formData.typeOfCheck !== null && (
        <>
          <Form.Group inline className={styles.group}>
            <Form.Label inline className={styles.commentsLabel}>Comments</Form.Label>
            <Form.Value>
              <Form.TextArea
                placeholder="Enter comments..."
                name="comment"
                value={props.formData.comment}
                onChange={props.onChange}
                className={styles.textArea}
                rows={6}
                maxLength={255}
              />
            </Form.Value>
          </Form.Group>
        </>
      )}

      {isNewCreditLessThanDistributed() && (
        <Alert variant="error" showClose={false}>
          <div className={styles.error}>
            <ErrorOutline className={styles.errorIcon} />
            <div>
              <strong>Warning:</strong> The Credit Limit will be insufficient for the amount of credit you have distributed. Please check the Customer Accounts.
            </div>
          </div>
        </Alert>
      )}
    </>
  );
}

DecreaseForm.propTypes = {
  busy: PropTypes.bool.isRequired,
  currentCreditLimit: PropTypes.number,
  currentAmountDistributed: PropTypes.number.isRequired,
  currentVatRate: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeTypeOfCheck: PropTypes.func.isRequired,
  onNewCreditLimitBlur: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  sourceOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  errors: PropTypes.object.isRequired,
};
