import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import {
  AddCircleOutline,
  UnfoldLess,
  UnfoldMore,
} from "@material-ui/icons";
import { ProspectList } from "../../components";
import { Heading, IconButton, ItemCountSelector, Pager, SearchInput } from "../../../common/components";
import { sorting } from "../../../../utils";
import styles from "./ProspectsPage.module.scss";

export default class ProspectsPage extends Component {

  static propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      pages: PropTypes.number.isRequired,
    }).isRequired,
    onSearch: PropTypes.func,
    onGoToProspect: PropTypes.func,
  }

  static defaultProps = {
    data: [],
    pagination: {
      page: 1,
      limit: 25,
      pages: 1,
    },
  }

  state = {
    searchText: "",
    page: 1,
    limit: 25,
    sortOrder: {
      knownAs: "ASC",
      contactName: "NONE",
      telephoneNumber: "NONE",
      emailAddress: "NONE",
      status: "NONE",
    },
    expandedProspects: [],
  }

  componentDidMount() {
    this.handleSearch();
  }

  render() {
    return (
      <Grid className={styles.container}>
        <Grid.Row>
          <Heading
            title="Prospects"
            rightAccessory={(
              <NavLink to="/onboarding/new/prospects">
                <IconButton text="Add New Prospect" icon={<AddCircleOutline />} onClick={() => { }} />
              </NavLink>
            )}
          />
        </Grid.Row>
        <Grid.Row className={styles.searchRow}>
          <SearchInput
            name="searchText"
            className={styles.searchInput}
            value={this.state.searchText}
            placeholder="Search Prospects"
            onChange={this.handleInputChanged}
            onSearch={this.handleSearch}
          />
          {this.state.expandedProspects.length === this.props.data?.length ? (
            <div
              className={styles.collapseExpand}
              onClick={() => this.setState({ expandedProspects: [] })}
            >
              <UnfoldLess className={styles.unfoldIcon} />
              Collapse All
            </div>
          ) : (
            <div
              className={styles.collapseExpand}
              onClick={() => this.setState({ expandedProspects: (this.props.data || []).map(prospect => prospect.id) })}
            >
              <UnfoldMore className={styles.unfoldIcon} />
              Expand All
            </div>
          )}
        </Grid.Row>
        <Grid.Row>
          <ProspectList
            data={this.props.data}
            expandedRows={this.state.expandedProspects}
            sortOrder={this.state.sortOrder}
            onColumnClick={this.handleColumnClicked}
            onRowClick={this.handleRowClicked}
          />
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column>
            <Pager
              pageCount={this.props.pagination.pages}
              selectedPage={this.props.pagination.page}
              onPageChanged={this.handlePageChanged}
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <ItemCountSelector
              value={this.props.pagination.limit}
              onChange={this.handleItemCountChanged}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleInputChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSearch = () => {
    const { onSearch } = this.props;
    const { searchText, page, limit, sortOrder } = this.state;

    onSearch && onSearch({
      pager: { page, limit },
      searchText,
      sortOrder,
    });
  }

  handleColumnClicked = (name, order) => {
    const newSortOrder = { ...this.state.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    this.setState({
      sortOrder: newSortOrder,
    }, this.handleSearch);
  }

  handleRowClicked = (expandedProspects) => {
    this.setState({ expandedProspects });
  }

  handlePageChanged = (page) => {
    this.setState({
      page,
    }, this.handleSearch);
  }

  handleItemCountChanged = (e) => {
    this.setState({
      page: 1,
      limit: e.value,
    }, this.handleSearch);
  }

}
