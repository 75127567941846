import gql from "graphql-tag";
import { apolloClient } from "../../apolloClient";

const query = gql`
query($input: AgencyDocumentUrlInput!) {
  getAgencyDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

const sizeToGigabytes = (size) => {
  return size / (1024 * 1024 * 1024);
};

export const uploadAgencyDocument = async (document, agency_id, path) => {
  const fileSize = sizeToGigabytes(document.size);

  if (fileSize > 5) {
    throw "You can only upload files up to 5GB";
  }

  const response = await apolloClient.query({
    query,
    variables: {
      input: {
        agency_id: agency_id,
        path: path,
      },
    },
  });
  const uploadUrl = response.data.getAgencyDocumentUploadUrl;

  await fetch(uploadUrl.url, {
    method: "PUT",
    body: document,
  });

  return uploadUrl;
};
